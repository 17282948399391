// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { Col, Radio, type RadioChangeEvent, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ImprRadio } from '@imprivata-cloud/components';
import {
  getAppTerminationConfig,
  updateAppTerminationConfig,
} from '../../../api/AppTermination/appTerminationService';
import { SettingsPageLayout } from '../components';
import SaveButton from '../../../components/page-layout/action-bar/SaveButton';
import CancelButton from '../../../components/page-layout/action-bar/CancelButton';
import SaveDiscardModal from '../../modals/save-discard-modal/SaveDiscardModal';
import InfoPopover from '../../../components/info-popover/InfoPopover';
import getConfig from '../../../appConfigUtils';
import TagInput from './components/TagInput';
import TerminateAllIcon from '../../../assets/svg/exclusion.svg?react';
import TerminateSomeIcon from '../../../assets/svg/inclusion.svg?react';
import DoNothingIcon from '../../../assets/svg/do-nothing.svg?react';
import styles from './AppTermination.module.less';
import { TERMINATION_MODES } from '../../../api/AppTermination/types';

type TerminationConfig = {
  terminationMode: TERMINATION_MODES;
  inclusionList: string[];
  exclusionList: string[];
};

const initialTerminationConfig: TerminationConfig = {
  terminationMode: TERMINATION_MODES.TERMINATE_NONE,
  inclusionList: [],
  exclusionList: [],
};

const AppTermination: React.FC = () => {
  const { t } = useTranslation();
  const [isPageTouched, setIsPageTouched] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { APP_TERMINATION_TERMINATE_ALL_OPTION_ENABLED } = getConfig();
  const [terminationConfig, setTerminationConfig] = useState<TerminationConfig>(
    initialTerminationConfig,
  );
  const [modifiedTerminationConfig, setModifiedTerminationConfig] =
    useState<TerminationConfig>(initialTerminationConfig);
  const [isConfigNeedsToBeFetched, setIsConfigNeedsToBeFetched] =
    useState(true);

  useEffect(() => {
    if (isConfigNeedsToBeFetched) {
      (async () => {
        try {
          const res = await getAppTerminationConfig();
          if (res?.appTerminationConfig) {
            const inclusions = res.appTerminationConfig.appTerminationInclusions
              .split(',')
              .filter(i => i);
            const exclusions = res.appTerminationConfig.appTerminationExclusions
              .split(',')
              .filter(i => i);
            setTerminationConfig(prev => ({
              ...prev,
              inclusionList: inclusions,
              exclusionList: exclusions,
              terminationMode: res.appTerminationConfig.appTerminationType,
            }));
          }
        } catch (err) {
          console.error('Error fetching app termination configuration:', err);
          throw err;
        }
      })();
    }
    setIsConfigNeedsToBeFetched(false);
  }, [isConfigNeedsToBeFetched]);

  useEffect(() => {
    setModifiedTerminationConfig(terminationConfig);
  }, [terminationConfig]);

  const onSave = () => {
    setIsPageTouched(false);
    setIsModalVisible(false);
    const appTerminationConfiguration = {
      appTerminationConfig: {
        appTerminationType: modifiedTerminationConfig.terminationMode,
        appTerminationInclusions:
          modifiedTerminationConfig.inclusionList.join(','),
        appTerminationExclusions:
          modifiedTerminationConfig.exclusionList.join(','),
      },
    };
    updateAppTerminationConfig(appTerminationConfiguration).catch(error => {
      console.error(
        'An error occurred while updating the app termination config:',
        error,
      );
    });
  };

  const onCancel = () => {
    setIsPageTouched(false);
    setIsModalVisible(false);
    setIsConfigNeedsToBeFetched(true);
  };

  const onChangeTerminationMode = (e: RadioChangeEvent) => {
    setModifiedTerminationConfig(prev => ({
      ...prev,
      terminationMode: e.target.value as TERMINATION_MODES,
    }));
    setIsPageTouched(true);
  };

  // When the user wants to leave without saving
  useEffect(() => {
    if (isPageTouched) {
      setIsModalVisible(true);
    }
  }, [isPageTouched]);

  const handleAppNameChange = (
    value: string[],
    type: 'inclusion' | 'exclusion',
  ) => {
    setModifiedTerminationConfig(prev => ({
      ...prev,
      [`${type}List`]: [...value],
    }));
    setIsPageTouched(true);
  };

  return (
    <SettingsPageLayout
      title={
        <>
          <SaveButton
            disabled={!isPageTouched}
            key="save-button"
            data-testid="save-button"
            onClick={onSave}
          />
          <CancelButton
            data-testid="cancel-button"
            key="cancel-button"
            onClick={onCancel}
            disabled={!isPageTouched}
          />
          <SaveDiscardModal
            title={t('app-termination.save-discard-modal.title')}
            cancelText={t('app-termination.save-discard-modal.discard')}
            okText={t('actions.save')}
            content={t('app-termination.save-discard-modal.content')}
            open={isModalVisible}
            onSave={() => {
              onSave();
            }}
            onDiscard={() => {
              onCancel();
            }}
          />
        </>
      }
    >
      <Row>
        <Col>
          <div data-testid="app-termination-page">
            <div
              className="h3-header"
              style={{ marginBottom: '44px', marginTop: '22px' }}
            >
              {t('app-termination.header')}
            </div>
            <Radio.Group
              onChange={onChangeTerminationMode}
              value={modifiedTerminationConfig.terminationMode}
            >
              {APP_TERMINATION_TERMINATE_ALL_OPTION_ENABLED && (
                <div className={'app-termination-terminate-all'}>
                  <Row>
                    <TerminateAllIcon className={styles.terminationIcon} />
                    <ImprRadio
                      value={TERMINATION_MODES.TERMINATE_ALL}
                      data-testid="app-termination-terminate-all-radio"
                    >
                      <Row align={'middle'}>
                        <span>{t('app-termination.terminate-all')}</span>
                        <InfoPopover
                          contentTranslationKey={
                            'app-termination.terminate-all-hint'
                          }
                          placement="right"
                          trigger="hover"
                        />
                      </Row>
                    </ImprRadio>
                  </Row>
                  <TagInput
                    containerTestid="app-termination-terminate-all-container"
                    inputTestid="app-termination-terminate-all-input"
                    value={modifiedTerminationConfig.exclusionList}
                    onChange={value => {
                      handleAppNameChange(value, 'exclusion');
                    }}
                    placeholder={t('app-termination.terminate-all-placeholder')}
                    disabled={
                      modifiedTerminationConfig.terminationMode !==
                      TERMINATION_MODES.TERMINATE_ALL
                    }
                    label={t('app-termination.exclusions')}
                  />
                </div>
              )}
              <div className="app-termination-terminate-some">
                <Row>
                  <TerminateSomeIcon className={styles.terminationIcon} />
                  <ImprRadio value={TERMINATION_MODES.TERMINATE_SOME}>
                    <Row align={'middle'}>
                      {t('app-termination.terminate-some')}
                      <InfoPopover
                        contentTranslationKey={
                          'app-termination.terminate-some-hint'
                        }
                        placement="right"
                        trigger="hover"
                      />
                    </Row>
                  </ImprRadio>
                </Row>
                <TagInput
                  containerTestid="app-termination-terminate-some-container"
                  inputTestid="app-termination-terminate-some-input"
                  value={modifiedTerminationConfig.inclusionList}
                  onChange={value => {
                    handleAppNameChange(value, 'inclusion');
                  }}
                  placeholder={t('app-termination.terminate-some-placeholder')}
                  disabled={
                    modifiedTerminationConfig.terminationMode !==
                    TERMINATION_MODES.TERMINATE_SOME
                  }
                  label={t('app-termination.inclusions')}
                />
              </div>
              <div className="app-termination-terminate-none">
                <Row>
                  <DoNothingIcon className={styles.terminationIcon} />
                  <ImprRadio value={TERMINATION_MODES.TERMINATE_NONE}>
                    <Row align={'middle'}>
                      {t('app-termination.do-nothing')}
                      <InfoPopover
                        contentTranslationKey={
                          'app-termination.do-nothing-hint'
                        }
                        placement="right"
                        trigger="hover"
                      />
                    </Row>
                  </ImprRadio>
                </Row>
              </div>
            </Radio.Group>
          </div>
        </Col>
      </Row>
    </SettingsPageLayout>
  );
};

export default AppTermination;
