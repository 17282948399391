// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Directories.module.less';

type Props = {
  totalSteps: number;
  steps: number;
};

const ProgressText: FC<Props> = ({ totalSteps, steps }) => {
  const { t } = useTranslation();
  const percentage = Math.round((steps * 100) / totalSteps);

  return (
    <p className={classes.progressText} data-testid="progress-text-test-id">
      {t('directories.progress-text', {
        value: percentage,
      })}
    </p>
  );
};

export default ProgressText;
