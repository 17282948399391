// Copyright 2022, Imprivata, Inc.  All rights reserved.

export enum ErrorMessages {
  'AUTHENTICATION_FAILED' = 'Authentication failed',
  'INCORRECT_TENANT_ID' = 'The Tenant ID is not valid. Please see your administrator.',
  'TENANT_ID_NO_PROVIDED' = 'No Tenant ID provided.',
  'SESSION_EXPIRED' = 'Session expired',
}

export enum ErrorCodes {
  'SESSION_EXPIRED' = 'session-expired',
  'IDP_DISABLED' = 'idp-disabled',
  'CREDENTIALS_EXPIRED' = 'credential-expired',
  'AMBIGUOUS_USERNAME' = 'ambiguous-username',
  'NO_PERMISSION' = 'UXID_ADMIN_NOTALLOWED',
  'GROUP_NOT_FOUND' = 'GROUP_NOT_FOUND',
  'GROUP_ALREADY_SYNC_ENABLED' = 'GROUP_ALREADY_SYNC_ENABLED',
}

// query parameter keys
export const QueryParamKey = {
  TENANT_ID: 'tenantId',
  SESSION_ID: 'session_id',
  AUTHN_API_VERSION: 'authnApiVersion',
};

export enum StorageKeys {
  SESSION_ID = 'SESSION_ID',
  IMPR_TENANT_TYPE = 'IMPR_TENANT_TYPE', // see TenantType (PRIMARY, TEST)
}

export enum TenantType {
  PRIMARY = 'PRIMARY',
  TEST = 'TEST',
}
