// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Banner, destroyBanners } from '@imprivata-cloud/components';
import { type RootState } from '../../../store/rootReducer';
import { usePersistantSessionState } from '../../../utils/hooks/usePersistantState';

import { transformSearchPattern } from '../../users/utils';
import { EndpointsSortValueEnum } from '../types';
import { EndpointFieldNames, getSortOptions } from '../utils';
import {
  getEndpointsListData,
  getEndpointsTotals,
  getInstallerData,
} from './actions';
import {
  type EndpointsListState,
  type EndpointsTotalsState,
  type InstallerDataState,
} from './reducers';
import { ErrorCode } from '../../../api/constants';

const ENDPOINTS_FILTER_VALUE_STORAGE_KEY = 'endpoints.endpoints-filter-value';

export const getInstallerDataState = (state: RootState): InstallerDataState => {
  return state.endpoints.installerData;
};

export function useInstallerData(): InstallerDataState {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const installerData = useSelector(getInstallerDataState);

  useEffect(() => {
    dispatch(getInstallerData.request());

    return () => {
      dispatch(getInstallerData.cancel());
    };
  }, [dispatch]);

  const installerError = installerData?.error;
  useEffect(() => {
    if (installerError) {
      const errorMsg = [
        'install-info-expired',
        'installation-token-not-found',
        ErrorCode.FATAL,
      ].includes(installerError)
        ? t(`endpoints.error-messages.${installerError}`)
        : installerError;

      Banner({
        type: 'error',
        message: errorMsg,
        duration: 10,
        datatestid: 'endpoints-banner',
      });
    } else {
      destroyBanners();
    }
    return () => {
      destroyBanners();
    };
  }, [installerError, t]);

  return installerData;
}

export function useEndpointsList(): EndpointsListState {
  const dispatch = useDispatch();
  const listState = useSelector<RootState, EndpointsListState>(
    ({ endpoints }) => endpoints.endpoints,
  );

  useEffect(() => {
    dispatch(
      getEndpointsListData.request({
        sortFields: [{ field: EndpointFieldNames.HOSTNAME }],
      }),
    );

    return () => {
      dispatch(getEndpointsListData.cancel());
    };
  }, [dispatch]);

  return listState;
}

export const useEndpointsPageState = (): {
  sortValue: EndpointsSortValueEnum;
  handleSortOrder: (value: EndpointsSortValueEnum) => void;
  handleSearchChange: (pattern: string) => void;
} => {
  const dispatch = useDispatch();
  const [searchPattern, setSearchPattern] = useState<string>('');
  const [sortValue, setFilterValue] =
    usePersistantSessionState<EndpointsSortValueEnum>(
      ENDPOINTS_FILTER_VALUE_STORAGE_KEY,
      EndpointsSortValueEnum.ALL_BY_NAME,
    );

  const handleSortOrder = (value: EndpointsSortValueEnum) => {
    setFilterValue(value);
  };

  const handleSearchChange = (value: string) => {
    setSearchPattern(value);
  };

  useEffect(() => {
    const pattern = transformSearchPattern(searchPattern);
    const sortOptions = getSortOptions(sortValue);

    dispatch(
      getEndpointsListData.request({
        search: {
          pattern,
          fields: [
            EndpointFieldNames.HOSTNAME,
            EndpointFieldNames.VERSION,
            EndpointFieldNames.CREATED_AT,
          ],
        },
        sortFields: [sortOptions],
      }),
    );
  }, [searchPattern, sortValue, dispatch]);

  return {
    sortValue,
    handleSortOrder,
    handleSearchChange,
  };
};

export const useEndpointsTotals = (): EndpointsTotalsState => {
  const dispatch = useDispatch();
  const totals = useSelector<RootState, EndpointsTotalsState>(
    ({ endpoints }) => endpoints.totals,
  );

  useEffect(() => {
    dispatch(
      getEndpointsTotals.request({
        sortFields: [{ field: EndpointFieldNames.HOSTNAME }],
      }),
    );

    return () => {
      dispatch(getEndpointsTotals.cancel());
    };
  }, [dispatch]);

  return totals;
};
