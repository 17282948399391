import React from 'react';
import { Link } from 'react-router-dom';
import EmptyStateIcon from '../../assets/svg/empty-state.svg?react';

import classes from './GroupEmptyStateContainer.module.less';

interface LinkProps {
  linkMessage: string;
  onLinkClick: () => void;
}

interface Props {
  header: string;
  operationLink?: LinkProps;
}

export const GroupEmptyStateContainer: React.FC<Props> = (props: Props) => {
  return (
    <div
      style={{
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '100px',
      }}
    >
      <EmptyStateIcon />
      <div className={classes.empyStateContainer}>
        <p>{props.header}</p>
        {props.operationLink && (
          <p>
            <Link
              to={''}
              onClick={e => {
                e.preventDefault();
                props.operationLink?.onLinkClick();
              }}
            >
              {props.operationLink?.linkMessage}
            </Link>
          </p>
        )}
      </div>
    </div>
  );
};
