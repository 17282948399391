// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import classes from './SyncFailDetailsModal.module.less';
import classesShared from '../ModalShared.module.less';
import {
  type DirectoriesSyncFailedPayload,
  type SyncFailureReason,
} from '../../../api/types';
import {
  DATE_TIME_FORMAT_OFFSET,
  formatDateForAstra,
  getLocalDateTime,
} from '../../../utils/DateUtils';

interface Props {
  failures: DirectoriesSyncFailedPayload;
  open: boolean;
  onClose?: () => void;
}

const SyncFailDetailsModal: React.FC<Props> = ({ onClose, open, failures }) => {
  const { t } = useTranslation();

  const getSyncFailureReason = useCallback(
    (failReason: SyncFailureReason) => {
      return t(`directories.sync-failure-reasons.${failReason}` as never);
    },
    [t],
  );

  return (
    <Modal
      className={clsx(classesShared.modal, classes.modal)}
      getContainer={() => document.body}
      open={open}
      data-testid="sync-fail-details-modal"
      onCancel={onClose}
      title={t('directories.modals.syncFailDetails.header')}
      footer={<></>}
    >
      <div className={classes.content}>
        {failures?.info?.map(failure => (
          <div
            className={classes.failEntry}
            key={`${failure.syncId}_${failure.failureReason}_${failure.endedAt}`}
          >
            <p>
              {formatDateForAstra(
                getLocalDateTime(failure.endedAt, DATE_TIME_FORMAT_OFFSET),
              )}
            </p>
            <p>{getSyncFailureReason(failure.failureReason)}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};
export default SyncFailDetailsModal;
