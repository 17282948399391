// Copyright 2022, Imprivata, Inc.  All rights reserved.

export enum UsersFilterValueEnum {
  ALL_BY_NAME,
  ADMINISTRATORS_BY_NAME,
}

export enum ApiUserFields {
  ROLE_TYPE = 'role-type',
}
export enum FilterOperators {
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  NOT_NULL = 'not-null',
}

export enum TagNames {
  ADMIN = 'admin',
}

export enum AuthenticatorType {
  PROX = 'prox',
  IMPRIVATA_ID = 'impr-id',
  IMPRIVATA_PIN = 'impr-pin',
}

export interface CredentialInfo {
  credentialId: string;
  appType: string;
  username: string;
  appName: string;
  displayName: string;
  alias?: string;
}
