// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import PageLayout from '../../components/page-layout/PageLayout';
import PageSubHeader from '../../components/page-layout/PageSubHeader';
import HelpButton from '../../components/page-layout/action-bar/HelpButton';
import ListItem from '../../components/list/ListItem';
import { useFindDirectories } from './store/hooks';
import { DIRECTORIES_ROUTE } from '../../routers/route-names';
import { LinkWithQuery } from '../../utils/routingHelpers';
import SetTitle from '../../utils/DynamicTitleHelper';
import DirectorySync from './DirectorySync';
import classes from './Directories.module.less';

const DirectoriesContainer: FC = () => {
  const { t } = useTranslation();
  SetTitle(t('navigation.directories'));

  const directories = useFindDirectories();

  return (
    <>
      <PageLayout
        title={t('navigation.directories')}
        data-testid="directories-page-container"
      >
        <PageSubHeader
          className={classes.leftHeader}
          data-testid="directories"
          title={
            <DirectorySync
              directory={(directories?.length && directories[0]) || undefined}
            />
          }
          extra={<HelpButton />}
        />
        {directories.map(directory => (
          <ListItem
            key={directory.directoryId}
            data-testid="directories-list-item"
            primaryText={directory.name}
            secondaryText={directory.idpTenantId}
            renderInMiddle={
              <LinkWithQuery
                to={`${DIRECTORIES_ROUTE}/${directory.directoryId}/groups`}
                data-testid="link-to-directory-groups"
                className={classes.groupsLabel}
              >
                {t('directories.group-count', {
                  count: directory.syncedGroupCount,
                })}
              </LinkWithQuery>
            }
          />
        ))}
      </PageLayout>
    </>
  );
};

export default DirectoriesContainer;
