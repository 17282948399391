// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEndpointsTotals } from './store/hooks';

const placeholderTitle = { width: 200 };

const EndpointsTotals: React.FC = function EndpointsTotals() {
  const { t } = useTranslation();
  const endpointsTotal = useEndpointsTotals();

  return (
    <Skeleton
      loading={endpointsTotal.loading}
      active
      paragraph={false}
      title={placeholderTitle}
    >
      <span data-testid="endpoints-total">
        <span className="primary-body-bold">
          {endpointsTotal.endpointsCount}
        </span>{' '}
        {t('endpoints.endpoint', { count: endpointsTotal.endpointsCount })}
      </span>
    </Skeleton>
  );
};

export default EndpointsTotals;
