// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type { AxiosResponse } from 'axios';
import type {
  IdpMetadataGetResponse,
  AadConfigurationDataGetResponse,
  IdpMetadataSaveRequest,
} from '../shared/types/api/identity-broker';
import { client } from './client';
import { IDENTITY_BROKER_SERVICE_PREFIX } from './endpoint-names';

export const getAadConfigurationData =
  async (): Promise<AadConfigurationDataGetResponse> => {
    const response = await client.post<
      AadConfigurationDataGetResponse,
      AxiosResponse<AadConfigurationDataGetResponse>
    >(
      `${IDENTITY_BROKER_SERVICE_PREFIX}/idp-metadata/aad-configuration-data/get`,
    );

    return response.data;
  };

export const getMetadata = async (): Promise<IdpMetadataGetResponse> => {
  const response = await client.post<
    IdpMetadataGetResponse,
    AxiosResponse<IdpMetadataGetResponse>,
    never
  >(`${IDENTITY_BROKER_SERVICE_PREFIX}/idp-metadata/get`);

  return response.data;
};

export const saveMetadata = async (
  payload: IdpMetadataSaveRequest,
): Promise<IdpMetadataSaveRequest> => {
  const response = await client.post<
    never,
    AxiosResponse<never>,
    IdpMetadataSaveRequest
  >(`${IDENTITY_BROKER_SERVICE_PREFIX}/idp-metadata/save`, payload);

  return response.data;
};
