// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { Layout } from 'antd';

import Header from '../layout/Header';
import classes from './PageLayout.module.less';

interface PageLayoutProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

const { Content } = Layout;

const PageLayout: React.FC<PageLayoutProps> = function PageLayout({
  title,
  children,
  className,
  contentClassName,
  ...props
}) {
  return (
    <Layout className={clsx(classes.layout, className)} {...props}>
      <Header data-testid="page-layout-header" title={title} />

      <Content
        data-testid="page-layout-content"
        className={clsx(
          classes.pageContent,
          contentClassName,
          'flex',
          'flex-column',
        )}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default PageLayout;
