// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  type ApiError2,
  type DirectoriesFindPayload,
  type DirectoriesSyncFailedPayload,
  type DirectorySyncProgressPayload,
} from '../../../api/types';

export const findDirectoriesActions = createAsyncAction(
  'directories/FIND_DIRECTORIES_REQUEST',
  'directories/FIND_DIRECTORIES_SUCCESS',
  'directories/FIND_DIRECTORIES_FAILURE',
  'directories/FIND_DIRECTORIES_CANCEL',
)<void, DirectoriesFindPayload, ApiError2, string | void>();

const syncAction = createAction('directory/SYNC_REQUEST')<void>();
const syncCompleteAction = createAction('directory/SYNC_COMPLETE')<void>();
const syncProgressAction = createAction(
  'directory/SYNC_PROGRESS',
)<DirectorySyncProgressPayload>();
const syncFailuresAction = createAction(
  'directory/SYNC_FAILURES',
)<DirectoriesSyncFailedPayload>();
const syncErrorAction = createAction('directory/SYNC_ERROR')<string | null>();

export const syncActions = {
  sync: syncAction,
  complete: syncCompleteAction,
  progress: syncProgressAction,
  failures: syncFailuresAction,
  error: syncErrorAction,
};
