// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers } from 'redux';
import { orgPreferencesReducer as orgPreferences } from '../containers/settings/appearance/store/reducers';
import {
  loginReducer as login,
  rehydrate as loginRehydrate,
} from '../containers/login/store/reducers';
import { initSetupReducer as initSetup } from '../containers/initial-setup/store/reducers';
import users from '../containers/users/store/reducer';
import apps from '../containers/applications/store/reducer';
import endpoints from '../containers/endpoints/store/reducers';
import { securityReducer as security } from '../containers/security/store/reducers';
import { directoriesReducer as directories } from '../containers/directories/store/reducers';
import { groupsReducer as groups } from '../containers/groups/store/reducers';

const rootReducer = combineReducers({
  orgPreferences,
  login,
  initSetup,
  users,
  apps,
  endpoints,
  security,
  directories,
  groups,
});

export function rehydrate(): Partial<RootState> {
  // retrieve auth info from storage
  return {
    login: loginRehydrate(),
  };
}

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
