// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, type Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { findGroups$, groupForSyncUnmark$ } from '../../../api/groupsService';
import { type RootAction } from '../../../store/rootAction';
import { type RootState } from '../../../store/rootReducer';
import {
  getGroups,
  getGroupsNextPortion,
  groupUnmarkForSync,
  startInitialSync,
} from './actions';
import { startInitialSync$ } from '../../../api/adminInitialSetupService';
import { type ApiError2 } from '../../../api/types';

const FIRST_PORTION_SIZE = 50;
const PORTION_SIZE = 25;

export const groupsEpic: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(getGroups.request)),
    mergeMap(({ payload }) =>
      findGroups$({
        selectors: payload,
        pageSize: FIRST_PORTION_SIZE,
        pageToken: undefined,
      }).pipe(
        switchMap(res => {
          return of(getGroups.success(res));
        }),
        catchError((err: ApiError2) => of(getGroups.failure(err))),
      ),
    ),
  );

export const groupsNextPortionEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(getGroupsNextPortion.request)),
    switchMap(() => {
      const portionToken = state$.value.groups.nextPortionToken;
      return findGroups$({
        pageToken: portionToken,
        pageSize: PORTION_SIZE,
      }).pipe(
        switchMap(res => {
          return of(getGroupsNextPortion.success(res));
        }),
        catchError((err: ApiError2) => of(getGroupsNextPortion.failure(err))),
      );
    }),
  );

export const groupSyncUnmarkEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(groupUnmarkForSync.request)),
    switchMap(({ payload }) => {
      return groupForSyncUnmark$({
        groupId: payload.groupId,
      }).pipe(
        switchMap(() => {
          return of(groupUnmarkForSync.success());
        }),
        catchError((err: ApiError2) => of(groupUnmarkForSync.failure(err))),
      );
    }),
  );

export const startInitialSyncEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(startInitialSync.request)),
    switchMap(({ payload }) => {
      return startInitialSync$({
        identityProviderId: payload.identityProviderId,
      }).pipe(
        switchMap(() => of(startInitialSync.success())),
        catchError((err: ApiError2) => of(startInitialSync.failure(err))),
      );
    }),
  );

export default combineEpics(
  groupsEpic,
  groupsNextPortionEpic,
  groupSyncUnmarkEpic,
  startInitialSyncEpic,
);
