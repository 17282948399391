// Copyright 2023, Imprivata, Inc.  All rights reserved.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import { Button, ButtonSize, ButtonVariant } from '@imprivata-cloud/components';
import { Spin, type RadioChangeEvent } from 'antd';
import { getApiApplication } from '../store/actions';
import editApplicationClasses from './EditApplicationModal.module.less';
import getConfig from '../../../appConfigUtils';
import {
  getApplicationByID,
  updateApplicationProfile,
} from '../../../api/Applications/appsService';
import { type GetApiProfiledApp } from '../store/types';
import SaveDiscardModal from '../../modals/save-discard-modal/SaveDiscardModal';
import EditApplicationForm from './EditApplicationForm';
import {
  CREDS_SOURCE,
  type EditApplicationModalProps,
  type ProfiledAppData,
  USERNAME_FORMAT,
} from './types';

const { EDIT_PROFILED_APPLICATIONS_ENABLED } = getConfig();

export const EditApplicationModal: React.FC<EditApplicationModalProps> = ({
  appId,
  displayName,
}: EditApplicationModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [changeConfirmation, setChangeConfirmation] = useState<boolean>(false);
  const [profiledApp, setProfiledApp] = useState<GetApiProfiledApp>({
    appId: '',
    appName: '',
    displayName: '',
    credSrcType: CREDS_SOURCE.OWN,
    usernameFormatType: USERNAME_FORMAT.SAM,
    createdAt: '',
    updatedAt: '',
  });
  const [appData, setAppData] = useState<ProfiledAppData>({
    appName: '',
    externalCreds: CREDS_SOURCE.OWN,
    usernameFormat: USERNAME_FORMAT.SAM,
  });

  const usernameFormatAvailable = appData.externalCreds !== CREDS_SOURCE.OWN;
  const { appName, externalCreds, usernameFormat } = appData;

  const startEditingApplication = async () => {
    try {
      const profiledAppData = await getApplicationByID(appId);
      setProfiledApp(profiledAppData?.app);
      const {
        app: { displayName, credSrcType, usernameFormatType },
      } = profiledAppData;
      setAppData({
        appName: displayName,
        externalCreds: credSrcType,
        usernameFormat: usernameFormatType,
      });
      setIsOpen(true);
    } catch (_) {
      console.error('failed to load application with id ' + appId);
      setIsOpen(false);
    }
  };

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAppData(prev => ({
      ...prev,
      appName: event.target.value,
    }));
  };

  const warnUser = (opt: boolean) => {
    setChangeConfirmation(opt);
  };

  const switchCredsSource = (e: RadioChangeEvent) => {
    warnUser(true);
    setAppData(prev => ({
      ...prev,
      externalCreds: e.target.value as CREDS_SOURCE,
    }));
  };

  const cancelCredSourceChange = () => {
    setAppData(prev => ({
      ...prev,
      externalCreds: profiledApp.credSrcType,
    }));
    warnUser(false);
  };

  const switchUsernameFormat = (e: RadioChangeEvent) => {
    setAppData(prev => ({
      ...prev,
      usernameFormat: e.target.value as USERNAME_FORMAT,
    }));
  };

  const saveChanges = () => {
    const { displayName, credSrcType, usernameFormatType } = profiledApp;
    setIsLoading(true);
    if (
      displayName === appName &&
      credSrcType === externalCreds &&
      usernameFormatType === usernameFormat
    ) {
      setIsOpen(false);
      setIsLoading(false);
    } else {
      void updateApplication({
        ...profiledApp,
        displayName: appName || displayName,
        credSrcType: externalCreds,
        usernameFormatType: usernameFormat || usernameFormatType,
      });
    }
  };

  const updateApplication = async (application: GetApiProfiledApp) => {
    try {
      await updateApplicationProfile(application);
      setIsOpen(false);
      dispatch(getApiApplication.request());
    } catch (_) {
      console.error('failed to update application with ID', appId);
    }
  };

  const discardChanges = () => {
    setIsOpen(false);
  };

  return EDIT_PROFILED_APPLICATIONS_ENABLED ? (
    <>
      {changeConfirmation && (
        <SaveDiscardModal
          title={t('apps.profile.edit.confirm-edit.title')}
          cancelText={t('apps.profile.edit.confirm-edit.discard')}
          okText={t('apps.profile.edit.confirm-edit.confirm')}
          content={t('apps.profile.edit.confirm-edit.content')}
          open={true}
          onSave={() => {
            warnUser(true);
          }}
          onDiscard={cancelCredSourceChange}
          closable={false}
        />
      )}
      <Button
        label={t('actions.edit')}
        variant={ButtonVariant.TEXT}
        size={ButtonSize.MAJOR}
        data-testid="open-edit-application-button"
        onClick={startEditingApplication}
      />
      <Modal
        className={editApplicationClasses.container}
        data-testid="edit-application-modal"
        centered={true}
        open={isOpen}
        closable={true}
        keyboard={true}
        onCancel={discardChanges}
        title={t('apps.profile.edit.title') + displayName}
        width={700}
        okText={t('actions.save')}
        cancelText={t('actions.cancel')}
        footer={
          <div className={editApplicationClasses.modalFooter}>
            <Button
              data-testid="application-save-changes-button"
              variant={ButtonVariant.PRIMARY}
              onClick={saveChanges}
              label={t('actions.save')}
              disabled={isLoading}
            />
            <Button
              data-testid="application-discard-changes-button"
              variant={ButtonVariant.SECONDARY}
              onClick={discardChanges}
              label={t('actions.cancel')}
              disabled={isLoading}
            />
          </div>
        }
      >
        {isLoading ? (
          <div className={editApplicationClasses.spinner}>
            <Spin size="large" data-testid="updating-application-spinner" />
          </div>
        ) : (
          <EditApplicationForm
            displayName={displayName}
            handleDisplayNameChange={handleDisplayNameChange}
            credsSourceValue={externalCreds}
            switchCredsSource={switchCredsSource}
            usernameFormatAvailable={usernameFormatAvailable}
            usernameFormatValue={usernameFormat || USERNAME_FORMAT.SAM}
            switchUsernameFormat={switchUsernameFormat}
          />
        )}
      </Modal>
    </>
  ) : null;
};

export default EditApplicationModal;
