import { type DashboardData } from './types';
import * as apiTypes from '../../api/Dashboard/types';
import { type NonEmptyArray } from '../../utils/type-helpers';

export const INITIAL_DATASETS: NonEmptyArray<apiTypes.DashboardDataType> = [
  apiTypes.DashboardDataType.AUTHNS,
  apiTypes.DashboardDataType.AUTHNS_METRICS,
  apiTypes.DashboardDataType.ENROLLED_METRICS,
  apiTypes.DashboardDataType.EPCS_READINESS,
  apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_METRICS,
  apiTypes.DashboardDataType.SSO_AUTHS_METRICS,
  // we've currently stopped consuming this dataset, maybe temporarily
  // apiTypes.DashboardDataType.SSO_AUTHS,
];

export const INITIAL_DATA_FETCH_INTERVAL: apiTypes.DashboardDataInterval =
  apiTypes.DashboardDataInterval.PREVIOUS_7_DAYS;

export const INITIAL_UNIQUE_USER_INTERVAL: apiTypes.UniqueUsersIntervalType =
  apiTypes.DashboardDataInterval.UNIQUE_USERS_CURRENT_MONTH;

export const defaultDatasetTypes: Array<keyof DashboardData> = [
  apiTypes.DashboardDataType.AUTHNS,
  apiTypes.DashboardDataType.AUTHNS_DETAILS,
  apiTypes.DashboardDataType.AUTHNS_METRICS,
  apiTypes.DashboardDataType.SSO_AUTHS_METRICS,
  apiTypes.DashboardDataType.SSO_AUTHS_DETAILS,
  // we've currently stopped consuming this dataset, maybe temporarily
  // apiTypes.DashboardDataType.SSO_AUTHS,
];

export const enrollmentDatasetTypes: Array<keyof DashboardData> = [
  apiTypes.DashboardDataType.ENROLLED_METRICS,
  apiTypes.DashboardDataType.ENROLLED_DETAILS,
];

export const epcsDatasetTypes: Array<keyof DashboardData> = [
  apiTypes.DashboardDataType.EPCS_READINESS,
  apiTypes.DashboardDataType.EPCS_READINESS_DETAILS,
];

export const uniqueUserDatasetTypes: Array<keyof DashboardData> = [
  apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_METRICS,
  apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS,
];

const getUniqueUsersTimeRange = (
  datasetName: keyof DashboardData,
  timeRangeType: apiTypes.DashboardDataInterval,
) => {
  if (datasetName === apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS) {
    return [
      apiTypes.DashboardDataInterval.UNIQUE_USERS_CURRENT_MONTH,
      apiTypes.DashboardDataInterval.UNIQUE_USERS_PREVIOUS_MONTH,
    ].includes(timeRangeType)
      ? timeRangeType
      : apiTypes.DashboardDataInterval.UNIQUE_USERS_CURRENT_MONTH;
  }
  return apiTypes.DashboardDataInterval.UNIQUE_USERS_TIME_RANGE;
};

// A convenience function to get datasets selectors and their corresponding timeranges
export function getDatasetSelectors(
  timeRangeType: apiTypes.DashboardDataInterval = INITIAL_DATA_FETCH_INTERVAL,
  filterSelectors?: apiTypes.DashboardDataType[],
  additionalFilters?: apiTypes.DashBoradDataExtraFilters,
): apiTypes.DatasetSelectorBody {
  return {
    datasetSelectors: [
      ...defaultDatasetTypes.map(datasetName => ({
        datasetName,
        timeRangeType,
      })),
      ...enrollmentDatasetTypes.map(datasetName => ({
        datasetName,
        timeRangeType: apiTypes.DashboardDataInterval.ALL_TIME_RANGE,
      })),
      ...epcsDatasetTypes.map(datasetName => ({
        datasetName,
        timeRangeType: apiTypes.DashboardDataInterval.ALL_TIME_RANGE,
      })),
      ...uniqueUserDatasetTypes.map(datasetName => ({
        datasetName,
        timeRangeType: getUniqueUsersTimeRange(datasetName, timeRangeType),
        ...(datasetName ===
          apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS && {
          entitlementType:
            additionalFilters?.[
              apiTypes.UniqueUserEntitlementType.ENITITLMENT_TYPE
            ],
        }),
      })),
    ].filter(
      selector =>
        (filterSelectors &&
          filterSelectors.length > 0 &&
          filterSelectors.includes(selector.datasetName)) ??
        true,
    ),
  };
}

export function getExportDatasetSelector(
  timeRangeType: apiTypes.DashboardDataInterval,
  datasetName: apiTypes.DashboardDataType,
  format: apiTypes.DatasetExportFormat = apiTypes.DatasetExportFormat.CSV,
  additionalFilters?: apiTypes.DashBoradDataExtraFilters,
): apiTypes.DatasetSelectorBody {
  const datasetSelector = {
    datasetName,
    timeRangeType,
    ...(additionalFilters && {
      entitlementType:
        additionalFilters?.[
          apiTypes.UniqueUserEntitlementType.ENITITLMENT_TYPE
        ],
    }),
  };
  return {
    datasetSelector,
    format,
  };
}
