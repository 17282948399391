/* eslint-disable import/no-duplicates */
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import {
  type ArrayTypeKeys,
  DashboardDataInterval,
  type DashboardDataType,
  type FilterTypeKeys,
  type UniqueUsersIntervalType,
} from '../../api/Dashboard/types';
import {
  DATE_TIME_FORMAT_OFFSET,
  formatDateWithoutTimeZoneChangeUTCOnly,
  getLocalDateTime,
} from '../../utils/DateUtils';
import {
  type DashboardData,
  type DashboardModalKey,
  type MappedDashboardData,
} from './types';

export const NotImplemented = 'ToBeImplemented';
export const NullValue = 'null';

export function extractValueAtKey<K extends string[] = string[]>(
  haystack: K extends Array<infer T> ? T[] : K[],
  needle: K extends Array<infer T> ? T : K[number],
  data: (string | number)[],
): string | number | undefined {
  if (!data) {
    return;
  }
  const idx = haystack.indexOf(needle);
  if (idx !== -1) {
    return data[idx];
  } else {
    return undefined;
  }
}

export const filterDeduplicate = <
  K extends DashboardModalKey = DashboardModalKey,
>(
  dedupColumn: FilterTypeKeys<K>,
  timestampColumn: FilterTypeKeys<K>,
  d: DashboardData[K],
): DashboardData[K] => {
  if (!d || !d.keys || !d.values) {
    return d;
  }

  const keys = d.keys ?? [];
  const values = d.values ?? [];

  // We now filter each row based on `check` fucntion which will receive
  // a Date object or a numeric timestamp, and returns whether to include or exclude the row.
  const filteredValues = values
    .filter(v => {
      // Extract timestamp from each row
      const timestamp = extractValueAtKey(keys, timestampColumn, v);

      const tDate = Date.parse(
        typeof timestamp === 'string' ? timestamp : `${timestamp}`,
      );
      if (isNaN(tDate)) {
        return false;
      } else {
        return true;
      }
    })
    .reduce(
      (acc, cur) => {
        //this reduce logic will aggregate for UNIQUE VALUES (so we dont repeat users in this detailed view)
        const dedupValue = extractValueAtKey(keys, dedupColumn, cur);

        if (!dedupValue) {
          return acc;
        }

        return acc?.find(d => d.includes(dedupValue))
          ? acc
          : [...(acc ?? []), cur];
      },
      [] as DashboardData[K]['values'],
    )
    ?.sort((a, b) => {
      // Sorting logic based on the 'user' column
      const userA = extractValueAtKey(keys, 'user', a) as string;
      const userB = extractValueAtKey(keys, 'user', b) as string;

      return userA.localeCompare(userB);
    });

  return {
    ...d,
    values: filteredValues,
  } as DashboardData[K];
};

export interface DateEnrichOptions<
  T extends DashboardDataType = DashboardDataType,
> {
  utc: boolean;
  dateKeys: ArrayTypeKeys<T>;
}

export function processDataDetails<T extends DashboardDataType>(
  data?: DashboardData[T],
  dateOptions?: DateEnrichOptions<T>,
): MappedDashboardData[] {
  if (!data || (typeof data === 'object' && !data.keys && !data.values)) {
    return [];
  }

  const { values = [], keys = [] } = data;

  if (!keys || (Array.isArray(keys) && keys.length === 0)) {
    return [];
  }

  const processed = (values || []).map(row =>
    row.reduce((acc, cur, idx) => {
      let value = '';
      if (cur && cur !== NullValue && cur !== NotImplemented) {
        value = `${cur}`;
      }

      return {
        ...acc,
        [`${keys[idx]}`]: value,
      };
    }, {} as MappedDashboardData),
  );

  if (dateOptions?.dateKeys) {
    return enrichLocalTime(dateOptions.dateKeys, processed, dateOptions.utc);
  } else {
    return processed;
  }
}

function formatDashboardDate(data: string, utc: boolean) {
  if (utc) {
    return formatDateWithoutTimeZoneChangeUTCOnly(data);
  } else {
    return getLocalDateTime(data, DATE_TIME_FORMAT_OFFSET);
  }
}

export function enrichLocalTime<
  K extends DashboardDataType = DashboardDataType,
>(
  dateKeys: Array<keyof MappedDashboardData<K>>,
  details: MappedDashboardData<K>[],
  utc: boolean,
): MappedDashboardData[] {
  return [...details].map(row => ({
    ...row,
    ...dateKeys.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]:
          (cur &&
            cur in row &&
            row[cur] &&
            formatDashboardDate(row[cur] as string, utc)) ||
          row[cur],
      };
    }, {} as MappedDashboardData),
  }));
}

export type UniqueUserDateData = {
  uniqueUsers: string | number;
  occurredAt: Date;
};

export type UniqueUsersData = {
  previous?: UniqueUserDateData;
  current?: UniqueUserDateData;
};

/**
 * normalizeUniqueUserData
 *
 * @summary Normalizes data received from backend.
 * @description Returns date data in UTC
 *  NOTE: This function returns information based on UTC only.
 *    If data is received in non-UTC format, the return values
 *    and how they are displayed may be UB.
 *
 * @param {string[]} keys - string[] - An array of strings representing keys.
 * @param {(string|number)[][]} values - (string|number)[][] - A two-dimensional array containing string or number values.
 * @returns
 */
export const normalizeUniqueUserData = (
  keys: DashboardData[DashboardDataType.UNIQUE_ACTIVE_USER_METRICS]['keys'],
  values: (string | number)[][],
): UniqueUsersData => {
  // Values are passed in usually as 2 rows: based on UTC month decide which is current and previous
  // Get today's date to compare whether `current` is actually current
  const currentDate = new Date();

  const previousMonthNumber =
    currentDate.getUTCMonth() === 0 ? 11 : currentDate.getUTCMonth() - 1;
  const current =
    values?.filter(val => {
      const timestamp = val?.[0];
      return new Date(timestamp).getUTCMonth() === new Date().getUTCMonth();
    })?.[0] ?? [];
  const previous =
    values?.filter(val => {
      const timestamp = val?.[0];
      return new Date(timestamp).getUTCMonth() === previousMonthNumber;
    })?.[0] ?? [];

  if (!keys || !current) {
    return {};
  }

  // prettier-ignore
  const getUsers = (extractValueAtKey<
    ArrayTypeKeys<DashboardDataType.UNIQUE_ACTIVE_USER_METRICS>
  >).bind(null, keys, 'active-users');
  // prettier-ignore
  const getOccurred = (extractValueAtKey<
    ArrayTypeKeys<DashboardDataType.UNIQUE_ACTIVE_USER_METRICS>
  >).bind(null, keys, 'bucket-id');

  // For current we default to this month
  const curOccurredAtValue = getOccurred(current);
  const curOccurredAt = curOccurredAtValue
    ? new Date(curOccurredAtValue)
    : currentDateUTC();

  const curUsers = getUsers(current),
    prevUsers = getUsers(previous);

  // For previous, we want to default to previous month
  const prevOccurredAtValue = getOccurred(previous);
  const prevOccurredAt = prevOccurredAtValue
    ? new Date(prevOccurredAtValue)
    : currentDateUTC(1);

  // Current data default value
  const currentData: UniqueUserDateData = {
    uniqueUsers: curUsers || 0,
    occurredAt: curOccurredAt,
  };

  // Previous data default value
  const previousData: UniqueUserDateData = {
    uniqueUsers: prevUsers || 0,
    occurredAt: prevOccurredAt,
  };

  // NOTE: The `getUTCMonth` makes this UTC dependent. If the data is NOT UTC, this
  // may not work the same.
  if (previous || curOccurredAt.getUTCMonth() === currentDate.getUTCMonth()) {
    return { current: currentData, previous: previousData };
  } else {
    // Current data is previous
    return {
      previous: currentData,
      current: {
        uniqueUsers: 0,
        occurredAt: currentDateUTC(),
      },
    };
  }
};

export const currentDateUTC = (monthOffset = 0): Date => {
  const date = new Date(new Date().getTime());

  if (monthOffset > 0) {
    date.setMonth(date.getMonth() - monthOffset);
  }
  return date;
};

//Unique Users Current & Previous Month based on Date

// It is important to note that locally, you have to update the mockdata
// to match CURRENT and PREVIOUS month you are currently at to check this funciontality
// when you triger the onClick for the corresponding HTML elements that use them
// i.e update api-mock/fixtures/dashboard/previous-7-days.json and so on

export const isCurrentMonth = (date: number | Date): boolean => {
  const currentDate = new Date();
  const providedDate = new Date(date);

  return (
    currentDate.getFullYear() === providedDate.getFullYear() &&
    currentDate.getMonth() === providedDate.getMonth()
  );
};

export const isPreviousMonth = (date: number | Date): boolean => {
  const currentDate = new Date();
  const providedDate = new Date(date);

  const currentMonth = currentDate.getMonth();
  const providedMonth = providedDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const providedYear = providedDate.getFullYear();

  const monthsDiff =
    (currentYear - providedYear) * 12 + (currentMonth - providedMonth);

  return monthsDiff === 1 || monthsDiff === -11; // -11 is checking for the January - December cycle (1 - 12 = -11)
};

export const formatNumber = (value: number): string | number => {
  if (value <= 9999) {
    return value;
  } else if (value >= 1e4 && value < 1e6) {
    return +(value / 1e3).toFixed(1) + 'k';
  } else if (value >= 1e6 && value < 1e9) {
    return +(value / 1e6).toFixed(1) + 'm';
  } else if (value >= 1e9 && value < 1e12) {
    return +(value / 1e9).toFixed(1) + 'b';
  } else if (value >= 1e12) {
    return +(value / 1e12).toFixed(1) + 't';
  } else {
    return value;
  }
};

export const getUniqueUsersDateFormat = (
  selection: UniqueUsersIntervalType,
): string => {
  if (selection === DashboardDataInterval.UNIQUE_USERS_CURRENT_MONTH) {
    return format(new Date(), 'MMM yyyy');
  }
  return format(subMonths(new Date(), 1), 'MMM yyyy');
};
