// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { List, ConfigProvider, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getApiApplication,
  getAppConfig,
  getAppIntegration,
  updateAppConfig,
} from './store/actions';
import EmptyStateIcon from '../../assets/svg/empty-state.svg?react';
import {
  type AppConfigState,
  type AppListState,
  type ApplicationsListState,
} from './store/reducer';
import { type RootState } from '../../store/rootReducer';
import AppModal from '../modals/AppModal';
import ListItem from '../../components/list/ListItem';
import { type ApiApplication, type AppConfig } from './store/types';
import DeleteApplicationButton from './components/DeleteApplicationButton';
import classes from './AppList.module.less';
import EditApplicationModal from './components/EditApplicationModal';

const AppList: React.FC = function () {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const appListState = useSelector<RootState, AppListState>(
    rootState => rootState.apps.appList,
  );

  const applicationsListState = useSelector<RootState, ApplicationsListState>(
    rootState => rootState.apps.applicationsList,
  );

  const appConfigState = useSelector<RootState, AppConfigState>(
    rootState => rootState.apps.appConfig,
  );

  const [appReady, setAppReady] = useState<boolean>(false);

  useEffect(() => {
    setAppReady(
      !appListState.loading &&
        !appConfigState.loading &&
        !applicationsListState.loading,
    );
  }, [appListState, appConfigState, applicationsListState]);

  const [applicationsList, setApplicationsList] = useState<ApiApplication[]>(
    [],
  );

  const isEpicHyperdrive = (appType: string, appName: string) => {
    return appType === 'saml' && appName === 'epic-hyperdrive' ? true : false;
  };

  useEffect(() => {
    // to get SAML data
    dispatch(getAppIntegration.request());
    // to get app config (if config exist - app will be shown in app list)
    dispatch(getAppConfig.request());
  }, [dispatch]);

  useEffect(() => {
    // to get the list of all applications
    dispatch(getApiApplication.request());
  }, [dispatch]);

  useEffect(() => {
    if (appListState.error) {
      console.error('Epic Hyperdrive erro', appListState.error);
      throw appListState.error;
    }
  }, [appListState.error]);

  useEffect(() => {
    if (applicationsListState.error) {
      console.error('Error listing applications:', applicationsListState.error);
      throw applicationsListState.error;
    }
  }, [applicationsListState.error]);

  const onConfigUpdate = (config: AppConfig) => {
    dispatch(
      updateAppConfig.request({
        applicationId: 'epic-hyperdrive',
        appConfig: config,
      }),
    );
  };

  useEffect(() => {
    if (!applicationsListState.error && !applicationsListState.loading) {
      setApplicationsList(applicationsListState.apiApplications);
    }
  }, [applicationsListState]);

  return (
    <>
      {appReady ? (
        <ConfigProvider renderEmpty={renderEmpty}>
          <List
            dataSource={applicationsList}
            loading={applicationsListState.loading}
            renderItem={apiApplication =>
              isEpicHyperdrive(
                apiApplication.appType,
                apiApplication.appName,
              ) ? (
                <ListItem
                  key={apiApplication.appId}
                  data-testid="apps-item"
                  primaryText={apiApplication.displayName}
                  renderOnTheRight={
                    <AppModal
                      appIntegration={appListState.appIntegrations[0]}
                      appConfig={appConfigState.config}
                      redirectUri={appConfigState.redirectUri}
                      onConfigUpdate={onConfigUpdate}
                      openFromUploads={false}
                    />
                  }
                />
              ) : (
                <ListItem
                  key={apiApplication.appId}
                  data-testid="applicaitons-item"
                  primaryText={apiApplication.displayName}
                  secondaryText={t('apps.profile.import.imported-profile')}
                  renderOnTheRight={
                    <>
                      <DeleteApplicationButton
                        appId={apiApplication.appId}
                        appName={apiApplication.displayName}
                      />
                      <EditApplicationModal
                        appId={apiApplication.appId}
                        displayName={apiApplication.displayName}
                      />
                    </>
                  }
                />
              )
            }
          />
        </ConfigProvider>
      ) : (
        <div className={classes.spinner}>
          <Spin data-testid="loading-applications-list-spinner" />
        </div>
      )}
    </>
  );

  function renderEmpty() {
    return (
      !applicationsListState.apiApplications.length && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#000',
            padding: '100px',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          <EmptyStateIcon data-testid="applist-empty-state-icon" />
          <p style={{ marginTop: '18px' }}>{t('apps.no-apps')}</p>
          <AppModal
            appIntegration={appListState.appIntegrations[0]}
            appConfig={appConfigState.config}
            redirectUri={appConfigState.redirectUri}
            onConfigUpdate={onConfigUpdate}
            openFromUploads={false}
          />
        </div>
      )
    );
  }
};

export default AppList;
