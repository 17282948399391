// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { List, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ImprChip, ImprTooltip } from '@imprivata-cloud/components';
import { type Authenticator, type TenantUser } from './store/types';
import { unenrollProxcards } from './store/actions';
import { type CredentialInfo, TagNames } from './types';
import AuthenticatorPopup from './AuthenticatorPopup';
import classes from './UsersList.module.less';
import { type CredentialsFindByUserIdRequest } from '../../api/Credentials/types';

const UserItem: React.FC<{
  user: TenantUser;
  index: number;
  authenticators?: Authenticator[];
  findCredentials: (
    filter: CredentialsFindByUserIdRequest,
  ) => Promise<CredentialInfo[] | undefined>;
  removeCredential: (credentialId: string, appType: string) => void;
  onTagClick: (tagName: TagNames) => void;
}> = ({
  user,
  authenticators,
  findCredentials,
  removeCredential,
  onTagClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const doUnenroll = (auth: Authenticator) => {
    dispatch(unenrollProxcards.request([auth]));
  };

  const handleOnClickImprChip = () => {
    onTagClick(TagNames.ADMIN);
  };

  const handleOnKeyPressImprChip = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onTagClick(TagNames.ADMIN);
    }
  };

  return (
    <List.Item
      data-testid="user-item"
      key={user.upn}
      className={`${classes.listItem}`}
    >
      <List.Item.Meta
        title={user.displayName}
        description={user.upn}
        data-testid="user-info"
      />
      <Col flex="auto">
        {user.roleType === 'admin' ? (
          <div className={classes.chipsWithTooltip}>
            <ImprTooltip title={t('users.admin-tag-tooltip')} color="#333">
              <ImprChip
                label={t('users.admin-tag')}
                color="processing"
                containerClassName={classes.chipStyle}
                data-testid="admin-tag"
                onClick={handleOnClickImprChip}
                onKeyPress={handleOnKeyPressImprChip}
              />
            </ImprTooltip>
          </div>
        ) : null}
      </Col>
      <Col flex="none" className={classes.authenticatorCol}>
        <AuthenticatorPopup
          userId={user.userId}
          username={user.displayName}
          onRemoveAuthenticator={doUnenroll}
          authenticators={authenticators}
          findCredentials={findCredentials}
          onRemoveCredential={removeCredential}
        />
      </Col>
    </List.Item>
  );
};

export default UserItem;
