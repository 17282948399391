// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import { type GroupsFindData, type ListSelectors } from '../../../shared/types';
import {
  type ApiError2,
  type GroupForSyncUnmarkRequestPayload,
  type StartInitialSyncPayload,
} from '../../../api/types';

export const getGroups = createAsyncAction(
  'groups/LIST_REQUEST',
  'groups/LIST_SUCCESS',
  'groups/LIST_FAILURE',
)<ListSelectors, GroupsFindData, ApiError2>();

export const getGroupsNextPortion = createAsyncAction(
  'groups/NEXT_PAGE_REQUEST',
  'groups/NEXT_PAGE_SUCCESS',
  'groups/NEXT_PAGE_FAILURE',
)<void, GroupsFindData, ApiError2>();

export const groupUnmarkForSync = createAsyncAction(
  'group/SYNC_UNMARK_REQUEST',
  'group/SYNC_UNMARK_SUCCESS',
  'group/SYNC_UNMARK_FAILURE',
)<GroupForSyncUnmarkRequestPayload, void, ApiError2>();

export const startInitialSync = createAsyncAction(
  'INITIAL_SYNC_REQUEST',
  'INITIAL_SYNC_SUCCESS',
  'INITIAL_SYNC_FAILURE',
)<StartInitialSyncPayload, void, ApiError2>();

export const clearGroupsErrorsAction = createAction('clear-errors')<boolean>();
