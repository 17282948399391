// Copyright 2023, Imprivata, Inc.  All rights reserved.

import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { Spin, Upload } from 'antd';
import { type UploadProps } from 'antd/lib/upload/interface';
import {
  Banner,
  Button,
  ButtonSize,
  ButtonVariant,
} from '@imprivata-cloud/components';
import { type UploadChangeParam } from 'antd/lib/upload';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  getApplicationProfileUploadUrl,
  uploadApplicationFile,
  uploadApplicationProfileFileData,
} from '../../../api/Applications/appsService';
import upload from '../../../assets/svg/upload.svg?react';
import { getApiApplication } from '../store/actions';

export interface UploadButtonProps {
  beforeUpload?: () => void;
  buttonType: 'initial' | 'upload';
  icon?: React.ReactNode;
  onChange?: () => void;
  onClick?: () => void;
  text: string;
  customRequest?: () => void;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  beforeUpload,
  buttonType,
  icon,
  onChange,
  onClick,
  text,
}: UploadButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startProfileUpload: UploadProps['customRequest'] = async ({
    onSuccess,
    onError,
    file,
  }) => {
    try {
      const { uploadUrl, readUrl } = await getApplicationProfileUploadUrl(
        (file as File).name,
        'private',
      );

      const { status } = await uploadApplicationFile(file as File, uploadUrl);

      if (status === 200) {
        await uploadApplicationProfileFileData(readUrl);
        onSuccess?.(file);
        dispatch(getApiApplication.request());
      } else {
        (onError as ((e: string) => void) | undefined)?.(
          "Couldn't upload file. Try again.",
        );
      }
    } catch (error) {
      (onError as ((e: unknown) => void) | undefined)?.(error);
    }
  };

  const handleOnChangeAction = ({ file }: UploadChangeParam) => {
    const { status } = file;

    if (status === 'uploading') {
      setIsLoading(true);
    } else if (status === 'done') {
      setIsLoading(false);
    } else {
      Banner({
        type: 'error',
        message: t('apps.profile.import.failure'),
        duration: 10,
        datatestid: 'upload-profile-failed-banner',
      });
      setIsLoading(false);
    }
  };

  return (
    <Upload
      accept="text/xml"
      customRequest={startProfileUpload}
      beforeUpload={beforeUpload}
      onChange={onChange || handleOnChangeAction}
      showUploadList={false}
      data-testid="import-application-profile-input"
    >
      {/* show one of two available buttons depending on the type supplied */}
      {buttonType === 'upload' ? (
        <Button
          disabled={isLoading}
          className="upload-application-btn"
          label={text}
          variant={ButtonVariant.TEXT}
          data-testid="upload-application-profile-button"
          onClick={onClick}
          icon={
            isLoading ? (
              <Spin
                data-testid="upload-application-spinner-icon"
                size="default"
                style={{
                  marginTop: '5px',
                  marginRight: '12px',
                  marginBottom: '5px',
                  marginLeft: '5px,',
                }}
              />
            ) : (
              icon || (
                <Icon
                  component={upload}
                  data-testid="upload-application-profile-icon"
                />
              )
            )
          }
          style={{
            fontWeight: '800',
            color: '#666AA2',
          }}
        />
      ) : (
        <Button
          disabled={isLoading}
          className="import-application-btn"
          data-testid="import-application-profile-button"
          label={text}
          size={ButtonSize.MAJOR}
          variant={ButtonVariant.TEXT}
          onClick={onClick}
          style={{
            fontSize: '20px',
            fontWeight: '600',
            width: 'fit-content',
          }}
        />
      )}
    </Upload>
  );
};

export default UploadButton;
