// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { client } from './client';
import { authz } from './endpoint-names';
import { type ValidatePermissionPayload } from './types';

export async function validatePermission() {
  return client
    .post<ValidatePermissionPayload>(authz.VALIDATE_PERMISSION)
    .then(res => res.data);
}

export async function logout() {
  return client.post<Record<string, never>>(authz.LOGOUT).then(res => res.data);
}
