// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Statistic } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { type ParseKeys } from 'i18next';
import classes from './DashboardCard.module.less';
import Card, { type CardProps } from '../../components/card/Card';

const DashboardCard: React.FC<
  {
    data: string;
    label: ParseKeys;
  } & CardProps
> = ({ data, label, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Card {...rest} className={clsx('elevation-off', rest.className || '')}>
      <Statistic
        title={t(label)}
        value={data}
        className={classes.stat}
        data-testid={label}
        formatter={val => (
          <span data-testid={`${label.split('.').pop()}-number`}>
            {val === '' ? '0' : val}
          </span>
        )}
        groupSeparator=""
      />
    </Card>
  );
};

export default DashboardCard;
