// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import { type Observable } from 'rxjs';
import { authenticators } from './endpoint-names';
import rxClient from './rxClient';

import {
  type AuthenticatorsFindByUserIdsRequest,
  type AuthenticatorsFindByUserIdsResponse,
  type AuthenticatorsUnenrollRequest,
} from './types';

export function findAuthenticatorsByUserIds$(
  payload: AuthenticatorsFindByUserIdsRequest,
): Observable<AuthenticatorsFindByUserIdsResponse> {
  return rxClient
    .post<AuthenticatorsFindByUserIdsResponse>(
      authenticators.FIND_AUTHENTICATORS,
      payload,
    )
    .pipe(map(res => res.data));
}

export function unenrollAuthenticators$(
  payload: AuthenticatorsUnenrollRequest,
): Observable<void> {
  return rxClient
    .post<void>(authenticators.UNENROLL, payload)
    .pipe(map(res => res.data));
}
