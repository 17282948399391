// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { _getEnvVariable, _getUrls } from '@imprivata-cloud/common';
import { ApiVersion } from './api/constants';
import { QueryParamKey } from './containers/login/store/constants';
import { getCurrentEnv } from './utils/build';
// NOTE: This is temporary until feature flags have been properly implemented
import featureFlags from './featureFlags.json';

export type FeatureFlags = typeof featureFlags;

declare global {
  interface Window {
    _appConfig: Record<string, string>;
  }
}

export type AppEnvConfig = {
  API_URL: string;
  AUTHN_API_VERSION: ApiVersion;
  OTLP_TRACING_URL?: string;
  ZIPKIN_TRACING_URL?: string;
  COLLECT_TRACING?: string;
  CSV_UPLOAD_ENABLED?: boolean;
  DIRECTORIES_ENABLED?: boolean;
  APPLICATIONS_ENABLED?: boolean;
  APPLICATIONS_EPIC_LOCK_ENABLED?: boolean;
  ENDPOINTS_ENABLED?: boolean;
  ENABLE_ADD_GROUP_TO_FEATURE?: boolean;
  SELF_SERVICE_URL?: string;
  SYSTEM_ENABLED?: boolean;
  IMPORT_APPLICATION_PROFILES_ENABLED?: boolean;
  IDENTITY_BROKER_PAGE_ENABLED?: boolean;
  APP_TERMINATION_PAGE_ENABLED?: boolean;
  USER_CREDENTIALS_ENABLED?: boolean;
  EDIT_PROFILED_APPLICATIONS_ENABLED?: boolean;
  APP_TERMINATION_TERMINATE_ALL_OPTION_ENABLED?: boolean;
  EDIT_PROFILED_APPLICATIONS_NAME_ENABLED?: boolean;
  LOGON_EVENTS_TERMINATE_ALL_OPTION_ENABLED?: boolean;
  IDENTITY_BROKER_EAMC_OPTIONS_ENABLED?: boolean;
  ADVANCED_SETTINGS_PAGE_ENABLED?: boolean;
  SSO_DASHBOARD_DATA_ENABLED?: boolean;
  EPCS_ENABLED?: boolean;
};

let envConfig: AppEnvConfig;

export function _initAppConfig(
  window: Window,
  env: ImportMetaEnv,
): AppEnvConfig {
  let authnApiVersion: ApiVersion;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(QueryParamKey.AUTHN_API_VERSION)) {
    authnApiVersion = urlParams.get(
      QueryParamKey.AUTHN_API_VERSION,
    ) as ApiVersion;
  } else {
    authnApiVersion = _getEnvVariable(
      window,
      env,
      'API_VERSION',
      ApiVersion.V2,
    ) as ApiVersion;
  }

  const { apiUrl, ussUrl, tracingUrl } = _getUrls();

  const currentEnv = getCurrentEnv();
  let features: Partial<AppEnvConfig> = featureFlags['prod'];

  if (Object.keys(featureFlags).includes(currentEnv)) {
    features = featureFlags[currentEnv as keyof FeatureFlags];
  }

  console.debug('[Admin:appConfigUtils] feature flags: ', {
    currentEnv,
    features,
  });

  // file deepcode ignore MissingArgument
  return {
    API_URL:
      apiUrl || (_getEnvVariable(window, env, 'API_URL', apiUrl) as string),
    AUTHN_API_VERSION: authnApiVersion,
    OTLP_TRACING_URL: _getEnvVariable(
      window,
      env,
      'OTLP_TRACING_URL',
      tracingUrl,
    ),
    ZIPKIN_TRACING_URL: _getEnvVariable(window, env, 'ZIPKIN_TRACING_URL'),
    COLLECT_TRACING: _getEnvVariable(window, env, 'COLLECT_TRACING'),
    SELF_SERVICE_URL: ussUrl
      ? ussUrl
      : _getEnvVariable(
          window,
          env,
          'SELF_SERVICE_URL',
          'https://localhost:3000',
        ),
    ...features,
  };
}

export default function getConfig(): AppEnvConfig {
  if (!envConfig) {
    envConfig = _initAppConfig(window, import.meta.env);
  }
  return envConfig;
}
