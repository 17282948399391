// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const AUTHN_PREFIX = '/authn-web';
export const ADMIN_PREFIX = '/admin-web';

export const AUTHN_V1_SERVICE_PREFIX = `${AUTHN_PREFIX}/authn/v1` as const;
export const AUTHN_V2_SERVICE_PREFIX = `${AUTHN_PREFIX}/authn/v2` as const;
export const AUTHN_V3_SERVICE_PREFIX = `${AUTHN_PREFIX}/authn/v3` as const;
export const AUTHZ_SERVICE_PREFIX = `${ADMIN_PREFIX}/session/v2` as const;
export const FILE_ACCESS_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/file-access/v2` as const;
export const ENROLL_SERVICE_PREFIX = `${ADMIN_PREFIX}/enroll/v2` as const;
export const AUTHENTICATORS_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/authenticators/v2` as const;
export const CREDENTIALS_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/credentials/v1` as const;
export const CREDENTIALS_SERVICE_PREFIX_V2 =
  `${ADMIN_PREFIX}/credentials/v2` as const;
export const APPLICATIONS_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/applications/v2` as const;
export const ALL_APPLICATIONS_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/applications/v3` as const;
export const APPLICATION_CONFIG_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/application-config/v1` as const;
export const IDENTITY_SERVICE_PREFIX = `${ADMIN_PREFIX}/identity/v2` as const;
export const IDENTITY_BROKER_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/identity-broker/v2` as const;
// endpoint admin svc
export const ENDPOINTS_SERVICE_PREFIX = `${ADMIN_PREFIX}/endpoint/v2` as const;
// endpoint update svc
export const ENDPOINT_DEVICES_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/endpoint-device/v2` as const;
// Used for appearance updates
export const PREFERENCES_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/org-preferences/v2` as const;
export const POLICY_SERVICE_PREFIX = `${ADMIN_PREFIX}/authn-policy/v2` as const;
export const DIRECTORY_SERVICE_PREFIX = `${ADMIN_PREFIX}/directory/v2` as const;
export const GROUP_SERVICE_PREFIX = `${ADMIN_PREFIX}/group/v2` as const;
export const DASHBOARD_SERVICE_PREFIX = `${ADMIN_PREFIX}/dashboard/v2` as const;
export const ADMIN_INITIAL_SETUP_PREFIX =
  `${ADMIN_PREFIX}/initial-setup/v3` as const;
// APP PROFILES
export const APP_PROFILES_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/applications/v3` as const;
// APP TERMINATION
export const APP_TERMINATION_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/app-termination/v1` as const;
export const CONNECT_EAMC_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/connect-eamc/v1` as const;
export const ADVANCED_SETTINGS_SERVICE_PREFIX =
  `${ADMIN_PREFIX}/settings/v1` as const;

export const authn = {
  GET_FACTOR_OPTIONS: `${AUTHN_V1_SERVICE_PREFIX}/factor-options/get` as const,
  GET_NEXT_FACTORS: `${AUTHN_V3_SERVICE_PREFIX}/next-factors/get` as const,
  GET_ORG_PREFERENCES:
    `${AUTHN_PREFIX}/org-preferences/v2/org-preferences/get` as const,
  CREATE_SESSION_V1: `${AUTHN_V1_SERVICE_PREFIX}/authn-session/start` as const,
  CREATE_SESSION_V2: `${AUTHN_V2_SERVICE_PREFIX}/authn-session/start` as const,
  AUTHENTICATE_V1: `${AUTHN_V1_SERVICE_PREFIX}/authenticate` as const,
  AUTHENTICATE_V2: `${AUTHN_V2_SERVICE_PREFIX}/authenticate` as const,
};

export const authz = {
  VALIDATE_PERMISSION:
    `${AUTHZ_SERVICE_PREFIX}/client-user-session/start` as const,
  LOGOUT: `${AUTHZ_SERVICE_PREFIX}/logout` as const,
};

export const users = {
  PREPARE_UPLOAD: `${FILE_ACCESS_SERVICE_PREFIX}/prepare-file-upload` as const,
  AUTHN_BULK_ENROLL:
    `${ENROLL_SERVICE_PREFIX}/authn-factors/bulk-enroll` as const,
  USERS_LIST: `${IDENTITY_SERVICE_PREFIX}/users/find` as const,
};

export const apps = {
  APP_INTEGRATION_FIND_BY_NAME:
    `${APPLICATIONS_SERVICE_PREFIX}/app-integration/find/by-name` as const,
  APPLICATIONS_FIND:
    `${ALL_APPLICATIONS_SERVICE_PREFIX}/applications/find` as const,
  APP_CONFIG_FIND:
    `${APPLICATION_CONFIG_SERVICE_PREFIX}/app-config/find` as const,
  APP_CONFIG_UPDATE:
    `${APPLICATION_CONFIG_SERVICE_PREFIX}/app-config/update` as const,
  APP_PROFILE_PREPARE_UPLOAD:
    `${FILE_ACCESS_SERVICE_PREFIX}/prepare-file-upload` as const,
  APP_PROFILE_FILE_UPLOAD:
    `${APP_PROFILES_SERVICE_PREFIX}/profiled-apps/import` as const,
  APP_PROFILED_APP_DELETE:
    `${APP_PROFILES_SERVICE_PREFIX}/profiled-apps/delete` as const,
  APP_GET_APPLICATION_BY_ID:
    `${APP_PROFILES_SERVICE_PREFIX}/profiled-app/get` as const,
  APP_UPDATE_APPLICATION:
    `${APP_PROFILES_SERVICE_PREFIX}/profiled-app/update` as const,
};

export const authenticators = {
  FIND_AUTHENTICATORS:
    `${AUTHENTICATORS_SERVICE_PREFIX}/authenticators/find/by-user-ids` as const,
  UNENROLL: `${AUTHENTICATORS_SERVICE_PREFIX}/authenticators/unenroll` as const,
};

export const credentials = {
  FIND_CREDENTIALS:
    `${CREDENTIALS_SERVICE_PREFIX}/credentials/find/by-app-and-user-id` as const,
  FIND_CREDENTIALS_BY_USER_ID:
    `${CREDENTIALS_SERVICE_PREFIX_V2}/credentials/find/by-user-id` as const,
  DELETE_CREDENTIAL:
    `${CREDENTIALS_SERVICE_PREFIX}/user-app-credential/delete` as const,
  DELETE_CREDENTIAL_V2:
    `${CREDENTIALS_SERVICE_PREFIX_V2}/user-app-credential/delete` as const,
};

export const endpoints = {
  // endpoint admin svc
  GET_INSTALLER_DATA:
    `${ENDPOINTS_SERVICE_PREFIX}/endpoint-installer-data/get` as const,
  // endpoint admin svc
  REGENERATE_TOKEN:
    `${ENDPOINTS_SERVICE_PREFIX}/installation-token/regenerate` as const,
  // endpoint update svc
  FIND_ENDPOINT_DEVICES:
    `${ENDPOINT_DEVICES_SERVICE_PREFIX}/endpoint-devices-with-agent-info/find` as const,
};

export const preferences = {
  GET_PREFERENCES: `${PREFERENCES_SERVICE_PREFIX}/org-preferences/get` as const,
  UPDATE_PREFERENCES:
    `${PREFERENCES_SERVICE_PREFIX}/org-preferences/update` as const,
};

export const connectEamc = {
  CREATE_CLIENT: `${CONNECT_EAMC_SERVICE_PREFIX}/client/create` as const,
};

export const policy = {
  GET_GRACE_PERIOD: `${POLICY_SERVICE_PREFIX}/grace-period/get` as const,
  UPDATE_GRACE_PERIOD: `${POLICY_SERVICE_PREFIX}/grace-period/update` as const,
  GET_DESKTOP_GRACE_PERIOD:
    `${POLICY_SERVICE_PREFIX}/desktop-grace-period/get` as const,
  UPDATE_DESKTOP_GRACE_PERIOD:
    `${POLICY_SERVICE_PREFIX}/desktop-grace-period/update` as const,
};

export const directories = {
  SYNC_DIRECTORIES: `${DIRECTORY_SERVICE_PREFIX}/directories/sync` as const,
  FIND_DIRECTORIES: `${DIRECTORY_SERVICE_PREFIX}/directories/find` as const,
  FIND_FAILED_SYNC_DIRECTORIES:
    `${DIRECTORY_SERVICE_PREFIX}/directories/failed-sync/find` as const,
  GET_SYNC_PROGRESS_DIRECTORY:
    `${DIRECTORY_SERVICE_PREFIX}/directory/sync-progress/get` as const,
};

export const groups = {
  FIND_GROUPS: `${GROUP_SERVICE_PREFIX}/groups/find`,
  SYNC_MARK: `${GROUP_SERVICE_PREFIX}/group-for-sync/mark`,
  SYNC_UNMARK: `${GROUP_SERVICE_PREFIX}/group-for-sync/unmark`,
};

export const dashboard = {
  GET_DASHBOARD_DATA: `${DASHBOARD_SERVICE_PREFIX}/dashboard-data/get` as const,
  GET_DASHBOARD_EXPORT_DATA: `${DASHBOARD_SERVICE_PREFIX}/data/export` as const,
};

export const adminInitialSetup = {
  AAD_GROUP_USER_COUNT:
    `${ADMIN_INITIAL_SETUP_PREFIX}/aad-group-user-count/get` as const,
  INITIAL_SYNC: `${ADMIN_INITIAL_SETUP_PREFIX}/initial-sync/start` as const,
  CHOOSE_ADMIN_GROUPS:
    `${ADMIN_INITIAL_SETUP_PREFIX}/admin-groups/choose` as const,
};

export const appTermination = {
  APP_TERMINATION_GET:
    `${APP_TERMINATION_SERVICE_PREFIX}/app-termination/get` as const,
  APP_TERMINATION_UPDATE:
    `${APP_TERMINATION_SERVICE_PREFIX}/app-termination/update` as const,
};

export const advancedSettings = {
  GET_CONFIG: `${ADVANCED_SETTINGS_SERVICE_PREFIX}/config/get` as const,
  SETTINGS_UPDATE:
    `${ADVANCED_SETTINGS_SERVICE_PREFIX}/settings/update` as const,
  CUSTOM_SETTINGS_CHANGE:
    `${ADVANCED_SETTINGS_SERVICE_PREFIX}/custom-settings/change` as const,
};
