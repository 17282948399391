// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const FETCH_ORG_PREFERENCES = 'FETCH_ORG_PREFERENCES';
export const PENDING_ORG_PREFERENCES = 'PENDING_ORG_PREFERENCES';
export const SUCCESS_ORG_PREFERENCES = 'SUCCESS_ORG_PREFERENCES';
export const ERROR_ORG_PREFERENCES = 'ERROR_ORG_PREFERENCES';

export const FETCH_DELAY_VALUE = 0;

export type CONSTANTS_TYPES =
  | typeof FETCH_ORG_PREFERENCES
  | typeof PENDING_ORG_PREFERENCES
  | typeof SUCCESS_ORG_PREFERENCES
  | typeof ERROR_ORG_PREFERENCES;
