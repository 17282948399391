// Copyright 2024, Imprivata, Inc.  All rights reserved.

export interface EditApplicationModalProps {
  appId: string;
  displayName: string;
}

export interface ProfiledAppData {
  appName: string;
  externalCreds: CREDS_SOURCE;
  usernameFormat?: USERNAME_FORMAT;
}

export enum CREDS_SOURCE {
  OWN = 'own',
  DOMAIN = 'domain',
}

export enum USERNAME_FORMAT {
  SAM = 'sam',
  UPN = 'upn',
  NET = 'net-bios',
}
