// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import axios from 'axios';
import getConfigs from '../appConfigUtils';
import { WEB_APP_NAME } from '../shared/constants';
import { Headers } from './constants';
import { authnResourceHeaderInterceptor } from './interceptors';

const { API_URL } = getConfigs();

const securityHeaders = {
  [Headers.StrictTransportSecurity]: 'max-age=86400;includeSubDomains',
  [Headers.XSSProtection]: '1;mode=block',
  [Headers.XFrameOptions]: 'DENY',
  [Headers.XContentTypeOptions]: 'nosniff',
};

export const client = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    ...securityHeaders,
    [Headers.ImprClientName]: jsonToBase64({ name: WEB_APP_NAME }),
  },
});

client.interceptors.request.use(authnResourceHeaderInterceptor);

export const noCredentialsClient = axios.create({
  headers: {
    ...securityHeaders,
  },
});
