// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type Epic, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { type ApiError2 } from '../../../api/types';
import { findDirectories$ } from '../../../api/directoriesService';
import { type RootAction } from '../../../store/rootAction';
import { type RootState } from '../../../store/rootReducer';
import { findDirectoriesActions } from './actions';
import { ContextNames } from '../../../i18n';
import { getErrorMessageCode } from '../../../i18n/utils';

export const findDirectoriesEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(findDirectoriesActions.request)),
    switchMap(() =>
      findDirectories$().pipe(
        map(res => {
          return findDirectoriesActions.success(res);
        }),
        catchError((err: ApiError2) =>
          of(
            findDirectoriesActions.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.DIRECTORIES, err.code),
            }),
          ),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(findDirectoriesActions.cancel))),
        ),
      ),
    ),
  );

export const directoriesEpic = combineEpics(findDirectoriesEpic);
