import React from 'react';
import { type CredentialInfo } from '../types';
import {
  deleteUserAppCredential,
  findCredentialsByUserId,
} from '../../../api/Credentials/credentialsService';
import { type CredentialsFindByUserIdRequest } from '../../../api/Credentials/types';

export const useLearnedCredentials = () => {
  const reqRef = React.useRef<CredentialsFindByUserIdRequest>();
  const [error, setError] = React.useState<string>();

  const fetchCredentials = React.useCallback(
    async (
      req?: CredentialsFindByUserIdRequest,
    ): Promise<CredentialInfo[] | undefined> => {
      if (!req) {
        return undefined;
      }

      try {
        const res = await findCredentialsByUserId(req);

        if (res && res.credentialInfo) {
          return res.credentialInfo;
        } else {
          return [];
        }
      } catch (err) {
        console.error('Error fetching credentials:', err);
        setError('Error fetching credentials');
        throw err;
      }
    },
    [],
  );

  const removeCredential = React.useCallback(
    (credentialId: string, appType: string) => {
      deleteUserAppCredential({
        credentialId,
        appType,
      })
        .then(res => {
          if (res && res.error) {
            throw res.error;
          } else {
            void fetchCredentials(reqRef?.current);
          }
        })
        .catch(err => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const msg =
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (err && typeof err === 'object' && err?.message) ||
            'Error deleting learned credential';
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setError(msg);

          console.error(
            'Error deleting learned credential: ',
            credentialId,
            appType,
            err,
          );
        });
    },
    [reqRef, fetchCredentials],
  );

  return {
    error,
    fetchCredentials,
    removeCredential,
  };
};
