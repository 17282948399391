// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type ThunkAction } from 'redux-thunk';
import { type Action, type PayloadAction } from 'typesafe-actions';
import {
  ERROR_ORG_PREFERENCES,
  FETCH_ORG_PREFERENCES,
  PENDING_ORG_PREFERENCES,
  SUCCESS_ORG_PREFERENCES,
} from './constants';
import { type OrgPreferences, type OrgPreferencesState } from './reducers';

type FetchOrgPreferencesActionType = Action<typeof FETCH_ORG_PREFERENCES>;
export const fetchOrgPreferences =
  (): ThunkAction<
    Promise<void>,
    OrgPreferencesState,
    undefined,
    FetchOrgPreferencesActionType
  > =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async dispatch => {
    dispatch({
      type: FETCH_ORG_PREFERENCES,
    });
  };

type LoadOrgPreferencesSuccessType = PayloadAction<
  typeof SUCCESS_ORG_PREFERENCES,
  {
    data: OrgPreferences;
  }
>;

export const loadOrgPreferencesSuccess =
  ({
    data,
    operations: _,
  }: {
    data: OrgPreferences;
    operations: string;
  }): ThunkAction<
    Promise<void>,
    OrgPreferencesState,
    undefined,
    LoadOrgPreferencesSuccessType
  > =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async dispatch => {
    dispatch({ type: SUCCESS_ORG_PREFERENCES, payload: { data } });
  };

export type LoadOrgPreferencesFailType = PayloadAction<
  typeof ERROR_ORG_PREFERENCES,
  { data: { error: string } }
>;

export const loadOrgPreferencesFail = ({
  error,
}: {
  error: string;
}): LoadOrgPreferencesFailType => ({
  type: ERROR_ORG_PREFERENCES,
  payload: { data: { error } },
});

export type LoadOrgPreferencesProgressType = Action<
  typeof PENDING_ORG_PREFERENCES
>;

export const loadOrgPreferencesInProgress =
  (): ThunkAction<
    Promise<void>,
    OrgPreferencesState,
    undefined,
    LoadOrgPreferencesProgressType
  > =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async dispatch => {
    dispatch({ type: PENDING_ORG_PREFERENCES });
  };

export type OrgPreferencesAction =
  | FetchOrgPreferencesActionType
  | LoadOrgPreferencesSuccessType
  | LoadOrgPreferencesFailType
  | LoadOrgPreferencesProgressType;
