// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { Line as AntLine, type Plot } from '@ant-design/plots';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Select } from '@imprivata-cloud/components';
import { type LineOptions } from '@antv/g2plot/lib/plots/line';
import { type ParseKeys } from 'i18next';
import {
  type DashboardData,
  DashboardIntervalDropdown,
  type DashboardLineChartData,
  type DashboardModalKey,
} from './types';
import { useDashboardFetch } from './hooks/dashboardData';
import DashboardCard from './DashboardCard';
import classes from './DashboardView.module.less';
import {
  baseLineChartConfig,
  configureDashboardModalState,
  configureDashboardCards,
} from './dashboardViewConfig';
import DashboardViewModal from '../modals/dashboard-modal/DashboardViewModal';
import Card from '../../components/card/Card';
import Enrollments from './components/Enrollments';
import UniqueUsers from './components/UniqueUsers';
import EpcsReadiness from './components/EpcsReadiness';
import ActionBar from './components/ActionBar';
import {
  extractValueAtKey,
  filterDeduplicate,
  getUniqueUsersDateFormat,
} from './utils';
import { downloadFile } from '../../utils/downloadHelpers';
import {
  type ArrayTypeKeys,
  type DashBoradDataExtraFilters,
  DashboardDataInterval,
  DashboardDataType,
  type EntitlementType,
  UniqueUserEntitlementType,
  type UniqueUsersIntervalType,
} from '../../api/Dashboard/types';
import {
  fetchDashboardExportData,
  getDashboardData,
  getDashboardExportData,
} from '../../api/Dashboard/dashboardService';
import {
  INITIAL_DATASETS,
  INITIAL_DATA_FETCH_INTERVAL,
  INITIAL_UNIQUE_USER_INTERVAL,
} from './apiConfiguration';

export const exportFilenameKey: Record<DashboardModalKey, ParseKeys> = {
  [DashboardDataType.AUTHNS_DETAILS]:
    'dashboard.download.authenticator-modal-header-prefix',
  [DashboardDataType.EPCS_REPORT]: 'dashboard.download.epcs-download-filename',
  [DashboardDataType.UNIQUE_USER_AUTHNS_DETAILS]:
    'dashboard.download.unique-users-download-filename',
  [DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS]:
    'dashboard.download.unique-users-download-filename',
  [DashboardDataType.ENROLLED_DETAILS]:
    'dashboard.download.enrollment-modal-header-prefix',
  [DashboardDataType.EPCS_READINESS_DETAILS]:
    'dashboard.download.epcs-readiness-download-filename',
  [DashboardDataType.SSO_AUTHS_DETAILS]:
    'dashboard.download.sso-modal-header-prefix',
} as const;

export const getExportInterval = (
  interval: DashboardDataInterval,
  key: DashboardDataType,
  uniqueUserInterval:
    | UniqueUsersIntervalType
    | undefined = DashboardDataInterval.UNIQUE_USERS_CURRENT_MONTH,
): DashboardDataInterval => {
  if (
    key === DashboardDataType.AUTHNS_DETAILS ||
    key === DashboardDataType.SSO_AUTHS_DETAILS
  ) {
    return interval;
  } else if (key === DashboardDataType.UNIQUE_USER_AUTHNS_DETAILS) {
    return DashboardDataInterval.UNIQUE_USERS_TIME_RANGE;
  } else if (key === DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS) {
    return uniqueUserInterval;
  } else if (key === DashboardDataType.UNIQUE_ACTIVE_USER_METRICS) {
    return DashboardDataInterval.UNIQUE_USERS_TIME_RANGE;
  } else {
    return DashboardDataInterval.ALL_TIME_RANGE;
  }
};

export const getExportData = async (
  interval: DashboardDataInterval,
  datasetName: DashboardModalKey,
  filename: string,
  additionalFilters: DashBoradDataExtraFilters | undefined,
  callback: (
    interval: DashboardDataInterval,
    filterSelectors: DashboardDataType[],
    additionalFilters: DashBoradDataExtraFilters | undefined,
  ) => Promise<DashboardData | undefined>,
) => {
  const data = await callback(interval, [datasetName], additionalFilters);
  // Extract the downloadUrl
  if (data && data[datasetName]) {
    const { keys = [], values = [] } = data[datasetName];
    const url = extractValueAtKey(keys, 'downloadLink', values[0]) as string;
    if (url) {
      console.debug('Downloading csv data now...');

      const fetched = await fetchDashboardExportData<string>?.(url);
      const suffix =
        DashboardIntervalDropdown[interval || INITIAL_DATA_FETCH_INTERVAL];

      if (
        (datasetName === DashboardDataType.AUTHNS_DETAILS ||
          DashboardDataType.SSO_AUTHS_DETAILS) &&
        suffix !== undefined
      ) {
        downloadFile(fetched, `${filename} - ${suffix}.csv`);
      } else {
        downloadFile(fetched, `${filename}.csv`);
      }
    } else {
      console.debug('No url, cannot download');
    }
  } else {
    console.debug('No csv data returned');
  }
};

export const useGetFileName = <K extends DashboardModalKey = DashboardModalKey>(
  uniqueUserInterval: UniqueUsersIntervalType,
  userEntitlementType?: UniqueUserEntitlementType | null,
) => {
  const { t } = useTranslation();
  return useCallback(
    (key: K) => {
      if (key === DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS) {
        const entitlement =
          userEntitlementType === UniqueUserEntitlementType.SSO
            ? t('dashboard.metrics-cards.unique-users.user-types.sso')
            : t('dashboard.metrics-cards.unique-users.user-types.epcs');
        const monthSelected = getUniqueUsersDateFormat(uniqueUserInterval);
        const prefix = `${monthSelected} ${entitlement}`;
        return `${prefix} ${t(
          'dashboard.download.unique-users-modal-header-prefix',
        )}`;
      }
      return `${t(exportFilenameKey[key])}`;
    },
    [uniqueUserInterval, t, userEntitlementType],
  );
};

const DashboardView: React.FC = () => {
  const { t } = useTranslation();
  const currentDate = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    timeStyle: 'long',
    timeZone: 'America/New_York',
  }).format();
  const [openModal, setOpenModal] = useState<{
    key: DashboardModalKey;
    filter?: (
      d: DashboardData[DashboardModalKey],
    ) => DashboardData[DashboardModalKey];
  }>();

  const [interval, setInterval] = useState<DashboardDataInterval>(
    INITIAL_DATA_FETCH_INTERVAL,
  );
  const [uniqueUserInterval, setUniqueUserInterval] =
    useState<UniqueUsersIntervalType>(INITIAL_UNIQUE_USER_INTERVAL);

  const [userEntitlementType, setUserEntitlementType] =
    useState<EntitlementType | null>(null);

  // Retrieve initial dashboard data onload
  const {
    getData: getInitialData,
    fetchedData: initialData,
    fetchState: initialFetchState,
  } = useDashboardFetch(getDashboardData);

  useEffect(() => {
    void getInitialData(interval, INITIAL_DATASETS);
  }, [getInitialData, interval]);

  // Retrieve modal data
  const {
    getData: fetchModalData,
    fetchedData: modalData,
    fetchState: continuousFetch,
    resetData: resetModalData,
  } = useDashboardFetch(getDashboardData);

  // Retrieve initial dashboard data onload
  const { getData: fetchExportData, fetchState: exportFetchState } =
    useDashboardFetch(getDashboardExportData);

  /** MODAL **/
  const modalHeaderPrefix = useCallback(
    (key?: DashboardDataType, _filename?: true) => {
      if (key === DashboardDataType.AUTHNS_DETAILS) {
        const suffix =
          DashboardIntervalDropdown[interval || INITIAL_DATA_FETCH_INTERVAL];
        return `${t(
          'dashboard.download.authenticator-modal-header-prefix',
        )}- ${suffix}`;
      } else if (key === DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS) {
        const entitlement =
          userEntitlementType === UniqueUserEntitlementType.SSO
            ? t('dashboard.metrics-cards.unique-users.user-types.sso')
            : t('dashboard.metrics-cards.unique-users.user-types.epcs');
        const monthSelected = getUniqueUsersDateFormat(uniqueUserInterval);
        const prefix = `${monthSelected} ${entitlement}`;
        return `${prefix} ${t(
          'dashboard.download.unique-users-modal-header-prefix',
        )}`;
      } else if (
        key === DashboardDataType.ENROLLED_DETAILS ||
        key === DashboardDataType.EPCS_READINESS_DETAILS
      ) {
        return `${t('dashboard.download.enrollment-modal-header-prefix')}`;
      } else if (key === DashboardDataType.SSO_AUTHS_DETAILS) {
        const suffix =
          DashboardIntervalDropdown[interval || INITIAL_DATA_FETCH_INTERVAL];
        return `${t('dashboard.download.sso-modal-header-prefix')}- ${suffix}`;
      }
    },
    [interval, t, uniqueUserInterval, userEntitlementType],
  );

  const modalState = React.useMemo(() => {
    if (openModal && modalData) {
      return configureDashboardModalState(
        openModal.key,
        modalData,
        openModal.filter,
      );
    } else {
      return undefined;
    }
  }, [modalData, openModal]);

  // Helper to update Modal State
  const updateModalState = useCallback(
    <K extends DashboardModalKey = DashboardModalKey>(
      key: K,
      filter?: (
        d: DashboardData[DashboardModalKey],
      ) => DashboardData[DashboardModalKey],
      additionalFilters?: DashBoradDataExtraFilters,
    ) => {
      const isUniqueUserCard =
        key === DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS;

      // First display modal
      setOpenModal({ key, filter });
      // Then fetch data (display spinner in meantime)

      void fetchModalData(
        isUniqueUserCard ? uniqueUserInterval : interval,
        [key],
        additionalFilters,
      );
    },
    [fetchModalData, interval, uniqueUserInterval],
  );

  /** Line chart data */
  const lineChartData: DashboardLineChartData[] = useMemo(() => {
    if (
      initialData &&
      initialData[DashboardDataType.AUTHNS] &&
      initialData[DashboardDataType.AUTHNS].keys &&
      initialData[DashboardDataType.AUTHNS].values
    ) {
      const authnData = initialData[DashboardDataType.AUTHNS];
      const { keys = [], values = [] } = authnData;

      // prettier-ignore
      const getValue = (extractValueAtKey<ArrayTypeKeys<DashboardDataType.AUTHNS>>).bind(
        null,
        keys
      );

      return values.reduce((acc, cur) => {
        const datetime = `${getValue('occurred-at', cur) || ''}`;
        const success = getValue('success-authns', cur) || 0;
        const failure = getValue('failure-authns', cur) || 0;

        const response = [
          ...acc,
          ...[
            // success
            {
              datetime,
              value: success,
              category: 'Success',
            },
            // failure
            {
              datetime,
              value: failure,
              category: 'Non-success',
            },
          ],
        ];
        return response;
      }, [] as DashboardLineChartData[]);
    } else {
      return [];
    }
  }, [initialData]);

  const linChartOnReady = useCallback(
    (plot: Plot<LineOptions>) => {
      plot.on('axis-label:click', () => {
        updateModalState(DashboardDataType.AUTHNS_DETAILS);
      });
    },
    [updateModalState],
  );

  useEffect(() => {
    if (!userEntitlementType) {
      return;
    }
    updateModalState(
      DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS,
      filterDeduplicate.bind(null, 'user', 'occurred-at'),
      {
        entitlementType: userEntitlementType,
      },
    );
  }, [updateModalState, uniqueUserInterval, userEntitlementType]);

  /**
   *
   * @param eType - entitlementType(sso/epcs)
   * @param monthType - month_type(previous/current)
   */
  const onUniqueUserMonthClick = (
    eType: EntitlementType,
    monthType: UniqueUsersIntervalType,
  ) => {
    setUniqueUserInterval(monthType);
    setUserEntitlementType(eType);
    setOpenModal({
      key: DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS,
    });
  };

  const getFileName = useGetFileName(uniqueUserInterval, userEntitlementType);

  const intervalOptions = Object.entries(DashboardIntervalDropdown)
    .map(([value, label]) => ({
      value,
      label,
      'data-testid': `impr-select-${value}`,
    }))
    .reverse();

  return (
    <>
      {initialFetchState?.loading && !initialData ? (
        <Spin
          data-testid="dashboard-view-spinner"
          className={classes.spinner}
          spinning
        />
      ) : (
        <>
          <div
            className={classes.container}
            data-testid="dashboard-view-container"
          >
            {/* Action bar */}
            <ActionBar
              csvButtonProps={{
                label: t('dashboard.download.epcs-download-link'),
                onClick: () => {
                  void getExportData(
                    DashboardDataInterval.ALL_TIME_RANGE,
                    DashboardDataType.EPCS_REPORT,
                    `${t(exportFilenameKey[DashboardDataType.EPCS_REPORT])}`,
                    undefined,
                    fetchExportData,
                  );
                },
              }}
            >
              <>
                {/* Select Interval */}
                <div className={classes.selectIntervalWrapper}>
                  <span data-testid="impr-select-interval-options">
                    <Select
                      defaultValue={INITIAL_DATA_FETCH_INTERVAL}
                      placement="bottomRight"
                      getPopupContainer={(trigger: HTMLElement) =>
                        trigger.parentNode as HTMLElement
                      }
                      onChange={setInterval}
                      options={intervalOptions}
                    />
                  </span>
                  <div
                    className={classes.dateInfo}
                    data-testid="dashboard-intervals-timestamp"
                  >
                    {t('dashboard.intervals.data-timestamp')} {currentDate}
                  </div>
                </div>
              </>
            </ActionBar>

            {/* Dashboard Modal */}
            {/* NOTE This needs to be refactored, unnecessary complexity */}
            {
              <DashboardViewModal
                title={modalHeaderPrefix(openModal?.key) || ''}
                open={!!openModal}
                onClose={() => {
                  resetModalData();
                  setOpenModal(undefined);
                  setUserEntitlementType(null);
                }}
                csvButtonProps={
                  (modalState?.key && {
                    label: t('dashboard.download.download-csv'),
                    onClick: () => {
                      if (modalState?.key) {
                        const additionalFilters = {
                          entitlementType: userEntitlementType ?? undefined,
                        };
                        void getExportData(
                          getExportInterval(
                            interval,
                            modalState.key,
                            uniqueUserInterval,
                          ),
                          modalState.key, // the ones that are for modals should be on this type
                          getFileName(modalState.key),
                          additionalFilters,
                          fetchExportData,
                        );
                      }
                    },
                    // If data is being fetched, show the button as loading...
                    loading: exportFetchState && exportFetchState.loading,
                  }) ||
                  undefined
                }
                table={{
                  columns: modalState?.columns,
                  dataSource: modalState?.data,
                  pagination: false,
                  loading: !!continuousFetch?.loading,
                }}
                disableFooter={true}
                totalItems={modalState?.data?.length}
              />
            }

            {/* Line Chart */}
            <div
              className={clsx('elevation-astra-1', classes.dashboardTop)}
              data-testid="line-chart-panel"
            >
              <div className={clsx(classes.lineChartWrapper, 'p-5')}>
                <span className="subtitle" data-testid="line-chart-subtitle">
                  {t('dashboard.chart.header')}
                </span>
                <div
                  className={classes.lineChart}
                  data-testid="line-chart-authentications"
                >
                  <AntLine
                    {...baseLineChartConfig(lineChartData, interval)}
                    onReady={linChartOnReady}
                    loading={!!initialFetchState?.loading}
                  />
                </div>
              </div>

              {/* Dashboard Cards */}
              <div className={classes.dashboardCards}>
                {configureDashboardCards(updateModalState, initialData, {
                  className: classes.dashboardCard,
                }).map((props, i) => (
                  <React.Fragment key={`${props?.label || i}_dashboard_card`}>
                    {props && <DashboardCard {...props} />}
                  </React.Fragment>
                ))}
              </div>
            </div>

            {/* Metrics Cards */}
            <div
              className={classes.metricsContainer}
              data-testid="metrics-cards-panel"
            >
              {/* Left column */}
              <div>
                {/* Prox card enrollments */}
                <Enrollments
                  className="pointer"
                  data={
                    initialData &&
                    initialData[DashboardDataType.ENROLLED_METRICS]
                  }
                  onClick={() => {
                    setOpenModal({
                      key: DashboardDataType.ENROLLED_DETAILS,
                    });
                    updateModalState(DashboardDataType.ENROLLED_DETAILS);
                  }}
                  header={t(`dashboard.metrics-cards.enrollments.header`)}
                  data-testid="enrollment-card"
                />

                {/* Unique users */}
                <UniqueUsers
                  data={
                    initialData &&
                    initialData[DashboardDataType.UNIQUE_ACTIVE_USER_METRICS]
                  }
                  header={
                    <>
                      <div>
                        {t(`dashboard.metrics-cards.unique-users.header`)}
                      </div>
                    </>
                  }
                  data-testid="unique-active-users-metrics"
                  onClickPreviousMonth={eType => {
                    onUniqueUserMonthClick(
                      eType,
                      DashboardDataInterval.UNIQUE_USERS_PREVIOUS_MONTH,
                    );
                  }}
                  onClickCurrentMonth={eType => {
                    onUniqueUserMonthClick(
                      eType,
                      DashboardDataInterval.UNIQUE_USERS_CURRENT_MONTH,
                    );
                  }}
                />

                {/* EPCS readiness */}
                <EpcsReadiness
                  className="pointer"
                  data={
                    initialData && initialData[DashboardDataType.EPCS_READINESS]
                  }
                  header={t(`dashboard.metrics-cards.epcs-readiness.header`)}
                  data-testid="epcs-readiness"
                  onClick={() => {
                    setOpenModal({
                      key: DashboardDataType.EPCS_READINESS_DETAILS,
                    });
                    updateModalState(DashboardDataType.EPCS_READINESS_DETAILS);
                  }}
                />
              </div>
              {/* Right column: Administrator activities */}
              <div>
                <Card
                  header={t(`dashboard.metrics-cards.administrator.header`)}
                  className="w-full h-full"
                  style={{ visibility: 'hidden' }}
                ></Card>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardView;
