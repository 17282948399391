/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  type ApiApplication,
  type AppConfig,
  type AppIntegration,
} from './types';
import {
  getAppConfig,
  getAppIntegration,
  getApiApplication,
  updateAppConfig,
} from './actions';
import { type RootAction } from '../../../store/rootAction';

export interface AppListState {
  loading: boolean;
  appIntegrations: AppIntegration[];
  error: string | null;
}

export interface ApplicationsListState {
  loading: boolean;
  apiApplications: ApiApplication[];
  error: string | null;
}

export interface AppConfigState {
  loading: boolean;
  config: AppConfig;
  redirectUri: string;
  error: string | null;
}

export interface AppsState {
  appList: AppListState;
  applicationsList: ApplicationsListState;
  appConfig: AppConfigState;
}

export const initialState: AppsState = {
  appList: {
    loading: true,
    appIntegrations: [],
    error: null,
  },
  applicationsList: {
    loading: true,
    apiApplications: [],
    error: null,
  },
  appConfig: {
    loading: false,
    config: {
      appUsernameAdMatch: null,
      samlEnabled: true,
      captureMaxRetries: 3,
      interconnectBaseUrls: null,
    },
    redirectUri: '',
    error: null,
  },
};

export const appListReducer = combineReducers<AppListState>({
  loading: createReducer<boolean>(initialState.appList.loading)
    .handleAction(getAppIntegration.request, () => true)
    .handleAction(
      [
        getAppIntegration.success,
        getAppIntegration.failure,
        getAppIntegration.cancel,
      ],
      () => false,
    ),
  appIntegrations: createReducer<AppIntegration[], RootAction>(
    initialState.appList.appIntegrations,
  ).handleAction(getAppIntegration.success, (_, { payload }) => {
    return payload;
  }),
  error: createReducer<string | null, RootAction>(initialState.appList.error)
    .handleAction(getAppIntegration.request, () => null)
    .handleAction(getAppIntegration.failure, (_, { payload }) => {
      return payload.code || '';
    })
    .handleAction(getAppIntegration.cancel, () => 'cancelled'),
});

export const applicationsListReducer = combineReducers<ApplicationsListState>({
  loading: createReducer<boolean>(initialState.applicationsList.loading)
    .handleAction(getApiApplication.request, () => true)
    .handleAction(
      [
        getApiApplication.success,
        getApiApplication.failure,
        getApiApplication.cancel,
      ],
      () => false,
    ),
  apiApplications: createReducer<ApiApplication[], RootAction>(
    initialState.applicationsList.apiApplications,
  ).handleAction(getApiApplication.success, (_, { payload }) => {
    return payload;
  }),
  error: createReducer<string | null, RootAction>(
    initialState.applicationsList.error,
  )
    .handleAction(getApiApplication.request, () => null)
    .handleAction(getApiApplication.failure, (_, { payload }) => {
      return payload.code || '';
    })
    .handleAction(getApiApplication.cancel, () => 'cancelled'),
});

export const appConfigReducer = combineReducers<AppConfigState>({
  loading: createReducer<boolean>(initialState.appConfig.loading)
    .handleAction([getAppConfig.request, updateAppConfig.request], () => true)
    .handleAction(
      [
        getAppConfig.success,
        getAppConfig.failure,
        getAppConfig.cancel,
        updateAppConfig.cancel,
        updateAppConfig.failure,
        updateAppConfig.success,
      ],
      () => false,
    ),
  config: createReducer<AppConfig, RootAction>(
    initialState.appConfig.config,
  ).handleAction(getAppConfig.success, (_, { payload }) => payload.appConfig),
  redirectUri: createReducer<string, RootAction>(
    initialState.appConfig.redirectUri,
  ).handleAction(getAppConfig.success, (_, { payload }) => payload.redirectUri),
  error: createReducer<string | null, RootAction>(initialState.appList.error)
    .handleAction(getAppConfig.request, () => null)
    .handleAction(getAppConfig.failure, (_, { payload }) => {
      return payload.code || '';
    })
    .handleAction(getAppConfig.cancel, () => 'cancelled'),
});

export default combineReducers<AppsState>({
  appList: appListReducer,
  applicationsList: applicationsListReducer,
  appConfig: appConfigReducer,
});
