/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { type TenantUser, type Authenticator } from './types';
import {
  getUsersList,
  getUsersListNextPortion,
  getUsersTotals,
  findAuthenticatorsByUserIds,
} from './actions';
import { type RootAction } from '../../../store/rootAction';

export interface TotalsState {
  loading: boolean;
  usersCount: number;
  error: string | null;
}

export interface UserListTotalState {
  fetchTotals: () => void;
  totals: TotalsState;
}

export interface UsersListState {
  loading: boolean;
  loadingNextPortion: boolean;
  rowsLoading: Record<number, boolean>; // FCS
  /** Normalized state is used to improve performance of virtualized list */
  ids: string[]; // list of user UPNs
  // user entities by UPNs. Not really clear why it was done this way (as opposed to user ids):
  entities: Record<string, TenantUser>;
  total: number;
  error: string | null;
  nextPortionToken: string;
}

export interface UserListFetchState
  extends Pick<
    UsersListState,
    'entities' | 'ids' | 'loading' | 'loadingNextPortion'
  > {
  fetchUsersList: () => void;
}

export interface AuthenticatorsListState {
  loading: boolean;
  entities: Record<string, Authenticator[]>; // Authenticators by user ids
  error: string | null;
}

export interface UsersState {
  totals: TotalsState;
  users: UsersListState;
  authenticators: AuthenticatorsListState;
}

export const initialState: UsersState = {
  totals: {
    usersCount: 0,
    loading: true,
    error: null,
  },
  users: {
    loading: true,
    loadingNextPortion: false,
    rowsLoading: {},
    ids: [],
    entities: {},
    total: 0,
    error: null,
    nextPortionToken: '',
  },
  authenticators: {
    entities: {},
    error: null,
    loading: true,
  },
};

export const totalsReducer = combineReducers<TotalsState>({
  loading: createReducer<boolean>(initialState.totals.loading)
    .handleAction(getUsersTotals.request, () => true)
    .handleAction(
      [getUsersTotals.cancel, getUsersTotals.success, getUsersTotals.failure],
      () => false,
    ),
  usersCount: createReducer<number, RootAction>(
    initialState.totals.usersCount,
  ).handleAction(
    getUsersTotals.success,
    (_, { payload }) => payload.totalCount,
  ),
  error: createReducer<string | null, RootAction>(initialState.totals.error)
    .handleAction([getUsersTotals.request, getUsersTotals.success], () => null)
    .handleAction(getUsersTotals.failure, (_, { payload }) => payload.code)
    .handleAction(getUsersTotals.cancel, () => 'cancelled'),
});

export const usersListReducer = combineReducers<UsersListState>({
  loading: createReducer<boolean>(initialState.users.loading)
    .handleAction(getUsersList.request, () => true)
    .handleAction(
      [getUsersList.success, getUsersList.failure, getUsersList.cancel],
      () => false,
    ),

  loadingNextPortion: createReducer<boolean>(
    initialState.users.loadingNextPortion,
  )
    .handleAction(getUsersListNextPortion.request, () => true)
    .handleAction(
      [
        getUsersListNextPortion.success,
        getUsersListNextPortion.failure,
        getUsersListNextPortion.cancel,
      ],
      () => false,
    ),

  rowsLoading: createReducer<Record<number, boolean>>(
    initialState.users.rowsLoading,
  ),

  ids: createReducer<string[], RootAction>(initialState.users.ids)
    .handleAction(getUsersList.failure, () => initialState.users.ids)
    .handleAction(
      getUsersList.success,
      (_, { payload }) => payload.result.users,
    )
    .handleAction(getUsersListNextPortion.success, (state, { payload }) => {
      return [...state, ...payload.result.users];
    }),

  entities: createReducer<Record<string, TenantUser>, RootAction>(
    initialState.users.entities,
  )
    .handleAction(getUsersList.failure, () => initialState.users.entities)
    .handleAction(
      getUsersList.success,
      (_, { payload }) => payload.entities.users || {},
    )
    .handleAction(
      getUsersListNextPortion.success,
      (state, { payload }) => ({ ...state, ...payload.entities.users }) || {},
    ),

  total: createReducer<number, RootAction>(
    initialState.users.total,
  ).handleAction(
    getUsersList.success,
    (_, { payload }) => payload.result.totalCount,
  ),

  error: createReducer<string | null, RootAction>(initialState.users.error)
    .handleAction(getUsersList.request, () => null)
    .handleAction(getUsersList.failure, (_, { payload }) => payload.code || '')
    .handleAction(getUsersList.cancel, () => 'cancelled'),

  nextPortionToken: createReducer<string, RootAction>(
    initialState.users.nextPortionToken,
  )
    .handleAction(
      getUsersList.failure,
      () => initialState.users.nextPortionToken,
    )
    .handleAction(
      [getUsersList.success, getUsersListNextPortion.success],
      (_, { payload }) => payload.result.pagingInfo.afterToken,
    ),
});

export const authenticatorsReducer = combineReducers<AuthenticatorsListState>({
  loading: createReducer<boolean>(initialState.authenticators.loading)
    .handleAction(findAuthenticatorsByUserIds.request, () => true)
    .handleAction(
      [
        findAuthenticatorsByUserIds.success,
        findAuthenticatorsByUserIds.failure,
        findAuthenticatorsByUserIds.cancel,
      ],
      () => false,
    ),
  entities: createReducer<Record<string, Authenticator[]>, RootAction>(
    initialState.authenticators.entities,
  ).handleAction(
    findAuthenticatorsByUserIds.success,
    (prevState, { payload }) => {
      const mergedState = { ...prevState, ...payload };
      return Object.entries(mergedState).reduce<
        Record<string, Authenticator[]>
      >((accumulator, [key, value]) => {
        if (value !== undefined) {
          accumulator[key] = value;
        }
        return accumulator;
      }, {});
    },
  ),
  error: createReducer<string | null, RootAction>(
    initialState.authenticators.error,
  )
    .handleAction(findAuthenticatorsByUserIds.request, () => null)
    .handleAction(
      findAuthenticatorsByUserIds.failure,
      (_, { payload }) => payload.code || '',
    )
    .handleAction(findAuthenticatorsByUserIds.cancel, () => 'cancelled'),
});

export default combineReducers<UsersState>({
  totals: totalsReducer,
  users: usersListReducer,
  authenticators: authenticatorsReducer,
});
