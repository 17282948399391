// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Observable } from 'rxjs';
import { ALL_ITEMS_PATTERN } from '../../shared/types/list';

export const transformSearchPattern = (pattern: string): string => {
  const specialSymbols = /[!#$%^&*()?":{}|<>]/;

  if (pattern.length === 0) {
    return ALL_ITEMS_PATTERN;
  } else if (pattern.includes('*')) {
    return pattern;
  } else if (pattern.includes(' ')) {
    return processSearchWithSpaces(pattern);
  } else if (specialSymbols.test(pattern)) {
    return '';
  } else {
    return `*${pattern}*`;
  }
};

export const createViewportObserver = (
  element: Element,
): Observable<IntersectionObserverEntry[]> =>
  new Observable<IntersectionObserverEntry[]>(subscriber => {
    const intersectionObserver = new IntersectionObserver(
      entries => {
        subscriber.next(entries);
      },
      {
        root: null,
      },
    );

    intersectionObserver.observe(element);
  });

const processSearchWithSpaces = (pattern: string): string => {
  return pattern
    .trim()
    .split(/ +/)
    .map(word => `*${word}*`)
    .join(' ');
};
