// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { type FormInstance } from 'antd';
import { base64ToJson } from '@imprivata-cloud/data-privacy-js';
import {
  type IdpName,
  type IdpMetadata,
} from '../../../../shared/types/api/identity-broker';
import { IdpForm } from '../components/IdpForm';
import { type AdfsIdpFormValues, type OneSignIdpFormValues } from '../types';

export const IdpFormContainer = <
  T extends AdfsIdpFormValues | OneSignIdpFormValues,
>(props: {
  formInstance: FormInstance<T>;
  metadataName: IdpName;
  header: string;
  onTouched?: () => void;
  autoFocus?: boolean;
  'data-testid'?: string;

  isLoaded: boolean;
  idpMetadata: IdpMetadata | undefined;
}) => {
  const {
    formInstance,
    metadataName,
    idpMetadata,
    isLoaded,
    onTouched,
    autoFocus,
    header,
    'data-testid': dataTestId,
  } = props;

  const metadataInfo: T | undefined = idpMetadata
    ? (base64ToJson(idpMetadata.metadataInfo) as unknown as T)
    : undefined;

  const isUpdating = idpMetadata !== undefined;

  return (
    <IdpForm
      // key is needed to fully rerender the form when initialValues are loaded,
      // otherwise they are not displayed correctly
      key={String(isLoaded)}
      idpName={metadataName}
      form={formInstance}
      isUpdating={isUpdating}
      disabled={!isLoaded}
      initialValues={metadataInfo}
      onTouched={onTouched}
      autoFocus={autoFocus}
      header={header}
      data-testid={dataTestId}
    />
  );
};
