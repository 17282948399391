// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { type RootAction } from '../../../../store/rootAction';
import { regenerateAgentKey$ } from '../../../../api/endpointsService';
import { getErrorMessageCode } from '../../../../i18n/utils';
import { ContextNames } from '../../../../i18n';
import { regenerateAgentKey } from './actions';
import { getInstallerData } from '../../../endpoints/store/actions';
import { type ApiError2 } from '../../../../api/types';

export const agentKeyRegenerationEpic: Epic<RootAction> = action$ => {
  return action$.pipe(
    filter(isActionOf(regenerateAgentKey.request)),
    switchMap(() => {
      return from(regenerateAgentKey$()).pipe(
        switchMap(res =>
          from([getInstallerData.success(res), regenerateAgentKey.success()]),
        ),
        catchError((error: ApiError2) =>
          from([
            getInstallerData.failure({
              ...error,
              code: getErrorMessageCode(ContextNames.ENDPOINTS, error.code),
            }),
          ]),
        ),
      );
    }),
  );
};
