// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAsyncAction } from 'typesafe-actions';
import { type ApiError2 } from '../../../api/types';
import {
  type UsersTotalsPayload,
  type NormalizedUsersListPayload,
  type Authenticator,
} from './types';
import { type ListSelectors } from '../../../shared/types/list';

export const getUsersTotals = createAsyncAction(
  'users/TOTALS_REQUEST',
  'users/TOTALS_SUCCESS',
  'users/TOTALS_FAILURE',
  'users/TOTALS_CANCEL',
)<ListSelectors, UsersTotalsPayload, ApiError2, string | void>();

export const getUsersList = createAsyncAction(
  'users/LIST_REQUEST',
  'users/LIST_SUCCESS',
  'users/LIST_FAILURE',
  'users/LIST_CANCEL',
)<ListSelectors, NormalizedUsersListPayload, ApiError2, string | void>();

export const findAuthenticatorsByUserIds = createAsyncAction(
  'authenticators/FIND_AUTHS_REQUEST',
  'authenticators/FIND_AUTHS_SUCCESS',
  'authenticators/FIND_AUTHS_FAILURE',
  'authenticators/FIND_AUTHS_CANCEL',
)<
  string[],
  Record<string, Authenticator[] | undefined>,
  ApiError2,
  string | void
>();

export const unenrollProxcards = createAsyncAction(
  'authenticators/UNENROLL_REQUEST',
  'authenticators/UNENROLL_SUCCESS',
  'authenticators/UNENROLL_FAILURE',
  'authenticators/UNENROLL_CANCEL',
)<Authenticator[], void, ApiError2, string | void>();

export const getUsersListNextPortion = createAsyncAction(
  'users/NEXT_PAGE_REQUEST',
  'users/NEXT_PAGE_SUCCESS',
  'users/NEXT_PAGE_FAILURE',
  'users/NEXT_PAGE_CANCEL',
)<void, NormalizedUsersListPayload, ApiError2, string | void>();
