// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Button, ButtonSize, ButtonVariant } from '@imprivata-cloud/components';
import { stringToBase64 } from '@imprivata-cloud/data-privacy-js';
import { Form, type FormInstance } from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CERTIFICATE_HIDDEN_FIELD_NAME,
  type AdfsIdpFormValues,
  type OneSignIdpFormValues,
} from '../types';
import { IdpName } from '../../../../shared/types/api/identity-broker';
import { TextInput } from './TextInput';

export const IdpForm = (props: {
  form: FormInstance;
  idpName: IdpName;
  isUpdating: boolean;
  disabled: boolean;
  header: string;
  initialValues?: AdfsIdpFormValues | OneSignIdpFormValues;
  autoFocus?: boolean;
  onTouched?: () => void;
  'data-testid'?: string;
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    form,
    idpName,
    initialValues,
    onTouched,
    isUpdating,
    disabled,
    autoFocus,
    header,
    'data-testid': dataTestId,
  } = props;

  const isFormEmpty = !Object.entries(
    form.getFieldsValue() as Record<string, string>,
  )
    .filter(
      ([key]) =>
        key !== CERTIFICATE_HIDDEN_FIELD_NAME && key !== 'idp_certificate',
    )
    .some(([_, value]) => {
      return Boolean(value);
    });

  const fieldRules = [{ required: !isFormEmpty, message: '' }];

  return (
    <Form
      form={form}
      data-testid={dataTestId}
      initialValues={
        initialValues && {
          ...initialValues,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [CERTIFICATE_HIDDEN_FIELD_NAME]:
            form.getFieldValue(CERTIFICATE_HIDDEN_FIELD_NAME) ||
            t('identity-broker.idp-form-common.uploaded'),
        }
      }
      onChange={onTouched}
      disabled={disabled}
      validateTrigger={[]}
    >
      <div className="subtitle" style={{ marginBottom: '20px' }}>
        {header}
      </div>
      <Form.Item name="issuer_uri" rules={fieldRules}>
        <TextInput
          autoFocus={autoFocus}
          data-testid="issuer-uri"
          label={t('identity-broker.idp-form-common.issuer-uri')}
        />
      </Form.Item>
      <Form.Item name="passive_redirect_url" rules={fieldRules}>
        <TextInput
          data-testid="passive-redirect-url"
          label={t('identity-broker.idp-form-common.passive-url')}
        />
      </Form.Item>

      {idpName === IdpName.ADFS && (
        <>
          <Form.Item name="active_url_1_2" rules={fieldRules}>
            <TextInput
              data-testid="active-url-1"
              label={t('identity-broker.adfs-idp.active-url-1')}
            />
          </Form.Item>
          <Form.Item name="active_url_1_3" rules={fieldRules}>
            <TextInput
              data-testid="active-url-2"
              label={t('identity-broker.adfs-idp.active-url-2')}
            />
          </Form.Item>
        </>
      )}

      {idpName === IdpName.OneSign && (
        <Form.Item name="active_url" rules={fieldRules}>
          <TextInput
            data-testid="active-url"
            label={t('identity-broker.one-sign-idp.active-url')}
          />
        </Form.Item>
      )}

      <Form.Item name="metadata_exchange_url" rules={fieldRules}>
        <TextInput
          data-testid="metadata-exchange-url"
          label={t('identity-broker.idp-form-common.metadata-exchange-url')}
        />
      </Form.Item>
      <Form.Item name="logoff_url" rules={fieldRules}>
        <TextInput
          data-testid="logoff-url"
          label={t('identity-broker.idp-form-common.log-off-url')}
        />
      </Form.Item>
      <Form.Item>
        <div style={{ display: 'inline-block', position: 'relative' }}>
          <Form.Item
            noStyle
            name={CERTIFICATE_HIDDEN_FIELD_NAME}
            rules={[{ required: !isUpdating && !isFormEmpty, message: '' }]}
          >
            <TextInput
              disabled
              data-testid="idp-certificate--filename-input"
              label={t('identity-broker.idp-form-common.idp-certificate')}
              style={{
                cursor: 'default',
              }}
            />
          </Form.Item>
          <Button
            style={{ position: 'absolute', top: '55%', left: '100%' }}
            data-testid="idp-certificate--browse-button"
            variant={ButtonVariant.LINK}
            size={ButtonSize.MINOR}
            onClick={() => {
              fileInputRef.current?.click();
            }}
            label="Browse..."
          />
          <Form.Item hidden noStyle name="idp_certificate">
            <input />
          </Form.Item>
          <input
            style={{ display: 'none' }}
            data-testid="idp-certificate--file-input"
            type="file"
            ref={fileInputRef}
            onChange={() => {
              const file =
                fileInputRef.current?.files && fileInputRef.current.files[0];
              if (file) {
                const reader = new FileReader();
                reader.readAsText(file, 'utf-8');
                reader.onload = (e: ProgressEvent<FileReader>) => {
                  const fileContent = e.target?.result?.toString();

                  if (fileContent) {
                    form.setFieldValue(
                      CERTIFICATE_HIDDEN_FIELD_NAME,
                      file.name,
                    );
                    form.setFields([
                      {
                        name: CERTIFICATE_HIDDEN_FIELD_NAME,
                        value: file.name,
                      },
                      {
                        name: 'idp_certificate',
                        value: stringToBase64(fileContent),
                        touched: true,
                      },
                    ]);
                    void form.validateFields([CERTIFICATE_HIDDEN_FIELD_NAME]);
                  }
                };
              }
            }}
          />
        </div>
      </Form.Item>
    </Form>
  );
};
