// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Switch } from 'react-router-dom';
import Notifications from './notifications/Notifications';
import Appearance from './appearance/Appearance';
import IdentityBroker from './identity-broker/IdentityBroker';
import System from './system/System';
import ProtectedRoute from '../ProtectedRoute';
import { settingsRoutes } from '../../routers/route-names';
import Agent from './agent/Agent';
import getConfig from '../../appConfigUtils';
import AppTermination from './app-termination/AppTermination';
import AdvancedSettings from './advanced/AdvancedSettings';

const SettingsRoutes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute path={settingsRoutes.SYSTEM} component={System} />
      <ProtectedRoute
        path={settingsRoutes.NOTIFICATIONS}
        component={Notifications}
      />
      <ProtectedRoute path={settingsRoutes.APPEARANCE} component={Appearance} />
      {getConfig().IDENTITY_BROKER_PAGE_ENABLED && (
        <ProtectedRoute
          path={settingsRoutes.IDENTITY_BROKER}
          component={IdentityBroker}
        />
      )}
      {getConfig().APP_TERMINATION_PAGE_ENABLED && (
        <ProtectedRoute
          path={settingsRoutes.APP_TERMINATION}
          component={AppTermination}
        />
      )}
      {getConfig().ADVANCED_SETTINGS_PAGE_ENABLED && (
        <ProtectedRoute
          path={settingsRoutes.ADVANCED_SETTINGS}
          component={AdvancedSettings}
        />
      )}
      <ProtectedRoute path={settingsRoutes.AGENT} component={Agent} />
    </Switch>
  );
};

export default SettingsRoutes;
