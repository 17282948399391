// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { type ActionButtonProps } from './types';
import getIcon from '../../../assets/svg/getIcon.svg?react';

const EndpointAgentLink: React.FC<ActionButtonProps> =
  function EndpointAgentLink({ onClick, className, disabled }) {
    const { t } = useTranslation();

    return (
      <Button
        label={t('actions.get-agent-installer')}
        variant={ButtonVariant.TEXT}
        data-testid="endpoint-agent-link"
        icon={<Icon component={getIcon} />}
        className={className}
        onClick={onClick}
        disabled={disabled}
      />
    );
  };

export default EndpointAgentLink;
