// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  type DashboardDataInterval,
  type DashboardPayload,
  type DatasetSelectorBody,
  type DashboardDataType,
  type DashBoradDataExtraFilters,
} from './types';
import { client, noCredentialsClient } from '../client';
import { dashboard } from '../endpoint-names';
import {
  getDatasetSelectors,
  getExportDatasetSelector,
} from '../../containers/dashboard/apiConfiguration';

export async function getDashboardData(
  timeRangeType: DashboardDataInterval,
  filterSelectors: Array<DashboardDataType>,
  additionalFilters?: DashBoradDataExtraFilters,
): Promise<DashboardPayload> {
  const datasetSelectors: DatasetSelectorBody = getDatasetSelectors(
    timeRangeType,
    filterSelectors,
    additionalFilters,
  );

  const data = await client.post<DashboardPayload>(
    dashboard.GET_DASHBOARD_DATA,
    datasetSelectors,
  );

  return data?.data;
}

export async function getDashboardExportData(
  timeRangeType: DashboardDataInterval,
  filterSelectors: Array<DashboardDataType>,
  additionalFilters?: DashBoradDataExtraFilters | undefined,
): Promise<DashboardPayload> {
  // We must have a selector, otherwise throw
  if (Array.isArray(filterSelectors) && filterSelectors.length === 0) {
    throw new Error('DatasetSelectors required for export data');
  }

  // Now it's safe to access, we've guaranteed at least one element
  const datasetName = filterSelectors[0];

  const datasetSelector: DatasetSelectorBody = getExportDatasetSelector(
    timeRangeType,
    datasetName,
    undefined,
    additionalFilters,
  );

  const data = await client.post<DashboardPayload>(
    dashboard.GET_DASHBOARD_EXPORT_DATA,
    datasetSelector,
  );

  return data?.data;
}

export async function fetchDashboardExportData<T>(url: string): Promise<T> {
  const data = await noCredentialsClient.get<T>(url);
  return data?.data;
}
