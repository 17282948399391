// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import {
  type DashboardDataDatasetPayload,
  type DashboardDataInterval,
  type DashboardDataType,
  type DashboardPayload,
  type DashboardPayloadData,
  type DashBoradDataExtraFilters,
} from '../../../api/Dashboard/types';
import { type DashboardData, type FetchState } from '../types';

export function parseRawDashboardData<T extends DashboardPayload>(
  data?: T,
): DashboardData | undefined {
  try {
    if (!data) {
      return undefined;
    }

    let dataset;
    if (Array.isArray(data.dataset)) {
      dataset = data.dataset[0].dataset;
    } else {
      dataset = data.dataset.dataset;
    }

    if (!dataset) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData: DashboardDataDatasetPayload = JSON.parse(dataset);
    const parseBody = (acc: DashboardData, cur: DashboardPayloadData) => {
      if (cur && 'metadata' in cur && cur.metadata) {
        const key = cur.metadata?.datasetName;
        if (key) {
          return {
            ...acc,
            [key]: {
              keys: cur.metadata.keys,
              values: cur.data,
            },
          };
        }
      }
      return acc;
    };

    if (Array.isArray(parsedData)) {
      return parsedData.reduce(parseBody, {} as DashboardData);
    } else {
      return parseBody({} as DashboardData, parsedData);
    }
  } catch (e) {
    console.error('Error parsing data: ', e);
    return undefined;
  }
}

export function useDashboardFetch(
  callback: (
    interval: DashboardDataInterval,
    filterSelectors: Array<DashboardDataType>,
    additionalFilters?: DashBoradDataExtraFilters,
  ) => Promise<DashboardPayload>,
  fetchOnly = false,
) {
  const [fetchState, setFetchState] = React.useState<FetchState>();
  const [fetchedData, setFetchedData] = React.useState<DashboardData>();

  const getData = React.useCallback(
    async (
      interval: DashboardDataInterval,
      filterSelectors: Array<DashboardDataType>,
      additionalFilters?: DashBoradDataExtraFilters,
    ): Promise<DashboardData | undefined> => {
      if (!interval) {
        return undefined;
      }

      setFetchState({ loading: true });

      try {
        const response = await callback(
          interval,
          filterSelectors,
          additionalFilters,
        );
        const data = parseRawDashboardData(response);

        if (!fetchOnly) {
          setFetchedData(data);
        }

        setFetchState(s => (s?.error ? { ...s, error: false } : s));

        return data;
      } catch (e) {
        console.error('Error fetching dashboardData: ', e);
        setFetchedData(p => (p === undefined ? p : undefined));
        setFetchState(s => ({ ...s, error: true }));
      } finally {
        setFetchState(s => ({ ...s, loading: false }));
      }

      return undefined;
    },
    [callback, fetchOnly],
  );

  const resetData = () => {
    setFetchedData(undefined);
  };

  return {
    getData,
    fetchedData,
    fetchState,
    resetData,
  };
}
