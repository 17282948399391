import React from 'react';
import Icon from '@ant-design/icons';
import { InputBox, ImprRadio } from '@imprivata-cloud/components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Radio,
  type RadioChangeEvent,
  Row,
  Space,
  Typography,
} from 'antd';
import editApplicationClasses from './EditApplicationModal.module.less';
import credentialsIcon from '../../../assets/svg/credentials-lock.svg?react';
import getConfig from '../../../appConfigUtils';
import { CREDS_SOURCE, USERNAME_FORMAT } from './types';

const { EDIT_PROFILED_APPLICATIONS_NAME_ENABLED } = getConfig();
const { Title } = Typography;

export interface EditApplicationFormProps {
  displayName: string;
  handleDisplayNameChange: (
    displayNameValue: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  credsSourceValue: CREDS_SOURCE;
  switchCredsSource: (event: RadioChangeEvent) => void;
  usernameFormatAvailable: boolean;
  usernameFormatValue: USERNAME_FORMAT;
  switchUsernameFormat: (event: RadioChangeEvent) => void;
}

export const EditApplicationForm: React.FC<EditApplicationFormProps> = ({
  displayName,
  handleDisplayNameChange,
  credsSourceValue,
  switchCredsSource,
  usernameFormatAvailable,
  usernameFormatValue,
  switchUsernameFormat,
}: EditApplicationFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col flex="auto">
          <div
            className={editApplicationClasses.displayNameLabel}
            data-testid="application-display-name-label"
          >
            {t('apps.profile.edit.display-name')}
          </div>
          <InputBox
            data-testid="application-display-name-input"
            width="100%"
            size="small"
            type="text"
            defaultValue={displayName}
            onChange={handleDisplayNameChange}
            autoFocus={true}
            disabled={!EDIT_PROFILED_APPLICATIONS_NAME_ENABLED}
            maxLength={64}
          />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Title
            level={4}
            className={editApplicationClasses.credentialsTitle}
            data-testid="application-credentials-title"
          >
            <Icon
              className={editApplicationClasses.credentialsIcon}
              component={credentialsIcon}
              data-testid="credentials-icon"
            />
            {t('apps.profile.edit.credentials-section')}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col push={1} span={23}>
          <div
            className={editApplicationClasses.credentialsLabel}
            data-testid="application-credentials-label"
          >
            {t('apps.profile.edit.creds-source-section')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col push={1} span={23}>
          <Radio.Group
            data-testid="application-credentials-source-radio"
            onChange={switchCredsSource}
            value={credsSourceValue}
          >
            <Space direction="vertical">
              <ImprRadio
                data-testid="application-credentials-source-radio-own"
                value={CREDS_SOURCE.OWN}
              >
                {t('apps.profile.edit.own-creds-radio-option')}
              </ImprRadio>
              <ImprRadio
                data-testid="application-credentials-source-radio-domain"
                value={CREDS_SOURCE.DOMAIN}
              >
                {t('apps.profile.edit.domain-creds-radio-option')}
              </ImprRadio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col push={2} span={22}>
          <div
            className={clsx(editApplicationClasses.usernameLabel, {
              [editApplicationClasses.disabled]: !usernameFormatAvailable,
            })}
            data-testid="application-username-format-label"
            aria-hidden={!usernameFormatAvailable}
          >
            {t('apps.profile.edit.username-format-section')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col push={2} span={22}>
          <Row>
            <Col span={8}>
              <Radio.Group
                data-testid="application-username-radio-group"
                disabled={!usernameFormatAvailable}
                value={usernameFormatValue}
                onChange={switchUsernameFormat}
              >
                <Space direction="vertical">
                  <ImprRadio
                    data-testid="application-username-radio-SAM"
                    value={USERNAME_FORMAT.SAM}
                  >
                    {t('apps.profile.edit.sam-radio-option')}
                  </ImprRadio>
                  <ImprRadio
                    data-testid="application-username-radio-UPN"
                    value={USERNAME_FORMAT.UPN}
                  >
                    {t('apps.profile.edit.upn-radio-option')}
                  </ImprRadio>
                  <ImprRadio
                    data-testid="application-username-radio-NET"
                    value={USERNAME_FORMAT.NET}
                  >
                    {t('apps.profile.edit.netbios-radio-option')}
                  </ImprRadio>
                </Space>
              </Radio.Group>
            </Col>
            <Col
              span={16}
              className={clsx({
                [editApplicationClasses.disabled]: !usernameFormatAvailable,
              })}
              data-testid="application-username-info"
              aria-hidden={!usernameFormatAvailable}
            >
              <Space direction="vertical">
                <div>jsmith</div>
                <div>jsmith@acme.com</div>
                <div>acme\jsmith</div>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditApplicationForm;
