// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDesktopGracePeriod, getGracePeriod } from './actions';
import {
  isLoadingSelector,
  dataSelector,
  desktopDataSelector,
} from './selectors';

export const useGetGracePeriod = (): {
  data: ReturnType<typeof dataSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
} => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector);
  const data = useSelector(dataSelector);
  useEffect(() => {
    dispatch(getGracePeriod());
  }, [dispatch]);

  return { data, isLoading: loading };
};

export const useGetDesktopGracePeriod = (): {
  desktopData: ReturnType<typeof desktopDataSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
} => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector);
  const desktopData = useSelector(desktopDataSelector);
  useEffect(() => {
    dispatch(getDesktopGracePeriod());
  }, [dispatch]);

  return { desktopData, isLoading: loading };
};
