// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { from, of } from 'rxjs';
import { map, mergeMap, startWith, catchError } from 'rxjs/operators';
import { type Epic, ofType } from 'redux-observable';
import { FETCH_ORG_PREFERENCES, SUCCESS_ORG_PREFERENCES } from './constants';

import {
  loadOrgPreferencesFail,
  loadOrgPreferencesInProgress,
  loadOrgPreferencesSuccess,
} from './actions';

import { fetchOrgPreferences } from '../../../../api/preferencesService';
import { getErrorMessageCode } from '../../../../i18n/utils';
import { ContextNames } from '../../../../i18n';

export const fetchAppearanceEpic: Epic = action$ =>
  action$.pipe(
    ofType(FETCH_ORG_PREFERENCES),
    mergeMap(() => {
      return from(fetchOrgPreferences()).pipe(
        map(response => {
          return loadOrgPreferencesSuccess({
            data: {
              orgName: response.orgName,
              logoUrl: response.logoUrl,
              usernameLabel: response.usernameLabel,
              passwordLabel: response.passwordLabel,
              proxCardLabel: response.proxCardLabel,
            },
            operations: SUCCESS_ORG_PREFERENCES,
          });
        }),
        catchError(error =>
          of(
            loadOrgPreferencesFail({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              error: getErrorMessageCode(ContextNames.APPEARANCE, error.code),
            }),
          ),
        ),
        startWith(loadOrgPreferencesInProgress()),
      );
    }),
  );
