/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { findDirectoriesActions, syncActions } from './actions';
import { type RootAction } from '../../../store/rootAction';
import {
  type Directory,
  DirectorySyncStatusCode,
  type DirectoriesSyncFailedPayload,
  type DirectorySyncProgressPayload,
} from '../../../api/types';

export interface FindDirectoriesState {
  loading: boolean;
  error: string | null;
  directories: Directory[];
}

// We will try to follow approach on login and do the less refactor possible. That's why we are creating a different state
export interface SyncDirectoriesState {
  loading: boolean;
  error: string | null;
  progress: DirectorySyncProgressPayload;
  failures: DirectoriesSyncFailedPayload;
}

export interface DirectoriesState {
  syncDirectories: SyncDirectoriesState;
  findDirectories: FindDirectoriesState;
}

export const initialSyncDetailsState: SyncDirectoriesState = {
  loading: false,
  error: null,
  progress: {
    info: undefined,
    latestSyncAt: '',
    syncStatus: DirectorySyncStatusCode.NOT_INITIALIZED,
  },
  failures: {
    info: [],
  },
};

export const initialState: DirectoriesState = {
  findDirectories: {
    directories: [],
    loading: false,
    error: null,
  },
  syncDirectories: initialSyncDetailsState,
};

export const findDirectoriesReducer = combineReducers<FindDirectoriesState>({
  loading: createReducer<boolean>(initialState.findDirectories.loading)
    .handleAction([findDirectoriesActions.request], () => true)
    .handleAction(
      [
        findDirectoriesActions.cancel,
        findDirectoriesActions.success,
        findDirectoriesActions.failure,
      ],
      () => false,
    ),
  directories: createReducer<Directory[], RootAction>(
    initialState.findDirectories.directories,
  ).handleAction(
    findDirectoriesActions.success,
    (_state, { payload }) => payload.directories,
  ),
  error: createReducer<string | null, RootAction>(
    initialState.findDirectories.error,
  )
    .handleAction(
      [findDirectoriesActions.request, findDirectoriesActions.success],
      () => null,
    )
    .handleAction(
      findDirectoriesActions.failure,
      (_, { payload }) => payload.code || null,
    )
    .handleAction(findDirectoriesActions.cancel, () => 'cancelled'),
});

export const syncDirectoriesReducer = combineReducers<SyncDirectoriesState>({
  loading: createReducer<boolean>(initialState.syncDirectories.loading)
    .handleAction([syncActions.sync], () => true)
    .handleAction(
      [
        syncActions.error,
        syncActions.complete,
        syncActions.progress,
        syncActions.failures,
      ],
      () => false,
    ),
  progress: createReducer<DirectorySyncProgressPayload, RootAction>(
    initialState.syncDirectories.progress,
  ).handleAction(syncActions.progress, (_state, { payload }) => payload),
  failures: createReducer<DirectoriesSyncFailedPayload, RootAction>(
    initialState.syncDirectories.failures,
  ).handleAction(syncActions.failures, (_state, { payload }) => payload),
  error: createReducer<string | null, RootAction>(
    initialState.syncDirectories.error,
  )
    .handleAction(
      // TODO: Maybe add action for complete
      [syncActions.sync, syncActions.progress, syncActions.failures],
      () => null,
    )
    .handleAction(syncActions.error, (_, { payload }) => payload || null),
});

export const directoriesReducer = combineReducers<DirectoriesState>({
  findDirectories: findDirectoriesReducer,
  syncDirectories: syncDirectoriesReducer,
});
