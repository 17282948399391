// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { from, of } from 'rxjs';
import { map, mergeMap, startWith, catchError } from 'rxjs/operators';
import { type Epic, ofType } from 'redux-observable';
import {
  GET_DESKTOP_GRACE_PERIOD,
  GET_GRACE_PERIOD,
  SUCCESS_DESKTOP_GRACE_PERIOD,
  SUCCESS_GRACE_PERIOD,
} from './constants';

import {
  loadDesktopGracePeriodSuccess,
  loadGracePeriodFail,
  loadGracePeriodInProgress,
  loadGracePeriodSuccess,
} from './actions';

import { getGracePeriod } from '../../../api/policyService';
import { getErrorMessageCode } from '../../../i18n/utils';
import { ContextNames } from '../../../i18n';
import { contextTypes } from '../../../api/constants';

export const getGracePeriodEpic: Epic = action$ =>
  action$.pipe(
    ofType(GET_GRACE_PERIOD),
    mergeMap(() => {
      return from(getGracePeriod({ contextType: contextTypes.clinical })).pipe(
        map(response => {
          return loadGracePeriodSuccess({
            data: {
              gracePeriodSecs: response.gracePeriodSecs,
              contextType: response.contextType,
            },
            operations: SUCCESS_GRACE_PERIOD,
          });
        }),
        catchError(error =>
          of(
            loadGracePeriodFail({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              error: getErrorMessageCode(ContextNames.SECURITY, error.code),
            }),
          ),
        ),
        startWith(loadGracePeriodInProgress()),
      );
    }),
  );

export const getDesktopGracePeriodEpic: Epic = action$ =>
  action$.pipe(
    ofType(GET_DESKTOP_GRACE_PERIOD),
    mergeMap(() => {
      return from(getGracePeriod({ contextType: contextTypes.desktop })).pipe(
        map(response => {
          return loadDesktopGracePeriodSuccess({
            data: {
              gracePeriodSecs: response.gracePeriodSecs,
              contextType: response.contextType,
            },
            operations: SUCCESS_DESKTOP_GRACE_PERIOD,
          });
        }),
        catchError(error =>
          of(
            loadGracePeriodFail({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              error: getErrorMessageCode(ContextNames.SECURITY, error.code),
            }),
          ),
        ),
        startWith(loadGracePeriodInProgress()),
      );
    }),
  );
