/* eslint-disable @typescript-eslint/require-await */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type ThunkAction } from 'redux-thunk';
import { type Action, type PayloadAction } from 'typesafe-actions';
import {
  SUCCESS_DESKTOP_GRACE_PERIOD,
  ERROR_GRACE_PERIOD,
  GET_GRACE_PERIOD,
  PENDING_GRACE_PERIOD,
  SUCCESS_GRACE_PERIOD,
  ERROR_DESKTOP_GRACE_PERIOD,
  GET_DESKTOP_GRACE_PERIOD,
  PENDING_DESKTOP_GRACE_PERIOD,
} from './constants';
import { type GracePeriod, type GracePeriodState } from './reducers';

type GetGracePeriodActionType = Action<typeof GET_GRACE_PERIOD>;
type GetDesktopGracePeriodActionType = Action<typeof GET_DESKTOP_GRACE_PERIOD>;

export const getGracePeriod =
  (): ThunkAction<
    Promise<void>,
    GracePeriodState,
    undefined,
    GetGracePeriodActionType
  > =>
  async dispatch => {
    dispatch({
      type: GET_GRACE_PERIOD,
    });
  };

export const getDesktopGracePeriod =
  (): ThunkAction<
    Promise<void>,
    GracePeriodState,
    undefined,
    GetDesktopGracePeriodActionType
  > =>
  async dispatch => {
    dispatch({
      type: GET_DESKTOP_GRACE_PERIOD,
    });
  };

type LoadGracePeriodSuccessType = PayloadAction<
  typeof SUCCESS_GRACE_PERIOD,
  {
    data: GracePeriod;
  }
>;

type LoadDesktopGracePeriodSuccessType = PayloadAction<
  typeof SUCCESS_DESKTOP_GRACE_PERIOD,
  {
    data: GracePeriod;
  }
>;

export const loadGracePeriodSuccess =
  ({
    data,
    operations: _,
  }: {
    data: GracePeriod;
    operations: string;
  }): ThunkAction<
    Promise<void>,
    GracePeriodState,
    undefined,
    LoadGracePeriodSuccessType
  > =>
  async dispatch => {
    dispatch({ type: SUCCESS_GRACE_PERIOD, payload: { data } });
  };

export const loadDesktopGracePeriodSuccess =
  ({
    data,
    operations: _,
  }: {
    data: GracePeriod;
    operations: string;
  }): ThunkAction<
    Promise<void>,
    GracePeriodState,
    undefined,
    LoadDesktopGracePeriodSuccessType
  > =>
  async dispatch => {
    dispatch({ type: SUCCESS_DESKTOP_GRACE_PERIOD, payload: { data } });
  };

export type LoadGracePeriodFailType = PayloadAction<
  typeof ERROR_GRACE_PERIOD,
  { data: { error: string } }
>;

export type LoadDesktopGracePeriodFailType = PayloadAction<
  typeof ERROR_DESKTOP_GRACE_PERIOD,
  { data: { error: string } }
>;

export const loadGracePeriodFail = ({
  error,
}: {
  error: string;
}): LoadGracePeriodFailType => ({
  type: ERROR_GRACE_PERIOD,
  payload: { data: { error } },
});

export const loadDesktopGracePeriodFail = ({
  error,
}: {
  error: string;
}): LoadDesktopGracePeriodFailType => ({
  type: ERROR_DESKTOP_GRACE_PERIOD,
  payload: { data: { error } },
});

export type LoadGracePeriodProgressType = Action<typeof PENDING_GRACE_PERIOD>;
export type LoadDesktopGracePeriodProgressType = Action<
  typeof PENDING_DESKTOP_GRACE_PERIOD
>;

export const loadGracePeriodInProgress =
  (): ThunkAction<
    Promise<void>,
    GracePeriodState,
    undefined,
    LoadGracePeriodProgressType
  > =>
  async dispatch => {
    dispatch({ type: PENDING_GRACE_PERIOD });
  };

export const loadDesktopGracePeriodInProgress =
  (): ThunkAction<
    Promise<void>,
    GracePeriodState,
    undefined,
    LoadDesktopGracePeriodProgressType
  > =>
  async dispatch => {
    dispatch({ type: PENDING_DESKTOP_GRACE_PERIOD });
  };

export type GracePeriodAction =
  | GetGracePeriodActionType
  | LoadGracePeriodSuccessType
  | LoadGracePeriodFailType
  | LoadGracePeriodProgressType
  | GetDesktopGracePeriodActionType
  | LoadDesktopGracePeriodSuccessType
  | LoadDesktopGracePeriodFailType
  | LoadDesktopGracePeriodProgressType;
