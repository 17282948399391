// Copyright 2024, Imprivata, Inc.  All rights reserved.

import i18n from '../../../i18n';
import { CERTIFICATE_HIDDEN_FIELD_NAME } from './types';

export const getTranslatedFieldName = (name: string): string => {
  switch (name) {
    case 'issuer_uri':
      return i18n.t('identity-broker.field-names.issuer_uri');
    case 'active_url':
      return i18n.t('identity-broker.field-names.active_url');
    case 'active_url_1_2':
      return i18n.t('identity-broker.field-names.active_url_1_2');
    case 'active_url_1_3':
      return i18n.t('identity-broker.field-names.active_url_1_3');
    case 'metadata_exchange_url':
      return i18n.t('identity-broker.field-names.metadata_exchange_url');
    case 'logoff_url':
      return i18n.t('identity-broker.field-names.logoff_url');
    case 'passive_redirect_url':
      return i18n.t('identity-broker.field-names.passive_redirect_url');
    case 'idp_certificate':
    case CERTIFICATE_HIDDEN_FIELD_NAME:
      return i18n.t('identity-broker.field-names.idp_certificate');
    default: {
      console.error(`Cannot find translation for field name "${name}"`);
      return name;
    }
  }
};

export const constructErrorMessage = ({
  adfsErrorField,
  oneSignErrorField,
}: {
  adfsErrorField?: string;
  oneSignErrorField?: string;
}) => {
  const certificateFieldName = 'idp_certificate';

  if (
    adfsErrorField === certificateFieldName ||
    oneSignErrorField === certificateFieldName
  ) {
    return i18n.t('identity-broker.error-messages.bad-file');
  } else if (adfsErrorField) {
    return i18n.t('identity-broker.error-messages.adfs-config-error', {
      fieldNames: getTranslatedFieldName(adfsErrorField),
    });
  } else if (oneSignErrorField) {
    return i18n.t('identity-broker.error-messages.one-sign-config-error', {
      fieldNames: getTranslatedFieldName(oneSignErrorField),
    });
  }

  return '';
};
