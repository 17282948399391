// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const GET_GRACE_PERIOD = 'GET_GRACE_PERIOD';
export const PENDING_GRACE_PERIOD = 'PENDING_GRACE_PERIOD';
export const SUCCESS_GRACE_PERIOD = 'SUCCESS_GRACE_PERIOD';
export const ERROR_GRACE_PERIOD = 'ERROR_GRACE_PERIOD';

export const GET_DESKTOP_GRACE_PERIOD = 'GET_DESKTOP_GRACE_PERIOD';
export const PENDING_DESKTOP_GRACE_PERIOD = 'PENDING_DESKTOP_GRACE_PERIOD';
export const SUCCESS_DESKTOP_GRACE_PERIOD = 'SUCCESS_DESKTOP_GRACE_PERIOD';
export const ERROR_DESKTOP_GRACE_PERIOD = 'ERROR_DESKTOP_GRACE_PERIOD';

export type CONSTANTS_TYPES =
  | typeof GET_GRACE_PERIOD
  | typeof PENDING_GRACE_PERIOD
  | typeof SUCCESS_GRACE_PERIOD
  | typeof ERROR_GRACE_PERIOD
  | typeof GET_DESKTOP_GRACE_PERIOD
  | typeof PENDING_DESKTOP_GRACE_PERIOD
  | typeof SUCCESS_DESKTOP_GRACE_PERIOD
  | typeof ERROR_DESKTOP_GRACE_PERIOD;
