// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const errorMapping = {
  'login.logout-due-invalid-session': 'warning',
  'login.internal-error': 'warning',
  UXID_SYNC_FAILED: 'error',
  UXID_SYNC_NOTPOSSIBLE: 'warning',
  'start-sync-failure': 'warning',
  'sync-already-running': 'warning',
  'sync-failed': 'error',
  'session-expired': 'info',
};
