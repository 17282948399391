// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Modal } from 'antd';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ModalShared.module.less';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onAction: () => void;
}

const AgentKeyRegerationModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      data-testid="key-regeneration-modal"
      title={t('agent.key-replace-title')}
      className={classes.modal}
      open={props.visible}
      onCancel={props.onCancel}
      footer={
        <div className={classes.modalFooter}>
          <Button
            label={t('agent.regenerate-key-modal.keep-current-button')}
            data-testid="cancel-regenerate-button"
            variant={ButtonVariant.PRIMARY}
            onClick={props.onCancel}
          />
          <Button
            label={t('agent.regenerate-key-modal.create-key-button')}
            data-testid="regenerate-button"
            onClick={props.onAction}
          />
        </div>
      }
    >
      <p>{t('agent.regenerate-key-modal.main-text')}</p>
    </Modal>
  );
};

export default AgentKeyRegerationModal;
