// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import { type ApiError2 } from '../../../api/types';
import { type ListSelectors } from '../../../shared/types/list';
import { type EndpointsListPayload, type InstallerDataPayload } from './types';

export const getInstallerData = createAsyncAction(
  'endpoints/GET_INSTALLER_DATA_REQUEST',
  'endpoints/GET_INSTALLER_DATA_SUCCESS',
  'endpoints/GET_INSTALLER_DATA_FAILURE',
  'endpoints/GET_INSTALLER_DATA_CANCEL',
)<void, InstallerDataPayload, ApiError2, string | void>();

export const getEndpointsListData = createAsyncAction(
  'endpoints/GET_ENDPOINTS_LIST_REQUEST',
  'endpoints/GET_ENDPOINTS_LIST_SUCCESS',
  'endpoints/GET_ENDPOINTS_LIST_FAILURE',
  'endpoints/GET_ENDPOINTS_LIST_CANCEL',
)<ListSelectors, EndpointsListPayload, ApiError2, string | void>();

export const getEndpointsListNextPortion = createAsyncAction(
  'endpoints/NEXT_PAGE_REQUEST',
  'endpoints/NEXT_PAGE_SUCCESS',
  'endpoints/NEXT_PAGE_FAILURE',
  'endpoints/NEXT_PAGE_CANCEL',
)<void, EndpointsListPayload, ApiError2, string | void>();

export const noEndpointsAction = createAction(
  'endpoints/NO_ENDPOINTS',
)<boolean>();

export const getEndpointsTotals = createAsyncAction(
  'endpoints/TOTALS_REQUEST',
  'endpoints/TOTALS_SUCCESS',
  'endpoints/TOTALS_FAILURE',
  'endpoints/TOTALS_CANCEL',
)<ListSelectors, number, ApiError2, string | void>();
