// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { type GracePeriodAction } from './actions';
import {
  GET_GRACE_PERIOD,
  GET_DESKTOP_GRACE_PERIOD,
  PENDING_GRACE_PERIOD,
  SUCCESS_GRACE_PERIOD,
  ERROR_GRACE_PERIOD,
  SUCCESS_DESKTOP_GRACE_PERIOD,
  PENDING_DESKTOP_GRACE_PERIOD,
  ERROR_DESKTOP_GRACE_PERIOD,
} from './constants';
import { type RootAction } from '../../../store/rootAction';

export interface GracePeriod {
  gracePeriodSecs: number;
  contextType?: string;
}

export interface GracePeriodState {
  data: GracePeriod;
  desktopData: GracePeriod;
  operations: string;
  error: string;
}

export const operationsReducer = createReducer<string, GracePeriodAction>(
  '',
).handleType(
  [
    GET_GRACE_PERIOD,
    GET_DESKTOP_GRACE_PERIOD,
    PENDING_GRACE_PERIOD,
    PENDING_DESKTOP_GRACE_PERIOD,
    SUCCESS_GRACE_PERIOD,
    SUCCESS_DESKTOP_GRACE_PERIOD,
    ERROR_GRACE_PERIOD,
    ERROR_DESKTOP_GRACE_PERIOD,
  ],
  (_state, action) => action.type,
);

export const gracePeriodDataReducer = createReducer<
  GracePeriod,
  GracePeriodAction
>({
  gracePeriodSecs: 3600 * 4.5,
  contextType: 'clinical-app-login',
}).handleType(SUCCESS_GRACE_PERIOD, (_state, { payload }) => {
  return payload.data;
});

export const desktopGracePeriodDataReducer = createReducer<
  GracePeriod,
  GracePeriodAction
>({
  gracePeriodSecs: 3600 * 4.5,
  contextType: 'endpoint-login',
}).handleType(SUCCESS_DESKTOP_GRACE_PERIOD, (_state, { payload }) => {
  return payload.data;
});

export const fetchErrorReducer = createReducer<string | null, RootAction>(
  null,
).handleType(
  ERROR_GRACE_PERIOD,
  (_, { payload }) => payload.data.error || null,
);

export const securityReducer = combineReducers({
  data: gracePeriodDataReducer,
  desktopData: desktopGracePeriodDataReducer,
  operations: operationsReducer,
  error: fetchErrorReducer,
});
