// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserTotals } from './store/hooks';
import { useSyncDirectories } from '../directories/store/hooks';

const placeholderTitle = { width: 200 };

const UsersTotals: React.FC = function UsersTotals() {
  const { totals, fetchTotals } = useUserTotals();
  const { loading, usersCount } = totals;
  const { t } = useTranslation();
  const { isSyncSuccess } = useSyncDirectories({});

  useEffect(() => {
    if (isSyncSuccess) {
      fetchTotals();
    }
  }, [isSyncSuccess, fetchTotals]);

  return (
    <Skeleton
      active
      loading={loading}
      paragraph={false}
      title={placeholderTitle}
      data-testid="users-total-loader"
    >
      <span data-testid="users-total">
        <span className="primary-body-bold">{usersCount}</span>{' '}
        {t('users.total-users', { count: usersCount })}
      </span>
    </Skeleton>
  );
};

export default UsersTotals;
