// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { SearchTagSelect } from '@imprivata-cloud/components';
import { Form, type FormInstance } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import type {
  IdpMetadata,
  IdpName,
} from '../../../../shared/types/api/identity-broker';
import { type Group } from '../../../../shared/types/api/groups';

import classes from '../IdentityBroker.module.less';
import { useGroupsQuery, useIdpMetadataQuery } from '../hooks';

export const GroupSelectionContainer = (props: {
  formInstance: FormInstance;
  metadataName: IdpName;
  title: string;
  onTouched?: () => void;
}) => {
  const { formInstance, metadataName, title, onTouched, ...restProps } = props;

  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data?.groups;

  const idpMetadatasQuery = useIdpMetadataQuery();

  const metadata: IdpMetadata | undefined =
    idpMetadatasQuery.data?.metadataList.find(
      metadata => metadata.metadataName === metadataName,
    );

  const isDataLoaded = idpMetadatasQuery.isFetched && groupsQuery.isFetched;
  const encodedGroupsString = metadata?.userRule || '';
  const decodedGroups = encodedGroupsString
    ? encodedGroupsString.split(',')
    : undefined;
  const oneSignGroups = Array.isArray(decodedGroups) ? decodedGroups : [];

  const initialGroups = groups
    ? oneSignGroups.flatMap(id => {
        const group = groups.find(group => group.groupId === id);
        if (group) {
          return [group.groupName];
        } else {
          console.error(`No group with id ${id}`);
          return [];
        }
      })
    : undefined;

  const [searchValue, setSearchValue] = useState('');
  const [selected, _setSelected] = useState<string[]>([]);
  const setSelected: typeof _setSelected = useCallback(
    (...args) => {
      setSearchValue('');
      _setSelected(...args);
    },
    [_setSelected, setSearchValue],
  );

  const options =
    groups && searchValue.length >= 2
      ? groups
          .map(g => g.groupName)
          .filter(name => {
            return name.toLowerCase().startsWith(searchValue.toLowerCase());
          })
      : [];

  useEffect(() => {
    const selectedGroups = selected.flatMap<Group>(name => {
      const group = groups?.find(g => g.groupName === name);
      return group ? [group] : [];
    });
    const groupIds = selectedGroups.map(group => group.groupId);
    formInstance.setFieldsValue({
      selected: groupIds,
    });
  }, [selected, formInstance, groups]);

  useEffect(() => {
    if (initialGroups) {
      setSelected(initialGroups);
    }
  }, [isDataLoaded, setSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.groupSelectionContainer} {...restProps}>
      <div className="group-box-label">{title}</div>
      <SearchTagSelect
        options={options.slice(0, 5)}
        value={selected}
        searchValue={searchValue}
        onChange={(...args) => {
          setSelected(...args);
          onTouched && onTouched();
        }}
        onSearch={setSearchValue}
      />
      {isDataLoaded && (
        <Form
          hidden={true}
          form={formInstance}
          initialValues={{
            selected: oneSignGroups,
          }}
        >
          <Form.Item name="selected" />
        </Form>
      )}
    </div>
  );
};
