// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type RawSpanAttributes } from '@imprivata-cloud/common';
import { tracer, SpanNames } from '../../tracing';

export function endAuthenticationTracingSpan(
  attributes?: RawSpanAttributes,
): void {
  tracer.endSpan(SpanNames.AUTHENTICATION, attributes);
}

export function startCreateUserSessionTracingSpan(
  attributes?: RawSpanAttributes,
): void {
  tracer.startSubspanFromParent(
    SpanNames.CREATE_USER_SESSION,
    SpanNames.AUTHENTICATION,
    attributes,
  );
}

export function endCreateUserSessionTracingSpan(
  attributes: RawSpanAttributes,
): void {
  tracer.endSpan(SpanNames.CREATE_USER_SESSION, attributes);
}
