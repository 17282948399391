// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import type { FormInstance } from 'antd';
import type { Setting, SettingChange } from '../../../shared/types';
import {
  AdvancedSettings,
  CategoryName,
  ChangeType,
  ConfigType,
  FeatureKey,
} from '../../../shared/types';

import SaveButton from '../../../components/page-layout/action-bar/SaveButton';
import CancelButton from '../../../components/page-layout/action-bar/CancelButton';
import { SettingsPageLayout } from '../components';
import SettingsForm from './SettingsForm';
import {
  changeCustomSettings,
  getAdvancedSettingsConfig,
} from '../../../api/AdvancedSettings/advancedSettingsService';
import SaveDiscardModal from '../../../containers/modals/save-discard-modal/SaveDiscardModal';
import { sortItemsByDate } from '../../../utils/DateUtils';
import getScriptType from './script';

export const initialSettingItem: Setting = {
  name: '',
  value: '',
  categoryName: CategoryName['custom-settings'],
  valueType: 'value',
  featureKey: FeatureKey.eamc,
  configType: ConfigType['advanced-settings'],
};

export enum FormName {
  scripts = 'scripts',
  customSettings = 'customSettings',
}

type FormValue = Array<FormName | number | string>;

const AdvancedSettings = () => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState<Setting[]>([
    initialSettingItem,
  ]);
  const [isPageTouched, setIsPageTouched] = useState<boolean>(false);
  const [isSettingsNeedsToBeFetched, setIsSettingsNeedsToBeFetched] =
    useState(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [scriptsForm] = Form.useForm();
  const [customSettingsForm] = Form.useForm();

  useEffect(() => {
    if (isSettingsNeedsToBeFetched) {
      (async () => {
        try {
          const res = await getAdvancedSettingsConfig({
            featureKey: FeatureKey.eamc,
            configType: ConfigType['advanced-settings'],
          });
          if (res?.settings.length) {
            const sortedByCreatedAt = sortItemsByDate(
              res.settings,
              'createdAt',
              'DESC',
            );

            const settings = convertToAdvancedSettings(sortedByCreatedAt);
            scriptsForm.setFieldsValue({
              scripts: settings.scripts?.length
                ? settings.scripts
                : [initialSettingItem],
            });
            customSettingsForm.setFieldsValue({
              customSettings: settings.customSettings?.length
                ? settings.customSettings
                : [initialSettingItem],
            });
            setAdvancedSettings(sortedByCreatedAt);
          }
        } catch (err) {
          console.error('Error fetching app termination configuration:', err);
          throw err;
        } finally {
          setIsLoaded(true);
        }
      })();
    }
    setIsSettingsNeedsToBeFetched(false);
  }, [isSettingsNeedsToBeFetched, scriptsForm, customSettingsForm]);

  const convertToAdvancedSettings = (settings: Setting[]): AdvancedSettings => {
    return settings
      .map((setting: Setting) => {
        return {
          ...setting,
          scriptType: getScriptType(setting.value),
        };
      })
      .reduce(
        (advancedSettings: AdvancedSettings, setting) => {
          if (setting.scriptType || setting.name.endsWith('script')) {
            advancedSettings.scripts.push(setting);
          } else {
            advancedSettings.customSettings.push(setting);
          }
          return advancedSettings;
        },
        { scripts: [], customSettings: [] },
      );
  };

  const validateFormIfNeeded = async (
    form: FormInstance,
    formName: FormName,
  ): Promise<Setting[]> => {
    return form.isFieldsTouched()
      ? form
          .validateFields(getFieldsForValidation(form, formName))
          .then((settings: AdvancedSettings) => settings[formName])
      : Promise.resolve(form.getFieldValue(formName) as Setting[]);
  };

  const onSave = () => {
    const scriptChanges = validateFormIfNeeded(scriptsForm, FormName.scripts);
    const customSettingsChanges = validateFormIfNeeded(
      customSettingsForm,
      FormName.customSettings,
    );

    Promise.all([scriptChanges, customSettingsChanges])
      .then(async settings => onChange(settings.flat().filter(Boolean)))
      .then(() => {
        setIsPageTouched(false);
        setIsModalVisible(false);
        setIsSettingsNeedsToBeFetched(true);
        console.log('save successful');
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onChange = async (settings: Setting[]) => {
    const settingsToDelete = advancedSettings
      .filter(
        setting =>
          !settings.some(
            formValue => formValue.settingId === setting.settingId,
          ),
      )
      .map(setting => mapToSettingChange(setting, ChangeType.DELETE));
    const settingToUpdate = settings
      .filter(setting => setting.name)
      .filter(
        setting =>
          !settingsToDelete.some(
            formValue => formValue.setting.settingId === setting.settingId,
          ),
      )
      .map(setting => mapToSettingChange(setting, ChangeType.UPSERT));
    await changeCustomSettings({
      changes: [...settingsToDelete, ...settingToUpdate],
    });
  };

  const mapToSettingChange = (
    setting: Setting,
    changeType: ChangeType,
  ): SettingChange => {
    return {
      changeType,
      setting,
    };
  };

  const onCancel = () => {
    setIsSettingsNeedsToBeFetched(true);
    setIsModalVisible(false);
    setIsPageTouched(false);
    console.log('cancel successful');
  };

  const onFormTouch = (form: FormInstance, formName: FormName) => {
    form
      .validateFields(getFieldsForValidation(form, formName))
      .then(() => {
        handleValidationSuccess(form, formName);
      })
      .catch(() => {
        setIsPageTouched(false);
      });
  };

  const handleValidationSuccess = (form: FormInstance, formName: FormName) => {
    const emptyFields = getFieldsForReset(form, formName);
    if (emptyFields.length) {
      form.resetFields(emptyFields);
    }
    setIsPageTouched(true);
  };

  const getFieldsForValidation = (
    form: FormInstance,
    formName: FormName,
  ): FormValue[] => {
    return getFields(
      form,
      formName,
      (field: Setting) => !!(field.name || field.value),
    );
  };

  const getFieldsForReset = (
    form: FormInstance,
    formName: FormName,
  ): FormValue[] => {
    return getFields(
      form,
      formName,
      (field: Setting) => !field.name && !field.value,
    );
  };

  const getFields = (
    form: FormInstance,
    formName: FormName,
    filter: (setting: Setting) => boolean,
  ): FormValue[] => {
    return (form.getFieldsValue() as AdvancedSettings)[formName].reduce(
      (acc: FormValue[], value: Setting, index: number) => {
        if (filter(value)) {
          acc.push(
            ...Object.keys(value).map(field => [formName, index, field]),
          );
        }
        return acc;
      },
      [],
    );
  };

  // When the user wants to leave without saving
  useEffect(() => {
    if (isPageTouched) {
      setIsModalVisible(true);
    }
  }, [isPageTouched]);

  return (
    <SettingsPageLayout
      title={
        <>
          <SaveButton
            disabled={!isPageTouched}
            key="save-button"
            data-testid="save-button"
            onClick={onSave}
          />
          <CancelButton
            data-testid="cancel-button"
            key="cancel-button"
            onClick={onCancel}
            disabled={!isPageTouched}
          />
          <SaveDiscardModal
            title={t('advanced-settings.save-discard-modal.title')}
            cancelText={t('advanced-settings.save-discard-modal.discard')}
            okText={t('actions.save')}
            content={t('advanced-settings.save-discard-modal.content')}
            open={isModalVisible}
            onSave={onSave}
            onDiscard={onCancel}
          />
        </>
      }
    >
      <div
        className="h2-header"
        style={{ marginBottom: '44px', marginTop: '22px' }}
      >
        {t('advanced-settings.header')}
      </div>
      <SettingsForm
        initialValues={[initialSettingItem]}
        formName={FormName.scripts}
        form={scriptsForm}
        onTouched={onFormTouch}
        disabled={!isLoaded}
      />
      <SettingsForm
        initialValues={[initialSettingItem]}
        formName={FormName.customSettings}
        form={customSettingsForm}
        onTouched={onFormTouch}
        disabled={!isLoaded}
      />
    </SettingsPageLayout>
  );
};

export default AdvancedSettings;
