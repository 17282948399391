// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import { type Observable } from 'rxjs';
import { type AxiosResponse } from 'axios';
import rxClient from '../rxClient';
import { apps } from '../endpoint-names';
import { type ApiError2 } from '../types';
import {
  type UpdateApiProfiledApp,
  type AppConfigGetResponse,
  type AppConfigUpdateRequest,
  type AppIntegrationFindData,
  type AppProfileImportRequestData,
  type AppProfilePrepareFileUploadResponse,
  type AppProfilePrepareFileUploadResponseData,
  type ApplicationsFindData,
  type DeleteAppProfileRequestData,
  type DeleteAppProfileResponse,
  type GetAppProfileByIdResponse,
  type UpdateAppProfileRequest,
} from '../../containers/applications/store/types';
import { client, noCredentialsClient } from '../client';

// ApiResponse SuccessResponse is AppIntegrationFindByNameResponse in our case
export function getAppIntegration$(): Observable<AppIntegrationFindData> {
  return rxClient
    .post<AppIntegrationFindData>(apps.APP_INTEGRATION_FIND_BY_NAME, {
      appName: 'epic-hyperdrive',
    })
    .pipe(map(res => res.data));
}

export function getApiApplication$(): Observable<ApplicationsFindData> {
  return rxClient
    .post<ApplicationsFindData>(apps.APPLICATIONS_FIND)
    .pipe(map(res => res.data));
}

export function getAppConfig$(): Observable<AppConfigGetResponse> {
  return rxClient
    .post<AppConfigGetResponse>(apps.APP_CONFIG_FIND, {
      applicationId: 'epic-hyperdrive',
    })
    .pipe(map(res => res.data));
}

export function updateAppConfig$(
  config: AppConfigUpdateRequest,
): Observable<void | ApiError2> {
  return rxClient
    .post<void>(apps.APP_CONFIG_UPDATE, config)
    .pipe(map(res => res.data));
}

export async function getApplicationProfileUploadUrl(
  fileSuffix: string,
  fileAccessModifier: 'private',
): Promise<AppProfilePrepareFileUploadResponseData> {
  return client
    .post<AppProfilePrepareFileUploadResponseData>(
      apps.APP_PROFILE_PREPARE_UPLOAD,
      {
        fileSuffix,
        fileAccessModifier,
      },
    )
    .then(res => res.data);
}

export async function uploadApplicationFile(
  file: File,
  uploadUrl: string,
): Promise<AppProfilePrepareFileUploadResponse> {
  const fileContent = await file.arrayBuffer();
  return noCredentialsClient.put(uploadUrl, fileContent);
}

export async function uploadApplicationProfileFileData(
  readUrl: string,
): Promise<AppProfileImportRequestData> {
  return client
    .post<AppProfileImportRequestData>(apps.APP_PROFILE_FILE_UPLOAD, {
      readUrl,
    })
    .then(res => res.data);
}

export const deleteApplicationProfile = async (
  appId: string,
): Promise<DeleteAppProfileResponse> => {
  return client.post<never, never, DeleteAppProfileRequestData>(
    apps.APP_PROFILED_APP_DELETE,
    {
      appIds: [appId],
    },
  );
};

export async function getApplicationByID(
  appId: string,
): Promise<GetAppProfileByIdResponse> {
  const data = await client.post<GetAppProfileByIdResponse>(
    apps.APP_GET_APPLICATION_BY_ID,
    {
      appId: appId,
    },
  );
  return data?.data;
}

export const updateApplicationProfile = async (
  application: UpdateApiProfiledApp,
) => {
  return client.post<never, AxiosResponse<never>, UpdateAppProfileRequest>(
    apps.APP_UPDATE_APPLICATION,
    { app: application },
  );
};
