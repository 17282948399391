// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, type Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { type ApiError2 } from '../../../api/types';
import {
  findEndpointDevices$,
  getInstallerData$,
} from '../../../api/endpointsService';
import { type RootAction } from '../../../store/rootAction';
import { type RootState } from '../../../store/rootReducer';
import {
  getEndpointsListData,
  getEndpointsListNextPortion,
  getEndpointsTotals,
  getInstallerData,
  noEndpointsAction,
} from './actions';
import { ContextNames } from '../../../i18n';
import { getErrorMessageCode } from '../../../i18n/utils';
import { ALL_ITEMS_PATTERN } from '../../../shared/types';

const FIRST_PORTION_SIZE = 50;
const PORTION_SIZE = 25;

export const installerDataEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(getInstallerData.request)),
    switchMap(() =>
      getInstallerData$().pipe(
        map(res => {
          return getInstallerData.success(res);
        }),
        catchError((err: ApiError2) =>
          of(
            getInstallerData.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.ENDPOINTS, err.code),
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isActionOf(getInstallerData.cancel)))),
      ),
    ),
  );

export const endpointsListEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(getEndpointsListData.request)),
    switchMap(({ payload }) =>
      findEndpointDevices$({
        selectors: payload,
        pageSize: FIRST_PORTION_SIZE,
      }).pipe(
        switchMap(res => {
          const isExhaustiveSearch =
            payload.search?.pattern === ALL_ITEMS_PATTERN;
          const isListEmpty = res.endpointDevices.length === 0;

          if (isExhaustiveSearch) {
            return [
              getEndpointsListData.success(res),
              noEndpointsAction(isListEmpty),
            ];
          } else {
            return [getEndpointsListData.success(res)];
          }
        }),
        catchError((err: ApiError2) =>
          of(
            getEndpointsListData.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.USERS, err.code),
            }),
          ),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(getEndpointsListData.cancel))),
        ),
      ),
    ),
  );

export const endpointsListNextPortionEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getEndpointsListNextPortion.request)),
    switchMap(() => {
      const portionToken = state$.value.endpoints.endpoints.nextPortionToken;
      return findEndpointDevices$({
        pageToken: portionToken,
        pageSize: PORTION_SIZE,
      }).pipe(
        switchMap(res => {
          return of(getEndpointsListNextPortion.success(res));
        }),
        catchError((err: ApiError2) =>
          of(
            getEndpointsListNextPortion.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.ENDPOINTS, err.code),
            }),
          ),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(getEndpointsListNextPortion.cancel))),
        ),
      );
    }),
  );

export const endpointsTotalsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(getEndpointsTotals.request)),
    switchMap(({ payload }) =>
      findEndpointDevices$({
        selectors: payload,
        pageSize: FIRST_PORTION_SIZE,
      }).pipe(
        map(res => {
          return getEndpointsTotals.success(res.totalCount);
        }),
        catchError((err: ApiError2) =>
          of(
            getEndpointsTotals.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.ENDPOINTS, err.code),
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isActionOf(getEndpointsTotals.cancel)))),
      ),
    ),
  );

export const endpointsEpics = combineEpics(
  endpointsListEpic,
  endpointsListNextPortionEpic,
  endpointsTotalsEpic,
);

export const installedDataEpic = installerDataEpic;
