// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import {
  Banner,
  Button,
  ButtonVariant,
  ImprChip,
  ImprTooltip,
} from '@imprivata-cloud/components';

import PageLayout from '../../components/page-layout/PageLayout';
import ListItem from '../../components/list/ListItem';
import { InfiniteScrollContainer } from '../../components/infinite-scroll';
import {
  FindGroupsField,
  SortOrder,
  type Filter,
  type Group,
} from '../../shared/types';
import { groupsStateSelector } from './store/selectors';
import {
  getGroups,
  getGroupsNextPortion,
  groupUnmarkForSync,
} from './store/actions';
import { findDirectoriesActions } from '../directories/store/actions';
import { getDirectoriesState } from '../directories/store/hooks';
import { DIRECTORIES_ROUTE } from '../../routers/route-names';
import GroupsSortFilter, { GroupFilter } from './GroupsSortFilter';
import { GroupsSortFilterValueEnum } from './types';
import { LinkWithQuery } from '../../utils/routingHelpers';
import { usePersistantSessionState } from '../../utils/hooks/usePersistantState';
import { transformSearchPattern } from '../users/utils';
import getConfig from '../../appConfigUtils';
import PageSubHeader from '../../components/page-layout/PageSubHeader';
import AddGroupLink from '../../components/page-layout/action-bar/AddGroupLink';
import AddGroupModal from '../modals/directories-modal/AddGroupModal';
import SaveDiscardModal from '../modals/save-discard-modal/SaveDiscardModal';
import ErrorModal from '../modals/ErrorModal';
import { GroupEmptyStateContainer } from './GroupEmptyStateContainer';
import { useKeyCallBack } from '../hooks';
import { getInitSetupState } from '../initial-setup/store/selectors';
import { updateSetupModeAction } from '../initial-setup/store/actions';
import { findGroups$ } from '../../api/groupsService';
import SetTitle from '../../utils/DynamicTitleHelper';
import classes from './Groups.module.less';
import DirectorySync from '../directories/DirectorySync';

// See: https://intranet.imprivata.com/display/AUD/Directories+-+Astra+Administrator+UI
const canDeleteGroup = (group: Group, groups?: Group[]) => {
  if (!groups || !groups.length) {
    return false;
  }

  if (!group.adminEnabled) {
    return true;
  } else {
    return (
      groups.filter(
        g => g.groupId !== group.groupId && g.adminEnabled && g.userCount > 0,
      ).length > 0
    );
  }
};

const Groups: React.FC = () => {
  const { t } = useTranslation();
  SetTitle(t('navigation.directories'));
  const { directoryId } = useParams<{ directoryId: string }>();
  const dispatch = useDispatch();
  const [searchPattern, setSearchPattern] = useState<string>('');
  const [groupToRemove, setGroupToRemove] = useState<Group>();
  const [isSyncRemoveNotAllowed, setIsSyncRemoveNotAllowed] = useState(false);
  const [bannerStatus, setBannerStatus] = useState<string>('');
  const { ENABLE_ADD_GROUP_TO_FEATURE } = getConfig();

  const [sortFilter, setSortFilterValue] =
    usePersistantSessionState<GroupsSortFilterValueEnum>(
      'groups.groups-filter-value',
      GroupsSortFilterValueEnum.ALL_BY_NAME,
    );

  const { isSetupMode } = useSelector(getInitSetupState);

  const { groups, loading, nextPortionToken, lastChangesUpdateTs } =
    useSelector(groupsStateSelector);

  const directories = useSelector(getDirectoriesState);

  useEffect(() => {
    // We need this if user refreshes the page
    if (!directories.length) {
      dispatch(findDirectoriesActions.request());
    }
  }, [dispatch, directories]);

  const fetchGroupsWithSearchAndFilter = useCallback(() => {
    const filters: Filter[] = [GroupFilter.IDENTITY_PROVIDER_ID(directoryId)];
    if (sortFilter === GroupsSortFilterValueEnum.ADMIN_BY_NAME) {
      filters.push(GroupFilter.ADMIN_ENABLED);
    }
    dispatch(
      getGroups.request({
        search: {
          pattern: transformSearchPattern(searchPattern),
          fields: [FindGroupsField.NAME],
        },
        sortFields: [{ field: FindGroupsField.NAME, order: SortOrder.ASC }],
        filters,
      }),
    );
  }, [dispatch, sortFilter, searchPattern, directoryId]);

  useEffect(() => {
    // needed when search or filter is applied
    fetchGroupsWithSearchAndFilter();
  }, [
    dispatch,
    directoryId,
    searchPattern,
    sortFilter,
    fetchGroupsWithSearchAndFilter,
    lastChangesUpdateTs,
  ]);

  useEffect(() => {
    if (bannerStatus) {
      Banner({
        type: 'info',
        message: t('directories.error-messages.sync-wait'),
        duration: 5,
      });
      setBannerStatus('');
    }
  }, [t, bannerStatus, dispatch]);

  const onMore = () => {
    if (!loading && nextPortionToken) {
      dispatch(getGroupsNextPortion.request());
    }
  };

  const currentDirectory = directories.find(d => d.directoryId === directoryId);

  const [addGroupModalOpened, setAddGroupModalOpened] =
    useState<boolean>(false);

  const [addAdminGroupModalOpened, setAddAdminGroupModalOpened] =
    useState<boolean>(isSetupMode);

  const handleOnClickImprChip = () => {
    setSortFilterValue(GroupsSortFilterValueEnum.ADMIN_BY_NAME);
  };

  const handleOnKeyPressImprChip = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setSortFilterValue(GroupsSortFilterValueEnum.ADMIN_BY_NAME);
    }
  };

  const handleRemoveSyncConfirmationModal = (group: Group) => {
    findGroups$({
      selectors: {
        filters: [
          GroupFilter.IDENTITY_PROVIDER_ID(directoryId),
          GroupFilter.ADMIN_ENABLED,
          GroupFilter.SYNC_ENABLED_TRUE,
          GroupFilter.USER_COUNT_ZERO,
        ],
      },
    }).subscribe(result => {
      if (canDeleteGroup(group, result?.groups)) {
        setGroupToRemove(group);
      } else {
        setIsSyncRemoveNotAllowed(true);
      }
    });
  };

  const handleGroupSyncRemove = () => {
    if (groupToRemove) {
      dispatch(
        groupUnmarkForSync.request({
          groupId: groupToRemove.groupId,
        }),
      );
      setGroupToRemove(undefined);
      setBannerStatus('wait-for-next-sync');
      // re-fetch groups after changes
    }
  };

  const closeAddGroupModal = () => {
    setAddGroupModalOpened(false);
  };
  useKeyCallBack(['Escape'], closeAddGroupModal);

  const closeAddAdminGroupModal = () => {
    if (isSetupMode) {
      dispatch(updateSetupModeAction({ redirectToSetup: false }));
    }
    setAddAdminGroupModalOpened(false);
  };
  useKeyCallBack(['Escape'], closeAddAdminGroupModal);

  const handleModalClose = () => {
    setGroupToRemove(undefined);
  };
  useKeyCallBack(['Escape'], handleModalClose);

  return (
    <PageLayout
      title={
        <>
          <LinkWithQuery to={`${DIRECTORIES_ROUTE}`}>
            {t('navigation.directories')}
          </LinkWithQuery>
          {` > ${currentDirectory?.name} ${t('groups.group-count_other')}`}
        </>
      }
      data-testid="groups-page-container"
    >
      <PageSubHeader
        style={{ paddingBottom: 0 }}
        title={
          <>
            {ENABLE_ADD_GROUP_TO_FEATURE && !!groups.length && (
              <>
                <AddGroupLink
                  onClick={() => {
                    setAddGroupModalOpened(true);
                  }}
                  label={t('actions.add-groups')}
                  data-testid="add-group-link-btn"
                />
                <AddGroupLink
                  onClick={() => {
                    setAddAdminGroupModalOpened(true);
                  }}
                  label={t('actions.add-admin-groups')}
                  data-testid="add-group-admin-link-btn"
                />
              </>
            )}
            {addGroupModalOpened && (
              <AddGroupModal
                directoryName={currentDirectory?.name}
                directoryId={directoryId}
                fetchGroupsWithSearchAndFilter={fetchGroupsWithSearchAndFilter}
                onClose={closeAddGroupModal}
              />
            )}
            {addAdminGroupModalOpened && (
              <AddGroupModal
                directoryName={currentDirectory?.name}
                directoryId={directoryId}
                isAdminGroups={true}
                isSetupMode={isSetupMode}
                fetchGroupsWithSearchAndFilter={fetchGroupsWithSearchAndFilter}
                onClose={closeAddAdminGroupModal}
              />
            )}
            <DirectorySync
              directory={(directories?.length && directories[0]) || undefined}
            />
          </>
        }
      />
      {groupToRemove && (
        <SaveDiscardModal
          title={t('groups.confirm-remove.title')}
          cancelText={t('actions.dont-remove')}
          okText={t('actions.remove')}
          content={t('groups.confirm-remove.content', {
            group: groupToRemove.groupName,
          })}
          open={true}
          onSave={handleGroupSyncRemove}
          onDiscard={() => {
            setGroupToRemove(undefined);
          }}
          onClose={() => {
            setGroupToRemove(undefined);
          }}
          closable={false}
        />
      )}
      <ErrorModal
        title={t('groups.remove-group-error.title')}
        content={t('groups.remove-group-error.content')}
        visible={!!isSyncRemoveNotAllowed}
        okText={t('actions.got-it')}
        onClose={() => {
          setIsSyncRemoveNotAllowed(false);
        }}
      />
      {currentDirectory?.syncedGroupCount ? (
        <>
          <GroupsSortFilter
            selectValue={sortFilter}
            disabled={false}
            onSelect={(value: GroupsSortFilterValueEnum) => {
              setSortFilterValue(value);
            }}
            onSearch={(value: string) => {
              setSearchPattern(value);
            }}
            groupCount={currentDirectory.syncedGroupCount}
          />
          {groups.length === 0 && (
            <GroupEmptyStateContainer header={t('groups.no-matching-groups')} />
          )}
        </>
      ) : (
        <GroupEmptyStateContainer
          header={t('groups.no-groups')}
          operationLink={{
            linkMessage: t('directories.add-group-empty-state-description', {
              directoryName: currentDirectory?.name,
            }),
            onLinkClick: () => {
              setAddAdminGroupModalOpened(true);
            },
          }}
        />
      )}

      <InfiniteScrollContainer onMore={onMore}>
        {groups.map(group => {
          return (
            <ListItem
              key={group.groupId}
              data-testid="groups-list-item"
              primaryText={group.groupName}
              secondaryText={t('groups.user-count', {
                count: group.userCount,
              })}
              renderInMiddle={
                group.adminEnabled ? (
                  <ImprTooltip
                    title={t('users.admin-tag-tooltip')}
                    color="#333"
                  >
                    <ImprChip
                      label={t('users.admin-tag')}
                      color="processing"
                      containerClassName={clsx('impr', classes.chipStyle)}
                      data-testid="admin-tag"
                      onClick={handleOnClickImprChip}
                      onKeyPress={handleOnKeyPressImprChip}
                    />
                  </ImprTooltip>
                ) : undefined
              }
              renderOnTheRight={
                <Button
                  data-testid="group-remove-from-sync-button"
                  label={t('actions.remove')}
                  variant={ButtonVariant.TEXT}
                  onClick={() => {
                    handleRemoveSyncConfirmationModal(group);
                  }}
                />
              }
            />
          );
        })}
      </InfiniteScrollContainer>
    </PageLayout>
  );
};

export default Groups;
