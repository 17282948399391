// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';
import { from, Observable } from 'rxjs';
import { client } from './client';

export class RxAxiosClient {
  client: AxiosInstance;

  constructor(clientArg: AxiosInstance) {
    this.client = clientArg;
  }

  isAxiosError = axios.isAxiosError;

  request<T = unknown>(
    config: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return new Observable(subscriber => {
      let completed = false;
      const cancelSource = axios.CancelToken.source();
      const reqSubscription = from(
        this.client.request<T>({
          ...config,
          cancelToken: cancelSource.token,
        }),
      ).subscribe({
        next: data => {
          completed = true;
          subscriber.next(data);
        },
        error: error => {
          completed = true;
          subscriber.error(error);
        },
        complete: () => {
          completed = true;
          subscriber.complete();
        },
      });

      return () => {
        reqSubscription.unsubscribe();
        if (!completed) {
          cancelSource.cancel('Unsubscribed from request observable');
        }
      };
    });
  }

  get<T = unknown>(
    url: string,
    config?: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return this.request<T>({
      ...config,
      url,
      method: 'GET',
    });
  }

  delete<T = unknown>(
    url: string,
    config?: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return this.request<T>({
      ...config,
      url,
      method: 'DELETE',
    });
  }

  head<T = unknown>(
    url: string,
    config?: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return this.request<T>({
      ...config,
      url,
      method: 'HEAD',
    });
  }

  options<T = unknown>(
    url: string,
    config?: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return this.request<T>({
      ...config,
      url,
      method: 'OPTIONS',
    });
  }

  post<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return this.request<T>({
      ...config,
      url,
      data,
      method: 'POST',
    });
  }

  put<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return this.request<T>({
      ...config,
      url,
      data,
      method: 'PUT',
    });
  }

  patch<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Observable<AxiosResponse<T>> {
    return this.request<T>({
      ...config,
      url,
      data,
      method: 'PATCH',
    });
  }
}

const rxClient = new RxAxiosClient(client);
export default rxClient;
