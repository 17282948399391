import { Button } from '@imprivata-cloud/components';
import { type ActionButtonProps } from '../../../components/page-layout/action-bar/types';
import classes from './shared.module.less';

export interface ActionBarProps {
  csvButtonProps?: ActionButtonProps;
}
const ActionBar = ({
  csvButtonProps,
  children,
  ...rest
}: React.PropsWithChildren<ActionBarProps> &
  React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={`flex flex-row gap-3 justify-space-between ${
        rest?.className || ''
      }`}
      data-testid="dashboard-action-bar"
      {...rest}
    >
      <div className="flex align-items-center">
        <Button
          {...csvButtonProps}
          className={`${classes.actionBarDownloadButton} ${
            csvButtonProps?.className || ''
          }`}
        />
      </div>
      {children !== undefined && children}
    </div>
  );
};

export default ActionBar;
