// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Col, Modal, Row, Select as AntdSelect, Typography } from 'antd';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  InputBox,
  Select,
  Banner,
  destroyBanners,
} from '@imprivata-cloud/components';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { type DefaultOptionType } from 'antd/lib/select';
import classesShared from './ModalShared.module.less';
import appModalClasses from './AppModal.module.less';
import {
  type AppConfig,
  type AppIntegration,
  WorkstationType,
} from '../applications/store/types';
import add from '../../assets/svg/add.svg?react';
import getIcon from '../../assets/svg/getIcon.svg?react';
import CopyIcon from '../../assets/icons/copy.svg?react';
import { copyToClipboard } from '../utils';
import { type RootState } from '../../store/rootReducer';
import UploadButton from '../applications/components/UploadButton';
import getConfig from '../../appConfigUtils';
import { type ApplicationsListState } from '../applications/store/reducer';
import { EPIC_APP_LOCK_INSTRUCTIONS_URL } from '../../shared/constants';

const { Title } = Typography;
const { Option } = AntdSelect;
const colFlexLeft = '220px';

interface Props {
  appIntegration: AppIntegration;
  appConfig: AppConfig;
  redirectUri: string;
  onConfigUpdate: (value: AppConfig) => void;
  openFromUploads: boolean;
}

const AppModal: React.FC<Props> = (props: Props) => {
  const { appIntegration, appConfig, redirectUri, openFromUploads } = props;
  const { idpCertificates, idpIssuer } = appIntegration;
  const { appUsernameAdMatch, interconnectBaseUrls } = appConfig;
  const {
    IMPORT_APPLICATION_PROFILES_ENABLED,
    APPLICATIONS_EPIC_LOCK_ENABLED,
  } = getConfig();

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const tenantId = useSelector<RootState, string | undefined>(
    rootState => rootState.login.tenantId,
  );
  const applicationsListState = useSelector<RootState, ApplicationsListState>(
    rootState => rootState.apps.applicationsList,
  );
  const cert = idpCertificates[0];
  const onUpdate = props.onConfigUpdate;
  const isAppConfigured = idpIssuer !== null && appUsernameAdMatch !== null;
  const applicationsAvalable =
    applicationsListState.apiApplications.length !== 0;
  const certFileName = t('apps.app-modal.cert-filename', { tenantId });
  const getEpicAppLockDefault = (workstationType: WorkstationType): string => {
    return Object.keys(
      t(`apps.app-modal.epic-lock-config.${workstationType}.options`, {
        returnObjects: true,
      }),
    )[0];
  };
  const hyperDriveConfig = {
    appUsernameAdMatch: appUsernameAdMatch !== null ? appUsernameAdMatch : true,
    samlEnabled: true as const,
    captureMaxRetries: 3,
    interconnectBaseUrls,
    epicAppLockConfig: {
      sharedWorkstation:
        props.appConfig.epicAppLockConfig?.sharedWorkstation ??
        getEpicAppLockDefault(WorkstationType.SHARED),
      privateWorkstation:
        props.appConfig.epicAppLockConfig?.privateWorkstation ??
        getEpicAppLockDefault(WorkstationType.PRIVATE),
    },
  };
  const [modifiedConfig, setModifiedConfig] =
    useState<AppConfig>(hyperDriveConfig);

  const [error, setError] = useState<string>();

  useEffect(() => {
    if (error) {
      Banner({
        type: 'error',
        message: error,
        duration: 10,
        datatestid: 'app-modal-error-message',
        onClose: () => {
          setError('');
        },
      });
    }
  }, [error]);

  const onSubmit = () => {
    const url = modifiedConfig.interconnectBaseUrls?.[0];
    if (url && !isValidUrl(url)) {
      setError(t('apps.app-modal.errors.interconnect-base-urls-invalid'));
      return;
    }
    if (
      modifiedConfig.samlEnabled &&
      !modifiedConfig.appUsernameAdMatch &&
      !url
    ) {
      setError(t('apps.app-modal.errors.interconnect-base-urls-required'));
      return;
    }
    destroyBanners();
    onUpdate(modifiedConfig);
    setModalOpen(false);
  };

  const onCancel = () => {
    setModifiedConfig(hyperDriveConfig);
    setModalOpen(false);
  };

  const getEpicAppLockOptions = (
    workstationType: WorkstationType,
  ): DefaultOptionType[] => {
    return Object.entries(
      t(`apps.app-modal.epic-lock-config.${workstationType}.options`, {
        returnObjects: true,
      }),
    ).map(([key, value]) => {
      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        label: value,
        value: key,
      };
    });
  };

  return (
    <>
      {/* to check if config exist and render proper modal open control */}
      {!openFromUploads && isAppConfigured && applicationsAvalable && (
        <Button
          className={appModalClasses.edit_hyperdrive}
          label={t('actions.edit')}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.MAJOR}
          data-testid="open-app-modal-button"
          onClick={() => {
            setModalOpen(true);
          }}
        />
      )}
      {!openFromUploads && !isAppConfigured && !applicationsAvalable && (
        <Button
          className={appModalClasses.add_hyperdrive}
          label={t('apps.add-epic-hd')}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.MAJOR}
          data-testid="open-app-modal-button"
          onClick={() => {
            setModalOpen(true);
          }}
        />
      )}
      {openFromUploads && (
        <Button
          className="add_hyperdrive_uploads"
          label={t('apps.add-epic-hd')}
          variant={ButtonVariant.TEXT}
          data-testid="add-epic-hyperdrive-button"
          onClick={() => {
            setModalOpen(true);
          }}
          icon={
            <Icon
              component={add}
              data-testid="add-epic-hyperdrive-application-icon"
            />
          }
        />
      )}

      {IMPORT_APPLICATION_PROFILES_ENABLED && !applicationsAvalable ? (
        <UploadButton
          data-testid="upload-application-button"
          buttonType="initial"
          text={t('apps.profile.import.application-profile')}
        />
      ) : null}
      <Modal
        data-testid="app-modal"
        title={t('apps.app-modal.title')}
        className={clsx(classesShared.modal, appModalClasses.appModal)}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div className={classesShared.modalFooter}>
            <Button
              label={t('actions.save')}
              data-testid="app-modal-save-button"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MAJOR}
              onClick={onSubmit}
            />
          </div>
        }
      >
        <InputBox
          label={t('apps.app-modal.display-name')}
          data-testid="app-modal-display-name"
          value={appIntegration.appDisplayName}
          width="100%"
          size="small"
          type="text"
          style={{ marginBottom: '20px' }}
        />
        <Select
          data-testid="self-service-dropdown-user"
          value={modifiedConfig.appUsernameAdMatch}
          majorStyle
          label={t('apps.app-modal.epic-user-label')}
          onSelect={value => {
            setModifiedConfig(prev => ({ ...prev, appUsernameAdMatch: value }));
          }}
        >
          <Option data-testid="app-config-epic-ad-same-option" value={true}>
            {t('apps.app-modal.epic-user-same-as-ad')}
          </Option>
          <Option
            data-testid="app-config-epic-ad-not-same-option"
            value={false}
          >
            {t('apps.app-modal.epic-user-not-same-as-ad')}
          </Option>
        </Select>
        <Row>
          <Col flex={colFlexLeft}>
            <Title level={4} style={{ marginBottom: '3px' }}>
              {t('apps.app-modal.saml-config-title')}
            </Title>
          </Col>
          <Col flex="auto">
            {/* suppress empty href */}
            {/* eslint-disable-next-line */}
            <a
              href={t('apps.app-modal.instructions-url')}
              target="_blank"
              data-testid="app-modal-instructions-url"
            >
              {t('apps.app-modal.instructions-link')}
            </a>
          </Col>
        </Row>
        <Row>
          <Col flex={colFlexLeft}>{t('apps.app-modal.download-cert')}</Col>
          <Col flex="auto">
            <a
              download={certFileName}
              href={
                'data:text/plain;charset=utf-8,' +
                certFileBody(cert.idpCertificate)
              }
              data-testid="app-modal-download-certificate"
            >
              <Icon
                className={classesShared.icon}
                style={{ fontSize: '24px' }}
                component={getIcon}
              />
            </a>
          </Col>
        </Row>
        <RowForCopy
          title={t('apps.app-modal.copy-entity-id')}
          testName="entity-id"
          href={idpIssuer}
        />
        <RowForCopy
          title={t('apps.app-modal.copy-redirect-uri')}
          testName="redirect-uri"
          href={redirectUri}
        />
        <div style={{ marginTop: '15px' }}>
          <InputBox
            label={t('apps.app-modal.interconnect-base-urls')}
            data-testid="app-modal-interconnect-base-urls"
            value={modifiedConfig.interconnectBaseUrls?.[0]}
            width="100%"
            size="small"
            type="text"
            onChange={e => {
              const value = e.target.value?.trim();
              setModifiedConfig(prev => ({
                ...prev,
                interconnectBaseUrls: value ? [value] : [],
              }));
            }}
          />
        </div>
        <div
          className={clsx(
            'impr-epic-lock',
            !APPLICATIONS_EPIC_LOCK_ENABLED && 'impr-epic-lock-hidden',
          )}
          data-testid="app-modal-epic-lock-config"
        >
          <Row>
            <Col flex={colFlexLeft}>
              <Title
                level={4}
                style={{ marginBottom: '3px' }}
                data-testid="app-modal-epic-lock-config-title"
              >
                {t('apps.app-modal.epic-lock-config.title')}
              </Title>
            </Col>
            <Col flex="auto">
              {/* suppress empty href */}
              {/* eslint-disable-next-line */}
              <a
                href={EPIC_APP_LOCK_INSTRUCTIONS_URL}
                target="_blank"
                data-testid="app-modal-epic-lock-config-instructions-url"
              >
                {t('apps.app-modal.epic-lock-config.instructions-link')}
              </a>
            </Col>
          </Row>
          <Select
            data-testid="app-modal-epic-lock-config-shared"
            className={classesShared.largerDropdown}
            majorStyle
            options={getEpicAppLockOptions(WorkstationType.SHARED)}
            label={t('apps.app-modal.epic-lock-config.shared.label')}
            value={modifiedConfig.epicAppLockConfig?.sharedWorkstation}
            onSelect={value => {
              setModifiedConfig(prev => ({
                ...prev,
                epicAppLockConfig: {
                  sharedWorkstation: value,
                  privateWorkstation:
                    prev.epicAppLockConfig?.privateWorkstation,
                },
              }));
            }}
          />
          <Select
            data-testid="app-modal-epic-lock-config-private"
            className={classesShared.largerDropdown}
            majorStyle
            options={getEpicAppLockOptions(WorkstationType.PRIVATE)}
            label={t('apps.app-modal.epic-lock-config.private.label')}
            value={modifiedConfig.epicAppLockConfig?.privateWorkstation}
            onSelect={value => {
              setModifiedConfig(prev => ({
                ...prev,
                epicAppLockConfig: {
                  sharedWorkstation: prev.epicAppLockConfig?.sharedWorkstation,
                  privateWorkstation: value,
                },
              }));
            }}
          />
        </div>
      </Modal>
    </>
  );
};

interface RowForCopyProps {
  title: string;
  testName: string;
  href: string;
}

const RowForCopy: React.FC<RowForCopyProps> = (props: RowForCopyProps) => {
  return (
    <Row>
      <Col flex={colFlexLeft}>{props.title}</Col>
      <Col flex="auto">
        <Button
          label=""
          variant={ButtonVariant.TEXT}
          icon={<Icon className={classesShared.icon} component={CopyIcon} />}
          onClick={e => {
            copyToClipboard(e, props.href);
          }}
          data-testid={`app-modal-copy-${props.testName}`}
        />
        <a
          style={{ maxWidth: '400px', overflowWrap: 'break-word' }}
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
          data-testid={`app-modal-${props.testName}-link`}
        >
          {props.href}
        </a>
      </Col>
    </Row>
  );
};

export function certFileBody(idpCert: string): string {
  // You must encode \n with %0A
  return (
    '-----BEGIN CERTIFICATE-----%0A' +
    // eslint-disable-next-line
    // @ts-ignore
    idpCert.match(/.{1,76}/g).join('%0A') + // Line length limit 76 chars
    '%0A-----END CERTIFICATE-----%0A%0A'
  );
}

export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

export default AppModal;
