// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type CREDS_SOURCE, type USERNAME_FORMAT } from '../components/types';

export type AppIntegrationFindData = {
  appIntegration: AppIntegration;
};

export type AppIntegration = {
  appIntegrationId: string;
  appName: string;
  appDisplayName: string;
  idpIssuer: string;
  idpCertificates: IdpCertificate[];
  createdAt: string;
  updatedAt: string;
};

export type ApplicationsFindData = {
  applications: ApiApplication[];
};

export type ApiApplication = {
  appId: string;
  appType: string;
  appName: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
};

export type IdpCertificate = {
  publicKey: string;
  idpCertificate: string;
};

export type AppConfig = {
  samlEnabled: true;
  appUsernameAdMatch: boolean | null;
  interconnectBaseUrls: string[] | null;
  captureMaxRetries: number;
  epicAppLockConfig?: EpicAppLockConfig;
};

export type EpicAppLockConfig = {
  sharedWorkstation?: string;
  privateWorkstation?: string;
};

export interface AppConfigGetResponse {
  appConfig: AppConfig;
  redirectUri: string;
}

export interface AppConfigUpdateRequest {
  applicationId: string;
  appConfig: AppConfig;
}

export type AppProfilePrepareFileUploadResponseData = {
  uploadUrl: string;
  readUrl: string;
};

export type AppProfilePrepareFileUploadResponse = {
  status: number;
};

export type AppProfileImportRequestData = {
  readURL: string;
};

export enum WorkstationType {
  SHARED = 'shared',
  PRIVATE = 'private',
}

export interface DeleteAppProfileRequestData {
  appIds: string[];
}

export interface DeleteAppProfileResponse {
  status: number;
}

export interface GetAppProfileByIdRequest {
  appId: string;
}

export interface GetAppProfileByIdResponse {
  app: GetApiProfiledApp;
}

export interface GetApiProfiledApp {
  appId: string;
  appName: string;
  displayName: string;
  credSrcType: CREDS_SOURCE;
  usernameFormatType: USERNAME_FORMAT;
  createdAt: string;
  updatedAt: string;
}

type UpdateApiProfiledAppBase =
  | {
      appId: string;
      usernameFormatType: USERNAME_FORMAT;
      credSrcType: CREDS_SOURCE.DOMAIN;
    }
  | {
      appId: string;
      usernameFormatType: USERNAME_FORMAT | undefined;
      credSrcType: CREDS_SOURCE.OWN;
    };

export type UpdateApiProfiledApp = Partial<
  Omit<GetApiProfiledApp, 'credSrcType' | 'usernameFormatType'>
> &
  UpdateApiProfiledAppBase;

export type UpdateAppProfileRequest = {
  app: UpdateApiProfiledApp;
};
