/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useState } from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  InputBox,
} from '@imprivata-cloud/components';
import CancelButton from '../../../components/page-layout/action-bar/CancelButton';
import HelpButton from '../../../components/page-layout/action-bar/HelpButton';
import SaveButton from '../../../components/page-layout/action-bar/SaveButton';
import { loginSelector } from '../../login/store/selectors';
import getConfig from '../../../appConfigUtils';
import classes from './System.module.less';
import { copyToClipboard } from '../../utils';
import CopyIcon from '../../../assets/svg/copy.svg?react';
import { SettingsPageLayout } from '../components';
import { createEAMToken } from '../../../api/connectEamcService';

const System: React.FC = () => {
  const { t } = useTranslation();
  const [tokenForm] = Form.useForm();
  const { tenantId } = useSelector(loginSelector);
  const { SELF_SERVICE_URL, EPCS_ENABLED } = getConfig();
  const [eamToken, setEamToken] = useState('');
  const [disableGenerateToken, setDisableGenerateToken] = useState(true);

  const linkValue =
    SELF_SERVICE_URL && `${SELF_SERVICE_URL}?tenantId=${tenantId}`;

  const eamInstructionsUrl = t('system.eam-integration.instructions-url');

  const createEAMIntegrationToken = () => {
    const enterpriseId = tokenForm.getFieldValue('enterpriseId') as string;
    if (enterpriseId) {
      void createEAMToken({
        enterpriseId,
        installationTokenExpirationInMinutes: 60,
      })
        .then(createTokenRes => {
          if (createTokenRes?.clientMetadata) {
            setEamToken(createTokenRes.clientMetadata.installationTokenValue);
          }
        })
        .catch(e => {
          // No action on error required at this point.
          console.debug('[Admin:error] connect EAM token error.', e);
        });
    }
  };

  const handleChange = () => {
    void tokenForm.validateFields().then(values => {
      if (values?.enterpriseId) {
        setDisableGenerateToken(false);
      } else {
        setDisableGenerateToken(true);
      }
    });
  };

  return (
    <SettingsPageLayout
      title={
        <>
          <SaveButton disabled />
          <CancelButton disabled />
          <HelpButton />
        </>
      }
    >
      <div>
        {EPCS_ENABLED && (
          <Row className={classes.row}>
            <Col>
              <div className="h3-header">{t('system.self-service-title')}</div>
              <p className="primary-body">{t('system.self-service-header')}</p>
              {!!linkValue && (
                <div>
                  <Icon
                    component={CopyIcon}
                    data-testid="copy-icon"
                    className={classes.icon}
                    onClick={e => {
                      copyToClipboard(e, linkValue);
                    }}
                  />
                  <a
                    className={classes.hyperLink}
                    data-testid="link-to-uss-ui"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={linkValue}
                  >
                    {linkValue}
                  </a>
                </div>
              )}
            </Col>
          </Row>
        )}

        <Row className={classes.eamRow}>
          <Col>
            <div className="h3-header" data-testid={'eam-integration-header'}>
              {t('system.eam-integration.header')}
            </div>
            <p className="primary-body">{t('system.eam-integration.label')}</p>
            <div style={{ display: 'flex' }}>
              <Form form={tokenForm} onChange={handleChange} layout={'inline'}>
                <Form.Item name="enterpriseId">
                  <InputBox
                    label={t('system.eam-integration.id-input-label')}
                    data-testid="eam-id-input"
                    type="text"
                  />
                </Form.Item>
                <Button
                  label={t('system.eam-integration.token-button-label')}
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.MINOR}
                  data-testid="eam-token-button"
                  htmlType="submit"
                  disabled={disableGenerateToken}
                  onClick={createEAMIntegrationToken}
                />
              </Form>
              {eamToken && (
                <div className={classes.eamCopySection}>
                  <Icon
                    component={CopyIcon}
                    data-testid="copy-eam-token-icon"
                    className={classes.eamCopyIcon}
                    onClick={e => {
                      copyToClipboard(e, eamToken);
                    }}
                  />
                  <a
                    className={classes.hyperLink}
                    data-testid="copy-eam-token"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="#"
                    onClick={e => {
                      copyToClipboard(e, eamToken);
                    }}
                  >
                    {t('system.eam-integration.copy-token-link')}
                  </a>
                </div>
              )}
            </div>
            <div className={classes.eamFootnote}>
              <span className={'primary-body'}>
                {t('system.eam-integration.footer-label')}
              </span>
              <a
                className={classes.hyperLink}
                onClick={() => {
                  window.open(
                    eamInstructionsUrl,
                    '_blank',
                    'noreferrer noopener',
                  );
                }}
                href={eamInstructionsUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {t('system.eam-integration.instructions-link')}
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </SettingsPageLayout>
  );
};

export default System;
