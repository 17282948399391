import { useMemo } from 'react';
import BarGuage from '../../../components/bar-guage/BarGuage';
import Card, { type CardProps } from '../../../components/card/Card';
import classes from './shared.module.less';
import { type DashboardData } from '../types';
import { extractValueAtKey } from '../utils';
import {
  type ArrayTypeKeys,
  type DashboardDataType,
} from '../../../api/Dashboard/types';

export const configureData = (
  data?: DashboardData[DashboardDataType.EPCS_READINESS],
) => {
  if (data) {
    const { keys = [], values = [] } = data;
    if (values.length > 0) {
      const getValue = (extractValueAtKey<ArrayTypeKeys<DashboardDataType.EPCS_READINESS>>).bind(null, keys); // prettier-ignore
      const total = getValue('epcs_readiness_total', values[0]) || 1;
      const eligible = getValue('epcs_readiness_eligible', values[0]) || 0;
      const notElligible = +(
        getValue('epcs_readiness_not_eligible', values[0]) || 0
      );
      const percentElligible = Math.round((+eligible / +total) * 100);
      return {
        percentElligible,
        notElligible,
      };
    }
  }

  return {
    percentElligible: 0,
    notElligible: 0,
  };
};

export interface EpcsReadinessData {
  percentElligible: number;
  notElligible: number;
}

export interface EpcsReadinessProps {
  data?: DashboardData[DashboardDataType.EPCS_READINESS];
}

const EpcsReadiness: React.FC<EpcsReadinessProps & CardProps> = ({
  data,
  ...rest
}) => {
  // data for epcs readiness
  const readinessData: EpcsReadinessData | undefined = useMemo(
    () => configureData(data),
    [data],
  );
  return (
    <Card {...rest}>
      <div
        className="flex flex-column gap-4"
        data-testid="epcs-readiness-container"
      >
        {readinessData ? (
          <>
            <div>
              {/* Horizontal bar guage */}
              <BarGuage
                percentEnrollment={readinessData.percentElligible}
                label={`${readinessData.percentElligible}% users eligible`}
              />
            </div>
            <div className={classes.metricsDataSmall}>
              <span>{readinessData.notElligible}</span>
              users not eligible
            </div>
          </>
        ) : (
          <span>No data</span>
        )}
      </div>
    </Card>
  );
};

export default EpcsReadiness;
