// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import Home from './containers/home/Home';
import LoginRoutes from './containers/login/LoginRoutes';
import Logout from './containers/login/Logout';
import ProtectedRoute from './containers/ProtectedRoute';
import { HOME_ROUTE, LOGIN_ROUTE, LOGOUT_ROUTE } from './routers/route-names';
import { ejectInterceptors } from './api/interceptors';
import { RouteWithQuery } from './utils/routingHelpers';
import { getBuildInfo } from './utils/build';
import { SpanNames, tracer } from './tracing';
import { ICP_LOGIN_ROUTE, ICP_PORTAL_ROUTE } from './icp/routes/route-names';
import UserLogin from './icp/containers/UserLogin';
import PortalHome from './icp/containers/portal/PortalHome';

const APP_VERSION = getBuildInfo();

let notified = false;

function App(): JSX.Element {
  if (!notified) {
    console.debug('APP VERSION [authn]: ', APP_VERSION);
    notified = true;
  }

  useEffect(() => {
    tracer.startSpan(SpanNames.INIT_APP, { version: APP_VERSION });

    return () => {
      try {
        tracer.endSpan(SpanNames.INIT_APP, { version: APP_VERSION });
      } catch (_) {
        /**  */
      }
      ejectInterceptors();
    };
  }, []);

  return (
    <Switch>
      <RouteWithQuery exact path={ICP_LOGIN_ROUTE} component={UserLogin} />
      <RouteWithQuery exact path={ICP_PORTAL_ROUTE} component={PortalHome} />
      <RouteWithQuery path={LOGIN_ROUTE}>
        <LoginRoutes />
      </RouteWithQuery>
      <RouteWithQuery exact path={LOGOUT_ROUTE} component={Logout} />
      <ProtectedRoute path={HOME_ROUTE} component={Home} />
    </Switch>
  );
}

export default App;
