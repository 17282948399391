import React from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  InputBox,
} from '@imprivata-cloud/components';
import { useAutoFocus } from '@imprivata-cloud/common';
import { Row } from 'antd';
import type { InputRef } from 'antd';
import classes from './UserLogin.module.less';

interface IProps {
  onSubmit: (emailInput: string) => void;
}

const UserLoginForm = ({ onSubmit }: IProps): JSX.Element => {
  const emailRef = useAutoFocus<InputRef>();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailInput = e.currentTarget.elements.namedItem(
      'emailInput',
    ) as HTMLInputElement;
    onSubmit(emailInput.value);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        data-testid="tenant-lookup-form"
        className={classes.loginContainer}
      >
        <Row>
          <label
            htmlFor="emailInput"
            data-testid="email-input-label"
            className={classes.loginHeader}
          >
            Log In
          </label>
          <InputBox
            className={classes.inputBox}
            ref={emailRef}
            type="text"
            placeholder={''}
            data-testid={'email-input'}
            autoFocus
            size="large"
            id="emailInput"
            role="textbox"
          />
        </Row>
        <Row>
          <Button
            className={classes.submitButton}
            size={ButtonSize.MAJOR}
            variant={ButtonVariant.PRIMARY}
            label={'Go'}
            data-testid="submit-button"
            htmlType="submit"
            id="submitButton"
          />
        </Row>
      </form>
    </div>
  );
};

export default UserLoginForm;
