/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  getEndpointsListData,
  getEndpointsListNextPortion,
  getEndpointsTotals,
  getInstallerData,
  noEndpointsAction,
} from './actions';
import { type RootAction } from '../../../store/rootAction';
import { regenerateAgentKey } from '../../settings/agent/store/actions';
import { type EndpointEntity } from './types';

interface InstallInfo {
  encodedInstallInfo: string;
  expirationDate?: string;
}

export interface InstallerDataState {
  loading: boolean;
  installInfo: InstallInfo;
  commandLine: string;
  endpointInstallerUrl: string;
  error: string | null;
  regeneration: boolean;
}

export interface EndpointsListState {
  noEndpoints: boolean;
  loading: boolean;
  loadingNextPortion: boolean;
  rowsLoading: Record<number, boolean>;
  entities: EndpointEntity[];
  total: number;
  error: string | null;
  nextPortionToken: string;
}
export interface EndpointsTotalsState {
  loading: boolean;
  endpointsCount: number;
  error: string | null;
}

export const endpointsInitialState: EndpointsListState = {
  noEndpoints: false,
  loading: false,
  loadingNextPortion: false,
  rowsLoading: {},
  entities: [],
  total: 0,
  error: null,
  nextPortionToken: '',
};

export const endpointsTotalsInitialState = {
  endpointsCount: 0,
  loading: true,
  error: null,
};

export const installerDataInitialState: InstallerDataState = {
  loading: false,
  installInfo: { encodedInstallInfo: '' },
  commandLine: '',
  endpointInstallerUrl: '',
  error: null,
  regeneration: false,
};

export interface EndpointsState {
  totals: EndpointsTotalsState;
  endpoints: EndpointsListState;
  installerData: InstallerDataState;
}

export const installerDataReducer = combineReducers<InstallerDataState>({
  loading: createReducer<boolean>(installerDataInitialState.loading)
    .handleAction(
      [getInstallerData.request, regenerateAgentKey.request],
      () => true,
    )
    .handleAction(
      [
        getInstallerData.cancel,
        getInstallerData.success,
        getInstallerData.failure,
      ],
      () => false,
    ),
  commandLine: createReducer<string, RootAction>(
    installerDataInitialState.commandLine,
  ).handleAction(
    getInstallerData.success,
    (_, { payload }) => payload.commandLine,
  ),
  endpointInstallerUrl: createReducer<string, RootAction>(
    installerDataInitialState.endpointInstallerUrl,
  ).handleAction(
    getInstallerData.success,
    (_, { payload }) => payload.endpointInstallerUrl,
  ),
  installInfo: createReducer<InstallInfo, RootAction>(
    installerDataInitialState.installInfo,
  ).handleAction(
    getInstallerData.success,
    (_, { payload }) => payload.installInfo,
  ),
  error: createReducer<string | null, RootAction>(
    installerDataInitialState.error,
  )
    .handleAction(
      [getInstallerData.request, getInstallerData.success],
      () => null,
    )
    .handleAction(
      getInstallerData.failure,
      (_, { payload }) => payload.code || null,
    )
    .handleAction(getInstallerData.cancel, () => null),
  regeneration: createReducer<boolean>(installerDataInitialState.regeneration)
    .handleAction(regenerateAgentKey.success, () => true)
    .handleAction(regenerateAgentKey.cancel, () => false),
});

export const endpointsListReducer = combineReducers<EndpointsListState>({
  loading: createReducer<boolean>(endpointsInitialState.loading)
    .handleAction(getEndpointsListData.request, () => true)
    .handleAction(
      [
        getEndpointsListData.success,
        getEndpointsListData.failure,
        getEndpointsListData.cancel,
      ],
      () => false,
    ),
  loadingNextPortion: createReducer<boolean>(
    endpointsInitialState.loadingNextPortion,
  )
    .handleAction(getEndpointsListNextPortion.request, () => true)
    .handleAction(
      [
        getEndpointsListNextPortion.success,
        getEndpointsListNextPortion.failure,
        getEndpointsListNextPortion.cancel,
      ],
      () => false,
    ),
  rowsLoading: createReducer<Record<number, boolean>>(
    endpointsInitialState.rowsLoading,
  ),
  entities: createReducer<EndpointEntity[], RootAction>(
    endpointsInitialState.entities,
  )
    .handleAction(
      getEndpointsListData.success,
      (_, { payload }) => payload.endpointDevices || [],
    )
    .handleAction(
      getEndpointsListNextPortion.success,
      (state, { payload }) => [...state, ...payload.endpointDevices] || [],
    ),
  total: createReducer<number, RootAction>(
    endpointsInitialState.total,
  ).handleAction(
    getEndpointsListData.success,
    (_, { payload }) => payload.totalCount,
  ),
  error: createReducer<string | null, RootAction>(endpointsInitialState.error)
    .handleAction(getEndpointsListData.request, () => null)
    .handleAction(
      getEndpointsListData.failure,
      (_, { payload }) => payload.code || '',
    )
    .handleAction(getEndpointsListData.cancel, () => 'cancelled'),
  nextPortionToken: createReducer<string, RootAction>(
    endpointsInitialState.nextPortionToken,
  ).handleAction(
    [getEndpointsListData.success, getEndpointsListNextPortion.success],
    (_, { payload }) => payload.pagingInfo.afterToken,
  ),
  noEndpoints: createReducer<boolean, RootAction>(
    endpointsInitialState.noEndpoints,
  ).handleAction(noEndpointsAction, (_, { payload }) => payload),
});

export const totalsReducer = combineReducers<EndpointsTotalsState>({
  loading: createReducer<boolean>(endpointsTotalsInitialState.loading)
    .handleAction(getEndpointsTotals.request, () => true)
    .handleAction(
      [
        getEndpointsTotals.cancel,
        getEndpointsTotals.success,
        getEndpointsTotals.failure,
      ],
      () => false,
    ),
  endpointsCount: createReducer<number, RootAction>(
    endpointsTotalsInitialState.endpointsCount,
  ).handleAction(getEndpointsTotals.success, (_, { payload }) => payload),
  error: createReducer<string | null, RootAction>(
    endpointsTotalsInitialState.error,
  )
    .handleAction(
      [getEndpointsTotals.request, getEndpointsTotals.success],
      () => null,
    )
    .handleAction(getEndpointsTotals.failure, (_, { payload }) => payload.code)
    .handleAction(getEndpointsTotals.cancel, () => 'cancelled'),
});

export default combineReducers<EndpointsState>({
  totals: totalsReducer,
  endpoints: endpointsListReducer,
  installerData: installerDataReducer,
});
