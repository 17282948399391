// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import { type Observable } from 'rxjs';
import rxClient from './rxClient';
import { endpoints } from './endpoint-names';
import {
  type EndpointsListPayload,
  type InstallerDataPayload,
} from '../containers/endpoints/store/types';
import { type ListSelectors } from '../shared/types';

export function getInstallerData$(): Observable<InstallerDataPayload> {
  return rxClient
    .post<InstallerDataPayload>(endpoints.GET_INSTALLER_DATA)
    .pipe(map(res => res.data));
}

export function regenerateAgentKey$(): Observable<InstallerDataPayload> {
  return rxClient
    .post<InstallerDataPayload>(endpoints.REGENERATE_TOKEN)
    .pipe(map(res => res.data));
}

export type GetEndpointsRequestSelectorsPayload = {
  selectors: ListSelectors;
  pageSize?: number;
};

export type GetEndpointsRequestNextPagePayload = {
  pageToken: string;
  pageSize?: number;
};

export function findEndpointDevices$(
  payload:
    | GetEndpointsRequestSelectorsPayload
    | GetEndpointsRequestNextPagePayload,
): Observable<EndpointsListPayload> {
  return rxClient
    .post<EndpointsListPayload>(endpoints.FIND_ENDPOINT_DEVICES, payload)
    .pipe(map(res => res.data));
}
