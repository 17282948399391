import React, { useEffect, useState } from 'react';
import { AuthnModule, EventType } from '@imprivata-cloud/authn';
import { tracer, workflowId } from '../../../tracing';
import history from '../../../routers/history';
import { redirectWithQuery } from '../../../utils/routingHelpers';
import { ICP_PORTAL_ROUTE } from '../../routes/route-names';

interface IDPLoginProps {
  tenantId: string;
}

const IDPLogin = ({ tenantId }: IDPLoginProps): JSX.Element => {
  const [paramsAdded, setParamsAdded] = useState(true);
  useEffect(() => {
    const paramsBefore = new URLSearchParams(window.location.search);
    paramsBefore.set('contextType', 'generic-app-login');
    paramsBefore.set('resourceType', 'admin-web-ui');
    paramsBefore.set('workflowId', tracer.getWorkflowId());
    paramsBefore.set('tenantId', tenantId);
    history.push({ search: paramsBefore.toString() });

    setParamsAdded(true);

    return () => {
      const paramsAfter = new URLSearchParams(window.location.search);
      paramsAfter.delete('contextType');
      paramsAfter.delete('resourceType');
      paramsAfter.delete('workflowId');
      paramsAfter.delete('tenantId');
      history.push({ search: paramsAfter.toString() });
    };
  }, [tenantId]);

  return (
    <>
      {(paramsAdded && tenantId && (
        <AuthnModule
          tenantId={tenantId}
          onEvent={({ event }) => {
            switch (event) {
              case EventType.AUTHENTICATED:
                // TODO: go to Portal page
                redirectWithQuery(ICP_PORTAL_ROUTE);
                break;
            }
          }}
          requestConfig={{
            clientName: 'admin-web',
          }}
          tracing={{
            workflowId,
            tracer,
          }}
        />
      )) || <></>}
    </>
  );
};

export default IDPLogin;
