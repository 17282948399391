// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { type OrgPreferencesAction } from './actions';

import {
  FETCH_ORG_PREFERENCES,
  PENDING_ORG_PREFERENCES,
  SUCCESS_ORG_PREFERENCES,
  ERROR_ORG_PREFERENCES,
} from './constants';
import { type RootAction } from '../../../../store/rootAction';

export interface OrgPreferences {
  logoUrl?: string;
  orgName?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
}

export interface OrgPreferencesState {
  data: OrgPreferences;
  operations: string;
  error: string;
}

export const operationsReducer = createReducer<string, OrgPreferencesAction>(
  '',
).handleType(
  [
    FETCH_ORG_PREFERENCES,
    PENDING_ORG_PREFERENCES,
    SUCCESS_ORG_PREFERENCES,
    ERROR_ORG_PREFERENCES,
  ],
  (_state, action) => action.type,
);
export const orgPreferencesDataReducer = createReducer<
  OrgPreferences,
  OrgPreferencesAction
>({}).handleType(SUCCESS_ORG_PREFERENCES, (_state, { payload }) => {
  return payload.data;
});
export const fetchErrorReducer = createReducer<string | null, RootAction>(
  null,
).handleType(
  ERROR_ORG_PREFERENCES,
  (_, { payload }) => payload.data.error || null,
);

export const orgPreferencesReducer = combineReducers({
  data: orgPreferencesDataReducer,
  operations: operationsReducer,
  error: fetchErrorReducer,
});
