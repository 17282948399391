import React, { useEffect, useState } from 'react';
import { Banner } from '@imprivata-cloud/components';
import type { FC } from 'react';
import { lookupTenant } from '../services/tenantLookupService';
import UserLoginForm from '../components/UserLoginForm';
import classes from '../components/UserLogin.module.less';
import ImprivataLogo from '../assets/Imprivata_Logo.svg?react';
import IDPLogin from './IDPLogin/IDPLogin';

const UserLogin: FC = () => {
  const [tenantId, setTenantId] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (emailInput: string) => {
    const tenantLookup = lookupTenant(emailInput);
    if (tenantLookup?.tenantId) {
      setTenantId(tenantLookup?.tenantId);
    } else {
      setError('Invalid email');
    }
  };

  useEffect(() => {
    if (error) {
      Banner({
        type: 'error',
        message: error,
        duration: 10,
        datatestid: 'tenant-error',
      });
    }
  }, [error]);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.logo}>
          <ImprivataLogo fill={'#2C3F57'} />
        </div>
        <div>
          <span className={classes.logoHeader}>Access Management</span>
        </div>
      </div>
      <div className={classes.container}>
        {!tenantId && (
          <div className={classes.centeredElement}>
            <UserLoginForm onSubmit={handleSubmit} />
          </div>
        )}
        {tenantId && <IDPLogin tenantId={tenantId} />}
      </div>
    </div>
  );
};

export default UserLogin;
