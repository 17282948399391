// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type RootAction } from '../../../store/rootAction';
import {
  SUCCESS_CHECK_SETUP_MODE,
  ERROR_CHECK_SETUP_MODE,
  UPDATE_CHECK_SETUP_MODE,
} from './constants';

export interface InitialSetupState {
  checkDone: boolean;
  isSetupMode: boolean;
  directoryId?: string;
  redirectToSetup?: boolean;
}

const initialState: InitialSetupState = {
  checkDone: false,
  isSetupMode: false,
};

export const initSetupReducer = (
  prevState: InitialSetupState | undefined,
  action: RootAction,
): InitialSetupState => {
  const state = prevState || initialState;
  switch (action.type) {
    case SUCCESS_CHECK_SETUP_MODE:
      // eslint-disable-next-line no-case-declarations
      const { isSetupMode, directoryId, checkDone, redirectToSetup } =
        action.payload;
      return {
        ...state,
        isSetupMode: isSetupMode,
        directoryId: directoryId,
        redirectToSetup: redirectToSetup,
        checkDone: checkDone ? checkDone : true,
      };
    case UPDATE_CHECK_SETUP_MODE:
      return {
        ...state,
        ...action.payload,
      };
    case ERROR_CHECK_SETUP_MODE: {
      return { ...state, checkDone: true, redirectToSetup: false };
    }
    default:
      return state;
  }
};
