// Copyright 2022, Imprivata, Inc.  All rights reserved.
import * as apiTypes from '../../api/Dashboard/types';
import { type FilterTypeKeys } from '../../api/Dashboard/types';

type DT = apiTypes.DashboardDataType;

// -------------
// HELPERS
// -------------

// Helper type to get a slice of `DashboardData`, i.e. { keys: [], values: [] }
export type DashboardDataSlice<K extends DT> = DashboardData[K];

// Helper type to get a remapped object from DashboardData, i.e. { [key in DashboardData]: string | number }
export type MappedDashboardData<K extends DT = DT> = Record<
  FilterTypeKeys<K>,
  string | number
>;

// Helper type to restrict modal DashboardDataType keys
export type DashboardModalKey =
  | apiTypes.DashboardDataType.EPCS_REPORT
  | apiTypes.DashboardDataType.UNIQUE_USER_AUTHNS_DETAILS
  | apiTypes.DashboardDataType.ENROLLED_DETAILS
  | apiTypes.DashboardDataType.EPCS_READINESS_DETAILS
  | apiTypes.DashboardDataType.AUTHNS_DETAILS
  | apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS
  | apiTypes.DashboardDataType.SSO_AUTHS_DETAILS;

// -------------
// UI Configuration
// -------------

// INTERVAL SELECT
export type DashboardDataIntervalDisplay = {
  [key in apiTypes.DashboardDataInterval]: string;
};
export const DashboardIntervalDropdown: Partial<DashboardDataIntervalDisplay> =
  {
    [apiTypes.DashboardDataInterval.PREVIOUS_12_MONTHS]: 'Last 12 Months',
    [apiTypes.DashboardDataInterval.PREVIOUS_30_DAYS]: 'Last 30 Days',
    [apiTypes.DashboardDataInterval.PREVIOUS_7_DAYS]: 'Last 7 Days',
    [apiTypes.DashboardDataInterval.LAST_24_HOURS]: 'Last 24 Hours',
  };

// LINECHART
export interface DashboardLineChartData {
  datetime: string;
  value: string | number;
  category: string;
}

// -------------
// Data Fetching & Resulting Data Shape
// -------------

// NOTE: Add dev update notes

// Dashboard data is fetched and returned in this format
export type DashboardData = {
  [apiTypes.DashboardDataType
    .AUTHNS]: DashboardDataEntry<apiTypes.DashboardDataType.AUTHNS>;
  [apiTypes.DashboardDataType
    .AUTHNS_DETAILS]: DashboardDataEntry<apiTypes.DashboardDataType.AUTHNS_DETAILS>;
  [apiTypes.DashboardDataType
    .AUTHNS_METRICS]: DashboardDataEntry<apiTypes.DashboardDataType.AUTHNS_METRICS>;
  [apiTypes.DashboardDataType
    .SSO_AUTHS_DETAILS]: DashboardDataEntry<apiTypes.DashboardDataType.SSO_AUTHS_DETAILS>;
  [apiTypes.DashboardDataType
    .SSO_AUTHS_METRICS]: DashboardDataEntry<apiTypes.DashboardDataType.SSO_AUTHS_METRICS>;
  [apiTypes.DashboardDataType
    .ENROLLED_DETAILS]: DashboardDataEntry<apiTypes.DashboardDataType.ENROLLED_DETAILS>;
  [apiTypes.DashboardDataType
    .ENROLLED_METRICS]: DashboardDataEntry<apiTypes.DashboardDataType.ENROLLED_METRICS>;
  [apiTypes.DashboardDataType
    .EPCS_READINESS]: DashboardDataEntry<apiTypes.DashboardDataType.EPCS_READINESS>;
  [apiTypes.DashboardDataType
    .EPCS_READINESS_DETAILS]: DashboardDataEntry<apiTypes.DashboardDataType.EPCS_READINESS_DETAILS>;
  [apiTypes.DashboardDataType
    .UNIQUE_ACTIVE_USER_METRICS]: DashboardDataEntry<apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_METRICS>;
  [apiTypes.DashboardDataType
    .UNIQUE_ACTIVE_USER_DETAILS]: DashboardDataEntry<apiTypes.DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS>;
  [apiTypes.DashboardDataType
    .UNIQUE_USER_AUTHNS]: DashboardDataEntry<apiTypes.DashboardDataType.UNIQUE_USER_AUTHNS>;
  [apiTypes.DashboardDataType
    .UNIQUE_USER_AUTHNS_DETAILS]: DashboardDataEntry<apiTypes.DashboardDataType.UNIQUE_USER_AUTHNS_DETAILS>;
  [apiTypes.DashboardDataType
    .EPCS_REPORT]: DashboardDataEntry<apiTypes.DashboardDataType.EPCS_REPORT>;
  // we've currently stopped consuming this dataset, maybe temporarily
  // [apiTypes.DashboardDataType
  //   .SSO_AUTHS]: DashboardDataEntry<apiTypes.DashboardDataType.SSO_AUTHS>;
};

export type UniqueUsersDashbordDataEntry<T> = {
  keys?: apiTypes.ArrayTypeKeys<T>;
  values?: Record<string, (string | number)[][]>;
};

export type DashboardDataEntry<T> = {
  keys?: apiTypes.ArrayTypeKeys<T>;
  values?: (string | number)[][];
};

// FETCH STATE (FOR API REQUEST HOOK)
export interface FetchState {
  error?: boolean;
  loading?: boolean;
}
