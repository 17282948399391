// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Select as AntdSelect } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, Select } from '@imprivata-cloud/components';
import { GroupsSortFilterValueEnum } from './types';
import classes from '../../components/list/Filters.module.less';
import {
  FindGroupsField,
  FilterOperator,
  type Filter,
} from '../../shared/types';

const { Option } = AntdSelect;

interface Props {
  selectValue: GroupsSortFilterValueEnum;
  disabled?: boolean;
  onSelect?: (value: GroupsSortFilterValueEnum) => void;
  onSearch?: (value: string) => void;
  groupCount?: number;
}

const GroupsSortFilter: React.FC<Props> = function (props: Props) {
  const { selectValue, disabled, onSelect, onSearch, groupCount } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Select
        value={selectValue}
        disabled={disabled}
        onSelect={onSelect}
        data-testid="groups-sort"
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
      >
        <Option
          data-testid="groups-sort-option--all-by-name"
          value={GroupsSortFilterValueEnum.ALL_BY_NAME}
        >
          {t('groups.groups-filters.all-groups')}
        </Option>
        <Option
          data-testid="groups-sort-option--admin-by-name"
          value={GroupsSortFilterValueEnum.ADMIN_BY_NAME}
        >
          {t('groups.groups-filters.admin-groups')}
        </Option>
      </Select>

      <div className={classes.count}>
        <span data-testid="groups-count">
          <span className="primary-body-bold">{groupCount}</span>{' '}
          {t('groups.group-count', { count: groupCount })}
        </span>
      </div>
      <div className={classes.search}>
        <Search
          disabled={disabled}
          placeholder="Search"
          onSearch={onSearch}
          data-testid="groups-search"
        />
      </div>
    </div>
  );
};

export const GroupFilter = {
  ADMIN_ENABLED: {
    field: FindGroupsField.ADMIN_ENABLED,
    value: true,
    operator: FilterOperator.EQUALS,
  } as Filter,

  SYNC_ENABLED_TRUE: {
    field: FindGroupsField.SYNC_ENABLED,
    operator: FilterOperator.EQUALS,
    value: true,
  } as Filter,

  SYNC_ENABLED_FALSE: {
    field: FindGroupsField.SYNC_ENABLED,
    operator: FilterOperator.EQUALS,
    value: false,
  } as Filter,

  IDP_DELETED_FALSE: {
    field: FindGroupsField.IDP_DELETED,
    operator: FilterOperator.EQUALS,
    value: false,
  } as Filter,

  USER_COUNT_ZERO: {
    field: FindGroupsField.USER_COUNT,
    value: 0,
    operator: FilterOperator.NOT_EQUALS,
  } as Filter,

  IDENTITY_PROVIDER_ID: (id: string | undefined) => ({
    field: FindGroupsField.IDENTITY_PROVIDER_ID,
    operator: FilterOperator.EQUALS,
    value: id,
  }),
};

export default GroupsSortFilter;
