// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import { type Observable } from 'rxjs';
import { client, noCredentialsClient } from './client';
import rxClient from './rxClient';
import { users } from './endpoint-names';
import {
  type AuthnFactorsBulkEnrollData,
  FileAccessModifier,
  type PrepareFileUploadResponse,
} from './types';
import { type UsersListPayload } from '../containers/users/store/types';
import { type ListSelectors } from '../shared/types';

export async function getUploadUrl(
  fileSuffix: string,
  fileAccessModifier: string = FileAccessModifier.PRIVATE,
) {
  return client
    .post<PrepareFileUploadResponse>(users.PREPARE_UPLOAD, {
      fileAccessModifier,
      fileSuffix,
    })
    .then(res => res.data);
}

export async function uploadFile(file: File, uploadUrl: string): Promise<void> {
  const fileContent = await file.arrayBuffer();
  return noCredentialsClient.put(uploadUrl, fileContent, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
}

export async function proxEnroll(readUrl: string) {
  const factorType = 'prox';
  return client
    .post<AuthnFactorsBulkEnrollData>(users.AUTHN_BULK_ENROLL, {
      readUrl,
      factorType,
    })
    .then(res => res.data);
}

export type FindUsersRequestPayload =
  | {
      selectors: ListSelectors;
      pageSize?: number;
    }
  | {
      pageToken: string;
      pageSize?: number;
    };

export function getUsersList$(
  payload: FindUsersRequestPayload,
): Observable<UsersListPayload> {
  return rxClient
    .post<UsersListPayload>(users.USERS_LIST, payload)
    .pipe(map(res => res.data));
}
