// Copyright 2024, Imprivata, Inc.  All rights reserved.

/*
 * Admin web app termination: https://github.com/imprivata-cloud/saas-api-idls/blob/main/openapi/admin-web-app-termination/v1/openapi/spec.yaml
 */

export enum TERMINATION_MODES {
  TERMINATE_ALL = 'TERMINATE_ALL',
  TERMINATE_SOME = 'TERMINATE_SOME',
  TERMINATE_NONE = 'TERMINATE_NONE',
}

export type AppTerminationGetResponse = {
  appTerminationConfig: AppTerminationConfig;
};

export type AppTerminationUpdateRequest = {
  appTerminationConfig: AppTerminationConfig;
};

export interface AppTerminationConfig {
  appTerminationType: TERMINATION_MODES;
  appTerminationInclusions: string;
  appTerminationExclusions: string;
}
