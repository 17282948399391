// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type PayloadAction } from 'typesafe-actions';
import {
  SUCCESS_CHECK_SETUP_MODE,
  ERROR_CHECK_SETUP_MODE,
  FETCH_CHECK_SETUP_MODE,
  PENDING_CHECK_SETUP_MODE,
  UPDATE_CHECK_SETUP_MODE,
} from './constants';
import { type SetupModePayload, type UpdateSetupModePayload } from './types';

export type SetupModeActionType = PayloadAction<
  typeof FETCH_CHECK_SETUP_MODE,
  Record<string, never>
>;

export const checkSetupModeAction = (): SetupModeActionType => {
  return { type: FETCH_CHECK_SETUP_MODE, payload: {} };
};

export type SetupModeActionPendingType = PayloadAction<
  typeof PENDING_CHECK_SETUP_MODE,
  Record<string, never>
>;

export const setupModeActionPending = (): SetupModeActionPendingType => {
  return { type: PENDING_CHECK_SETUP_MODE, payload: {} };
};

export type SetupModeActionSuccessType = PayloadAction<
  typeof SUCCESS_CHECK_SETUP_MODE,
  SetupModePayload
>;

export const setupModeActionSuccess = (
  payload: SetupModePayload,
): SetupModeActionSuccessType => {
  return { type: SUCCESS_CHECK_SETUP_MODE, payload };
};

export type SetupModeActionErrorType = PayloadAction<
  typeof ERROR_CHECK_SETUP_MODE,
  { error?: string }
>;

export const setupModeActionError = (
  error?: string,
): SetupModeActionErrorType => {
  return { type: ERROR_CHECK_SETUP_MODE, payload: { error } };
};

export type UpdateSetupModeType = PayloadAction<
  typeof UPDATE_CHECK_SETUP_MODE,
  UpdateSetupModePayload
>;

export const updateSetupModeAction = (
  payload: UpdateSetupModePayload,
): UpdateSetupModeType => {
  return { type: UPDATE_CHECK_SETUP_MODE, payload };
};
