import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type AADGroupUserCountGetPayload,
  type AADGroupUserCountGetResponse,
  type AdminGroupsChooseRequest,
  type StartInitialSyncPayload,
} from './types';
import { adminInitialSetup } from './endpoint-names';
import rxClient from './rxClient';

export function startInitialSync$(
  payload: StartInitialSyncPayload,
): Observable<void> {
  return rxClient
    .post<void>(adminInitialSetup.INITIAL_SYNC, payload)
    .pipe(map(res => res.data));
}

export function aadGroupUserCountGet$(
  payload: AADGroupUserCountGetPayload,
): Observable<AADGroupUserCountGetResponse> {
  return rxClient
    .post<AADGroupUserCountGetResponse>(
      adminInitialSetup.AAD_GROUP_USER_COUNT,
      payload,
    )
    .pipe(map(res => res.data));
}

export function chooseAdminGroups$(
  payload: AdminGroupsChooseRequest,
): Observable<void> {
  return rxClient
    .post<void>(adminInitialSetup.CHOOSE_ADMIN_GROUPS, payload)
    .pipe(map(res => res.data));
}
