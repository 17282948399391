// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type FindListPayload, type GroupsFindData } from '../shared/types';
import { groups } from './endpoint-names';
import rxClient from './rxClient';
import { client } from './client';
import {
  type GroupForSyncMarkRequestPayload,
  type GroupForSyncUnmarkRequestPayload,
} from './types';

export function findGroups$(
  payload: FindListPayload,
): Observable<GroupsFindData> {
  return rxClient
    .post<GroupsFindData>(groups.FIND_GROUPS, payload)
    .pipe(map(res => res.data));
}

export const findGroups = async (
  payload: FindListPayload,
): Promise<GroupsFindData> => {
  const res = await client.post<GroupsFindData>(groups.FIND_GROUPS, payload);

  return res.data;
};

export function groupForSyncMark$(
  payload: GroupForSyncMarkRequestPayload,
): Observable<void> {
  return rxClient
    .post<void>(groups.SYNC_MARK, payload)
    .pipe(map(res => res.data));
}

export function groupForSyncUnmark$(
  payload: GroupForSyncUnmarkRequestPayload,
): Observable<void> {
  return rxClient
    .post<void>(groups.SYNC_UNMARK, payload)
    .pipe(map(res => res.data));
}
