// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  type CreateSessionPayload,
  type OrgPreferencesData,
} from '../../../api/types';

export const initialStateTestAction = createAsyncAction(
  'initial-state-test/REQUEST',
  'initial-state-test/SUCCESS',
  'initial-state-test/FAILURE',
)<void, void, void>();

export const getOrgPreferencesAction = createAsyncAction(
  'org-preferences/REQUEST',
  'org-preferences/SUCCESS',
  'org-preferences/FAILURE',
)<string, OrgPreferencesData, string>();

export const createSessionAction = createAsyncAction(
  'create-session/REQUEST',
  'create-session/SUCCESS',
  'create-session/FAILURE',
)<{ password: string }, CreateSessionPayload, string>();

export const stateSaveUsernameAction = createAction(
  'state-save-username',
)<string>();

export const stateSaveUsernameEnteredAction = createAction(
  'state-save-username-is-entered',
)<boolean>();

export const validatePermissionAction = createAsyncAction(
  'validate-permission/REQUEST',
  'validate-permission/SUCCESS',
  'validate-permission/FAILURE',
)<{ sessionId?: string; tenantId?: string }, boolean, string>();

export const invalidSessionAction = createAsyncAction(
  'invalid-session/REQUEST',
  'invalid-session/SUCCESS',
  'invalid-session/FAILURE',
)<
  // The params will be used to fill the reset state
  { storedURL?: string; errorMsgKey: string },
  { storedURL?: string; errorMsgKey: string },
  void
>();

export const clearStoredUrlAction = createAction('clear-stored-url')();

export const clearErrorsAction = createAction('clear-errors')<boolean>();

export const clearSessionIdAction = createAction('clear-session-id')<string>();

export const ambiguousUsernameAction = createAction('ambiguous-username')();
