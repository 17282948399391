import { deleteHeader, setHeader } from '../../api/api';
import { Headers } from '../../api/constants';
import { StorageKeys } from './store/constants';
import { saveToStorage, removeFromStorage } from '../../utils';

export const saveTenantId = (tenantId: string) => {
  setHeader(Headers.ImprTenantId, tenantId);
};

export const clearTenantId = () => {
  deleteHeader(Headers.ImprTenantId);
};

export const saveSessionId = (sessionId: string) => {
  saveToStorage(StorageKeys.SESSION_ID, sessionId);
};

export const clearSessionId = () => {
  removeFromStorage(StorageKeys.SESSION_ID);
};
