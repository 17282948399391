// Copyright 2022, Imprivata, Inc.  All rights reserved.

export type FindListPayload = PayloadWithSelectors | PayloadWithPageToken;

export type PayloadWithSelectors = {
  selectors: ListSelectors;
  pageSize?: number;
};

export type PayloadWithPageToken = {
  pageToken: string;
  pageSize?: number;
};

export type ListSelectors = {
  fields?: string[];
  sortFields?: SortField[];
  filters?: Filter[];
  search?: SearchSelector;
};

export type SortField<T = string> = {
  field: T;
  order?: SortOrder;
};

export type Filter = {
  field: string;
  value?: string | boolean | number;
  operator?: FilterOperator;
};

export type SearchSelector<T = string> = {
  pattern: string;
  fields?: T[];
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FilterOperator {
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  NOT_NULL = 'not-null',
}

export const ALL_ITEMS_PATTERN = '*';

export type PagingInfo = {
  beforeToken: string;
  afterToken: string;
};
