// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { UploadOutlined } from '@ant-design/icons';
import { Typography, Upload, Button } from 'antd';
import { Banner } from '@imprivata-cloud/components';
import {
  type UploadProps,
  type RcFile,
  type UploadChangeParam,
} from 'antd/lib/upload';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './LogoUploader.module.less';

// Tech debt task: move file upload funcs of usersService into its own fileService
import { getUploadUrl, uploadFile } from '../../../api/usersService';
import { FileAccessModifier } from '../../../api/types';

import defaultLogo from '../../../assets/example/default-logo.png';

const maxFileSize = 102400; // 100k
const allowedExtensions = ['.png', '.jpg', '.gif'];
const allowedFileTypes = ['image/png', 'image/jpg', 'image/gif'];

const handleFileValidation = (file: RcFile) => {
  const parts = file.name.split('.');
  const fileExt = parts[parts.length - 1] || '';
  const isValidType =
    allowedFileTypes.includes(file.type) ||
    allowedExtensions.includes(`.${fileExt}`);

  if (file.size > maxFileSize || !isValidType) {
    Banner({
      type: 'error',
      message: "Couldn't upload file.",
      description: 'Try again.',
      duration: 10,
      datatestid: 'logouploader-file-size-message',
    });
    return Upload.LIST_IGNORE;
  } else {
    return true;
  }
};

interface UploaderProps {
  onUploadCallback: (url: string) => void;
  storedLogoUrl?: string;
  reset: boolean;
}

const LogoUploader: FC<UploaderProps> = ({
  onUploadCallback,
  storedLogoUrl,
  reset,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(storedLogoUrl);

  useEffect(() => {
    setLogoUrl(storedLogoUrl);
  }, [reset, storedLogoUrl]);

  // eslint-disable-next-line
  const uploadFileAction: UploadProps['customRequest'] = async ({
    onSuccess,
    onError,
    file,
  }) => {
    try {
      const links = await getUploadUrl(
        (file as RcFile).name,
        FileAccessModifier.PUBLIC,
      );
      await uploadFile(file as RcFile, links.uploadUrl);
      onUploadCallback(links.readUrl);
      setLogoUrl(links.readUrl);

      onSuccess?.(file as RcFile);
    } catch (e) {
      console.log('File service rejected file upload because:', e);
      onError?.(new Error("Couldn't upload file. Try again."));
    }
  };

  const handleOnChangeAction = ({ file }: UploadChangeParam) => {
    const { status } = file;

    if (status === 'uploading') {
      setIsLoading(true);
      return;
    }

    if (status === 'error') {
      Banner({
        type: 'error',
        message: `${file.name} ${file.error}`,
        duration: 10,
        datatestid: 'logouploader-file-error-message',
      });
    }

    setIsLoading(false);
  };

  const props = {
    customRequest: uploadFileAction,
    multiple: false,
    accept: [...allowedFileTypes, ...allowedExtensions].join(','),
    maxCount: 1,
    beforeUpload: handleFileValidation,
    onChange: handleOnChangeAction,
  };

  return (
    <>
      <Upload
        {...props}
        className={classes.uploadArea}
        data-testid="logo-uploader"
        showUploadList={false}
      >
        <label className={classes.uploadLink}>
          {t('appearance.organization.customization.logo.label')}
        </label>
        <Button
          className={classes.uploadBtn}
          type="text"
          data-testid="logo-upload-link"
          icon={<UploadOutlined />}
          disabled={isLoading}
          tabIndex={-1}
        >
          {t('appearance.organization.customization.logo.actions.upload')}
        </Button>

        <div className={classes.imageBox}>
          <img
            className={classes.imageInner}
            alt="Logo Upload Placeholder"
            src={logoUrl || defaultLogo}
            data-testid="logo-upload-placeholder"
          />
        </div>
      </Upload>
      <Typography.Paragraph>
        {t('appearance.organization.customization.logo.upload.description')}
      </Typography.Paragraph>
    </>
  );
};

export default LogoUploader;
