// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { Modal } from 'antd';
import React from 'react';
import classes from './save-discard-modal/SaveDiscardModal.module.less';
import CrossIcon from '../../assets/svg/cancel.svg?url';

interface ErrorMoadalProps {
  title: string;
  content: string;
  visible: boolean;
  okText: string;
  onClose?: () => void;
}

const ErrorModal: React.FC<ErrorMoadalProps> = (props: ErrorMoadalProps) => {
  return (
    <Modal
      className={classes.modal}
      okText={props.okText}
      open={props.visible}
      data-testid="error-modal"
      onCancel={props.onClose}
      footer={null}
    >
      <div className={classes.modalContentWraper}>
        <div>
          <img src={CrossIcon} alt="Cross" />
          <div className={classes.modalTitle}>{props.title}</div>
          <div className={classes.modalContent}>{props.content}</div>
        </div>
        <div className={classes.modalFooterButtons}>
          <Button
            data-testid="error-modal-ok-button"
            variant={ButtonVariant.PRIMARY}
            onClick={props.onClose}
            label={props.okText}
            autoFocus
          />
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
