// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useState, useEffect, useRef } from 'react';
import { Table, Modal, type TableProps } from 'antd';

import Icon from '@ant-design/icons';
import {
  Button,
  type ButtonProps,
  ButtonVariant,
} from '@imprivata-cloud/components';
import getIcon from '../../../assets/svg/getIcon.svg?react';

import classes from './DashboardViewModal.module.less';

type TableRecords = Partial<Record<string, string | number>>;

interface Props<T extends TableRecords = TableRecords> {
  title: string;
  open: boolean;
  onClose: () => void;
  disableFooter?: boolean;
  footer?: string | null;
  csvButtonProps?: ButtonProps;
  table?: TableProps<T extends TableRecords ? TableRecords : string>;
  totalItems?: number;
}

function DashboardViewModal<
  T extends Partial<Record<string, string>> = Partial<Record<string, string>>,
>({ csvButtonProps, open, ...props }: Props<T>) {
  const [loading, setLoading] = useState(false);
  const [pageSize, setpageSize] = useState<number>(50);
  const contentRef = useRef<HTMLDivElement>(null);

  const { table, title, onClose, disableFooter, footer, totalItems } = props;

  useEffect(() => {
    setLoading(false);
  }, [pageSize]);

  useEffect(() => {
    if (!open) {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }
  }, [open]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.currentTarget;
    const bottomReached =
      target.scrollHeight - target.scrollTop - 1 == target.clientHeight;
    if (bottomReached && !loading) {
      setLoading(true);
      setpageSize(prevpageSize => prevpageSize + 50);
    }
  };

  const currentItemsCount = () => {
    if (totalItems && pageSize > totalItems) {
      return totalItems;
    } else {
      return pageSize;
    }
  };

  return (
    <Modal
      title={<span className={classes.modalTitle}>{title}</span>}
      centered
      open={open}
      onCancel={onClose}
      className={classes.dashboardViewModal}
      footer={disableFooter ? null : footer}
      data-testid="dashboard-view-modal"
      bodyStyle={{ overflowY: 'auto', maxHeight: '80vh' }}
    >
      <div onScroll={handleScroll} ref={contentRef}>
        {csvButtonProps && (
          <Button
            icon={<Icon component={getIcon} />}
            variant={ButtonVariant.TEXT}
            data-testid="csv-download-link"
            className="float-right"
            {...csvButtonProps}
          />
        )}
        {table && (
          <>
            <Table
              {...table}
              pagination={{
                hideOnSinglePage: true,
                pageSize: pageSize,
                simple: true,
                total: pageSize,
              }}
            />
            <p
              className={classes.modalItemsCounter}
              data-testid="modal-items-counter"
            >
              Showing {currentItemsCount()} of {totalItems} items
            </p>
          </>
        )}
      </div>
    </Modal>
  );
}

export default DashboardViewModal;
