// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type ReactElement } from 'react';
import {
  Link,
  useLocation,
  Route,
  type LinkProps,
  type RouteProps,
  type RedirectProps,
  Redirect,
} from 'react-router-dom';
import history from '../routers/history';

export type LinkWithQueryProps = LinkProps;

export const LinkWithQuery = ({
  children,
  to,
  ...props
}: LinkWithQueryProps): ReactElement<LinkProps> => {
  const { search } = useLocation();

  let toStr = '';

  if (typeof to === 'string') {
    toStr = to;
  } else if (typeof to === 'object') {
    toStr = to.pathname || '';
  }

  return (
    <Link to={toStr + search} {...props}>
      {children}
    </Link>
  );
};

export const RouteWithQuery = ({
  children,
  ...props
}: RouteProps): ReactElement<RouteProps> => {
  const { search } = useLocation();

  return (
    <Route path={`${props.path}${search}`} {...props}>
      {children}
    </Route>
  );
};

export const RedirectWithQuery: React.FC<
  Omit<RedirectProps, 'to'> & { to: string }
> = props => {
  const { search } = useLocation();
  const currentSearchParams = new URLSearchParams(search);
  const redirectUrl = new URL(props.to, window.location.origin);

  const mergedSearchParams = new URLSearchParams({
    ...Object.fromEntries(currentSearchParams),
    ...Object.fromEntries(redirectUrl.searchParams),
  });

  return (
    <Redirect
      {...props}
      to={`${redirectUrl.pathname}?${mergedSearchParams.toString()}`}
    />
  );
};

export function redirectWithQuery(path: string): void {
  history.push(path + window.location.search);
}
