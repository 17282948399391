// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type PagingInfo } from '..';

export type GroupsFindData = {
  groups: Group[];
  totalCount: number;
  latestUpdatedAt?: number;
  pagingInfo?: PagingInfo;
};

export interface GroupAdminEnabledUpdatePayload {
  groupId: string;
  adminEnabled: boolean;
}

export interface GroupSyncEnabledUpdateRequestPayload {
  groupId: string;
  syncEnabled: boolean;
}

export interface Group {
  groupName: string;
  groupId: string;
  userCount: number;
  syncEnabled: boolean;
  adminEnabled: boolean;
  idpDeleted: boolean;
  hash?: string;
}

// https://github.com/imprivata-cloud/astra-identity-common/blob/main/src/main/java/com/imprivata/astra/identity/common/mappers/data/FindGroupsField.java
export enum FindGroupsField {
  IDENTITY_PROVIDER_ID = 'identity-provider-id', // Matches directory ID
  NAME = 'name',
  ADMIN_ENABLED = 'admin-enabled',
  SYNC_ENABLED = 'sync-enabled',
  USER_COUNT = 'user-count',
  IDP_DELETED = 'idp-deleted',
}
