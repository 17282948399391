/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  getGroups,
  getGroupsNextPortion,
  groupUnmarkForSync,
  startInitialSync,
} from './actions';
import { type Group } from '../../../shared/types';
import { type RootAction } from '../../../store/rootAction';

export interface GroupsState {
  loading: boolean;
  error: string | null;
  nextPortionToken: string;
  groups: Group[];
  lastChangesUpdateTs: number;
}

export const initialState: GroupsState = {
  loading: false,
  nextPortionToken: '',
  groups: [],
  error: null,
  lastChangesUpdateTs: 0,
};

export const groupsReducer = combineReducers<GroupsState>({
  loading: createReducer(initialState.loading)
    .handleAction(
      [
        getGroups.request,
        getGroupsNextPortion.request,
        groupUnmarkForSync.request,
      ],
      () => true,
    )
    .handleAction(
      [
        getGroups.success,
        getGroups.failure,
        getGroupsNextPortion.success,
        getGroupsNextPortion.failure,
        groupUnmarkForSync.success,
        groupUnmarkForSync.failure,
        startInitialSync.success,
        startInitialSync.failure,
      ],
      () => false,
    ),

  error: createReducer<string | null, RootAction>(initialState.error)
    .handleAction(groupUnmarkForSync.failure, (_, { payload }) => payload.code)
    .handleAction([getGroups.request, getGroups.success], () => null)
    .handleAction(
      getGroups.failure,
      (_state, { payload }) => payload.code || null,
    ),

  nextPortionToken: createReducer<string, RootAction>(
    initialState.nextPortionToken,
  )
    .handleAction(getGroups.failure, () => initialState.nextPortionToken)
    .handleAction(
      [getGroups.success, getGroupsNextPortion.success],
      (_, { payload }) => payload.pagingInfo?.afterToken || '',
    ),

  groups: createReducer<Group[], RootAction>(initialState.groups)
    .handleAction(getGroups.failure, () => initialState.groups)
    .handleAction(getGroups.success, (_, { payload }) =>
      payload.groups.filter(group => group.syncEnabled),
    )
    .handleAction(getGroupsNextPortion.success, (groups, { payload }) => [
      ...groups,
      ...payload.groups.filter(group => group.syncEnabled),
    ]),

  lastChangesUpdateTs: createReducer<number, RootAction>(
    initialState.lastChangesUpdateTs,
  ).handleAction(groupUnmarkForSync.success, () => Date.now()),
});
