// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { jsonToBase64, stringToBase64 } from '@imprivata-cloud/data-privacy-js';
import { Banner } from '@imprivata-cloud/components';
import {
  CERTIFICATE_HIDDEN_FIELD_NAME,
  type AdfsIdpFormValues,
  type OneSignIdpFormValues,
} from './types';
import CancelButton from '../../../components/page-layout/action-bar/CancelButton';
import SaveButton from '../../../components/page-layout/action-bar/SaveButton';
import { saveMetadata } from '../../../api/identityBrokerService';
import {
  IdpName,
  IdpProtocol,
  UserRuleType,
  type IdpMetadata,
} from '../../../shared/types/api/identity-broker';
import { SettingsPageLayout } from '../components';
import { IdpFormContainer } from './containers/IdpFormContainer';
import { GroupSelectionContainer } from './containers/GroupsSelectionContainer';
import { AstraIdpInfo } from './containers/AstraIdpInfo';
import { constructErrorMessage } from './utils';
import { useIdpMetadataQuery } from './hooks';
import {
  Header,
  HeaderDescription,
  RoutingSectionHeader,
  DisplayNameInput,
} from './components';

type ValidationError = {
  errorFields: {
    errors: [string];
    name: [string];
  }[];
};

const IdentityBroker: React.FC = () => {
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState<string>('');

  const idpMetadataQuery = useIdpMetadataQuery({
    onSuccess: data => {
      if (data.incomplete) {
        Banner({
          type: 'error',
          message: t('identity-broker.error-messages.incomplete-configuration'),
          duration: 10,
          datatestid: 'idp-form--error-message',
        });
      }

      setDisplayName(data.entraIdDisplayName);
    },
  });

  const idpMetadata = idpMetadataQuery.data?.metadataList;
  const isLoaded = idpMetadataQuery.isFetched;

  const [isPageTouched, setIsPageTouched] = useState(false);

  const [eamcGroupsForm] = Form.useForm<{ selected: string[] }>();
  const [oneSignGroupsForm] = Form.useForm<{ selected: string[] }>();
  const [oneSignForm] = Form.useForm<OneSignIdpFormValues>();
  const [adfsForm] = Form.useForm<AdfsIdpFormValues>();

  const [adfsErrorField, setAdfsErrorField] = useState<string>();
  const [oneSignErrorField, setOneSignErrorField] = useState<string>();

  const errorMessage = constructErrorMessage({
    adfsErrorField,
    oneSignErrorField,
  });

  const clearErrorMessage = () => {
    setAdfsErrorField('');
    setOneSignErrorField('');
  };

  useEffect(() => {
    if (errorMessage) {
      // when error message changes, it means that there was a submission, after every
      // submission save button should be disabled
      setIsPageTouched(false);

      Banner({
        type: 'error',
        message: errorMessage,
        duration: 10,
        datatestid: 'idp-form--error-message',
        onClose: clearErrorMessage,
      });
    }
  }, [errorMessage]);

  const onSubmit = () => {
    clearErrorMessage();

    adfsForm
      .validateFields()
      .then(() => {
        setAdfsErrorField(undefined);

        oneSignForm
          .validateFields()
          .then(() => {
            setOneSignErrorField(undefined);

            const metadataList = [];

            const adfsFormValues = adfsForm.getFieldsValue();
            const oneSignFormValues = oneSignForm.getFieldsValue();

            const oneSignGroupFormValues = oneSignGroupsForm.getFieldsValue();
            const eamcGroupFormValues = eamcGroupsForm.getFieldsValue();

            const isIdpFormEmpty = (
              idpFormValues: OneSignIdpFormValues | AdfsIdpFormValues,
            ) => {
              return !Object.entries(idpFormValues)
                .filter(
                  ([key]) =>
                    // the user can't "clear" the certificate field
                    // also, when certificate has been uploaded before, the fileld shows text "Uploaded", which is also not clearable
                    // thus, the form is considired empty even when the certificate file field is not empty
                    key !== CERTIFICATE_HIDDEN_FIELD_NAME &&
                    key !== 'idp_certificate',
                )
                .some(([_, value]) => !!value);
            };

            const { [CERTIFICATE_HIDDEN_FIELD_NAME]: _1, ...adfsMetadataInfo } =
              adfsFormValues;
            const {
              [CERTIFICATE_HIDDEN_FIELD_NAME]: _2,
              ...oneSignMetadatainfo
            } = oneSignFormValues;

            if (!isIdpFormEmpty(adfsFormValues)) {
              metadataList.push({
                metadataName: IdpName.ADFS,
                priority: 3,
                protocol: IdpProtocol.WS_FED,
                metadataInfo: stringToBase64(JSON.stringify(adfsMetadataInfo)),
              });
            }

            if (
              !isIdpFormEmpty(oneSignFormValues) ||
              oneSignGroupFormValues.selected.length !== 0
            ) {
              const oneSignMetadata: IdpMetadata = {
                metadataName: IdpName.OneSign,
                priority: 2,
                protocol: IdpProtocol.WS_FED,
                metadataInfo: '',
              };

              if (!isIdpFormEmpty(oneSignFormValues)) {
                oneSignMetadata.metadataInfo = stringToBase64(
                  JSON.stringify(oneSignMetadatainfo),
                );
              }

              if (oneSignGroupFormValues.selected.length !== 0) {
                oneSignMetadata.userRule = jsonToBase64({
                  type: UserRuleType.STRING_LIST,
                  value: stringToBase64(
                    JSON.stringify(oneSignGroupFormValues.selected),
                  ),
                });
              }

              metadataList.push(oneSignMetadata);
            }

            if (eamcGroupFormValues.selected.length !== 0) {
              metadataList.push({
                metadataName: IdpName.EAMC,
                priority: 1,
                protocol: IdpProtocol.WS_FED,
                metadataInfo: stringToBase64(JSON.stringify({})),
                userRule: jsonToBase64({
                  type: UserRuleType.STRING_LIST,
                  value: stringToBase64(
                    JSON.stringify(eamcGroupFormValues.selected),
                  ),
                }),
              });
            }

            // save default value if the display name is empty
            const entraIdDisplayName =
              displayName || t('identity-broker.default-display-name');

            saveMetadata({
              entraIdDisplayName,
              metadataList: [...metadataList],
            })
              .then(async () => {
                setIsPageTouched(false);
                await idpMetadataQuery.refetch();
              })
              .catch(
                (error: {
                  data?: { fieldName?: string; metadataName?: string };
                }) => {
                  if (error?.data?.fieldName && error?.data.metadataName) {
                    if (error.data.metadataName === (IdpName.ADFS as string)) {
                      setAdfsErrorField(error.data.fieldName);
                    } else if (
                      error.data.metadataName === (IdpName.OneSign as string)
                    ) {
                      setOneSignErrorField(error.data.fieldName);
                    } else {
                      console.error(
                        'Unknown metadataName',
                        error.data.metadataName,
                      );
                    }
                  } else {
                    console.error('Unknown error', error);
                  }
                },
              );
          })
          .catch((error: ValidationError) => {
            if (error.errorFields) {
              setOneSignErrorField(error?.errorFields[0]?.name[0]);
            } else {
              console.error('Unexpected error', error);
            }
          });
      })
      .catch((error: ValidationError) => {
        if (error.errorFields) {
          setAdfsErrorField(error.errorFields[0]?.name[0]);
        } else {
          console.error('Unexpected error', error);
        }
      });
  };

  const onCancel = () => {
    oneSignForm.resetFields();
    oneSignGroupsForm.resetFields();
    adfsForm.resetFields();
  };

  return (
    <SettingsPageLayout
      title={[
        <SaveButton
          disabled={!isPageTouched}
          key="save-button"
          data-testid="save-button"
          onClick={onSubmit}
        />,
        <CancelButton
          data-testid="cancel-button"
          key="cancel-button"
          onClick={onCancel}
        />,
      ]}
    >
      <div data-testid="identity-broker-page">
        <div>
          <Header />
          <HeaderDescription />

          <DisplayNameInput
            data-testid="idp-form--display-name"
            disabled={!isLoaded}
            value={displayName}
            onChange={e => {
              setIsPageTouched(true);
              setDisplayName(e.target.value);
            }}
          />

          <RoutingSectionHeader />
          <GroupSelectionContainer
            data-testid="eamc-group-selection-container"
            formInstance={eamcGroupsForm}
            title={t(
              'identity-broker.routing-section.group-selection.eamc-label',
            )}
            metadataName={IdpName.EAMC}
            onTouched={() => {
              setIsPageTouched(true);
            }}
          />
          <GroupSelectionContainer
            data-testid="one-sign-group-selection-container"
            formInstance={oneSignGroupsForm}
            title={t('identity-broker.group-selection.label')}
            metadataName={IdpName.OneSign}
            onTouched={() => {
              setIsPageTouched(true);
            }}
          />
          <div style={{ marginBottom: 60 }}>
            <IdpFormContainer<OneSignIdpFormValues>
              data-testid="one-sign-idp-form"
              header={t('identity-broker.one-sign-idp.header')}
              formInstance={oneSignForm}
              metadataName={IdpName.OneSign}
              onTouched={() => {
                setIsPageTouched(true);
              }}
              isLoaded={isLoaded}
              idpMetadata={idpMetadata?.find(
                m => m.metadataName === IdpName.OneSign,
              )}
            />
            <IdpFormContainer<AdfsIdpFormValues>
              data-testid="adfs-idp-form"
              header={t('identity-broker.adfs-idp.header')}
              formInstance={adfsForm}
              metadataName={IdpName.ADFS}
              onTouched={() => {
                setIsPageTouched(true);
              }}
              isLoaded={isLoaded}
              idpMetadata={idpMetadata?.find(
                m => m.metadataName === IdpName.ADFS,
              )}
            />
          </div>
          <AstraIdpInfo />
        </div>
      </div>
    </SettingsPageLayout>
  );
};

export default IdentityBroker;
