// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/page-layout/PageLayout';
import AppsList from './AppList';
import AppsFilters from './AppsFilters';
import SetTitle from '../../utils/DynamicTitleHelper';
import UploadsBar from './UploadsBar';
import { type RootState } from '../../store/rootReducer';
import { type ApplicationsListState } from './store/reducer';

const Applications: FC = () => {
  const { t } = useTranslation();
  SetTitle(t('navigation.apps'));

  const applicationsListState = useSelector<RootState, ApplicationsListState>(
    rootState => rootState.apps.applicationsList,
  );

  const [applicationsAvailable, setApplicationsAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    if (applicationsListState.apiApplications.length > 0) {
      setApplicationsAvailable(true);
    } else {
      setApplicationsAvailable(false);
    }
  }, [applicationsListState]);

  return (
    <PageLayout title={t('navigation.apps')}>
      {applicationsAvailable ? <UploadsBar data-testid="uploads-bar" /> : null}
      {applicationsAvailable && <AppsFilters />}
      <AppsList />
    </PageLayout>
  );
};

export default Applications;
