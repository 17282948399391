// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { type FunctionComponent } from 'react';
import { Popover } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { type AbstractTooltipProps as AntdPopoverProps } from 'antd/lib/tooltip';
import { type ParseKeys } from 'i18next';
import InfoIcon from '../../assets/svg/info.svg?react';
import classes from './InfoPopover.module.less';

type InfoPopoverProps = AntdPopoverProps & {
  contentTranslationKey: ParseKeys;
};

const InfoPopover: FunctionComponent<InfoPopoverProps> = props => {
  const { t } = useTranslation();
  const contentTranslationKey = props.contentTranslationKey;

  return (
    <Popover
      {...props}
      content={<Trans t={t} i18nKey={contentTranslationKey} />}
      autoAdjustOverflow={true}
    >
      <InfoIcon className={classes.infoPopoverIcon} />
    </Popover>
  );
};

export default InfoPopover;
