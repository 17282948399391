// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React, { type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputBox } from '@imprivata-cloud/components';
import clsx from 'clsx';
import classes from './index.module.less';

export const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={clsx('h3-header', classes.header)}>
      {t('identity-broker.header.main')}
    </div>
  );
};

export const HeaderDescription: React.FC = () => {
  const { t } = useTranslation();

  const documentationUrl =
    'https://docs.imprivata.com/eamc/content/topics/connector_hyperdrive.html';

  return (
    <div className={classes.headerDescription}>
      <span className="primary-body">
        {t('identity-broker.header.description')}
      </span>
      <a
        className={classes.headerDescriptionLink}
        onClick={() => {
          window.open(documentationUrl, '_blank', 'noreferrer noopener');
        }}
        href={documentationUrl}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('identity-broker.header.documentation-link-btn')}
      </a>
    </div>
  );
};

export const RoutingSectionHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={clsx('subtitle', classes.routingSectionHeader)}>
      {t('identity-broker.routing-section.header')}
    </div>
  );
};

export const DisplayNameInput: React.FC<{
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
}> = props => {
  const { t } = useTranslation();

  return (
    <div className={classes.displayNameInputContainer}>
      <InputBox
        type="text"
        label={t('identity-broker.display-name')}
        {...props}
      />
    </div>
  );
};
