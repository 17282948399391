// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { useAutologinRequest } from './store/hooks';

const AutologinContainer = (): JSX.Element => {
  const { isLoading, hasAuthData, redirectTo } = useAutologinRequest();

  if (hasAuthData && isLoading) {
    return <Spin style={{ marginTop: '200px' }} spinning data-testid="spin" />;
  }

  return <Redirect to={redirectTo} />;
};

export default AutologinContainer;
