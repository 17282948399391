// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useRef, useEffect, type PropsWithChildren } from 'react';
import { type Subscription } from 'rxjs';
import { createEndpointsViewportObserver } from '../../containers/endpoints/utils';

interface InfiniteScrollContainerProps {
  onMore: () => void;
}

export const InfiniteScrollContainer = (
  props: PropsWithChildren<InfiniteScrollContainerProps>,
) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let subscription: Subscription;
    if (ref.current) {
      subscription = createEndpointsViewportObserver(ref.current).subscribe(
        events => {
          if (events.some(event => event.isIntersecting)) {
            props.onMore();
          }
        },
      );
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [ref, props]);

  return (
    <div className="infinite-scroll-container">
      {props.children}
      <div ref={ref} style={{ height: 1 }} />
    </div>
  );
};
