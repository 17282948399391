// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import Icon from '@ant-design/icons';
import { type ActionButtonProps } from './types';
import AddIcon from '../../../assets/svg/addIcon.svg?react';

const AddGroupLink: React.FC<ActionButtonProps> = function AddGroupLink({
  onClick,
  className,
  disabled,
  label,
  'data-testid': dataTestId,
}) {
  return (
    <Button
      label={label}
      variant={ButtonVariant.TEXT}
      data-testid={dataTestId}
      icon={<Icon component={AddIcon} />}
      className={className}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default AddGroupLink;
