// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { ImprHeaders } from '@imprivata-cloud/common';
import { client } from './client';
import { preferences } from './endpoint-names';
import {
  type OrgPreferencesUpdateRequest,
  type OrgPreferencesData,
} from './types';

export async function fetchOrgPreferences(): Promise<OrgPreferencesData> {
  return client
    .post<OrgPreferencesData>(preferences.GET_PREFERENCES, undefined, {
      headers: {
        [ImprHeaders.ImprTenantId]:
          new URLSearchParams(window.location.search).get('tenantId') || '',
      },
    })
    .then(res => res.data);
}

export async function updateOrgPreferences(
  updateRequest: OrgPreferencesUpdateRequest,
): Promise<unknown> {
  return client
    .post(preferences.UPDATE_PREFERENCES, {
      logoUrl: updateRequest.logoUrl,
      orgName: updateRequest.orgName,
      usernameLabel: updateRequest.usernameLabel,
      passwordLabel: updateRequest.passwordLabel,
      proxCardLabel: updateRequest.proxCardLabel,
    })
    .then(res => res.data as unknown);
}
