// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { StorageKeys } from './constants';
import { getFromStorage } from '../../../utils';
import { setHeader } from '../../../api/api';
import { Headers } from '../../../api/constants';
import { type RootAction } from '../../../store/rootAction';
import {
  ambiguousUsernameAction,
  clearErrorsAction,
  clearSessionIdAction,
  clearStoredUrlAction,
  createSessionAction,
  invalidSessionAction,
  stateSaveUsernameAction,
  stateSaveUsernameEnteredAction,
  validatePermissionAction,
} from './actions';

export interface LoginState {
  tenantId?: string;
  factorOptionsError?: string;
  sessionId?: string;
  sessionIdError?: string;
  username: string;
  authenticationError?: string;
  disabledError?: string;
  expiredError?: string;
  sessionExpiredError?: string;
  authnToken?: string;
  hasPermission?: boolean;
  permissionError?: string;
  preloginMessageKey?: string;
  storedURL?: string;
  logoURL?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  authenticating: boolean;
  validatingPermissions: boolean;
  isUsernameEntered: boolean;
}

export const initialState: LoginState = {
  authenticating: false,
  validatingPermissions: false,
  isUsernameEntered: false,
  hasPermission: undefined,
  sessionId: undefined,
  tenantId: undefined,
  username: '',
};

export const initialSessionId = 'initial-session-id';

export function rehydrate(): LoginState {
  // retrieve auth info from storage
  const storedSessionId = getFromStorage(StorageKeys.SESSION_ID) || undefined;
  const headerTenantId =
    new URLSearchParams(window.location.search).get('tenantId') || '';

  if (storedSessionId && headerTenantId) {
    setHeader(Headers.ImprTenantId, headerTenantId);
  }

  return {
    ...initialState,
    sessionId: storedSessionId,
    tenantId: headerTenantId,
    hasPermission: !!storedSessionId || undefined,
  };
}

export const loginReducer = createReducer<LoginState, RootAction>(initialState)
  .handleAction(ambiguousUsernameAction, state => {
    return {
      ...state,
      isUsernameEntered: false,
      authenticating: false,
      username: '',
      preloginMessageKey: 'errors.UXID_MULTIPLEUSERS',
    };
  })
  .handleAction(createSessionAction.success, (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  })
  .handleAction(
    createSessionAction.failure,
    (state, { payload: sessionIdError }) => {
      return {
        ...state,
        sessionIdError,
      };
    },
  )
  .handleAction(stateSaveUsernameAction, (state, { payload: username }) => {
    return {
      ...state,
      username,
      isUsernameEntered: true,
      authenticationError: undefined,
      sessionExpiredError: undefined,
      preloginMessageKey: undefined,
      permissionError: undefined,
      sessionId: undefined,
    };
  })
  .handleAction(
    stateSaveUsernameEnteredAction,
    (state, { payload: isUsernameEntered }) => {
      return {
        ...state,
        isUsernameEntered,
      };
    },
  )
  .handleAction(validatePermissionAction.request, (state, { payload }) => {
    const sessionId = payload.sessionId || state.sessionId;
    const tenantId = payload.tenantId || state.tenantId;
    return {
      ...state,
      sessionId,
      tenantId,
      hasPermission: undefined,
      validatingPermissions: true,
    };
  })
  .handleAction(
    validatePermissionAction.success,
    (state, { payload: hasPermission }) => {
      return {
        ...state,
        hasPermission,
        validatingPermissions: false,
      };
    },
  )
  .handleAction(
    validatePermissionAction.failure,
    (state, { payload: permissionError }) => {
      return {
        ...state,
        hasPermission: false,
        permissionError,
        validatingPermissions: false,
      };
    },
  )
  .handleAction(invalidSessionAction.success, (state, { payload }) => {
    const { storedURL, errorMsgKey } = payload;
    // preserves tenantId so user can be redirected
    // back to login page without typing tenantId again
    return {
      ...initialState,
      hasPermission: undefined,
      storedURL: storedURL || state.storedURL,
      preloginMessageKey: errorMsgKey,
    };
  })
  .handleAction(clearStoredUrlAction, state => ({
    ...state,
    storedURL: undefined,
  }))
  .handleAction(
    clearSessionIdAction,
    (state, { payload: sessionExpiredError }) => {
      return {
        ...state,
        sessionExpiredError,
        authenticating: false,
        sessionId: undefined,
      };
    },
  )
  .handleAction(clearErrorsAction, (state, { payload: isUsernameEntered }) => ({
    ...state,
    isUsernameEntered,
    authenticationError: undefined,
    sessionExpiredError: undefined,
    preloginMessageKey: undefined,
    permissionError: undefined,
    factorOptionsError: undefined,
    sessionIdError: undefined,
  }));
