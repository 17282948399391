// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { Menu, Layout, type MenuProps } from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MenuHome from '../../assets/svg/menu-home.svg?react';
import MenuSecurity from '../../assets/svg/menu-security.svg?react';
import MenuUsers from '../../assets/svg/menu-users.svg?react';
import MenuDirectories from '../../assets/svg/menu-directories.svg?react';
import MenuEndpoints from '../../assets/svg/menu-endpoints.svg?react';
import MenuApplications from '../../assets/svg/menu-applications.svg?react';
import MenuSettings from '../../assets/svg/menu-settings.svg?react';
import getConfig from '../../appConfigUtils';

import classes from './SiderMenu.module.less';

const { Sider } = Layout;

interface SiderMenuProps {
  siderProps?: React.ComponentProps<typeof Sider>;
  menuProps?: React.ComponentProps<typeof Menu>;
}

export enum MenuItemKeys {
  Home = 'home',
  Security = 'security',
  Users = 'users',
  Directories = 'directories',
  Endpoints = 'endpoints',
  Apps = 'apps',
  Settings = 'settings',
  Logout = 'logout',
}

const SiderMenu: React.FC<SiderMenuProps> = function SiderMenu({
  siderProps = {},
  menuProps = {},
}) {
  const {
    DIRECTORIES_ENABLED,
    APPLICATIONS_ENABLED,
    ENDPOINTS_ENABLED,
    EPCS_ENABLED,
  } = getConfig();
  const { t } = useTranslation();
  const { collapsedWidth = 150, ...restSiderProps } = siderProps;
  const { mode, className: menuClassName, ...restMenuProps } = menuProps;

  const items: MenuProps['items'] = [
    {
      type: 'group',
      className: classes.topGroup,
      children: [
        {
          className: classes.menuItem,
          key: MenuItemKeys.Home,
          icon: <Icon component={MenuHome} />,
          label: (
            <span className="nav-label" data-testid="side-menu-button--home">
              {t('navigation.astra')}
            </span>
          ),
        },
        {
          className: classes.menuItem,
          key: MenuItemKeys.Security,
          icon: <Icon component={MenuSecurity} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--security"
            >
              {t('navigation.security')}
            </span>
          ),
        },
        {
          style: !DIRECTORIES_ENABLED ? { display: 'none' } : {},
          className: classes.menuItem,
          key: MenuItemKeys.Directories,
          icon: <Icon component={MenuDirectories} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--directories"
            >
              {t('navigation.directories')}
            </span>
          ),
        },
        {
          className: classes.menuItem,
          key: MenuItemKeys.Users,
          icon: <Icon component={MenuUsers} />,
          label: (
            <span className="nav-label" data-testid="side-menu-button--users">
              {t('navigation.users')}
            </span>
          ),
        },
        {
          style: !ENDPOINTS_ENABLED ? { display: 'none' } : {},
          className: classes.menuItem,
          key: MenuItemKeys.Endpoints,
          icon: <Icon component={MenuEndpoints} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--endpoints"
            >
              {t('navigation.endpoints')}
            </span>
          ),
        },
        {
          style:
            !EPCS_ENABLED || !APPLICATIONS_ENABLED ? { display: 'none' } : {},
          className: classes.menuItem,
          key: MenuItemKeys.Apps,
          icon: <Icon component={MenuApplications} />,
          label: (
            <span className="nav-label" data-testid="side-menu-button--apps">
              {t('navigation.apps')}
            </span>
          ),
        },
      ],
    },
    {
      type: 'group',
      className: classes.midGroup,
      children: [
        {
          className: classes.menuItem,
          key: MenuItemKeys.Settings,
          icon: <Icon component={MenuSettings} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--settings"
            >
              {t('navigation.settings')}
            </span>
          ),
        },
      ],
    },
    {
      type: 'group',
      className: classes.bottomGroup,
      children: [
        {
          className: classes.menuItem,
          key: MenuItemKeys.Logout,
          label: (
            <span className="nav-label" data-testid="side-menu-button--logout">
              {t('navigation.logout')}
            </span>
          ),
        },
      ],
    },
  ];

  return (
    <Sider
      theme="dark"
      collapsed
      collapsedWidth={collapsedWidth}
      collapsible={false}
      data-testid="side-menu"
      {...restSiderProps}
    >
      <Menu
        theme="dark"
        mode="inline"
        className={clsx(classes.menu, menuClassName)}
        {...restMenuProps}
        items={items}
      />
    </Sider>
  );
};

export default SiderMenu;
