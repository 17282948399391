// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isApiErrorLike } from '@imprivata-cloud/common/types';
import type { ApiError2, Directory } from '../../../api/types';
import { DirectorySyncStatusCode } from '../../../api/types';
import { type RootState } from '../../../store/rootReducer';
import { findDirectoriesActions, syncActions } from './actions';
import {
  getDirectorySyncProgress,
  findFailedSyncDirectories,
  syncDirectories,
} from '../../../api/directoriesService';
import { initialSyncDetailsState } from './reducers';
import { getSyncState } from './selectors';
import { getErrorMessageCode } from '../../../i18n/utils';
import { ContextNames } from '../../../i18n';

export const getDirectoriesState = (state: RootState): Directory[] => {
  return state.directories.findDirectories.directories;
};

export function useFindDirectories(): Directory[] {
  const dispatch = useDispatch();
  const directories = useSelector(getDirectoriesState);

  useEffect(() => {
    dispatch(findDirectoriesActions.request());

    return () => {
      dispatch(findDirectoriesActions.cancel());
    };
  }, [dispatch]);

  return directories;
}

export const syncDirectoriesWithProgress = async (directoryId: string) => {
  if (!directoryId) {
    const msg = 'Cannot sync directories without directory ID';
    console.error(msg);
    throw new Error(msg);
  }

  return Promise.all([
    getDirectorySyncProgress({ directoryId }),
    findFailedSyncDirectories({ directoryId }),
  ]);
};

// Track whether app has loaded for first getProgress call
let appLoaded = false;

export const useSyncDirectories = ({
  directoryId,
}: {
  directoryId?: string;
}) => {
  const dispatch = useDispatch();

  const state = useSelector(getSyncState);

  const busy = state.progress?.syncStatus === DirectorySyncStatusCode.RUNNING;

  const sync = useCallback(async () => {
    // This shouldn't be possible (button disabled) but good to have
    if (busy || state.loading) {
      return;
    }

    // Begin sync loading
    dispatch(syncActions.sync());

    // Then sync directories
    try {
      await syncDirectories({ directoryIds: [] });
    } catch (error) {
      const err =
        'error' in (error as Record<string, unknown>)
          ? (error as { error: ApiError2 }).error
          : (error as ApiError2);

      if (isApiErrorLike(err)) {
        dispatch(
          syncActions.error(
            getErrorMessageCode(ContextNames.DIRECTORIES, err.code),
          ),
        );
      }

      return err;
    }

    return;
  }, [busy, dispatch, state.loading]);

  // Error handling function
  const handleError = useCallback(
    (error: ApiError2) => {
      console.error('Error fetching sync progress', error);
      dispatch(syncActions.progress(initialSyncDetailsState.progress));
      dispatch(syncActions.failures(initialSyncDetailsState.failures));
    },
    [dispatch],
  );

  // Function to fetch sync progress and any failures
  const getSyncProgress = useCallback(
    (id: string) => {
      syncDirectoriesWithProgress(id)
        ?.then(([progress, failedSyncDirectories]) => {
          dispatch(syncActions.progress(progress));
          dispatch(syncActions.failures(failedSyncDirectories));
          if (
            progress.info?.directoryId &&
            progress.syncStatus === DirectorySyncStatusCode.RUNNING
          ) {
            const { directoryId: _id } = progress.info;
            getSyncProgress(_id);
          }
        })
        .catch(handleError);
    },
    [dispatch, handleError],
  );

  // Main function to kick off directory sync.
  const doDirectorySync = useCallback(async () => {
    if (!directoryId) {
      console.error('Cannot sync directories without directory ID');
      return;
    }

    // Kick off sync
    const hasError = await sync();

    // When sync finished, if no error present, get progress
    if (!hasError) {
      getSyncProgress(directoryId);
    }
  }, [directoryId, sync, getSyncProgress]);

  // On load check to see if any sync is in progress
  useEffect(() => {
    if (directoryId && !appLoaded && !busy) {
      appLoaded = true;
      getSyncProgress(directoryId);
    }
  }, [directoryId, busy, getSyncProgress]);

  return {
    busy,
    state,
    doDirectorySync,
    getSyncProgress,
    isSyncSuccess:
      state?.progress?.syncStatus === DirectorySyncStatusCode.SUCCEEDED &&
      !state.loading,
  };
};
