// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { checkSetupModeAction } from './actions';
import { getInitSetupState } from './selectors';
import { DIRECTORIES_ROUTE } from '../../../routers/route-names';

export const useCheckForSetupMode = (): { checkDone: boolean } => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { checkDone, directoryId, redirectToSetup } =
    useSelector(getInitSetupState);

  const isGroupsPageOpened =
    history.location.pathname.startsWith(DIRECTORIES_ROUTE);

  useEffect(() => {
    if (!checkDone) {
      dispatch(checkSetupModeAction());
    }
  }, [dispatch, checkDone]);

  useEffect(() => {
    if (redirectToSetup && !isGroupsPageOpened) {
      history.push(
        `${DIRECTORIES_ROUTE}/${directoryId}/groups` + location.search,
      );
    }
  }, [
    history,
    redirectToSetup,
    location.search,
    directoryId,
    isGroupsPageOpened,
  ]);

  return { checkDone };
};
