import { appTermination } from '../endpoint-names';
import {
  type AppTerminationGetResponse,
  type AppTerminationUpdateRequest,
} from './types';
import { client } from '../client';

export async function getAppTerminationConfig(): Promise<AppTerminationGetResponse> {
  const data = await client.post<AppTerminationGetResponse>(
    appTermination.APP_TERMINATION_GET,
  );
  return data?.data;
}

export async function updateAppTerminationConfig(
  payload: AppTerminationUpdateRequest,
): Promise<void> {
  await client.post(appTermination.APP_TERMINATION_UPDATE, payload);
}
