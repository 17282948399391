// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import classes from './BarGuage.module.less';

export type BarGuageProps = {
  label: string;
  percentEnrollment: number;
} & React.HTMLAttributes<HTMLDivElement>;

const BarGuage: React.FC<BarGuageProps> = ({ label, percentEnrollment }) => {
  return (
    <div className={classes.guageWrapper}>
      <div
        className={classes.guageColumn}
        style={{
          width: `${percentEnrollment}%`,
        }}
      ></div>
      {label && <div className={classes.guageMetric}>{label}</div>}
    </div>
  );
};

export default BarGuage;
