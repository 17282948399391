import React from 'react';
import type { FC } from 'react';
import classes from './PortalHome.module.less';
import ImprivataLogo from '../../assets/Imprivata_Logo.svg?react';

const PortalHome: FC = () => {
  return (
    <div data-testid="portal-home">
      <div className={classes.header}>
        <div className={classes.logo}>
          <ImprivataLogo fill={'#2C3F57'} />
        </div>
        <div>
          <span className={classes.logoHeader}>Access Management</span>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.productsBox}>
          <div className={classes.products}>
            <span className={classes.productsHeader}>Find out more</span>
          </div>
          <div className={classes.products}>
            <span className={classes.productText}>
              Mobile Device Management
            </span>
          </div>
        </div>
        <div>
          <span className={classes.productsHeader}>
            Welcome, test@imprivata.com
          </span>
          <div className={classes.products}>
            <span className={classes.productText}>
              Data Identity Intelligence
            </span>
          </div>
          <div className={classes.products}>
            <span className={classes.productText}>
              Enterprise Access Management Cloud
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortalHome;
