// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './TestTenantLabel.module.less';

export default function TestTenantLabel() {
  const { t } = useTranslation();
  return (
    <span className={classes.testTenantLabel} data-testid="testTenantLabel">
      {t('test-tenant-label')}
    </span>
  );
}
