// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { type InstallerDataPayload } from '../endpoints/store/types';
import classes from './ModalShared.module.less';
import { copyToClipboard } from '../utils';

interface Props {
  visible: boolean;
  onCancel: () => void;
  data: InstallerDataPayload;
}

const InstallerAgentModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('endpoints.installer-title')}
      className={classes.modal}
      open={props.visible}
      data-testid="installer-modal"
      onCancel={props.onCancel}
      footer={null}
    >
      <>
        <p data-testid="installer-header-id">
          {t('endpoints.installer-header')}
        </p>
        <p data-testid="download-installer-id">
          {t('endpoints.download-installer')}{' '}
          <span className={classes.iconArea}>
            {/* The react-router-dom Link component does not work correctly
            for this download behavior, so using html anchor: */}
            <a
              download={props.data.endpointInstallerUrl}
              data-testid="download-installer-link-id"
              href={props.data.endpointInstallerUrl}
            >
              <DownloadOutlined className={classes.icon} />
            </a>
          </span>
        </p>
        <p data-testid="installation-key-id">
          {t('endpoints.installation-key')}{' '}
          <span className={classes.iconArea}>
            <Link
              data-testid="installation-key-link-id"
              to="#"
              onClick={e => {
                copyToClipboard(e, props.data.installInfo.encodedInstallInfo);
              }}
            >
              <CopyOutlined className={classes.icon} />
            </Link>
          </span>
        </p>
        <p data-testid="command-line-syntax-id">
          {t('endpoints.command-line-syntax')}{' '}
          <span className={classes.iconArea}>
            <Link
              data-testid="command-line-syntax-link-id"
              to="#"
              onClick={e => {
                copyToClipboard(e, props.data.commandLine);
              }}
            >
              <CopyOutlined className={classes.icon} />
            </Link>
          </span>
        </p>
      </>
    </Modal>
  );
};

export default InstallerAgentModal;
