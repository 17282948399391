// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect, useState } from 'react';

type SupportedValueType = number | string | boolean;
type StateHook = <T extends SupportedValueType>(
  key: string,
  startValue: T,
) => [T, (value: T) => void];
type StateHookWithStorage = <T extends SupportedValueType>(
  key: string,
  startValues: T,
  storage: Storage,
) => [T, (values: T) => void];

const usePersistantStateForStorage: StateHookWithStorage = (
  key,
  startValue,
  storage,
) => {
  const [value, setValue] = useState(startValue);

  useEffect(() => {
    const retrievedValue = storage.getItem(key);
    if (retrievedValue !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setValue(JSON.parse(retrievedValue));
    }
    // eslint-disable-next-line
  }, []);

  const storeValue = useCallback(
    (newValue: typeof value) => {
      storage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    [key, storage],
  );

  return [value, storeValue];
};

export const usePersistantSessionState: StateHook = (key, startValue) =>
  usePersistantStateForStorage(key, startValue, sessionStorage);
