// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import CancelButton from '../../../components/page-layout/action-bar/CancelButton';
import HelpButton from '../../../components/page-layout/action-bar/HelpButton';
import SaveButton from '../../../components/page-layout/action-bar/SaveButton';
import PageSubHeader from '../../../components/page-layout/PageSubHeader';

const Notifications: React.FC = () => (
  <>
    <PageSubHeader
      title={
        <>
          <SaveButton />
          <CancelButton />
        </>
      }
      extra={
        <>
          <HelpButton />
        </>
      }
    />
    <div>Notifications</div>
  </>
);

export default Notifications;
