// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React, {
  type FC,
  useState,
  type ChangeEvent,
  useRef,
  useEffect,
} from 'react';
import { Input, type InputRef, Row, Col } from 'antd';
import { ImprChip } from '@imprivata-cloud/components';
import clsx from 'clsx';
import { uuid } from 'short-uuid';
import classes from './TagInput.module.less';

interface TagInputProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: string[];
  onChange: (value: string[]) => void;
  containerTestid?: string;
  inputTestid?: string;
  disabled?: boolean;
  label?: string;
}

const TagInput: FC<TagInputProps> = props => {
  const {
    value: tags,
    onChange,
    placeholder,
    containerTestid,
    inputTestid,
    disabled,
    label,
    ...rest
  } = props;
  const [content, setContent] = useState<string>();
  const inputRef = useRef<InputRef>(null);
  const inputKeys = ['Enter', ','];

  const handleDelete = (index: number) => {
    if (tags.length === 0) {
      return;
    }
    const updatedValue = tags.filter((_, i) => i !== index);
    onChange(updatedValue);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  const handleBlur = () => {
    if (content) {
      const newValue = content.trim();
      // to not to allow empty tags
      if (newValue !== '' && newValue !== ',') {
        // to remove the comma from the tag
        onChange([...tags, newValue]);
        setContent('');
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputKeys.includes(e.key) && content) {
      const newValue = content.trim();
      // to not to allow empty tags
      if (newValue !== '' && newValue !== ',') {
        e.preventDefault();
        // to remove the comma from the tag
        onChange([...tags, newValue]);
        setContent('');
      }
    }
    // Remove value by `backspace` key
    if (e.key === 'Backspace' && !content) {
      handleDelete(tags.length - 1);
    }
  };

  useEffect(() => {
    !disabled && inputRef.current?.focus();
  }, [disabled]);

  return (
    <div
      {...rest}
      data-testid={containerTestid}
      className={classes.tagInput}
      onClick={() => inputRef.current?.focus()}
    >
      <Row>
        <Col>
          <span className={classes.tagInputLabel}>{label}</span>
          <div
            className={clsx(
              classes.tagInputContainer,
              'ant-input',
              disabled && `ant-input-disabled ${classes.tagInputDisabled}`,
            )}
          >
            {tags.map((item, index) => (
              <ImprChip
                className={clsx(disabled && classes.disabledChip)}
                label={item}
                closable={!disabled}
                key={`tag-${uuid()}`}
                onClose={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDelete(index);
                }}
                color={'processing'}
                data-testid="tag-input-chip"
              />
            ))}
            <Input
              maxLength={64}
              ref={inputRef}
              bordered={false}
              placeholder={!tags.length ? placeholder : ''}
              className={clsx(classes.input, tags.length && classes.nonEmpty)}
              value={content}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              data-testid={inputTestid}
              disabled={disabled}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TagInput;
