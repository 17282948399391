export const copyToClipboard = async (text: string) => {
  return navigator.clipboard.writeText(text);
};

export const downloadFile = (
  filename: string,
  fileContent: string,
  contentType = 'application/octet-stream',
) => {
  const a = document.createElement('a');
  const blob = new Blob([fileContent], { type: contentType });
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.click();
};
