// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { client } from './client';
import { type Headers } from './constants';

export function setHeader(header: Headers, value: string): void {
  client.defaults.headers.common[header] = value;
}

export function deleteHeader(header: Headers): void {
  delete client.defaults.headers.common[header];
}
