// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAsyncAction } from 'typesafe-actions';
import { type ApiError2 } from '../../../../api/types';

export const regenerateAgentKey = createAsyncAction(
  'endpoints/REGENERATE_AGENT_KEY_REQUEST',
  'endpoints/REGENERATE_AGENT_KEY_SUCCESS',
  'endpoints/REGENERATE_AGENT_KEY_FAILURE',
  'endpoints/REGENERATE_AGENT_KEY_CANCEL',
)<void, void, ApiError2, string | void>();
