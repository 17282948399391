// Copyright 2024, Imprivata, Inc.  All rights reserved.

export const CERTIFICATE_HIDDEN_FIELD_NAME = '_idp_certificate_filename';

export type OneSignIdpVisibleFormValues = Omit<
  OneSignIdpFormValues,
  typeof CERTIFICATE_HIDDEN_FIELD_NAME
>;

export interface OneSignIdpFormValues extends IdpFormValuesBase {
  active_url: string;
}

export interface AdfsIdpFormValues extends IdpFormValuesBase {
  active_url_1_2: string;
  active_url_1_3: string;
}

export interface IdpFormValuesBase {
  issuer_uri: string;
  logoff_url: string;
  passive_redirect_url: string;
  metadata_exchange_url: string;
  idp_certificate: string;
  [CERTIFICATE_HIDDEN_FIELD_NAME]: string;
}
