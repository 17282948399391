// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { type Location } from 'history';
import classes from './SaveDiscardModal.module.less';
import QuestionSvg from '../../../assets/svg/question.svg?url';
import { useKeyCallBack } from '../../hooks';

interface Props {
  title: string;
  content: string;
  open: boolean;
  cancelText: string;
  okText: string;
  closable?: boolean;
  onDiscard: () => void;
  onSave: () => void;
  onClose?: () => void;
}

const SaveDiscardModal = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(props.open);

  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [focusedButton, setFocusedButton] = useState<'left' | 'right'>('left');

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleBlockedRoute = (nextLocation: Location) => {
    if (
      !confirmedNavigation &&
      props.open &&
      history.location.pathname !== nextLocation.pathname
    ) {
      setIsModalOpen(true);
      setLastLocation(nextLocation);
      return false;
    }

    return true;
  };

  useKeyCallBack(['ArrowRight', 'ArrowLeft', 'Tab'], (ev: KeyboardEvent) => {
    if (ev.key === 'ArrowRight' || ev.key === 'Tab') {
      setFocusedButton('right');
    } else if (ev.key === 'ArrowLeft') {
      setFocusedButton('left');
    }
  });

  const handleSaveNavigationClick = () => {
    closeModal();
    props.onSave();
    setTimeout(() => {
      setConfirmedNavigation(true);
    }, 300);
  };

  const discardButtonClicked = () => {
    props.onDiscard();
    closeModal();
    setConfirmedNavigation(true);
  };

  const handleDiscardNavigationClick = (e: React.MouseEvent<HTMLElement>) => {
    // don't close modal if click outside dialog
    if (e.currentTarget.className === 'ant-modal-wrap') {
      return;
    }
    // if clicked on the modal X icon just close the modal
    if (e.currentTarget.className === 'ant-modal-close') {
      // to prevent to menu active item being changed
      history.push(location.pathname + location.search);
      closeModal();
    } else {
      // discard button was clicked
      discardButtonClicked();
    }
  };

  useKeyCallBack(['Escape'], () => {
    if (isModalOpen) {
      discardButtonClicked();
    }
  });

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname + lastLocation.search);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: { returnValue: string }) => {
      if (props.open && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = props.content;
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => {
      window.removeEventListener('beforeunload', unload);
    };
  }, [props.open, props.content, shouldUnload]);

  const isOpen = (props.open && isModalOpen === undefined) || isModalOpen;

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <Modal
        className={classes.modal}
        cancelText={props.cancelText}
        okText={props.okText}
        open={isOpen}
        data-testid="save-discard-modal"
        onCancel={handleDiscardNavigationClick}
        onOk={handleSaveNavigationClick}
        footer={null}
        closable={props.closable}
      >
        <div className={classes.modalContentWraper}>
          <div>
            <img src={QuestionSvg} alt="Question Mark" />
            <div className={classes.modalTitle}>{props.title}</div>
            <div className={classes.modalContent}>{props.content}</div>
          </div>
          <div className={classes.modalFooterButtons}>
            <Button
              data-testid="save-discard-modal-cancel-button"
              variant={ButtonVariant.PRIMARY}
              onClick={handleDiscardNavigationClick}
              label={props.cancelText}
              autoFocus={focusedButton === 'left'}
              aria-label="Discard"
            />
            <Button
              data-testid="save-discard-modal-ok-button"
              variant={ButtonVariant.SECONDARY}
              onClick={handleSaveNavigationClick}
              label={props.okText}
              autoFocus={focusedButton === 'right'}
              aria-label="Save"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SaveDiscardModal;
