import {
  type InputHTMLAttributes,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import { getAadConfigurationData } from '../../../../api/identityBrokerService';
import { type AadConfigurationDataGetResponse } from '../../../../shared/types/api/identity-broker';
import { copyToClipboard, downloadFile } from './utils';
import classes from '../IdentityBroker.module.less';

export const AstraIdpInfo = () => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<AadConfigurationDataGetResponse>();

  useEffect(() => {
    void getAadConfigurationData().then(res => {
      setInfo(res);
    });
  }, []);

  return (
    <div
      data-testid="astra-idp-configuration-section"
      className={classes.astraIdpContainer}
    >
      <div className="subtitle" data-testid="section-header">
        {t('identity-broker.astra-idp.header')}
      </div>

      <CopyField
        data-testid="issuer-uri"
        label={t('identity-broker.idp-form-common.issuer-uri')}
        value={info?.issuerUri || ''}
      />
      <CopyField
        data-testid="passive-logon-uri"
        label={t('identity-broker.idp-form-common.passive-logon-uri')}
        value={info?.passiveRedirectUrl || ''}
      />
      <CopyField
        data-testid="active-url"
        label={t('identity-broker.idp-form-common.active-logon-uri')}
        value={info?.activeUrl || ''}
      />
      <CopyField
        data-testid="metadata-exchange-url"
        label={t('identity-broker.idp-form-common.metadata-exchange-url')}
        value={info?.metadataExchangeUrl || ''}
      />
      <CopyField
        data-testid="logoff-url"
        label={t('identity-broker.idp-form-common.log-off-url')}
        value={info?.logoffUrl || ''}
      />

      <DownloadField
        data-testid="download-certificate"
        label={t('identity-broker.astra-idp.download-cert-label')}
        filename="Imprivata Astra Signing Certificate.pem"
        fileContent={info?.idpCertificate || ''}
      />
    </div>
  );
};

const DownloadField = (
  props: {
    label: string;
    filename: string;
    fileContent: string;
  } & InputHTMLAttributes<HTMLDivElement>,
) => {
  const { label, fileContent, filename, ...restProps } = props;

  return (
    <div className={classes.copyField} {...restProps}>
      <div>
        <div className="primary-body" data-testid="label">
          {label}
        </div>
      </div>
      <div
        data-testid="download-button"
        onClick={() => {
          downloadFile(filename, fileContent);
        }}
      >
        <DownloadOutlined className="icon" />
      </div>
    </div>
  );
};

export const CopyField = (
  props: {
    label: string;
    value: string;
  } & InputHTMLAttributes<HTMLDivElement>,
) => {
  const { label, value, ...restProps } = props;

  return (
    <div className={classes.copyField} {...restProps}>
      <div>
        <div className="primary-body" data-testid="label">
          {label}
        </div>
        <div className="value-text" data-testid="value">
          <URL>
            <ColorizedUrl url={value} />
          </URL>
        </div>
      </div>
      <div
        data-testid="copy-button"
        onClick={async () => copyToClipboard(value)}
      >
        <CopyOutlined className="icon" />
      </div>
    </div>
  );
};

export const URL = ({ children }: PropsWithChildren<unknown>) => {
  return <span style={{ fontFamily: 'Roboto Mono' }}>{children}</span>;
};

export const ColorizedUrl = (props: { url: string }) => {
  return (
    <>
      {
        // /(?=<)|(?<=>)/ splits 'tenatId=<AstraTenantId>&workflowId=<WorkflowId>' into ['tenatId=', '<AstraTenantId>', '&workflowId=', '<WorkflowId>']
        props.url.split(/(?=<)|(?<=>)/).map(el => {
          if (el.startsWith('<') && el.endsWith('>')) {
            const parameterName = el.substring(1, el.length - 1);
            return (
              <span className={classes.colorizedParameter} key={`${el}`}>
                {parameterName}
              </span>
            );
          } else {
            return el;
          }
        })
      }
    </>
  );
};
