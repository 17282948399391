// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React, { type PropsWithChildren } from 'react';
import { PageHeader, Layout } from 'antd';
import classes from './SettingsPageLayout.module.less';

const { Content } = Layout;

export const SettingsPageLayout = ({
  children,
  title,
}: PropsWithChildren<{ title?: React.ReactNode }>) => {
  return (
    <Content className={classes.pageContainer}>
      {title && (
        <div className={classes.subheaderContainer}>
          <PageHeader className={classes.pageSubHeader} title={title} />
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </Content>
  );
};
