// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/page-layout/PageLayout';
import NavigationMenu from '../../components/navigation-menu/NavigationMenu';
import SettingsContextProvider from './SettingsContextProvider';
import SettingsRoutes from './SettingsRoutes';

import classes from './Settings.module.less';
import SetTitle from '../../utils/DynamicTitleHelper';

const { Sider } = Layout;

const Settings: React.FC = () => {
  const { t } = useTranslation();
  SetTitle(t('navigation.settings'));
  return (
    <PageLayout
      title={t('navigation.settings')}
      className={classes.settings}
      contentClassName={classes.content}
    >
      <SettingsContextProvider>
        <Layout className={classes.layout}>
          <Sider className={classes.sider}>
            <NavigationMenu />
          </Sider>
          <SettingsRoutes />
        </Layout>
      </SettingsContextProvider>
    </PageLayout>
  );
};

export default Settings;
