// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import AntCard, { type CardProps as AntCardProps } from 'antd/lib/card/Card';
import clsx from 'clsx';
import classes from './Card.module.less';

export type CardProps = {
  header?: React.ReactNode;
  children?: React.ReactNode;
} & AntCardProps;

const Card: React.FC<CardProps> = ({ header, children, ...rest }) => {
  return (
    <AntCard
      bordered={false}
      {...rest}
      className={clsx('elevation-astra-1', classes.card, rest.className || '')}
    >
      {header && <div className="subtitle">{header}</div>}
      {children}
    </AntCard>
  );
};

export default Card;
