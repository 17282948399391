// Dataset specific values
export const METRICS_IMPR_ID = 'impr-id';
export const METRICS_PROX = 'prox';

export const TIMESTAMP = 'Timestamp';
export const USER = 'User';
export const USER_UPN = 'user-upn';
export const USER_DISPLAY_NAME = 'user-display-name';
export const APP_USER_NAME = 'app-username';
export const OCCURED_AT = 'occurred-at';
export const ACTIVITY = 'Activity';
export const ACTION_TYPE = 'action-type';
export const RESULT = 'Result';
export const ENDPOINT = 'Endpoint';
export const AUTHENTICATOR = 'Authenticator';
export const AUTHENTICATOR_FACTOR_TYPE = 'authenticator-factor-type';
export const AUTHENTICATOR_DISPLAY_NAME = 'authenticator-display-name';
export const AAD_GROUP_NAMES = 'Group';
