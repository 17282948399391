// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAsyncAction } from 'typesafe-actions';
import { type ApiError2 } from '../../../api/types';
import {
  type ApiApplication,
  type AppConfigGetResponse,
  type AppConfigUpdateRequest,
  type AppIntegration,
} from './types';

export const getAppIntegration = createAsyncAction(
  'apps/LIST_REQUEST',
  'apps/LIST_SUCCESS',
  'apps/LIST_FAILURE',
  'apps/LIST_CANCEL',
)<void, AppIntegration[], ApiError2, string | void>();

export const getApiApplication = createAsyncAction(
  'apps/APPS_LIST_REQUEST',
  'apps/APPS_LIST_SUCCESS',
  'apps/APPS_LIST_FAILURE',
  'apps/APPS_LIST_CANCEL',
)<void, ApiApplication[], ApiError2, string | void>();

export const getAppConfig = createAsyncAction(
  'app-config/CONFIG_REQUEST',
  'app-config/CONFIG_SUCCESS',
  'app-config/CONFIG_FAILURE',
  'app-config/CONFIG_CANCEL',
)<void, AppConfigGetResponse, ApiError2, string | void>();

export const updateAppConfig = createAsyncAction(
  'app-config/CONFIG_UPDATE_REQUEST',
  'app-config/CONFIG_UPDATE_SUCCESS',
  'app-config/CONFIG_UPDATE_FAILURE',
  'app-config/CONFIG_UPDATE_CANCEL',
)<AppConfigUpdateRequest, void, ApiError2, string | void>();
