// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type StorageKeys as LoginKeys } from './containers/login/store/constants';

type StorageKey = LoginKeys;

export function saveToStorage(key: StorageKey, value: string): void {
  sessionStorage.setItem(key, value);
}

export function getFromStorage(key: StorageKey): string | null {
  return sessionStorage.getItem(key);
}

export function removeFromStorage(key: StorageKey): void {
  sessionStorage.removeItem(key);
}
