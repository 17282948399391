import { useMemo } from 'react';
import { Statistic, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import Card, { type CardProps } from '../../../components/card/Card';
import { type DashboardData } from '../types';
import * as utils from '../utils';
import classes from './shared.module.less';
import {
  type DashboardDataType,
  type EntitlementType,
  UniqueUserEntitlementType,
} from '../../../api/Dashboard/types';

const { Column } = Table;

const headerStyle = {
  fontSize: '16px',
  color: 'var(--gray-30)',
  paddingLeft: '16px',
  fontWeight: 900,
};

export interface UniqueUserProps {
  data?: DashboardData[DashboardDataType.UNIQUE_ACTIVE_USER_METRICS];
  onClick?: () => void;
  onClickPreviousMonth?: (key: EntitlementType) => void;
  onClickCurrentMonth?: (key: EntitlementType) => void;
}

export interface TableRow {
  key: string;
  type: EntitlementType;
  lastMonth: string | number;
  thisMonth: string | number;
}

/**
 * UniqueUsers
 *
 * @summary Widget showing the unique users for current and previous month
 *
 * @description NOTE: This is displaying (and requires) data in UTC FORMAT ONLY.
 *
 * @param {UniqueUserProps & CardProps}
 * @returns {JSX.Element}
 **/
const UniqueUsers: React.FC<UniqueUserProps & CardProps> = ({
  data,
  onClickPreviousMonth,
  onClickCurrentMonth,
  ...rest
}) => {
  // data for unique users
  const { t } = useTranslation();
  const uniqueTapnGoUserData = useMemo(() => {
    const { previous, current } = utils.normalizeUniqueUserData(
      data?.keys ?? [],
      data?.values?.filter((_e, i) =>
        data?.values?.[i].includes(UniqueUserEntitlementType.SSO),
      ) ?? [],
    );

    // Convert this information into UTC NOT LOCAL

    return { previous, current };
  }, [data]);

  const uniqueEpcsUserData = useMemo(() => {
    const { previous, current } = utils.normalizeUniqueUserData(
      data?.keys ?? [],
      data?.values?.filter((_e, i) =>
        data?.values?.[i].includes(UniqueUserEntitlementType.EPCS),
      ) ?? [],
    );

    // Convert this information into UTC NOT LOCAL

    return { previous, current };
  }, [data]);

  const dataSource: TableRow[] = [
    {
      key: t('dashboard.metrics-cards.unique-users.user-types.sso'),
      type: UniqueUserEntitlementType.SSO,
      lastMonth: utils.formatNumber(
        Number(uniqueTapnGoUserData?.previous?.uniqueUsers ?? 0),
      ),
      thisMonth: utils.formatNumber(
        Number(uniqueTapnGoUserData?.current?.uniqueUsers ?? 0),
      ),
    },
    {
      key: t('dashboard.metrics-cards.unique-users.user-types.epcs'),
      type: UniqueUserEntitlementType.EPCS,
      lastMonth: utils.formatNumber(
        Number(uniqueEpcsUserData?.previous?.uniqueUsers ?? 0),
      ),
      thisMonth: utils.formatNumber(
        Number(uniqueEpcsUserData?.current?.uniqueUsers ?? 0),
      ),
    },
  ];

  return (
    <Card {...rest}>
      <div
        className={classes.uniqueUsersContainer}
        data-testid="unique-users-container"
      >
        <Table
          dataSource={dataSource}
          size="small"
          className={classes.uniqueUsersTable}
          pagination={false}
        >
          <Column
            title=""
            dataIndex="key"
            key="key"
            render={(key: string) => (
              <span className={classes.uniqueUserType}>{key}</span>
            )}
          />
          <Column
            title={t(
              'dashboard.metrics-cards.unique-users.filter-types.lastmonth',
            )}
            onHeaderCell={() => ({
              style: headerStyle,
            })}
            dataIndex="lastMonth"
            key="lastMonth"
            render={(lastMonth: number, row: TableRow) => (
              <div
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                  onClickPreviousMonth && onClickPreviousMonth(row.type)
                }
                data-testid={`${row.type}-unique-users-previous`}
              >
                <Statistic
                  value={lastMonth ?? 0}
                  className={classes.previousUniqueUser}
                  groupSeparator=""
                />
              </div>
            )}
          />
          <Column
            title={t(
              'dashboard.metrics-cards.unique-users.filter-types.thismonth',
            )}
            dataIndex="thisMonth"
            key="thisMonth"
            onHeaderCell={() => ({
              style: headerStyle,
            })}
            render={(thisMonth: number, row: TableRow) => (
              <div
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                  onClickCurrentMonth && onClickCurrentMonth(row.type)
                }
                data-testid={`${row.type}-unique-users-current`}
              >
                <Statistic
                  value={thisMonth ?? 0}
                  className={classes.currentUniqueUser}
                  groupSeparator=""
                />
              </div>
            )}
          />
        </Table>
      </div>
      {/* <div
        className={classes.uniqueUsersContainer}
        data-testid="unique-users-container"
      >
        {uniqueUserData ? (
          <>
            {uniqueUserData.previous && (
              <div
                onClick={() => onClickPreviousMonth && onClickPreviousMonth()}
                data-testid="unique-users-previous"
              >
                <Statistic
                  title={format(
                    utils.currentDateUTC(1),
                    UNIQUE_USER_DATE_FORMAT,
                  )}
                  value={uniqueUserData.previous.uniqueUsers}
                  className={classes.previousUniqueUser}
                  groupSeparator=""
                />
              </div>
            )}
            {uniqueUserData.current && (
              <div
                onClick={() => onClickCurrentMonth && onClickCurrentMonth()}
                data-testid="unique-users-current"
              >
                <Statistic
                  value={uniqueUserData.current.uniqueUsers}
                  title={format(
                    utils.currentDateUTC(),
                    UNIQUE_USER_DATE_FORMAT,
                  )}
                  className={classes.currentUniqueUser}
                  groupSeparator=""
                />
              </div>
            )}
          </>
        ) : (
          <span>No data</span>
        )}
      </div> */}
    </Card>
  );
};

export default UniqueUsers;
