// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Select as AntdSelect } from 'antd';
import React from 'react';
import { Search, Select } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';
import classes from '../../components/list/Filters.module.less';
import { UsersFilterValueEnum } from './types';
import UsersTotals from './UsersTotals';

const { Option } = AntdSelect;

interface Props {
  value: UsersFilterValueEnum;
  disabled?: boolean;
  onSelect?: (value: UsersFilterValueEnum) => void;
  onSearch?: (value: string) => void;
}

const UsersFilters: React.FC<Props> = function UsersFilters(props: Props) {
  const { value, disabled, onSelect, onSearch } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Select
        value={value}
        disabled={disabled}
        onSelect={onSelect}
        data-testid="users-filter"
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
      >
        <Option
          data-testid="tenant-filter-option--all"
          value={UsersFilterValueEnum.ALL_BY_NAME}
        >
          {t('users.users-filters.all-users-by-name')}
        </Option>
        <Option
          data-testid="tenant-filter-option--administrators"
          value={UsersFilterValueEnum.ADMINISTRATORS_BY_NAME}
        >
          {t('users.users-filters.administrators-by-name')}
        </Option>
      </Select>

      <div className={classes.count}>
        <UsersTotals />
      </div>
      <div className={classes.search}>
        <Search
          disabled={disabled}
          placeholder="Search"
          onSearch={onSearch}
          data-testid="users-search"
        />
      </div>
    </div>
  );
};

export default UsersFilters;
