// Copyright 2024, Imprivata, Inc.  All rights reserved.

export enum ScriptType {
  PowerShell = 'PowerShell',
  VisualBasic = 'Visual Basic',
  Batch = 'Batch',
  Python = 'Python',
}

export type ScriptPattern = {
  scriptType: ScriptType;
  pattern: RegExp;
};

export const ScriptPatterns: ScriptPattern[] = [
  {
    scriptType: ScriptType.PowerShell,
    pattern:
      /\b(?:Add-|Clear-|Copy-|Export-|Get-|Import-|Move-|New-|Out-|Remove-|Rename-|Set-|Start-|Stop-|Test-|Write-|ForEach-|Where-|Select-|Sort-|Group-|Measure-|Convert-|ConvertFrom-|ConvertTo-|Format-|Join-|Split-|Invoke-|Register-|Unregister-|Trace-|Assert-|Compare-|NewObject|Out-Default|Write-Host|Write-Output|Write-Warning|Write-Verbose|Write-Error|If|Else|ElseIf|Switch|While|Do|For|Foreach|Function|Param|Begin|Process|End|Filter|SYNOPSIS|DESCRIPTION|PARAMETER|EXAMPLE|LINK|NOTES|Try|Catch|Finally|Throw)\b(?![^"]*"(?:[^"]*"[^"]*")*[^"]*$)/g,
  },
  {
    scriptType: ScriptType.VisualBasic,
    pattern:
      /\b(?:Sub|Function|End|End\s+\/Sub|End\s+Function|If|Then|Else|ElseIf|End\s+If|For|To|Step|Next|Do|Loop|While|Until|Select\s+Case|Case|End\s+Select|With|End\s+With|Try|Catch|Finally|Throw|End\s+Try|Dim|Const|As|Integer|String|Boolean|Object|Variant|Long|Short|Byte|Double|Decimal|Single|Date|Array|Wend|Exit|Exit\s+Sub|Exit\s+Function|Error|Resume)\b(?![^"]*"(?:[^"]*"[^"]*")*[^"]*$)/g,
  },
  {
    scriptType: ScriptType.Batch,
    pattern:
      /\b(?:@?echo|set|if|else|for|goto|call|exit|pause|rem|REM|exist|cd|dir|copy|move|del|md|rd|ren|type|find|findstr|xcopy|attrib|cls|color|date|time|tasklist|taskkill|title|echo\.|pause\.|goto\.|call\.|exit\.)\b(?![^"]*"(?:[^"]*"[^"]*")*[^"]*$)/g,
  },
  {
    scriptType: ScriptType.Python,
    pattern:
      /\b(?:import|from|def|class|if|elif|else|for|while|try|except|finally|with|return|yield|print|assert|pass|break|continue|global|nonlocal|lambda|async|await|as|True|False|None|and|or|not|in|is|del)\b(?![^"]*"(?:[^"]*"[^"]*")*[^"]*$)/g,
  },
];
