import { credentials } from '../endpoint-names';
import {
  type CredentialsFindByUserIdResponse,
  type CredentialsFindByUserIdRequest,
  type UserAppCredentialDeleteRequest,
  type CredentialsDeleteResponse,
} from './types';
import { client } from '../client';

export async function findCredentialsByUserId(
  payload: CredentialsFindByUserIdRequest,
): Promise<CredentialsFindByUserIdResponse> {
  const data = await client.post<CredentialsFindByUserIdResponse>(
    credentials.FIND_CREDENTIALS_BY_USER_ID,
    payload,
  );
  return data?.data;
}

export async function deleteUserAppCredential(
  payload: UserAppCredentialDeleteRequest,
): Promise<CredentialsDeleteResponse> {
  const data = await client.post<CredentialsDeleteResponse>(
    credentials.DELETE_CREDENTIAL_V2,
    payload,
  );
  return data?.data;
}
