// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type RootState } from '../../../../store/rootReducer';
import { FETCH_ORG_PREFERENCES, PENDING_ORG_PREFERENCES } from './constants';
import { type OrgPreferences } from './reducers';

export const isLoadingSelector = ({ orgPreferences }: RootState): boolean =>
  orgPreferences?.operations === PENDING_ORG_PREFERENCES ||
  orgPreferences?.operations === FETCH_ORG_PREFERENCES
    ? true
    : false;

export const dataSelector = ({ orgPreferences }: RootState): OrgPreferences =>
  orgPreferences.data;
