// Copyright 2023, Imprivata, Inc.  All rights reserved.

import React, { type FC, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteApplicationProfile } from '../../../api/Applications/appsService';
import { getApiApplication } from '../store/actions';
import SaveDiscardModal from '../../modals/save-discard-modal/SaveDiscardModal';

export interface DeleteApplicationButtonProps {
  appId: string;
  appName: string;
  className?: string;
}

export const DeleteApplicationButton: FC<DeleteApplicationButtonProps> = ({
  appId,
  appName,
  className,
}: DeleteApplicationButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const proceedToDeletion = () => {
    setModalOpen(true);
  };

  const cancelDeletion = () => {
    setModalOpen(false);
  };

  const deleteProfiledApplication = async (appId: string) => {
    try {
      await deleteApplicationProfile(appId);
      dispatch(getApiApplication.request());
      setModalOpen(false);
    } catch (error) {
      console.error('Error deleting application:', error);
      throw error;
    }
  };

  return (
    <>
      <Button
        className={className}
        label={t('actions.remove')}
        variant={ButtonVariant.TEXT}
        size={ButtonSize.MAJOR}
        data-testid="delete-profiled-application-button"
        onClick={() => {
          proceedToDeletion();
        }}
      />
      {modalOpen && (
        <SaveDiscardModal
          title={t('apps.profile.confirm-remove.title')}
          cancelText={t('actions.dont-remove')}
          okText={t('actions.remove')}
          content={t('apps.profile.confirm-remove.content', {
            applicationName: appName,
          })}
          open={modalOpen}
          onSave={async () => deleteProfiledApplication(appId)}
          onDiscard={() => {
            cancelDeletion();
          }}
          closable={false}
        />
      )}
    </>
  );
};

export default DeleteApplicationButton;
