/**
 * downloadFile
 *
 * @summary Simple helper to create the dom elements needed to download a file
 *
 * @param data - The data already in the format for download
 * @param filename - The filename to save the data as
 */
export const downloadFile = (data: string, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
