/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { ConfigProvider, List, Spin } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import { type Subscription } from 'rxjs';
import { useEndpointsList } from './store/hooks';
import { getEndpointsListNextPortion } from './store/actions';
import { createEndpointsViewportObserver } from './utils';
import NoEndpointsIcon from '../../assets/svg/empty-state.svg?react';
import ListItem from '../../components/list/ListItem';

const EndpointsList: React.FC = function EndpointsList() {
  const { entities, loading, loadingNextPortion } = useEndpointsList();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const store = useStore();
  const ref = useRef<HTMLDivElement>(null);

  const endpointsIntersectionHandler = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const intersectionEvent = entries[0];
      if (intersectionEvent.isIntersecting) {
        const hasMore = store.getState().endpoints.endpoints.nextPortionToken;
        const isLoading =
          store.getState().endpoints.endpoints.loadingNextPortion;

        if (hasMore && !isLoading) {
          dispatch(getEndpointsListNextPortion.request());
        }
      }
    },
    [store, dispatch],
  );

  useEffect(() => {
    let subscription: Subscription | null = null;
    if (ref.current) {
      subscription = createEndpointsViewportObserver(ref.current).subscribe(
        endpointsIntersectionHandler,
      );
    }

    return () => subscription?.unsubscribe();
  }, [ref, endpointsIntersectionHandler]);

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <List
        dataSource={entities}
        loading={loading}
        renderItem={(_, index) => (
          <ListItem
            className="endpoint-item"
            data-testid="endpoint-item"
            primaryText={entities[index].hostname}
            secondaryText={`${t('endpoints.installed')} ${entities[index].createdAt.substring(0, 10)}`}
            renderInMiddle={
              <span data-testid="endpoint-version">
                {entities[index].version}
              </span>
            }
          />
        )}
        loadMore={
          <div
            ref={ref}
            style={{
              padding: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {loadingNextPortion && <Spin data-testid="next-portion-loader" />}
          </div>
        }
      />
    </ConfigProvider>
  );

  function renderEmpty() {
    return (
      <div
        style={{
          textAlign: 'center',
          color: '#000',
        }}
      >
        <div className='ant-divider ant-divider-horizontal role="separator"' />
        <NoEndpointsIcon />
        <p data-testid="no-matching-endpoints">
          {t('endpoints.no-matching-endpoints')}
        </p>
      </div>
    );
  }
};

export default EndpointsList;
