// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { from, of } from 'rxjs';
import { switchMap, startWith, catchError } from 'rxjs/operators';
import { type Epic, ofType, combineEpics } from 'redux-observable';
import { type RootAction } from '../../../store/rootAction';

import { FETCH_CHECK_SETUP_MODE } from './constants';

import {
  setupModeActionSuccess,
  setupModeActionError,
  setupModeActionPending,
} from './actions';
import { findDirectories$ } from '../../../api/directoriesService';
import { type SetupModePayload } from './types';

export const checkSetupModeEpic: Epic<RootAction> = action$ => {
  return action$.pipe(
    ofType(FETCH_CHECK_SETUP_MODE),
    switchMap(() => {
      return from(findDirectories$()).pipe(
        switchMap(res => {
          if (
            res.directories &&
            res.directories.length > 0 &&
            res.directories[0].syncedGroupCount === 0
          ) {
            const setupPayload: SetupModePayload = {
              isSetupMode: true,
              redirectToSetup: true,
              directoryId: res.directories[0].directoryId,
            };
            return of(setupModeActionSuccess(setupPayload));
          } else {
            return of(
              setupModeActionError(
                'FindDirectoriesPayload: ' + JSON.stringify(res),
              ),
            );
          }
        }),
        catchError(e => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return of(setupModeActionError(e));
        }),
        startWith(setupModeActionPending()),
      );
    }),
  );
};

export const initialSetupEpic = combineEpics(checkSetupModeEpic);
