interface CommonProps {
  policyId?: string;
  createdAt?: string;
  updatedAt?: string;
  featureKey: string;
  configType: string;
}

export interface ConfigGetRequest {
  featureKey: FeatureKey;
  configType: ConfigType;
}

export interface ConfigGetResponse {
  policies: Policy[];
  policyRules: PolicyRule[];
  settings: Setting[];
  categoryMetadata: CategoryMetadata[];
  settingMetadata: SettingMetadata[];
}

export interface SettingsUpdateRequest {
  settings: Setting[];
}

export interface CustomSettingsChangeRequest {
  changes: SettingChange[];
}

export interface SettingChange {
  changeType: ChangeType;
  setting: Setting;
}

export enum ChangeType {
  UPSERT = 'upsert',
  DELETE = 'delete',
}

export interface Policy extends CommonProps {
  name: string;
  parentPolicyId?: string;
}

export interface PolicyRule extends CommonProps {
  policyRuleId?: string;
  ruleType: string;
  expr?: string;
}

export interface Setting extends CommonProps {
  categoryName: string;
  name: string;
  value: string;
  valueType: string;
  settingId?: string;
  instanceKey?: string;
  scriptType?: string;
}

export interface SettingMetadata {
  featureKey: string;
  categoryName: string;
  settingName: string;
  supportsValueRule: boolean;
  dataType: string;
  enumType: string;
  accessType: string;
  instanceKey?: string;
}

export interface CategoryMetadata {
  featureKey: string;
  categoryName: string;
  categoryType: string;
  supportsInstanceKeys: boolean;
}

export enum ConfigType {
  'advanced-settings' = 'advanced-settings',
  'security' = 'security',
  'vda' = 'vda',
}
export enum FeatureKey {
  'eamc' = 'eamc',
}

export enum CategoryName {
  'custom-settings' = 'custom-settings',
}

export interface ScriptSettings {
  scripts: Setting[];
}

export interface CustomSettings {
  customSettings: Setting[];
}

export interface AdvancedSettings extends ScriptSettings, CustomSettings {}
