// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Select as AntdSelect } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, Select } from '@imprivata-cloud/components';
import { EndpointsSortValueEnum } from './types';
import classes from '../../components/list/Filters.module.less';
import EndpointsTotals from './EndpointsTotals';

const { Option } = AntdSelect;

interface Props {
  value: EndpointsSortValueEnum;
  disabled?: boolean;
  onSelect?: (value: EndpointsSortValueEnum) => void;
  onSearch?: (value: string) => void;
}

const EndpointsSort: React.FC<Props> = function EndpointsSort(props: Props) {
  const { value, disabled, onSelect, onSearch } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Select
        value={value}
        disabled={disabled}
        onSelect={onSelect}
        data-testid="endpoints-sort"
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
      >
        <Option
          data-testid="endpoint-sort-option--all-by-name"
          value={EndpointsSortValueEnum.ALL_BY_NAME}
        >
          {t('endpoints.endpoints-filters.all-endpoints')}
        </Option>
        <Option
          data-testid="endpoint-sort-option--most-up-to-date"
          value={EndpointsSortValueEnum.MOST_UP_TO_DATE_BY_VERSION}
        >
          {t('endpoints.endpoints-filters.most-up-to-date-by-version')}
        </Option>
        <Option
          data-testid="endpoint-sort-option--least-up-to-date"
          value={EndpointsSortValueEnum.LEAST_UP_TO_DATE_BY_VERSION}
        >
          {t('endpoints.endpoints-filters.least-up-to-date-by-version')}
        </Option>
        <Option
          data-testid="endpoint-sort-option--most-recently-installed"
          value={EndpointsSortValueEnum.MOST_RECENTLY_INSTALLED_BY_INSTALL_DATE}
        >
          {t(
            'endpoints.endpoints-filters.most-recently-installed-by-install-date',
          )}
        </Option>
        <Option
          data-testid="endpoint-sort-option--oldest-installed"
          value={EndpointsSortValueEnum.OLDEST_INSTALLED_BY_INSTALL_DATE}
        >
          {t('endpoints.endpoints-filters.oldest-installed-by-install-date')}
        </Option>
      </Select>

      <div className={classes.count}>
        <EndpointsTotals />
      </div>
      <div className={classes.search}>
        <Search
          disabled={disabled}
          placeholder="Search"
          onSearch={onSearch}
          data-testid="endpoints-search"
        />
      </div>
    </div>
  );
};

export default EndpointsSort;
