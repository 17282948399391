// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect } from 'react';
import { Layout, Spin } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { type RouteProps } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../routers/route-names';
import layoutClasses from '../../styles/layout.module.less';
import { loginSelector } from './store/selectors';
import { updateSetupModeAction } from '../initial-setup/store/actions';
import { clearSessionId, clearTenantId } from './utils';
import { logout } from '../../api/sessionService';

export const cleanup = (redirectUrl: string) => {
  void logout().finally(() => {
    sessionStorage.clear();

    // remove auth info from persistent storage
    clearSessionId();
    clearTenantId();

    // Redirect user to clear out any global state
    window.location.href = redirectUrl;
  });
};

export const Logout = (_props: RouteProps) => {
  const dispatch = useDispatch();
  const { tenantId } = useSelector(loginSelector);

  React.useEffect(() => {
    dispatch(
      updateSetupModeAction({
        isSetupMode: false,
        checkDone: false,
        redirectToSetup: false,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const redirectUrl = tenantId
      ? `${LOGIN_ROUTE}?tenantId=${tenantId}`
      : `${LOGIN_ROUTE}`;

    // Clear session info and localStorage
    cleanup(redirectUrl);
  }, [tenantId]);

  return (
    <Layout
      data-testid="logout"
      className={clsx(
        layoutClasses.mainContainer,
        layoutClasses.centeredContent,
      )}
    >
      <Spin />
    </Layout>
  );
};

export default Logout;
