import type { ClientCreateRequest, ClientCreateResponse } from './types';
import { client } from './client';
import { connectEamc } from './endpoint-names';

export async function createEAMToken(
  createTokenRequest: ClientCreateRequest,
): Promise<ClientCreateResponse> {
  return client
    .post(connectEamc.CREATE_CLIENT, createTokenRequest)
    .then(res => {
      return res.data as ClientCreateResponse;
    });
}
