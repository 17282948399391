// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type RouteProps } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { loginRoutes } from '../routers/route-names';
import layoutClasses from '../styles/layout.module.less';
import { QueryParamKey } from './login/store/constants';
import { loginSelector } from './login/store/selectors';
import { RedirectWithQuery, RouteWithQuery } from '../utils/routingHelpers';

export type ProtectedRouteProps = RouteProps;

export default function ProtectedRoute({
  ...routeProps
}: ProtectedRouteProps): JSX.Element {
  const { hasPermission, tenantId, validatingPermissions } =
    useSelector(loginSelector);

  if (validatingPermissions) {
    return (
      <Layout
        className={clsx(
          layoutClasses.mainContainer,
          layoutClasses.centeredContent,
        )}
      >
        <Spin spinning />
      </Layout>
    );
  }

  if (hasPermission) {
    return <RouteWithQuery {...routeProps} />;
  } else {
    const redirectUrl =
      loginRoutes.BASE +
      (tenantId ? `?${QueryParamKey.TENANT_ID}=${tenantId}` : '');

    console.log('REDIRECTION: ', redirectUrl);

    return <RedirectWithQuery to={redirectUrl} />;
  }
}
