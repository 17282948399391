// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { client } from './client';
import { policy } from './endpoint-names';

import {
  type GracePeriodGetResponse,
  type GracePeriodGetRequest,
  type GracePeriodUpdateRequest,
} from '../shared/types/api/authn-policy-v2';

export async function getGracePeriod(
  getRequest: GracePeriodGetRequest,
): Promise<GracePeriodGetResponse> {
  return client
    .post(policy.GET_GRACE_PERIOD, {
      contextType: getRequest.contextType,
    })
    .then(res => {
      return res.data as GracePeriodGetResponse;
    });
}

export async function updateGracePeriod(
  updateRequest: GracePeriodUpdateRequest,
): Promise<unknown> {
  return client
    .post(policy.UPDATE_GRACE_PERIOD, {
      gracePeriodSecs: updateRequest.gracePeriodSecs,
      contextType: updateRequest.contextType,
    })
    .then(res => res.data as unknown);
}
