// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uploadsBarClasses from './UploadsBar.module.less';
import UploadButton from './components/UploadButton';
import { type RootState } from '../../store/rootReducer';
import {
  type AppConfigState,
  type AppListState,
  type ApplicationsListState,
} from './store/reducer';
import { type AppConfig, type IdpCertificate } from './store/types';
import { updateAppConfig } from './store/actions';
import AppModal from '../modals/AppModal';
import getConfig from '../../appConfigUtils';

const { IMPORT_APPLICATION_PROFILES_ENABLED } = getConfig();

const UploadsBar: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const appListState = useSelector<RootState, AppListState>(
    rootState => rootState.apps.appList,
  );
  const applicationsListState = useSelector<RootState, ApplicationsListState>(
    rootState => rootState.apps.applicationsList,
  );
  const appConfigState = useSelector<RootState, AppConfigState>(
    rootState => rootState.apps.appConfig,
  );
  const tenantId = useSelector<RootState, string | undefined>(
    rootState => rootState.login.tenantId,
  );

  const [hasIdpIssuer, setHasidpIssuer] = useState<boolean>(false);

  const [appIntegrationData, setAppIntegrationData] = useState<{
    cert: IdpCertificate;
    idpIssuer: string;
    appDisplayName: string;
    certFileName: string;
  }>();

  useEffect(() => {
    const appIntegration = appListState.appIntegrations?.[0];
    if (!appIntegration || appIntegrationData) {
      return;
    }

    const { idpCertificates, idpIssuer, appDisplayName } = appIntegration;

    setHasidpIssuer(appIntegration.idpIssuer !== null);

    setAppIntegrationData({
      cert: idpCertificates[0],
      idpIssuer,
      appDisplayName,
      certFileName: t('apps.app-modal.cert-filename', { tenantId }),
    });
  }, [appIntegrationData, appListState, t, tenantId]);

  const { config } = appConfigState;
  const { samlEnabled, appUsernameAdMatch } = config;
  const isAppConfigured =
    hasIdpIssuer && appUsernameAdMatch !== null && samlEnabled !== null;

  const applicationsAvailable =
    applicationsListState.apiApplications.length > 0;

  const onConfigUpdate = (config: AppConfig) => {
    dispatch(
      updateAppConfig.request({
        applicationId: 'epic-hyperdrive',
        appConfig: config,
      }),
    );
  };

  return (
    <div
      className={uploadsBarClasses.container}
      data-testid="uploads-bar"
      style={{ justifyContent: 'flex-start' }}
    >
      {IMPORT_APPLICATION_PROFILES_ENABLED && (
        <UploadButton
          buttonType="upload"
          text={t('apps.profile.import.application-profile')}
        />
      )}
      {applicationsAvailable && !isAppConfigured && appIntegrationData && (
        <AppModal
          appIntegration={appListState.appIntegrations[0]}
          appConfig={appConfigState.config}
          redirectUri={appConfigState.redirectUri}
          onConfigUpdate={onConfigUpdate}
          openFromUploads={true}
        />
      )}
    </div>
  );
};

export default UploadsBar;
