// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const contextTypes = {
  generic: 'generic-app-login',
  clinical: 'clinical-app-login',
  desktop: 'endpoint-login',
};
export const resourceType = 'admin-web-ui';

export enum Headers {
  ImprAuthnResource = 'Impr-Authn-Resource',
  ImprTenantId = 'Impr-Tenant-Id',
  ImprClientName = 'Impr-Client-Name',
  XContentTypeOptions = 'X-Content-Type-Options',
  XSSProtection = 'X-XSS-Protection',
  XFrameOptions = 'X-Frame-Options',
  StrictTransportSecurity = 'Strict-Transport-Security',
  // Response headers (only lowercase works)
  ImprTenantType = 'impr-tenant-type',
}

/**
 * @deprecated we are close to not needing this
 * All prod code should be migrated to v2/v3 with new errors now.
 * Leaving this only to keep a few tests which I don't have time to rework now,
 */
export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum ErrorCode {
  FATAL = 'fatal',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  SESSION_EXPIRED = 'session-expired',
  INVALID_SESSION_ID = 'invalid-session-id',
}
