// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/page-layout/PageLayout';
import PageSubHeader from '../../components/page-layout/PageSubHeader';
import HelpButton from '../../components/page-layout/action-bar/HelpButton';
import EndpointAgentLink from '../../components/page-layout/action-bar/EndpointAgentLink';
import InstallerAgentModal from '../modals/InstallerAgentModal';
import { useEndpointsPageState, useInstallerData } from './store/hooks';
import SetTitle from '../../utils/DynamicTitleHelper';
import EndpointsList from './EndpointsList';
import EndpointsSort from './EndpointsSort';
import EndpointSettingsLink from '../../components/page-layout/action-bar/EndpointSettingsLink';
import { type EndpointsListState } from './store/reducers';
import { type RootState } from '../../store/rootReducer';
import NoInstalledEndpoints from './NoEndpoints';

const EndpointsContainer: FC = () => {
  const { t } = useTranslation();
  SetTitle(t('navigation.endpoints'));

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const { sortValue, handleSortOrder, handleSearchChange } =
    useEndpointsPageState();

  const installerData = useInstallerData();

  const endpointsListState = useSelector<RootState, EndpointsListState>(
    ({ endpoints }) => endpoints.endpoints,
  );

  return (
    <PageLayout title={t('navigation.endpoints')}>
      <PageSubHeader
        title={
          <>
            <EndpointAgentLink
              onClick={showModal}
              disabled={!!installerData?.error}
            />
            <EndpointSettingsLink />
            <InstallerAgentModal
              visible={modalVisible}
              onCancel={closeModal}
              data={installerData}
            />
            <HelpButton />
          </>
        }
      />
      {endpointsListState.noEndpoints && (
        <NoInstalledEndpoints setModalOpen={setModalVisible} />
      )}

      {!endpointsListState.noEndpoints && (
        <>
          <EndpointsSort
            value={sortValue}
            disabled={false}
            onSelect={handleSortOrder}
            onSearch={handleSearchChange}
          />
          <EndpointsList />
        </>
      )}
    </PageLayout>
  );
};

export default EndpointsContainer;
