// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const USERS_ROUTE = '/users';
export const SETTINGS_ROUTE = '/settings';
export const APPS_ROUTE = '/applications';
export const ENDPOINTS_ROUTE = '/endpoints';
export const DIRECTORIES_ROUTE = '/directories';
export const SECURITY_ROUTE = '/security';
export const LOGOUT_ROUTE = '/logout';
export const NOT_FOUND_ROUTE = '/not-found';

export const loginRoutes = {
  BASE: LOGIN_ROUTE,
  AUTOLOGIN: `${LOGIN_ROUTE}/session`,
} as const;

export const settingsRoutes = {
  BASE: SETTINGS_ROUTE,
  APPEARANCE: `${SETTINGS_ROUTE}/appearance`,
  IDENTITY_BROKER: `${SETTINGS_ROUTE}/identity-broker`,
  NOTIFICATIONS: `${SETTINGS_ROUTE}/notifications`,
  SYSTEM: `${SETTINGS_ROUTE}/system`,
  AGENT: `${SETTINGS_ROUTE}/agent`,
  APP_TERMINATION: `${SETTINGS_ROUTE}/app-termination`,
  ADVANCED_SETTINGS: `${SETTINGS_ROUTE}/advanced-settings`,
} as const;
