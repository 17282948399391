// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItemKeys } from '../../../components/layout/SiderMenu';
import {
  APPS_ROUTE,
  DIRECTORIES_ROUTE,
  ENDPOINTS_ROUTE,
  HOME_ROUTE,
  SECURITY_ROUTE,
  SETTINGS_ROUTE,
  USERS_ROUTE,
  LOGOUT_ROUTE,
  NOT_FOUND_ROUTE,
} from '../../../routers/route-names';

const routesMap: Record<string, string> = {
  [MenuItemKeys.Home]: HOME_ROUTE,
  [MenuItemKeys.Apps]: APPS_ROUTE,
  [MenuItemKeys.Directories]: DIRECTORIES_ROUTE,
  [MenuItemKeys.Endpoints]: ENDPOINTS_ROUTE,
  [MenuItemKeys.Security]: SECURITY_ROUTE,
  [MenuItemKeys.Users]: USERS_ROUTE,
  [MenuItemKeys.Settings]: SETTINGS_ROUTE,
  [MenuItemKeys.Logout]: LOGOUT_ROUTE,
};

export function getActiveItemFromPath(path: string): string | undefined {
  if (path === HOME_ROUTE) {
    return MenuItemKeys.Home;
  }
  const pathParts = path.split('/').slice(1);
  return Object.keys(routesMap).find(key => {
    const route = routesMap[key];

    return pathParts[0] === route.slice(1);
  });
}

export function useAppNavigation(): [
  string,
  Dispatch<SetStateAction<string>>,
  (item: string) => void,
] {
  const location = useLocation();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState<string>(
    getActiveItemFromPath(location.pathname) ?? MenuItemKeys.Home,
  );
  const handleClick = useCallback(
    (item: string) => {
      const routePath = routesMap[item] ?? NOT_FOUND_ROUTE;

      setActiveItem(item);
      history.push(routePath + location.search);
    },
    [history, location.search],
  );

  return [activeItem, setActiveItem, handleClick];
}
