// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Banner } from '@imprivata-cloud/components';
import type { OrgPreferences } from '@imprivata-cloud/authn';
import { loginSelector } from './selectors';
import { validatePermissionAction } from './actions';
import { QueryParamKey } from './constants';
import { USERS_ROUTE, loginRoutes } from '../../../routers/route-names';
import { fetchOrgPreferences } from '../../../api/preferencesService';
import { getBaseUrl } from '../../../utils/build';

export const useGetInitialOrgPreferences = () => {
  const initalOrgPreferencesFetched = useRef(false);

  const [preferences, setPreferences] = useState<OrgPreferences | undefined>();

  useEffect(() => {
    console.debug('[Admin] Fetching initial preferences');
    if (!initalOrgPreferencesFetched.current) {
      void fetchOrgPreferences()
        .then(data => {
          const baseUrl = getBaseUrl().replace(/^\/+/g, '');
          const logo = (data.logoUrl || '').replace(/^\/+/g, '');

          const prefs = {
            ...data,
            logoUrl: [baseUrl, logo].filter(Boolean).join('/'),
          };

          console.debug('[Admin] Fetched initial preferences', prefs);
          setPreferences(data || {});
        })
        .catch(e => {
          console.debug('[Admin:error] preferences', e);
          setPreferences({});
        })
        .finally(() => {
          initalOrgPreferencesFetched.current = true;
        });
    }
  }, []);

  const ready = preferences !== undefined;

  return { preferences, ready };
};

export const useQueryParams = (): URLSearchParams => {
  const location = useLocation();
  return useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
};

export const useLoginHook = (): {
  isLoading: boolean;
  preloginMessageKey?: string;
  storedURL?: string;
  errorToDisplay?: string;
  usernameEntered: boolean;
  hasPermission: boolean;
  sessionExpiredError?: string;
  authenticationError?: string;
  logoURL?: string;
  passwordLabel?: string;
  usernameLabel?: string;
  permissionError?: string;
  username?: string;
} => {
  const {
    sessionIdError,
    hasPermission,
    permissionError,
    authenticating,
    validatingPermissions,
    preloginMessageKey,
    storedURL,
    logoURL,
    usernameLabel,
    passwordLabel,
    isUsernameEntered,
    sessionExpiredError,
    factorOptionsError,
    authenticationError,
    username,
  } = useSelector(loginSelector);

  const usernameEntered = isUsernameEntered;
  const errorToDisplay =
    sessionIdError || factorOptionsError || preloginMessageKey;

  return {
    isLoading: authenticating || validatingPermissions,
    storedURL,
    logoURL,
    usernameLabel,
    passwordLabel,
    errorToDisplay,
    usernameEntered,
    sessionExpiredError,
    hasPermission: !!hasPermission,
    authenticationError,
    permissionError,
    username,
    preloginMessageKey,
  };
};

export const useAutologinRequest = (): {
  hasAuthData: boolean;
  isLoading: boolean;
  redirectTo: string;
} => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { validatingPermissions, hasPermission } = useSelector(loginSelector);
  const tenantId = params.get(QueryParamKey.TENANT_ID) || '';
  const sessionId = params.get(QueryParamKey.SESSION_ID) || '';
  const hasAuthData = !!(tenantId.length && sessionId.length);
  const permissionUndefined = hasPermission === undefined;
  const location = useLocation();

  const redirectTo = hasPermission
    ? `${USERS_ROUTE}?${location.search}`
    : `${loginRoutes.BASE}?${location.search}`;

  useEffect(() => {
    if (hasAuthData && permissionUndefined) {
      dispatch(validatePermissionAction.request({ tenantId, sessionId }));
    }
  }, [dispatch, hasAuthData, permissionUndefined, tenantId, sessionId]);

  useEffect(() => {
    if (!hasAuthData) {
      Banner({
        type: 'error',
        message: t('login.autologin.no-session-or-tenant-id'),
        duration: 10,
        datatestid: 'auto-login-message',
      });
    }
  }, [t, hasAuthData]);

  return {
    hasAuthData,
    isLoading: hasPermission === undefined || validatingPermissions,
    redirectTo,
  };
};
