// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { findDirectories } from '../../../api/directoriesService';
import { findGroups } from '../../../api/groupsService';
import {
  FilterOperator,
  FindGroupsField,
  SortOrder,
} from '../../../shared/types';
import { getMetadata } from '../../../api/identityBrokerService';

export const useDirectoriesQuery = () => {
  return useQuery({
    queryFn: findDirectories,
    queryKey: ['findDirectories'],
  });
};

export const useGroupsQuery = () => {
  const directoriesQuery = useDirectoriesQuery();
  // this code works until we don't have more that one directory
  const directory = directoriesQuery.data?.directories[0];

  return useQuery({
    enabled: directory !== undefined,
    queryKey: ['findGroups'],
    queryFn: async () =>
      findGroups({
        selectors: {
          filters: [
            {
              field: FindGroupsField.IDENTITY_PROVIDER_ID,
              operator: FilterOperator.EQUALS,
              value: directory?.directoryId,
            },
            {
              field: FindGroupsField.SYNC_ENABLED,
              operator: FilterOperator.EQUALS,
              value: true,
            },
            {
              field: FindGroupsField.IDP_DELETED,
              operator: FilterOperator.EQUALS,
              value: false,
            },
          ],
          sortFields: [
            {
              field: FindGroupsField.NAME,
              order: SortOrder.ASC,
            },
          ],
        },
        pageSize: 999,
      }),
  });
};

export const useIdpMetadataQuery = ({
  onSuccess,
}: {
  onSuccess?: (data: Awaited<ReturnType<typeof getMetadata>>) => void;
} = {}) => {
  return useQuery({
    queryFn: getMetadata,
    queryKey: ['findMetadata'],
    onSuccess,
  });
};
