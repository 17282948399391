// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { Col, Layout, Row } from 'antd';
import ImprivataLogo from '../ImprivataLogo';

import classes from './Header.module.less';
import { getFromStorage } from '../../utils';
import {
  StorageKeys,
  TenantType,
} from '../../containers/login/store/constants';
import TestTenantLabel from './TestTenantLabel';

export interface HeaderProps {
  title: React.ReactNode;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ title, className, ...rest }) => {
  return (
    <Layout.Header className={clsx(classes.header, className)} {...rest}>
      <Layout>
        <Row justify="space-between" align="middle">
          <Col style={{ minWidth: '33%' }}>
            <h2 className="h2-header" style={{ marginBottom: 0 }}>
              {title}
            </h2>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            {getFromStorage(StorageKeys.IMPR_TENANT_TYPE) ===
              TenantType.TEST && <TestTenantLabel />}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <ImprivataLogo />
          </Col>
        </Row>
      </Layout>
    </Layout.Header>
  );
};

export default Header;
