// -------------
// HELPERS
// -------------

// Will provide keys based on DashboardDataType

export type FilterTypeKeys<K extends DashboardDataType> =
  K extends DashboardDataType ? DashboardDataTypeKeysMap[K] : never;

// Will allow a type of array of dashboard data type keys
export type ArrayTypeKeys<K> = K extends DashboardDataType
  ? Array<FilterTypeKeys<K>>
  : never;

// -------------
// App Configuration
// -------------

/**
 * NOTE: This section will be updated as new data is configured. The steps are:
 * 1. Update `DashboardDataType` with the new key for the backend data.
 * 2. Create the keys below following the others as an example under `KEYS`
 * 3. Update `MAP` to include the new data type and keys
 */

// TYPES
export enum DashboardDataType {
  AUTHNS = 'authns',
  AUTHNS_METRICS = 'authns-metrics',
  AUTHNS_DETAILS = 'authns-details',
  SSO_AUTHS_METRICS = 'sso-completed-authns-metrics',
  SSO_AUTHS_DETAILS = 'sso-completed-authns-details',
  ENROLLED_METRICS = 'enrolled-methods-metrics',
  ENROLLED_DETAILS = 'enrolled-methods-details',
  EPCS_READINESS = 'epcs-readiness-metrics',
  EPCS_READINESS_DETAILS = 'epcs-readiness-details',
  UNIQUE_USER_AUTHNS = 'unique-user-authns',
  UNIQUE_USER_AUTHNS_DETAILS = 'unique-user-authns-details',
  UNIQUE_ACTIVE_USER_METRICS = 'active-users-metrics',
  UNIQUE_ACTIVE_USER_DETAILS = 'active-users-details',
  EPCS_REPORT = 'epcs-report',
  // we've currently stopped consuming this dataset, maybe temporarily
  // SSO_AUTHS = 'sso-completed-authns',
}

export type DashboardDataTypeKeys = keyof typeof DashboardDataType;
export type DashboardDataTypeKeyValues = `${DashboardDataType}`;

// MAP - Never used directly, only as an enum => types map
export type DashboardDataTypeKeysMap = {
  [DashboardDataType.AUTHNS]: AuthnsKeys;
  [DashboardDataType.AUTHNS_DETAILS]: AuthnsDetailsKeys;
  [DashboardDataType.AUTHNS_METRICS]: AuthnsMetricsKeys;
  // [DashboardDataType.SSO_AUTHS]: SSOAuthsKeys;
  [DashboardDataType.SSO_AUTHS_METRICS]: SSOAuthsMetricsKeys;
  [DashboardDataType.SSO_AUTHS_DETAILS]: SSOAuthsDetailsKeys;
  [DashboardDataType.ENROLLED_DETAILS]: DetailsKeys;
  [DashboardDataType.ENROLLED_METRICS]: EnrolledMetricsKeys;
  [DashboardDataType.EPCS_READINESS]: EpcsReadinessKeys;
  [DashboardDataType.EPCS_READINESS_DETAILS]: DetailsKeys;
  [DashboardDataType.UNIQUE_USER_AUTHNS_DETAILS]: DetailsKeys;
  [DashboardDataType.UNIQUE_USER_AUTHNS]: UniqueUserAuthnsKeys;
  [DashboardDataType.EPCS_REPORT]: EpcsReportKeys;
  [DashboardDataType.UNIQUE_ACTIVE_USER_METRICS]: UniqueActiveUserMetricKeys;
  [DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS]: UniqueActiveUserDetailsKeys;
};

export type CombinedDashboardDataType = {
  Combinedkeys: AuthnsMetricsKeys & SSOAuthsMetricsKeys;
};

// KEYS
export type AuthnsKeys =
  | 'occurred-at'
  | 'success-authns'
  | 'failure-authns'
  | 'epcs-authns';

export type AuthnsMetricsKeys =
  | 'success-authns'
  | 'success-passwordless-authns'
  | 'total-authns'
  | 'epcs-authns';

export type AuthnsDetailsKeys =
  | 'occurred-at'
  | 'user-upn'
  | 'user-display-name'
  | 'action-type'
  | 'overall-result-type'
  | 'overall-failure-code'
  | 'factor1-type'
  | 'factor2-type'
  | 'resource-type'
  | 'endpoint-type'
  | 'endpoint-name'
  | 'authn-context-type'
  | 'timestamp'
  | 'user'
  | 'activity'
  | 'result'
  | 'authenticator'
  | 'endpoint';

export type SSOAuthsMetricsKeys =
  | 'success-authns'
  | 'total-authns'
  | 'failure-authns';

export type SSOAuthsDetailsKeys =
  | 'occurred-at'
  | 'timestamp'
  | 'user'
  | 'activity'
  | 'result'
  | 'endpoint';

// we've currently stopped consuming this dataset, maybe temporarily
// export type SSOAuthsKeys = 'bucket_id' | 'success-authns' | 'failure-authns';

export type EnrolledMetricsKeys =
  | 'authentication-factor-type'
  | 'total-users'
  | 'enrolled-users'
  | 'not-enrolled-users';

export type EpcsReadinessKeys =
  | 'epcs_readiness_total'
  | 'epcs_readiness_eligible'
  | 'epcs_readiness_not_eligible';

export type EpcsReportKeys = 'downloadLink';

export type UniqueUserAuthnsKeys = 'occurred-at' | 'unique-users';

export type UniqueActiveUserMetricKeys =
  | 'bucket-id'
  | 'active-users'
  | 'entitlement-type'
  | 'entitlement-name';

export type UniqueActiveUserDetailsKeys =
  | 'occurred-at'
  | 'user'
  | 'aad-group-names';

// General type of details using following format
export type DetailsKeys =
  | 'timestamp'
  | 'user'
  | 'activity'
  | 'result'
  | 'authenticator'
  | 'endpoint';

// -------------
// API Types
// -------------

/**
 * NOTE: This shouldn't change much. They should be standardized across all requests
 */

// Dashboard data interval for fetching data in intervals
export enum DashboardDataInterval {
  PREVIOUS_30_DAYS = 'previous-30-days',
  PREVIOUS_12_MONTHS = 'previous-12-months',
  PREVIOUS_7_DAYS = 'previous-7-days',
  LAST_24_HOURS = 'last-24-hours',

  // Not included in select dropdown
  ALL_TIME_RANGE = 'all',
  UNIQUE_USERS_TIME_RANGE = 'last-2-months',
  UNIQUE_USERS_CURRENT_MONTH = 'current-month',
  UNIQUE_USERS_PREVIOUS_MONTH = 'previous-month',
}

// API Request Types

export enum DatasetExportFormat {
  CSV = 'csv',
}

export type DatasetSelector = {
  datasetName: DashboardDataType;
  timeRangeType: DashboardDataInterval | string;
  entitlementType?: UniqueUserEntitlementType;
};

export type DatasetSelectorBody =
  | {
      datasetSelectors: DatasetSelector[];
    }
  | {
      datasetSelector: DatasetSelector;
      format: DatasetExportFormat;
    };

// API Response Type

export type DashboardPayload =
  | {
      dataset: Array<{
        dataset: string;
      }>;
    }
  | {
      dataset: {
        dataset: string;
      };
    };

export interface DashboardPayloadData<
  T extends DashboardDataType = DashboardDataType,
> {
  metadata: DashboardMetadata<T>;
  data: (string | number)[][];
}

export type DashboardDataDatasetPayload = Array<DashboardPayloadData>;

export interface DashboardMetadata<T extends DashboardDataType> {
  tenantId: string;
  datasetType: string;
  datasetName: keyof Extract<DashboardDataType, T>;
  timeRangeType: DashboardDataInterval;
  granularityType: string;
  keys: ArrayTypeKeys<T>;
}

export enum UniqueUserEntitlementType {
  ENITITLMENT_TYPE = 'entitlementType',
  SSO = 'sso',
  EPCS = 'epcs',
}

export type EntitlementType =
  | UniqueUserEntitlementType.EPCS
  | UniqueUserEntitlementType.SSO;

export type UniqueUsersIntervalType =
  | DashboardDataInterval.UNIQUE_USERS_CURRENT_MONTH
  | DashboardDataInterval.UNIQUE_USERS_PREVIOUS_MONTH;
export interface DashBoradDataExtraFilters {
  entitlementType?: EntitlementType;
}
