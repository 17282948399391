// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const SpanNames = {
  INIT_APP: 'init_app' as const,
  GET_FACTOR_OPTIONS: 'get_factor_options' as const,
  AUTHENTICATION: 'authentication' as const,
  CREATE_AUTHN_SESSION: 'create_authn_session' as const,
  AUTHENTICATE_REQUEST: 'authenticate_request' as const,
  CREATE_USER_SESSION: 'create_user_session' as const,
};
