// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Select as AntdSelect } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@imprivata-cloud/components';
import classes from '../../components/list/Filters.module.less';
import { AppsFilterValueEnum } from './types';

const { Option } = AntdSelect;

const AppsFilters: React.FC = function () {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Select
        value={AppsFilterValueEnum.ALL_BY_NAME}
        onSelect={() => -1}
        data-testid="apps-filter"
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
      >
        <Option value={AppsFilterValueEnum.ALL_BY_NAME}>
          {t('apps.filters.all-apps-by-name')}
        </Option>
      </Select>
    </div>
  );
};

export default AppsFilters;
