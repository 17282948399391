// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { normalize, schema } from 'normalizr';
import {
  type NormalizedUsersListPayload,
  type TenantUser,
  type UsersListPayload,
  type UsersTotalsPayload,
} from './types';

const userEntity = new schema.Entity<TenantUser>('users', undefined, {
  idAttribute: (d: TenantUser) => d.upn,
});
const userListResponseSchema = {
  users: new schema.Array(userEntity),
};
export const normalizeUsersList = (
  res: UsersListPayload,
): NormalizedUsersListPayload => {
  return normalize(res, userListResponseSchema);
};

export const getUserTotalCount = (
  userListApiResponse: UsersListPayload,
): UsersTotalsPayload => {
  return { totalCount: userListApiResponse.totalCount } as UsersTotalsPayload;
};
