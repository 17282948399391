// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type RootState } from '../../../store/rootReducer';
import {
  GET_DESKTOP_GRACE_PERIOD,
  GET_GRACE_PERIOD,
  PENDING_DESKTOP_GRACE_PERIOD,
  PENDING_GRACE_PERIOD,
} from './constants';
import { type GracePeriod } from './reducers';

export const isLoadingSelector = ({ security }: RootState): boolean =>
  security?.operations === PENDING_GRACE_PERIOD ||
  security?.operations === GET_GRACE_PERIOD ||
  security?.operations === PENDING_DESKTOP_GRACE_PERIOD ||
  security?.operations === GET_DESKTOP_GRACE_PERIOD
    ? true
    : false;

export const dataSelector = ({ security }: RootState): GracePeriod =>
  security.data;

export const desktopDataSelector = ({ security }: RootState): GracePeriod =>
  security.desktopData;
