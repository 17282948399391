// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type Authenticator } from '../containers/users/store/types';
import { type ErrorCode } from './constants';

export interface ApiResponseData {
  error?: {
    code?: ErrorCode;
  };
}

export type ApiResponse2<T> = T | ApiErrorResponseBody2;

export interface ApiErrorResponseBody2 {
  error: ApiError2;
}

export interface ApiError2 {
  code: string;
  type: ApiErrorType;
  message?: string;
  data?: Record<string, unknown>;
}

export enum ApiErrorType {
  APP = 'app',
  SYSTEM = 'system',
}

export type CreateSessionPayload = {
  tenantId: string;
  sessionId: string;
};

export type AuthenticatePayload = {
  authnToken: string;
};

export type PrepareFileUploadResponse = {
  uploadUrl: string;
  readUrl: string;
};

export interface AuthnFactorsBulkEnrollData {
  createdCount: number;
  deletedCount: number;
}

/**
 * Org Preferences
 */

export interface OrgPreferencesUpdateRequest {
  logoUrl?: string;
  orgName?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
}

export interface OrgPreferencesData {
  logoUrl?: string;
  orgName?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
  createdAt?: string;
  updatedAt?: string;
}

/**
 * Authenticators
 */

export interface AuthenticatorsFindByUserIdsRequest {
  userIds: string[];
}

export interface AuthenticatorsUnenrollRequest {
  authenticatorIds: string[];
}

export interface AuthenticatorsFindByUserIdsResponse {
  authenticators: Authenticator[];
}

export type ValidatePermissionPayload = Record<string, unknown>;

export enum FactorTypeEnum {
  'PWD' = 'pwd',
}

export enum FileAccessModifier {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export interface CredentialsDataDecrypted extends Record<string, unknown> {
  saml: string; // base64 encrypted saml xml
}

/**
 * Group sync
 */
export interface GroupForSyncMarkRequestPayload {
  groupId: string;
  isAdmin: boolean;
}

export interface GroupForSyncUnmarkRequestPayload {
  groupId: string;
}

/**
 * Directories, sync progress, and failure
 */

export type Directory = {
  name: string;
  directoryId: string;
  syncedGroupCount: number;
  idpTenantId: string;
  latestSyncAt?: string;
};

export interface DirectoriesFindPayload {
  directories: Directory[];
}

export interface DirectorySyncGetRequest {
  directoryIds?: string[];
}

export interface DirectorySyncProgressRequest {
  directoryId?: string;
}

export type DirectoryFailedSyncRequest = DirectorySyncProgressRequest;

export enum DirectorySyncStatusCode {
  NOT_INITIALIZED = 'not-initialized',
  RUNNING = 'running',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface SyncProgressInfo {
  directoryId: string;
  syncId: string;
  totalStepsProcessed: number;
  totalSteps: number;
}

export interface DirectorySyncProgressPayload {
  syncStatus: DirectorySyncStatusCode;
  info?: SyncProgressInfo;
  failureReason?: SyncFailureReason;
  /**
    The completion time of the most recent successful sync.
    If the 'syncStatus' attr is 'running' the completion time of the previous successful sync.
    If the 'syncStatus' attr is 'succeeded' this will be the completion time.
    If the 'syncStatus' attr is 'failed' the value will be
    the time of the most recent successful sync.
    If no sync has ever been successful it is not returned.
   */
  latestSyncAt?: string;
}

export enum SyncFailureReason {
  IDP_FAILURE = 'idp-failure',
  TRANSIENT_IDP_FAILURE = 'transient-idp-failure',
  SYNC_INTERRUPTED = 'sync-interrupted',
  TRANSIENT_SYSTEM_FAILURE = 'transient-system-failure',
  SYSTEM_FAILURE = 'system-failure',
  // NOTE: Not currently being used, trying to change that
  // SYNC_ALREADY_RUNNING = 'sync-already-running'
}

export interface FailedSyncsInfo {
  syncId: string;
  failureReason: SyncFailureReason;
  createdAt: string;
  endedAt: string;
}

export interface DirectoriesSyncFailedPayload {
  info: FailedSyncsInfo[];
}

/**
 * AAD Group
 */

export interface StartInitialSyncPayload {
  identityProviderId: string;
}

export interface AADGroupUserCountGetPayload {
  identityProviderId: string;
  groupId: string;
}

export type AADGroupUserCountGetResponse = {
  userCount: number;
  hash: string;
};

export type AdminGroup = {
  groupId: string;
  userCount: number;
  hash?: string;
};

export interface AdminGroupsChooseRequest {
  identityProviderId: string;
  groups: AdminGroup[];
}

export interface ClientCreateRequest {
  enterpriseId: string;
  installationTokenExpirationInMinutes: number;
}

export type TokenSecret = {
  data: string;
  origin: null;
  isProxied: false;
};

export type ClientMetadata = {
  clientId: string;
  eamEnterpriseId: string;
  installationToken: TokenSecret;
  tokenExpiryTime: number;
  createdAt: number;
  installationTokenValue: string;
};

export interface ClientCreateResponse {
  clientMetadata: ClientMetadata;
}
