// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { format, parseJSON } from 'date-fns';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATE_TIME_FORMAT_OFFSET = 'yyyy-MM-dd HH:mm:ss zzzz';

export const computeDateDiffInDays = (
  dateStr: string,
  dateFormat: string,
): number => {
  const localDateTime = getLocalDateTime(dateStr, dateFormat);
  const dateMillis = Date.parse(localDateTime);
  const currentTimeMillis = Date.parse(new Date().toString());
  return Math.trunc((currentTimeMillis - dateMillis) / 86400000);
};

export const isoStringToDate = (isoStr: string, dateFormat: string): string => {
  const localDateTime = getLocalDateTime(isoStr, dateFormat);
  return format(new Date(localDateTime), dateFormat);
};

export const getLocalDateTime = (
  date: string | number,
  dateFormat: string,
): string => {
  return format(new Date(date), dateFormat);
};

export const formatDateWithoutTimeZoneChangeUTCOnly = (
  dateStr: string,
): string => {
  //'yyyy-MM-dd HH:mm:ss' format
  return parseJSON(dateStr)
    .toISOString()
    .replace(/T/, ' ') // replace T with a space
    .replace(/\..+/, '') // delete the dot and everything after;
    .concat(' UTC');
};

export const formatDateForAstra = (dateStr: string): string => {
  const dateList = dateStr.split(' ');
  const date = dateList[0];
  const time = dateList[1];
  const offset = dateList[2].replace('GMT', 'UTC');
  if (offset.substring(4) === '00:00') {
    return `${date} ${time} UTC`;
  } else if (offset.substring(7) === '00') {
    return `${date} ${time} ${offset.substring(0, 6)}`;
  } else {
    return `${date} ${time} ${offset}`;
  }
};

export function sortItemsByDate<T>(
  items: T[],
  dateField: string,
  direction: 'ASC' | 'DESC',
): T[] {
  return items.sort((a, b) => {
    const dateA = a[dateField as keyof T]
      ? new Date(a[dateField as keyof T] as string)
      : null;
    const dateB = b[dateField as keyof T]
      ? new Date(b[dateField as keyof T] as string)
      : null;
    if (dateA && dateB) {
      return direction === 'ASC'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    } else {
      return 0;
    }
  });
}
