// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

export interface SettingsContextType {
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
}

export const SettingsContext = createContext<Partial<SettingsContextType>>({
  link: '',
});

export const useSettingsContext = (): Partial<SettingsContextType> => {
  const context = useContext(SettingsContext);

  return context;
};

const SettingsContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [link, setLink] = useState('');
  return (
    <SettingsContext.Provider value={{ link, setLink }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
