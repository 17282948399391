// Copyright 2024, Imprivata, Inc.  All rights reserved.

import {
  Button,
  ButtonVariant,
  ImprTextArea,
  InputBox,
} from '@imprivata-cloud/components';
import { Col, Form, Row } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { useTranslation } from 'react-i18next';
import type { FormInstance } from 'antd';
import type { Setting } from '../../../shared/types';
import {
  DATE_TIME_FORMAT_OFFSET,
  isoStringToDate,
} from '../../../utils/DateUtils';
import AddIcon from '../../../assets/svg/addIcon.svg?react';
import TrashIcon from '../../../assets/svg/trash.svg?react';
import HelpIcon from '../../../assets/svg/help.svg?react';
import styles from './AdvancedSettings.module.less';
import { FormName, initialSettingItem } from './AdvancedSettings';

const SettingsForm = (props: {
  formName: FormName;
  form: FormInstance;
  initialValues?: Setting[];
  disabled: boolean;
  onTouched: (form: FormInstance, formName: FormName) => void;
  'data-testid'?: string;
}) => {
  const {
    formName,
    form,
    initialValues,
    disabled,
    onTouched,
    'data-testid': dataTestId,
  } = props;
  const { t } = useTranslation();
  const formHeader = `advanced-settings.${formName}.header`;
  const formDescription = `advanced-settings.${formName}.description`;
  const inputLabel =
    formName === FormName.scripts
      ? 'advanced-settings.scripts.event-name'
      : 'advanced-settings.customSettings.settings-name';
  const textAreaLabel =
    formName === FormName.scripts
      ? 'advanced-settings.scripts.script-body'
      : 'advanced-settings.customSettings.settings-value';
  const addButtonLabel =
    formName === FormName.scripts
      ? 'actions.add-scripts'
      : 'actions.add-custom-settings';

  const scriptTypeLabel = (setting: Setting) => {
    const type =
      setting.scriptType || t('advanced-settings.scripts.unknown-type');
    return setting.settingId
      ? t('advanced-settings.scripts.identified-as', {
          value: type,
        })
      : '';
  };

  return (
    <div className={styles.scriptsContainer} id={`${formName}-container`}>
      <div className="h3-header" style={{ marginBottom: '11px' }}>
        {t(formHeader)}
      </div>
      <span>{t(formDescription)}</span>
      <Form
        form={form}
        initialValues={
          initialValues && {
            [formName]: initialValues,
          }
        }
        onChange={() => {
          onTouched(form, formName);
        }}
        autoComplete="off"
        data-testid={dataTestId}
        scrollToFirstError
        disabled={disabled}
      >
        <Form.List name={formName}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div key={key} className={styles.scriptsItem}>
                  <Row gutter={[24, 16]}>
                    <Col span={8}>
                      <Form.Item
                        name={[name, 'name']}
                        rules={[
                          {
                            min: 1,
                            required: true,
                            message: t(
                              'advanced-settings.field-errors.event-name',
                            ),
                          },
                        ]}
                      >
                        <InputBox
                          data-testid={`${formName}-name`}
                          label={
                            (
                              <div className={styles.textAreaLabel}>
                                {t(inputLabel)}
                                <a href="#">
                                  <HelpIcon />
                                </a>
                              </div> // will fix this in components lib and then remove this type conversion
                            ) as unknown as string
                          }
                          type="text"
                          width="100%"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={15}>
                      <Form.Item
                        name={[name, 'value']}
                        rules={[
                          {
                            min: 1,
                            required: true,
                            message: t(
                              'advanced-settings.field-errors.script-body',
                            ),
                          },
                        ]}
                      >
                        <ImprTextArea
                          data-testid={`${formName}-value`}
                          rows={10}
                          containerClassName={styles.scriptsTextAreaContainer}
                          style={{
                            resize:
                              formName === FormName.scripts
                                ? 'none'
                                : 'vertical',
                            height:
                              formName === FormName.scripts ? '100%' : '50px',
                          }}
                          label={
                            <div className={styles.textAreaLabel}>
                              {t(textAreaLabel)}
                              <a href="#">
                                <HelpIcon />
                              </a>
                              {formName === FormName.scripts && (
                                <span>
                                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
                                  {`${scriptTypeLabel(form.getFieldValue([FormName.scripts, name]))} ${
                                    form.getFieldValue([FormName.scripts, name])
                                      .updatedAt /* eslint-disable-line @typescript-eslint/no-unsafe-member-access */ !==
                                    undefined
                                      ? t(
                                          'advanced-settings.scripts.updated-at',
                                          {
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                            value: isoStringToDate(
                                              (form.getFieldValue([
                                                FormName.scripts,
                                                name,
                                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                              ]).updatedAt as string) || '',
                                              DATE_TIME_FORMAT_OFFSET,
                                            ),
                                          },
                                        )
                                      : ''
                                  }
                                  `}
                                </span>
                              )}
                            </div>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1} style={{ alignSelf: 'center' }}>
                      {fields.length > 1 && (
                        <TrashIcon
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            form.getFieldValue([formName, name]).settingId &&
                              onTouched(form, formName);
                            remove(name);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
              <div
                className="ant-divider ant-divider-horizontal"
                role="separator"
                style={{ margin: '16px 16px' }}
              />
              <Row justify={'center'}>
                <Form.Item style={{ marginBottom: '16px' }}>
                  <Button
                    label={t(addButtonLabel)}
                    variant={ButtonVariant.TEXT}
                    data-testid="add-scripts-button"
                    icon={<Icon component={AddIcon} />}
                    onClick={() => {
                      add(initialSettingItem, 0);
                      document
                        .getElementById(`${formName}-container`)
                        ?.scrollIntoView({
                          behavior: 'smooth',
                        });
                    }}
                  />
                </Form.Item>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default SettingsForm;
