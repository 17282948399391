// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { switchMap, map, catchError, tap, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { type Epic, combineEpics } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { type RootAction } from '../../../store/rootAction';

import { validatePermissionAction, invalidSessionAction } from './actions';
import { validatePermission } from '../../../api/sessionService';

import {
  endAuthenticationTracingSpan,
  startCreateUserSessionTracingSpan,
  endCreateUserSessionTracingSpan,
} from '../tracing';

import {
  saveSessionId,
  saveTenantId,
  clearSessionId,
  clearTenantId,
} from '../utils';
import { ErrorCodes } from './constants';
import { type RootState } from '../../../store/rootReducer';

export const validatePermissionEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(validatePermissionAction.request)),
    tap(({ payload }) => {
      startCreateUserSessionTracingSpan();

      const { sessionId, tenantId } =
        payload?.sessionId && payload?.tenantId ? payload : state$.value.login;
      if (sessionId && tenantId) {
        saveSessionId(sessionId);
        saveTenantId(tenantId);
      }
    }),
    switchMap(() => {
      return from(validatePermission()).pipe(
        map(response => {
          endCreateUserSessionTracingSpan({ response });
          endAuthenticationTracingSpan();

          return validatePermissionAction.success(true);
        }),
        catchError(error => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          endCreateUserSessionTracingSpan({ error });
          endAuthenticationTracingSpan();

          clearSessionId();
          return of(validatePermissionAction.failure(ErrorCodes.NO_PERMISSION));
        }),
      );
    }),
  );

export const invalidSessionEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(invalidSessionAction.request)),
    tap(() => {
      sessionStorage.clear();
    }),
    switchMap(({ payload }) => {
      // remove auth info from persistent storage
      clearSessionId();
      clearTenantId();

      return of(invalidSessionAction.success(payload));
    }),
    catchError(() => {
      return of(invalidSessionAction.failure());
    }),
  );

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const loginEpic = combineEpics(
  validatePermissionEpic,
  invalidSessionEpic,
);
