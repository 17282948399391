// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type {
  ConfigGetRequest,
  ConfigGetResponse,
  CustomSettingsChangeRequest,
} from '../../shared/types';
import { client } from '../client';
import { advancedSettings } from '../endpoint-names';

export async function getAdvancedSettingsConfig(
  payload: ConfigGetRequest,
): Promise<ConfigGetResponse> {
  const response = await client.post<ConfigGetResponse>(
    advancedSettings.GET_CONFIG,
    payload,
  );
  return response?.data;
}

export async function changeCustomSettings(
  payload: CustomSettingsChangeRequest,
): Promise<void> {
  await client.post(advancedSettings.CUSTOM_SETTINGS_CHANGE, payload);
}
