// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Col, Row } from 'antd';
import {
  Button,
  Banner,
  ButtonSize,
  ButtonVariant,
} from '@imprivata-cloud/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CancelButton from '../../../components/page-layout/action-bar/CancelButton';
import EndpointAgentLink from '../../../components/page-layout/action-bar/EndpointAgentLink';
import HelpButton from '../../../components/page-layout/action-bar/HelpButton';
import SaveButton from '../../../components/page-layout/action-bar/SaveButton';
import agentClasses from './Agent.module.less';
import { useInstallerData } from '../../endpoints/store/hooks';
import AgentKeyRegerationModal from '../../modals/AgentKeyRegerationModal';
import InstallerAgentModal from '../../modals/InstallerAgentModal';
import { regenerateAgentKey } from './store/actions';
import { SettingsPageLayout } from '../components';

const Agent: React.FC = () => {
  const [agentModalOpen, setAgentModalOpen] = useState(false);
  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const installerData = useInstallerData();

  const handleKeyRegenerationAction = () => {
    dispatch(regenerateAgentKey.request());
    setRegenerateModalOpen(false);
  };

  useEffect(() => {
    if (installerData.regeneration) {
      Banner({
        type: 'success',
        message: t('agent.token-successfully-regenerated'),
        duration: 10,
        datatestid: 'agent-regeneration-success',
      });
      //this cancel is for turning the 'regeneration' flag to false
      dispatch(regenerateAgentKey.cancel());
    }
    if (installerData.error && installerData.error !== 'cancelled') {
      Banner({
        type: 'error',
        message: t(`endpoints.error-messages.${installerData.error}` as never),
        duration: 10,
        datatestid: 'agent-regeneration-error',
      });
    }
  }, [dispatch, installerData, t]);

  return (
    <SettingsPageLayout
      title={
        <>
          <SaveButton disabled />
          <CancelButton disabled />
          <EndpointAgentLink
            onClick={() => {
              setAgentModalOpen(!agentModalOpen);
            }}
            disabled={installerData.error !== null}
          />
          <InstallerAgentModal
            visible={agentModalOpen}
            onCancel={() => {
              setAgentModalOpen(!agentModalOpen);
            }}
            data={installerData}
          />
          <HelpButton />
        </>
      }
    >
      <div>
        <Row>
          <Col>
            <div className="h3-header">{t('agent.key-replace-title')}</div>
            <p className="primary-body">{t('agent.key-replace-subtitle')}</p>
            <div className={agentClasses.buttonWraper}>
              <Button
                label={t('agent.key-replace-button')}
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.MAJOR}
                data-testid="open-regenerate-modal-button"
                onClick={() => {
                  setRegenerateModalOpen(!regenerateModalOpen);
                }}
              />
            </div>
          </Col>
        </Row>
        <AgentKeyRegerationModal
          visible={regenerateModalOpen}
          onCancel={() => {
            setRegenerateModalOpen(!regenerateModalOpen);
          }}
          onAction={handleKeyRegenerationAction}
        />
      </div>
    </SettingsPageLayout>
  );
};

export default Agent;
