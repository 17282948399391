import { useMemo } from 'react';

import classes from './shared.module.less';
import BarGuage from '../../../components/bar-guage/BarGuage';
import Card, { type CardProps } from '../../../components/card/Card';
import badgeImg from '../../../assets/svg/badge.svg?url';
import iidImage from '../../../assets/svg/astra_IID.svg?url';
import { type DashboardData } from '../types';
import { extractValueAtKey } from '../utils';
import { METRICS_IMPR_ID, METRICS_PROX } from '../constants';
import {
  type ArrayTypeKeys,
  type DashboardDataType,
} from '../../../api/Dashboard/types';

export const configureData = (
  data?: DashboardData[DashboardDataType.ENROLLED_METRICS],
) => {
  let enrollData: EnrollmentData = {};
  if (data) {
    const { keys = [], values = [] } = data;
    if (values.length > 0) {
      const getValue = (extractValueAtKey<ArrayTypeKeys<DashboardDataType.ENROLLED_METRICS>>).bind(null, keys); // prettier-ignore

      // Get nested data for calcs
      const proxData = values.find(v => v[0] === METRICS_PROX);
      const iidData = values.find(v => v[0] === METRICS_IMPR_ID);
      if (proxData) {
        const total = getValue('total-users', proxData) || 1;
        const enrolled = getValue('enrolled-users', proxData) || 0;
        const notEnrolled = +(getValue('not-enrolled-users', proxData) || 0);
        const percentEnrollment = Math.round((+enrolled / +total) * 100);

        enrollData = {
          ...enrollData,
          prox: {
            percentEnrollment,
            notEnrolled,
          },
        };
      }

      if (iidData) {
        const total = getValue('total-users', iidData) || 1;
        const enrolled = getValue('enrolled-users', iidData) || 0;
        const notEnrolled = +(getValue('not-enrolled-users', iidData) || 0);
        const percentEnrollment = Math.round((+enrolled / +total) * 100);

        enrollData = {
          ...enrollData,
          iid: {
            percentEnrollment,
            notEnrolled,
          },
        };
      }
    }
  }
  return enrollData;
};

export interface EnrollmentData {
  prox?: {
    percentEnrollment: number;
    notEnrolled: number;
  };
  iid?: {
    percentEnrollment: number;
    notEnrolled: number;
  };
}

export interface EnrollmentsProps {
  data?: DashboardData[DashboardDataType.ENROLLED_METRICS];
}

const Enrollments = ({ data, ...rest }: EnrollmentsProps & CardProps) => {
  // data for enrollments
  const enrollmentData: EnrollmentData | undefined = useMemo(
    () => configureData(data),
    [data],
  );

  return (
    <Card {...rest}>
      <div
        className="flex flex-column gap-4"
        data-testid="enrollments-container"
      >
        {/* Prox Card Metrics */}
        <div className="flex flex-row gap-3">
          <div>
            <img
              alt="Prox"
              src={badgeImg}
              data-testid="badgeImage"
              width={32}
              height={32}
            />
          </div>
          <div className="flex-auto">
            <div>
              {/* Horizontal bar guage */}
              <BarGuage
                percentEnrollment={enrollmentData?.prox?.percentEnrollment || 0}
                label={`${
                  enrollmentData?.prox?.percentEnrollment ?? 0
                }% users enrolled`}
              />
            </div>
            <div className={classes.metricsDataSmall}>
              <span>{enrollmentData?.prox?.notEnrolled ?? 0}</span>
              users not enrolled
            </div>
          </div>
        </div>

        {/* IID Metrics */}
        <div className="flex flex-row gap-3">
          <div>
            <img
              alt="IID"
              src={iidImage}
              data-testid="iidImage"
              width={32}
              height={32}
            />
          </div>
          <div className="flex-auto">
            <div>
              {/* Horizontal bar guage */}
              <BarGuage
                percentEnrollment={enrollmentData?.iid?.percentEnrollment || 0}
                label={`${
                  enrollmentData?.iid?.percentEnrollment || 0
                }% users enrolled`}
              />
            </div>
            <div className={classes.metricsDataSmall}>
              <span>{enrollmentData?.iid?.notEnrolled || 0}</span>
              users not enrolled
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Enrollments;
