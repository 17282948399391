// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FormEvent } from 'react';

export function copyToClipboard(e: FormEvent, content: string): void {
  e.preventDefault();
  // noinspection JSIgnoredPromiseFromCall
  void navigator.clipboard.writeText(content);
}

export function getTenantId() {
  return new URLSearchParams(window.location.search).get('tenantId');
}
