// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Observable } from 'rxjs';
import { type SortField, SortOrder } from '../../shared/types/list';
import { EndpointsSortValueEnum } from './types';

export enum EndpointFieldNames {
  HOSTNAME = 'hostname',
  VERSION = 'version',
  CREATED_AT = 'created_at',
}

export const createEndpointsViewportObserver = (
  element: Element,
): Observable<IntersectionObserverEntry[]> =>
  new Observable<IntersectionObserverEntry[]>(subscriber => {
    const intersectionObserver = new IntersectionObserver(
      entries => {
        subscriber.next(entries);
      },
      {
        root: null,
      },
    );

    intersectionObserver.observe(element);
  });

export const getSortOptions = (
  sortValue: EndpointsSortValueEnum,
): SortField<EndpointFieldNames> => {
  let sorter = { field: EndpointFieldNames.HOSTNAME, order: SortOrder.ASC };

  switch (sortValue) {
    case EndpointsSortValueEnum.ALL_BY_NAME:
      sorter = { field: EndpointFieldNames.HOSTNAME, order: SortOrder.ASC };
      break;
    case EndpointsSortValueEnum.MOST_UP_TO_DATE_BY_VERSION:
      sorter = { field: EndpointFieldNames.VERSION, order: SortOrder.DESC };
      break;
    case EndpointsSortValueEnum.LEAST_UP_TO_DATE_BY_VERSION:
      sorter = { field: EndpointFieldNames.VERSION, order: SortOrder.ASC };
      break;
    case EndpointsSortValueEnum.MOST_RECENTLY_INSTALLED_BY_INSTALL_DATE:
      sorter = { field: EndpointFieldNames.CREATED_AT, order: SortOrder.DESC };
      break;
    case EndpointsSortValueEnum.OLDEST_INSTALLED_BY_INSTALL_DATE:
      sorter = {
        field: EndpointFieldNames.CREATED_AT,
        order: SortOrder.ASC,
      };
      break;
  }

  return sorter;
};
