/* eslint-disable @typescript-eslint/no-confusing-void-expression */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { merge } from 'rxjs';
import { Banner, destroyBanners } from '@imprivata-cloud/components';
import { groupsStateSelector } from './selectors';
import { clearGroupsErrorsAction } from './actions';
import { groupForSyncMark$ } from '../../../api/groupsService';
import { type Group } from '../../../shared/types';
import { chooseAdminGroups$ } from '../../../api/adminInitialSetupService';
import { type AdminGroup } from '../../../api/types';

export const useGroupUpdate = ({
  onSubmitSuccess,
  onSubmitError,
}: {
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
}): {
  onSubmit: ({
    selectedGroups,
    isAdminGroups,
    isSetupMode,
  }: {
    selectedGroups: Group[];
    isAdminGroups: boolean;
    isSetupMode?: boolean;
    directoryId: string;
  }) => void;
  loading: boolean;
} => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error, loading } = useSelector(groupsStateSelector);

  const onSubmit = ({
    selectedGroups,
    isAdminGroups,
    isSetupMode,
    directoryId,
  }: {
    selectedGroups: Group[];
    isAdminGroups: boolean;
    isSetupMode?: boolean;
    directoryId: string;
  }) => {
    const merged$ = isSetupMode
      ? chooseAdminGroups$({
          identityProviderId: directoryId,
          groups: selectedGroups.map(grp => {
            const adminGroup: AdminGroup = {
              groupId: grp.groupId,
              userCount: grp.userCount,
              hash: grp.hash,
            };
            return adminGroup;
          }),
        })
      : merge(
          ...selectedGroups.map(selectedGroup => {
            return groupForSyncMark$({
              groupId: selectedGroup.groupId,
              isAdmin: isAdminGroups,
            });
          }),
        );

    merged$.subscribe({
      complete: () => onSubmitSuccess && onSubmitSuccess(),
      error: () => onSubmitError && onSubmitError(),
    });
  };

  useEffect(() => {
    const clearError = () => dispatch(clearGroupsErrorsAction(!error));
    const destroy = () => {
      destroyBanners();
    };
    if (error) {
      Banner({
        type: 'warning',
        message: t(error as never),
        duration: 10,
        onClose: clearError,
        onClick: clearError,
        datatestid: 'add-group-failed-message',
      });
    } else {
      destroy();
    }
  }, [error, dispatch, t]);

  return { onSubmit, loading };
};
