// Copyright 2024, Imprivata, Inc.  All rights reserved.

export interface AadConfigurationDataGetResponse {
  issuerUri: string;
  passiveRedirectUrl: string;
  activeUrl: string;
  metadataExchangeUrl: string;
  logoffUrl: string;
  idpCertificate: string;
}

export interface IdpMetadataGetResponse {
  entraIdDisplayName: string;
  metadataList: IdpMetadata[];
  incomplete: boolean;
}

export interface IdpMetadataSaveRequest {
  entraIdDisplayName: string;
  metadataList: IdpMetadata[];
}

export interface IdpMetadata {
  metadataName: IdpName;
  protocol: IdpProtocol;
  metadataInfo: string; // base64 encoded IdpMetadataInfo
  priority: number;
  metadataUrl?: string;
  // user rule is of two forms:
  // when fetching: comma separated group ids
  // when saving: base64 encoded UserRule
  userRule?: string;
}

export interface OneSignIdpMetadataInfo extends IdpMetadataInfoBase {
  active_url: string;
}

export interface AdfsIdpMetadataInfo extends IdpMetadataInfoBase {
  active_url_1_2: string;
  active_url_1_3: string;
}

export interface IdpMetadataInfoBase {
  issuer_uri: string;
  passive_redirect_url: string;
  metadata_exchange_url: string;
  logoff_url: string;
  idp_certificate: string;
}

export interface UserRule {
  type: UserRuleType;
  value: string;
}

export enum UserRuleType {
  STRING_LIST = 'stringList',
}

export enum IdpName {
  'EAMC' = 'EAMC',
  'OneSign' = 'OneSign',
  'ADFS' = 'ADFS',
}

export enum IdpProtocol {
  'SAML' = 'SAML',
  'WS_FED' = 'WS_FED',
}
