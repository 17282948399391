import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SyncOutlined from '@ant-design/icons/lib/icons/SyncOutlined';
import {
  Banner,
  Button,
  ButtonVariant,
  ImprTooltip,
} from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';

import {
  DATE_TIME_FORMAT_OFFSET,
  formatDateForAstra,
  getLocalDateTime,
} from '../../utils/DateUtils';
import { type Directory, DirectorySyncStatusCode } from '../../api/types';
import { errorMapping } from '../../utils/BannerConstants';
import WarningIcon from '../../assets/svg/caution.svg?react';
import { useSyncDirectories } from './store/hooks';
import ProgressText from './ProgressText';
import SyncFailDetailsModal from '../modals/directories-modal/SyncFailDetailsModal';
import classes from './DirectorySync.module.less';
import { syncActions } from './store/actions';

export interface DirectorySyncProps {
  directory?: Directory;
  error?: string;
}

export const DirectorySync = ({
  directory,
  error: parentError,
}: DirectorySyncProps) => {
  const directoryId =
    (directory && directory.syncedGroupCount && directory.directoryId) ||
    undefined;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [isSyncFailDetailsModalOpen, setIsSyncFailDetailsModalOpen] =
    useState(false);

  const {
    state: syncState,
    busy,
    doDirectorySync,
  } = useSyncDirectories({
    directoryId,
  });

  const { error, loading, progress, failures } = syncState || {};

  const lastSync = directoryId && progress ? progress.latestSyncAt : '';
  const warningsCount = (directoryId && failures?.info?.length) || 0;

  const syncError = parentError || error;

  /**
   * Error and banners
   */
  useEffect(() => {
    if (syncError) {
      const primaryMsg = i18n.exists(
        `directories.error-messages.${syncError}-primary`,
      )
        ? t(`directories.error-messages.${syncError}-primary` as never)
        : t(`directories.error-messages.${syncError}` as never);
      const secondaryMsg = i18n.exists(
        `directories.error-messages.${syncError}-secondary`,
      )
        ? t(`directories.error-messages.${syncError}-secondary` as never)
        : undefined;
      const errorType = errorMapping[syncError as never];
      Banner({
        type: errorType ? errorType : 'error',
        message: primaryMsg,
        description: secondaryMsg,
        duration: 10,
        datatestid: 'directories-error-message',
      });

      // Clear state so that the banner does not show up again
      dispatch(syncActions.error(''));
    }
  }, [syncError, t, i18n, dispatch]);

  return (
    <div className={classes.syncContainer}>
      <Button
        label={t('actions.sync-directories')}
        variant={ButtonVariant.TEXT}
        data-testid="sync-directories-btn"
        icon={<SyncOutlined />}
        onClick={doDirectorySync}
        disabled={!directoryId || loading || busy}
      />

      {/* Last sync */}
      {lastSync &&
        ((
          <span className={classes.lastSync}>
            {t('directories.updated', {
              value:
                formatDateForAstra(
                  getLocalDateTime(lastSync, DATE_TIME_FORMAT_OFFSET),
                ) || '',
            })}
          </span>
        ) || <></>)}

      {/* Warnings */}
      {(warningsCount > 0 && (
        <button
          className={classes.warningsCount}
          onClick={() => {
            setIsSyncFailDetailsModalOpen(true);
          }}
        >
          <ImprTooltip
            title={t('directories.failed-syncs', {
              value: warningsCount,
            })}
            color="#333"
          >
            <WarningIcon />
          </ImprTooltip>
        </button>
      )) || <></>}

      {/* Progress info */}
      {(progress?.info &&
        progress?.syncStatus === DirectorySyncStatusCode.RUNNING && (
          <ProgressText
            totalSteps={progress.info.totalSteps || 1}
            steps={progress.info.totalStepsProcessed || 1}
          />
        )) || <></>}

      {/* Modal for failures */}
      <SyncFailDetailsModal
        failures={failures}
        open={isSyncFailDetailsModalOpen}
        onClose={() => {
          setIsSyncFailDetailsModalOpen(false);
        }}
      />
    </div>
  );
};

export default DirectorySync;
