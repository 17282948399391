// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Col, Row } from 'antd';
import React, { type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import NoEndpointsIcon from '../../assets/svg/empty-state.svg?react';
import classes from './Endpoints.module.less';

const NoInstalledEndpoints: React.FC<{
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setModalOpen }) => {
  const { t } = useTranslation();
  return (
    <Row justify="center" align="stretch">
      <Col className={classes.noInstalledEndpoints}>
        <NoEndpointsIcon />
        <p data-testid="need-to-install-endpoint">
          {t('endpoints.need-to-install-endpoint')}
          <button
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {t('endpoints.need-to-install-endpoint2')}
          </button>{' '}
          {t('endpoints.need-to-install-endpoint3')}
        </p>
      </Col>
    </Row>
  );
};

export default NoInstalledEndpoints;
