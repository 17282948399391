// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, type Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { type ApiError2 } from '../../../api/types';
import { getUsersList$ } from '../../../api/usersService';
import {
  findAuthenticatorsByUserIds$,
  unenrollAuthenticators$,
} from '../../../api/authenticatorsService';
import { ContextNames } from '../../../i18n';
import { getErrorMessageCode } from '../../../i18n/utils';
import { type RootAction } from '../../../store/rootAction';
import { type RootState } from '../../../store/rootReducer';
import {
  findAuthenticatorsByUserIds,
  getUsersList,
  getUsersListNextPortion,
  getUsersTotals,
  unenrollProxcards,
} from './actions';
import { getUserTotalCount, normalizeUsersList } from './schema';
import { type Authenticator } from './types';

const FIRST_PORTION_SIZE = 50;
const PORTION_SIZE = 25;

export const usersTotalsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(getUsersTotals.request)),
    switchMap(({ payload }) =>
      getUsersList$({
        selectors: payload,
      }).pipe(
        map(res => {
          return getUsersTotals.success(getUserTotalCount(res));
        }),
        catchError((err: ApiError2) =>
          of(
            getUsersTotals.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.USERS, err.code),
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isActionOf(getUsersTotals.cancel)))),
      ),
    ),
  );

export const findAuthenticatorsByUserIdsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(findAuthenticatorsByUserIds.request)),
    mergeMap(({ payload: userIds }) =>
      findAuthenticatorsByUserIds$({ userIds }).pipe(
        map(res => {
          const authenticators: Record<string, Authenticator[] | undefined> =
            {};
          if (res.authenticators) {
            res.authenticators.forEach(authenticator => {
              if (!authenticators[authenticator.userId]) {
                authenticators[authenticator.userId] = [authenticator];
              } else {
                authenticators[authenticator.userId]?.push(authenticator);
              }
            });
          }
          // add undefined properties to override values in the redux state
          userIds.forEach(id => {
            if (!authenticators[id]) {
              authenticators[id] = undefined;
            }
          });

          return findAuthenticatorsByUserIds.success(authenticators);
        }),
        catchError((err: ApiError2) =>
          of(
            findAuthenticatorsByUserIds.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.USERS, err.code),
            }),
          ),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(findAuthenticatorsByUserIds.cancel))),
        ),
      ),
    ),
  );

export const unenrollProxcardsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(unenrollProxcards.request)),
    switchMap(({ payload: authenticators }) =>
      unenrollAuthenticators$({
        authenticatorIds: authenticators.map(auth => auth.authenticatorId),
      }).pipe(
        switchMap(() => {
          return from([
            unenrollProxcards.success(),
            findAuthenticatorsByUserIds.request(
              authenticators.map(auth => auth.userId),
            ),
          ]);
        }),
        catchError((err: ApiError2) =>
          of(
            unenrollProxcards.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.USERS, err.code),
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isActionOf(unenrollProxcards.cancel)))),
      ),
    ),
  );

export const usersListEpic: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(getUsersList.request)),
    switchMap(({ payload }) =>
      getUsersList$({
        selectors: payload,
        pageSize: FIRST_PORTION_SIZE,
      }).pipe(
        switchMap(res => {
          const userIdsToFetchAuthenticators = res.users.map(
            user => user.userId,
          );
          return from([
            getUsersList.success(normalizeUsersList(res)),
            findAuthenticatorsByUserIds.request(userIdsToFetchAuthenticators),
          ]);
        }),
        catchError((err: ApiError2) =>
          of(
            getUsersList.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.USERS, err.code),
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isActionOf(getUsersList.cancel)))),
      ),
    ),
  );

export const usersListNextPortionEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getUsersListNextPortion.request)),
    switchMap(() => {
      const portionToken = state$.value.users.users.nextPortionToken;
      return getUsersList$({
        pageToken: portionToken,
        pageSize: PORTION_SIZE,
      }).pipe(
        switchMap(res => {
          const userIdsToFetchAuthenticators = res.users.flatMap(user =>
            user ? [user.userId] : [],
          );

          return from([
            getUsersListNextPortion.success(normalizeUsersList(res)),
            findAuthenticatorsByUserIds.request(userIdsToFetchAuthenticators),
          ]);
        }),
        catchError((err: ApiError2) =>
          of(
            getUsersListNextPortion.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.USERS, err.code),
            }),
          ),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(getUsersListNextPortion.cancel))),
        ),
      );
    }),
  );

export default combineEpics(
  usersTotalsEpic,
  usersListEpic,
  usersListNextPortionEpic,
  findAuthenticatorsByUserIdsEpic,
  unenrollProxcardsEpic,
);
