// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  type UserListFetchState,
  type UserListTotalState,
  type TotalsState,
  type AuthenticatorsListState,
} from './reducer';
import { type RootState } from '../../../store/rootReducer';
import { getUsersList, getUsersTotals } from './actions';
import { ApiUserFields, TagNames, UsersFilterValueEnum } from '../types';
import { usePersistantSessionState } from '../../../utils/hooks/usePersistantState';
import { transformSearchPattern } from '../utils';
import { UserFieldNames } from './constants';
import { SortOrder } from '../../../shared/types';

const USERS_FILTER_VALUE_STORAGE_KEY = 'users.users-filter-value';

export function useUserTotals(): UserListTotalState {
  const dispatch = useDispatch();
  const totals = useSelector<RootState, TotalsState>(
    ({ users }) => users.totals,
  );

  const fetchTotals = useCallback(() => {
    dispatch(
      getUsersTotals.request({
        sortFields: [{ field: UserFieldNames.DISPLAY_NAME }],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    fetchTotals();
    return () => {
      dispatch(getUsersTotals.cancel());
    };
  }, [dispatch, fetchTotals]);

  return {
    totals,
    fetchTotals,
  };
}

export function useUsersList(): UserListFetchState {
  const dispatch = useDispatch();
  const { loading, ids, entities, loadingNextPortion } = useSelector<
    RootState,
    Omit<UserListFetchState, 'fetchUsersList'>
  >(
    ({
      users: {
        users: { ids, entities, loadingNextPortion, loading },
      },
    }) => ({
      loading,
      ids,
      entities,
      loadingNextPortion,
    }),
  );

  const fetchUsersList = useCallback(() => {
    dispatch(
      getUsersList.request({
        sortFields: [{ field: UserFieldNames.DISPLAY_NAME }],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    fetchUsersList();

    return () => {
      dispatch(getUsersList.cancel());
    };
  }, [dispatch, fetchUsersList]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return { loading, ids, entities, loadingNextPortion, fetchUsersList };
}

export function useAuthenticatorsList(): AuthenticatorsListState {
  return useSelector<RootState, AuthenticatorsListState>(
    ({ users }) => users.authenticators,
  );
}

export const useUsersPageState = (): {
  filterValue: UsersFilterValueEnum;
  handleFilterChange: (value: UsersFilterValueEnum) => void;
  handleSearchChange: (pattern: string) => void;
  handleTagClick: (tagName: TagNames) => void;
} => {
  const dispatch = useDispatch();

  const [filterValue, setFilterValue] =
    usePersistantSessionState<UsersFilterValueEnum>(
      USERS_FILTER_VALUE_STORAGE_KEY,
      UsersFilterValueEnum.ALL_BY_NAME,
    );

  const [searchPattern, setSearchPattern] = useState<string>('');

  const handleFilterChange = (value: UsersFilterValueEnum) => {
    setFilterValue(value);
  };

  const handleSearchChange = (value: string) => {
    setSearchPattern(value);
  };

  const handleTagClick = useCallback(
    (tagName: TagNames) => {
      // change to switch statement when there are more tag kinds
      if (tagName === TagNames.ADMIN) {
        setFilterValue(UsersFilterValueEnum.ADMINISTRATORS_BY_NAME);
      }
    },
    [setFilterValue],
  );

  useEffect(() => {
    const pattern = transformSearchPattern(searchPattern);
    const filters = [];

    if (filterValue === UsersFilterValueEnum.ADMINISTRATORS_BY_NAME) {
      filters.push({
        field: ApiUserFields.ROLE_TYPE,
        value: 'admin',
      });
    }

    dispatch(
      getUsersList.request({
        search: {
          pattern,
          fields: [UserFieldNames.DISPLAY_NAME, UserFieldNames.UPN],
        },
        filters,
        sortFields: [
          { field: UserFieldNames.DISPLAY_NAME, order: SortOrder.ASC },
        ],
      }),
    );
  }, [searchPattern, filterValue, dispatch]);

  return {
    filterValue,
    handleFilterChange,
    handleSearchChange,
    handleTagClick,
  };
};
