// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrgPreferences } from './actions';
import { isLoadingSelector, dataSelector } from './selectors';

export const useFetchOrgPreferences = (): {
  data: ReturnType<typeof dataSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const data = useSelector(dataSelector);
  useEffect(() => {
    dispatch(fetchOrgPreferences());
  }, [dispatch]);

  return { data, isLoading: loading };
};
