// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, type Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { type ApiError2 } from '../../../api/types';
import { ContextNames } from '../../../i18n';
import { getErrorMessageCode } from '../../../i18n/utils';
import { type RootAction } from '../../../store/rootAction';
import { type RootState } from '../../../store/rootReducer';
import {
  getApiApplication,
  getAppConfig,
  getAppIntegration,
  updateAppConfig,
} from './actions';
import {
  getApiApplication$,
  getAppConfig$,
  getAppIntegration$,
  updateAppConfig$,
} from '../../../api/Applications/appsService';

export const appListEpic: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(getAppIntegration.request)),
    switchMap(() => {
      return getAppIntegration$().pipe(
        map(res => {
          return getAppIntegration.success([res.appIntegration]);
        }),
        catchError((err: ApiError2) =>
          of(
            getAppIntegration.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.APPS, err.code),
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isActionOf(getAppIntegration.cancel)))),
      );
    }),
  );

export const applicationsListEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(getApiApplication.request)),
    switchMap(() => {
      return getApiApplication$().pipe(
        map(res => {
          return getApiApplication.success(res.applications);
        }),
        catchError((err: ApiError2) =>
          of(
            getApiApplication.failure({
              ...err,
              code: getErrorMessageCode(ContextNames.APPS, err.code),
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isActionOf(getApiApplication.cancel)))),
      );
    }),
  );

export const appConfigGetEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(getAppConfig.request)),
    switchMap(() => {
      return getAppConfig$().pipe(
        map(res => getAppConfig.success(res)),
        catchError((err: ApiError2) => of(getAppConfig.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(getAppConfig.cancel)))),
      );
    }),
  );

export const appConfigUpdateEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(updateAppConfig.request)),
    switchMap(({ payload }) => {
      return updateAppConfig$(payload).pipe(
        switchMap(() => {
          return from([
            updateAppConfig.success(),
            // to update app list
            getAppConfig.request(),
            getApiApplication.request(),
          ]);
        }),
        catchError((err: ApiError2) => of(updateAppConfig.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(updateAppConfig.cancel)))),
      );
    }),
  );

export default combineEpics(
  appListEpic,
  applicationsListEpic,
  appConfigGetEpic,
  appConfigUpdateEpic,
);
