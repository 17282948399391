import { AUTHN_MODULE_APP_VERSION } from '@imprivata-cloud/authn';
import { COMMON_MODULE_APP_VERSION } from '@imprivata-cloud/common';

export const getBuildInfo = () => {
  return `(admin=${IMPR_APP_VERSION}) (authn=${AUTHN_MODULE_APP_VERSION}) (common=${COMMON_MODULE_APP_VERSION})`;
};

export const getBaseUrl = () => import.meta.env.BASE_URL;

export const getCurrentEnv = () => {
  const url = window.location.host;
  const urlRegex = /\w+\.\w+\.(\w+)\.\w+\.(\w+)/gim;
  const matches = urlRegex.exec(url);

  let currentEnv = 'prod';

  if (url?.includes('localhost')) {
    currentEnv = 'dev';
  } else if (Array.isArray(matches) && matches.length > 0) {
    matches.shift();
    const [appEnv, tld] = matches;
    console.debug(appEnv, tld);
    currentEnv = appEnv;
  }

  return currentEnv;
};
