// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Button, ButtonVariant } from '@imprivata-cloud/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { type ActionButtonProps } from './types';
import gearIcon from '../../../assets/svg/menu-settings.svg?react';
import { settingsRoutes } from '../../../routers/route-names';
import { redirectWithQuery } from '../../../utils/routingHelpers';

const EndpointSettingsLink: React.FC<ActionButtonProps> =
  function EndpointSettingsLink({ className, disabled }) {
    const { t } = useTranslation();

    return (
      <Button
        label={t('actions.agent-settings')}
        variant={ButtonVariant.TEXT}
        data-testid="agent-settings-link"
        icon={<Icon component={gearIcon} />}
        className={className}
        onClick={() => {
          redirectWithQuery(settingsRoutes.AGENT);
        }}
        disabled={disabled}
      />
    );
  };

export default EndpointSettingsLink;
