// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FC, useState } from 'react';
import { Row, Col, Typography, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Select } from '@imprivata-cloud/components';
import PageLayout from '../../components/page-layout/PageLayout';
import PageSubHeader from '../../components/page-layout/PageSubHeader';
import HelpButton from '../../components/page-layout/action-bar/HelpButton';
import classes from './Security.module.less';
import { useGetDesktopGracePeriod, useGetGracePeriod } from './store/hooks';
import SaveButton from '../../components/page-layout/action-bar/SaveButton';
import CancelButton from '../../components/page-layout/action-bar/CancelButton';
import { updateGracePeriod } from '../../api/policyService';
import { contextTypes } from '../../api/constants';
import SetTitle from '../../utils/DynamicTitleHelper';
import SaveDiscardModal from '../modals/save-discard-modal/SaveDiscardModal';
import {
  loadDesktopGracePeriodSuccess,
  loadGracePeriodSuccess,
} from './store/actions';
import {
  SUCCESS_DESKTOP_GRACE_PERIOD,
  SUCCESS_GRACE_PERIOD,
} from './store/constants';
import { type GracePeriodUpdateRequest } from '../../shared/types/api/authn-policy-v2';

const { Title } = Typography;

const FOUR_AND_A_HALF: number = 3600 * 4.5;
const DEFAULT_GRACE_PERIOD = FOUR_AND_A_HALF;
const SIX_AND_A_HALF: number = 3600 * 6.5;
const NINE: number = 3600 * 9;
const ELEVEN: number = 3600 * 11;
const THIRTEEN: number = 3600 * 13;

const SecurityContainer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useGetGracePeriod();
  const { desktopData } = useGetDesktopGracePeriod();
  const gracePeriod = data ? data.gracePeriodSecs : DEFAULT_GRACE_PERIOD;

  SetTitle(t('navigation.security'));
  const adminDropdownKey: string =
    'security.admin-center.control-details-2' as const;
  const selfServiceDropdownKey =
    'security.self-service.control-details-2' as const;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [gracePeriodSelection, setGracePeriodSelection] = useState<number>();

  type ContextType = (typeof contextTypes)[keyof typeof contextTypes];

  const updatePolicyDetails = async () => {
    const createGracePeriodUpdateRequest = (
      contextType: ContextType,
    ): GracePeriodUpdateRequest => ({
      gracePeriodSecs: gracePeriodSelection || gracePeriod,
      contextType,
    });

    const gracePeriodUpdateRequest = createGracePeriodUpdateRequest(
      contextTypes.clinical,
    );

    const desktopGracePeriodUpdateRequest = createGracePeriodUpdateRequest(
      contextTypes.desktop,
    );

    await Promise.all([
      updateGracePeriod(gracePeriodUpdateRequest),
      updateGracePeriod(desktopGracePeriodUpdateRequest),
    ]);

    const gracePeriodData = {
      gracePeriodSecs: gracePeriodUpdateRequest.gracePeriodSecs,
      contextType: data.contextType,
    };

    const desktopGracePeriodData = {
      gracePeriodSecs: desktopGracePeriodUpdateRequest.gracePeriodSecs,
      contextType: desktopData.contextType,
    };

    dispatch(
      loadGracePeriodSuccess({
        data: gracePeriodData,
        operations: SUCCESS_GRACE_PERIOD,
      }),
    );

    dispatch(
      loadDesktopGracePeriodSuccess({
        data: desktopGracePeriodData,
        operations: SUCCESS_DESKTOP_GRACE_PERIOD,
      }),
    );

    setIsDirty(false);
  };

  const resetGracePeriodSelection = () => {
    setGracePeriodSelection(gracePeriod);
    setIsDirty(false);
  };

  const onGracePeriodSelectionChange = (val: number) => {
    setGracePeriodSelection(val);
    setIsDirty(true);
  };

  return (
    <PageLayout title={t('navigation.security')}>
      <PageSubHeader
        title={
          <>
            <SaveButton
              data-testid="save-button"
              onClick={async () => updatePolicyDetails()}
              disabled={!isDirty}
            />
            <CancelButton
              data-testid="cancel-button"
              onClick={() => {
                resetGracePeriodSelection();
              }}
              disabled={!isDirty}
            />
          </>
        }
        extra={
          <>
            <HelpButton />
          </>
        }
      />
      <SaveDiscardModal
        title={t('appearance.organization.save-discard-modal.title')}
        cancelText={t('appearance.organization.save-discard-modal.discard')}
        okText={t('actions.save')}
        content={t('appearance.organization.save-discard-modal.content')}
        open={isDirty}
        onClose={() => {
          resetGracePeriodSelection();
        }}
        onSave={async () => updatePolicyDetails()}
        onDiscard={() => {
          resetGracePeriodSelection();
        }}
      />
      <div className={classes.securitySection}>
        <Row className={classes.paddedRow}>
          <Col span={8}>
            <div className={`${classes.h3Header} h3-header`}>
              {t('security.desktop-access.title')}
            </div>
            <p className="primary-body">
              {t('security.desktop-access.details')}
            </p>
            <p className="primary-body">
              {t('security.desktop-access.details-2')}
            </p>
          </Col>
          <Col span={12}>
            <p className="primary-body">
              {t('security.desktop-access.control-details')}
            </p>
            <Input.Group className="secondary-body" size="large">
              <Select
                data-testid="desktop-access-grace-period-selection"
                onChange={(val: number) => {
                  onGracePeriodSelectionChange(val);
                }}
                value={gracePeriodSelection || gracePeriod}
                defaultValue={gracePeriod}
                className={classes.securityDropdown}
                optionLabelProp="label"
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
                placeholder={t('security.grace-period.default')}
                options={[
                  {
                    label: t('security.grace-period.430'),
                    value: FOUR_AND_A_HALF,
                    ['data-testid']: 'desktop-access-grace-period-4.5-option',
                  },
                  {
                    label: t('security.grace-period.630'),
                    value: SIX_AND_A_HALF,
                    ['data-testid']: 'desktop-access-grace-period-6.5-option',
                  },
                  {
                    label: t('security.grace-period.9'),
                    value: NINE,
                    ['data-testid']: 'desktop-access-grace-period-9-option',
                  },
                  {
                    label: t('security.grace-period.11'),
                    value: ELEVEN,
                    ['data-testid']: 'desktop-access-grace-period-11-option',
                  },
                  {
                    label: t('security.grace-period.13'),
                    value: THIRTEEN,
                    ['data-testid']: 'desktop-access-grace-period-13-option',
                  },
                ]}
                majorStyle
              />
            </Input.Group>
          </Col>

          <Col span={1} />
          <Col span={10}>
            <Title level={5}>
              {t('security.grace-period.why-durations.title')}
            </Title>
            <p>{t('security.grace-period.why-durations.description')}</p>
          </Col>
        </Row>

        <Row className={classes.paddedRow}>
          <Col span={8}>
            <div className={`${classes.h3Header} h3-header`}>
              {t('security.clinical-app-access.title')}
            </div>
            <p className="primary-body">
              {t('security.clinical-app-access.details')}
            </p>
            <p className="primary-body">
              {t('security.clinical-app-access.details-2')}
            </p>
          </Col>
          <Col span={12}>
            <p className="primary-body">
              {t('security.clinical-app-access.control-details')}
            </p>
            <Input.Group className="secondary-body" size="large">
              <Select
                data-testid="grace-period-selection"
                onChange={(val: number) => {
                  onGracePeriodSelectionChange(val);
                }}
                value={gracePeriodSelection || gracePeriod}
                defaultValue={gracePeriod}
                className={classes.securityDropdown}
                optionLabelProp="label"
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
                placeholder={t('security.grace-period.default')}
                options={[
                  {
                    label: t('security.grace-period.430'),
                    value: FOUR_AND_A_HALF,
                    ['data-testid']: 'grace-period-4.5-option',
                  },
                  {
                    label: t('security.grace-period.630'),
                    value: SIX_AND_A_HALF,
                    ['data-testid']: 'grace-period-6.5-option',
                  },
                  {
                    label: t('security.grace-period.9'),
                    value: NINE,
                    ['data-testid']: 'grace-period-9-option',
                  },
                  {
                    label: t('security.grace-period.11'),
                    value: ELEVEN,
                    ['data-testid']: 'grace-period-11-option',
                  },
                  {
                    label: t('security.grace-period.13'),
                    value: THIRTEEN,
                    ['data-testid']: 'grace-period-13-option',
                  },
                ]}
                majorStyle
              />
            </Input.Group>
          </Col>
          <Col span={1} />
          <Col span={10}>
            <Title level={5}>
              {t('security.grace-period.why-durations.title')}
            </Title>
            <p>{t('security.grace-period.why-durations.description')}</p>
          </Col>
        </Row>

        <Row className={classes.paddedRow}>
          <Col span={8}>
            <div className={`${classes.h3Header} h3-header`}>
              {t('security.order-signing.title')}
            </div>
            <p className="primary-body">
              {t('security.order-signing.details')}
            </p>
          </Col>
          <Col span={12}>
            <p className="primary-body">
              {t('security.order-signing.control-details')}
            </p>
          </Col>
        </Row>
        <Row className={classes.paddedRow}>
          <Col span={8}>
            <div className={`${classes.h3Header} h3-header`}>
              {t('security.attestation.title')}
            </div>
            <p className="primary-body">{t('security.attestation.details')}</p>
          </Col>
          <Col span={12} />
        </Row>
        <Row className={classes.paddedRow}>
          <Col span={8}>
            <div className={`${classes.h3Header} h3-header`}>
              {t('security.self-service.title')}
            </div>
            <p className="primary-body">{t('security.self-service.details')}</p>
            <p className="primary-body">
              {t('security.self-service.details-2')}
            </p>
          </Col>
          <Col span={12}>
            <p className="primary-body">
              {t('security.self-service.control-details')}
            </p>
            <Input.Group className="secondary-body" size="large">
              <Select
                data-testid="self-service-dropdown"
                value={t(selfServiceDropdownKey)}
                defaultValue={t(selfServiceDropdownKey)}
                className={classes.largerDropdown}
                disabled={true}
                placeholder={t(selfServiceDropdownKey)}
                majorStyle={true}
              ></Select>
            </Input.Group>
          </Col>
        </Row>
        <Row className={classes.paddedRow}>
          <Col span={8}>
            <div className={`${classes.h3Header} h3-header`}>
              {t('security.admin-center.title')}
            </div>
            <p className="primary-body">{t('security.admin-center.details')}</p>
            <p className="primary-body">
              {t('security.admin-center.details-2')}
            </p>
          </Col>
          <Col span={12}>
            <p className="primary-body">
              {t('security.admin-center.control-details')}
            </p>
            <Input.Group className="secondary-body" size="large">
              <Select
                data-testid="admin-center-dropdown"
                value={t(adminDropdownKey)}
                defaultValue={t(adminDropdownKey)}
                className={classes.largerDropdown}
                disabled={true}
                placeholder={t(adminDropdownKey)}
                majorStyle={true}
              ></Select>
            </Input.Group>
          </Col>
        </Row>
        <Row className={classes.paddedRow}>
          <Col span={8}>
            <div className={`${classes.h3Header} h3-header`}>
              {t('security.web-app-login.header')}
            </div>
            <p className="primary-body">
              {t('security.web-app-login.subheader')}
            </p>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
};

export default SecurityContainer;
