// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import { type Observable } from 'rxjs';
import { directories } from './endpoint-names';
import {
  type DirectoriesFindPayload,
  type DirectoriesSyncFailedPayload,
  type DirectorySyncProgressPayload,
  type DirectorySyncGetRequest,
  type DirectoryFailedSyncRequest,
  type DirectorySyncProgressRequest,
} from './types';
import rxClient from './rxClient';
import { client } from './client';

export async function syncDirectories({
  directoryIds,
}: DirectorySyncGetRequest): Promise<void> {
  const res = await client.post<void>(directories.SYNC_DIRECTORIES, {
    directoryIds,
  });
  return res.data;
}

export const findDirectories = async (): Promise<DirectoriesFindPayload> => {
  const res = await client.post<DirectoriesFindPayload>(
    directories.FIND_DIRECTORIES,
  );

  return res.data;
};

export function findDirectories$(): Observable<DirectoriesFindPayload> {
  return rxClient
    .post<DirectoriesFindPayload>(directories.FIND_DIRECTORIES)
    .pipe(map(res => res.data));
}

export const findFailedSyncDirectories = async ({
  directoryId,
}: DirectoryFailedSyncRequest): Promise<DirectoriesSyncFailedPayload> => {
  const res = await client.post<DirectoriesSyncFailedPayload>(
    directories.FIND_FAILED_SYNC_DIRECTORIES,
    { directoryId },
  );

  return res.data;
};

export const getDirectorySyncProgress = async ({
  directoryId,
}: DirectorySyncProgressRequest): Promise<DirectorySyncProgressPayload> => {
  const res = await client.post<DirectorySyncProgressPayload>(
    directories.GET_SYNC_PROGRESS_DIRECTORY,
    { directoryId },
  );

  return res.data;
};
