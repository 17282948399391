// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/page-layout/PageLayout';
import UsersList from './UsersList';
import { useUsersPageState } from './store/hooks';
import UsersFilters from './UsersFilters';
import SetTitle from '../../utils/DynamicTitleHelper';
import { useFindDirectories } from '../directories/store/hooks';
import DirectorySync from '../directories/DirectorySync';
import PageSubHeader from '../../components/page-layout/PageSubHeader';

const UsersContainer: FC = () => {
  const { t } = useTranslation();
  SetTitle(t('navigation.users'));

  const {
    filterValue,
    handleFilterChange,
    handleSearchChange,
    handleTagClick,
  } = useUsersPageState();

  const directories = useFindDirectories();

  return (
    <PageLayout title={t('navigation.users')}>
      <PageSubHeader
        title={
          <DirectorySync
            directory={(directories?.length && directories[0]) || undefined}
          />
        }
      />
      <UsersFilters
        value={filterValue}
        disabled={false}
        onSelect={handleFilterChange}
        onSearch={handleSearchChange}
      />
      <UsersList onTagClick={handleTagClick} />
    </PageLayout>
  );
};

export default UsersContainer;
