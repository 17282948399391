// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { fetchAppearanceEpic } from '../containers/settings/appearance/store/epics';
import { loginEpic } from '../containers/login/store/epics';
import { initialSetupEpic } from '../containers/initial-setup/store/epics';
import usersEpic from '../containers/users/store/epics';
import appsEpic from '../containers/applications/store/epics';
import {
  endpointsEpics,
  installedDataEpic,
} from '../containers/endpoints/store/epics';
import { directoriesEpic } from '../containers/directories/store/epics';
import groupsEpic from '../containers/groups/store/epics';
import {
  getDesktopGracePeriodEpic,
  getGracePeriodEpic,
} from '../containers/security/store/epics';
import { agentKeyRegenerationEpic } from '../containers/settings/agent/store/epics';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const rootEpic = combineEpics(
  fetchAppearanceEpic,
  loginEpic,
  initialSetupEpic,
  usersEpic,
  appsEpic,
  endpointsEpics,
  installedDataEpic,
  agentKeyRegenerationEpic,
  getGracePeriodEpic,
  getDesktopGracePeriodEpic,
  directoriesEpic,
  groupsEpic,
);

export type RootEpic = ReturnType<typeof rootEpic>;

export default rootEpic;
