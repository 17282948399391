// Copyright 2022, Imprivata, Inc.  All rights reserved.

import clsx from 'clsx';
import './ListItem.less';

export interface ListItemProps {
  primaryText: string;
  secondaryText?: string;
  renderInMiddle?: JSX.Element;
  renderOnTheRight?: JSX.Element;
  // props that are passed foreword
  'data-testid'?: string;
  className?: string;
}

const ListItem: React.FC<ListItemProps> = props => {
  return (
    <div
      className={clsx('impr', 'list-item-container', props.className)}
      data-testid={props['data-testid'] || 'list-item'}
    >
      <div className="list-item-left">
        <div>
          <div
            data-testid="list-item-primary-text"
            className="list-item-primary-text"
          >
            {props.primaryText}
          </div>
          {props.secondaryText ? (
            <div
              data-testid="list-item-secondary-text"
              className="list-item-secondary-text"
            >
              {props.secondaryText}
            </div>
          ) : null}
        </div>
      </div>
      <div className="list-item-middle">
        {props.renderInMiddle ? props.renderInMiddle : null}
      </div>
      <div className="list-item-right">
        {props.renderOnTheRight ? props.renderOnTheRight : null}
      </div>
    </div>
  );
};

export default ListItem;
