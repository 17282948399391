// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type AxiosError, type InternalAxiosRequestConfig } from 'axios';
import { createContextPropagationInterceptor } from '@imprivata-cloud/common';
import { ADMIN_PREFIX, authn, authz } from './endpoint-names';
import { client } from './client';
import { ErrorCode, Headers, resourceType } from './constants';
import { type AppDispatch } from '../store/createStore';
import { invalidSessionAction } from '../containers/login/store/actions';
import { ApiErrorType, type ApiResponseData } from './types';
import { LOGIN_ROUTE } from '../routers/route-names';
import { tracer } from '../tracing';

export const authnResourceHeaderInterceptor = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  if (config.headers && config.url && config.url.startsWith(ADMIN_PREFIX)) {
    config.headers[Headers.ImprAuthnResource] = resourceType;
  }

  return config;
};

function dispatchInvalidSessionLogout(
  dispatch: AppDispatch,
  showBanner = true,
) {
  const location = window.location;
  dispatch(
    invalidSessionAction.request({
      errorMsgKey: showBanner ? 'login.logout-due-invalid-session' : '',
      storedURL:
        location.pathname !== LOGIN_ROUTE
          ? location.pathname + location.search
          : undefined,
    }),
  );
}

/**
 * Please note that when you just return,
 * there's no automatic exception throwing in Axios later -
 * you basically suppress the error and let the flow go on normally.
 * You must throw an error at some point only then axios call will re-throw.
 */
export function onRejectedInterceptor(dispatch: AppDispatch) {
  return (res: AxiosError<ApiResponseData>): void => {
    // 'Cancel: Unsubscribed from request observable' also come here and have no response
    const genericErrorToStopTheFlow = 'Generic error to stop the flow.';
    if (
      res.response?.status === 403 &&
      res.response?.data?.error?.code === ErrorCode.PERMISSION_DENIED
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      if (res?.request?.responseURL?.includes(authz.VALIDATE_PERMISSION)) {
        console.debug('[Interceptor:error] validate: ', res.response);

        throw res.response?.data?.error;
      }
      console.debug(
        '[Interceptor:error] 403/permission denied: ',
        res.response,
      );

      dispatchInvalidSessionLogout(dispatch, false);
      throw new Error(genericErrorToStopTheFlow);
    }

    const invalidSessionLogoutCodes = [
      ErrorCode.SESSION_EXPIRED,
      ErrorCode.INVALID_SESSION_ID,
    ];

    if (
      (res.response?.status === 401 ||
        invalidSessionLogoutCodes.includes(
          res.response?.data?.error?.code as ErrorCode,
        )) &&
      // Do not start logout if you are already calling logout
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      !res?.request?.responseURL?.includes(authz.LOGOUT)
    ) {
      console.debug('[Interceptor:error] 401/invalid session: ', res.response);
      dispatchInvalidSessionLogout(dispatch);
      throw new Error(genericErrorToStopTheFlow);
    }

    if (res.response?.status === 500) {
      if (
        res.response.config?.url === authn.CREATE_SESSION_V1 ||
        res.response.config?.url === authn.CREATE_SESSION_V2 ||
        res.response.config?.url === authn.AUTHENTICATE_V1 ||
        res.response.config?.url === authn.AUTHENTICATE_V2 ||
        res.response.config?.url === authn.GET_FACTOR_OPTIONS ||
        res.response.config?.url === authn.GET_NEXT_FACTORS
      ) {
        console.debug('[Interceptor:error] authn: ', res.response);

        dispatch(
          invalidSessionAction.request({
            storedURL: window.location.pathname + window.location.search,
            errorMsgKey: 'login.internal-error',
          }),
        );
        throw new Error(genericErrorToStopTheFlow);
      }
    }

    console.debug('[Interceptor:error] unknown: ', res.response);

    throw (
      res.response?.data?.error ?? {
        code: ErrorCode.FATAL, // has the most appropriate message
        type: ApiErrorType.APP,
      }
    );
  };
}

let requestInterceptors: number[] = [];
let responseInterceptors: number[] = [];

export const applyInterceptors = (dispatch: AppDispatch): void => {
  requestInterceptors.push(
    client.interceptors.request.use(
      createContextPropagationInterceptor(tracer),
    ),
  );

  responseInterceptors.push(
    client.interceptors.response.use(
      undefined,
      onRejectedInterceptor(dispatch),
    ),
  );
};

export const ejectInterceptors = (): void => {
  requestInterceptors.forEach(interceptor => {
    client.interceptors.request.eject(interceptor);
  });

  responseInterceptors.forEach(interceptor => {
    client.interceptors.response.eject(interceptor);
  });

  requestInterceptors = [];
  responseInterceptors = [];
};
