// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type { ScriptPattern, ScriptType } from './types';
import { ScriptPatterns } from './types';

type Match = {
  matches: number;
  scriptPattern: ScriptPattern;
};

export const getScriptType = (value?: string): ScriptType | undefined => {
  if (value) {
    const result = ScriptPatterns.map(scriptType =>
      match(value, scriptType),
    ).reduce((prev, current) =>
      prev.matches >= current.matches ? prev : current,
    );
    return result?.matches > 0 ? result?.scriptPattern?.scriptType : undefined;
  }
  return undefined;
};

const match = (value: string, scriptPattern: ScriptPattern): Match => {
  return {
    matches: value.match(scriptPattern.pattern)?.length || 0,
    scriptPattern,
  };
};
