// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { type Subscription } from 'rxjs';
import { List, Spin, ConfigProvider } from 'antd';
import { useDispatch, useStore } from 'react-redux';
import clsx from 'clsx';
import type { ListProps } from 'antd';

import { type AppState } from '../../store/createStore';
import { useUsersList, useAuthenticatorsList } from './store/hooks';
import { getUsersListNextPortion } from './store/actions';
import { createViewportObserver } from './utils';
import { type TagNames } from './types';
import UserItem from './UserItem';
import { useLearnedCredentials } from './hooks/useLearnedCredentials';
import { useSyncDirectories } from '../directories/store/hooks';
import classes from './UsersList.module.less';

export interface UsersListProps extends ListProps<string> {
  onTagClick: (tagName: TagNames) => void;
}

const UsersList = ({ onTagClick, ...rest }: UsersListProps) => {
  const { ids, entities, loading, loadingNextPortion, fetchUsersList } =
    useUsersList();
  const upnToIdMap = new Map<string, string>();
  const { t } = useTranslation();

  const { fetchCredentials, removeCredential } = useLearnedCredentials();
  const { busy, isSyncSuccess } = useSyncDirectories({});

  useEffect(() => {
    if (isSyncSuccess) {
      fetchUsersList();
    }
  }, [isSyncSuccess, fetchUsersList]);

  const customizeRenderEmpty = () => (
    <div
      data-testid="empty-users-list-component"
      style={{
        textAlign: 'center',
        color: '#000',
      }}
    >
      <div className='ant-divider ant-divider-horizontal role="separator"'></div>
      <p>{t('users.no-matching-users')}</p>
    </div>
  );

  for (const key in entities) {
    const value = entities[key];
    upnToIdMap.set(value.upn, value.userId);
  }

  const { entities: authenticators } = useAuthenticatorsList();

  const dispatch = useDispatch();
  const store = useStore<AppState>();
  const ref = useRef<HTMLDivElement>(null);

  const intersectionHandler = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const intersectionEvent = entries[0];
      if (intersectionEvent.isIntersecting) {
        const hasMore = store.getState().users.users.nextPortionToken;
        const isLoading = store.getState().users.users.loadingNextPortion;

        if (hasMore && !isLoading) {
          dispatch(getUsersListNextPortion.request());
        }
      }
    },
    [store, dispatch],
  );

  useEffect(() => {
    let subscription: Subscription | null = null;
    if (ref.current) {
      subscription = createViewportObserver(ref.current).subscribe(
        intersectionHandler,
      );
    }

    return () => subscription?.unsubscribe();
  }, [ref, intersectionHandler]);

  // This is temporary until the cadence between fetching data and starting the fetch is cleaner.
  // There is no reason to add so much indirection, which is causing performance issues and UI flickering.
  const data = useMemo(
    () => ({
      loading:
        loading && !ids?.length && !busy
          ? {
              // Horrible horrible css hack bc antd decided to hardcode a minimum height.
              wrapperClassName: classes.spinHack,
            }
          : false,
      dataSource: ids,
    }),
    [loading, ids, busy],
  );

  return (
    <section className="flex flex-column flex-1">
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <List
          data-testid="users-list-component"
          {...rest}
          className={clsx('flex', 'flex-1', 'flex-column', rest.className)}
          {...data}
          renderItem={(id, index) => (
            <UserItem
              index={index}
              user={entities[id]}
              authenticators={authenticators[upnToIdMap.get(id) || -1]}
              findCredentials={fetchCredentials}
              removeCredential={removeCredential}
              onTagClick={onTagClick}
            />
          )}
          loadMore={
            <div
              ref={ref}
              style={{
                padding: '1em',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loadingNextPortion && <Spin data-testid="next-portion-loader" />}
            </div>
          }
        />
      </ConfigProvider>
    </section>
  );
};

export default UsersList;
